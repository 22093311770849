import { Button } from 'antd';
import { EditTopChanger } from 'component/CompIndex';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCustomDateFormat } from 'utils/UtilsIndex';

const AvailableTimeSlot = ({ isModalOpen, setIsModalOpen, handleSave }) => {
  const { staffAvailabilityData } = useSelector((state) => state.Service);
  const [activeTab, setActiveTab] = useState({
    index: -1,
    value: '',
  });
  const handleActiveTab = (index, data) => {
    setActiveTab({ ...data, index });
  };
  const handleCloseModal = () => {
    setActiveTab({ ...activeTab, index: -1 });
    setIsModalOpen(false);
  };
  return (
    <div>
      {/* <EditTopChanger title="Available Time Slot" /> */}
      <div className="py-5 text-center text-xl font-semibold text-[#0C344E]">
        Available Slots
      </div>
      <div className="flex flex-wrap items-center justify-start gap-2 p-2">
        {staffAvailabilityData?.map((data, index) => (
          <div
            key={index}
            onClick={() => handleActiveTab(index, data)}
            className={`${
              activeTab?.index === index
                ? ' border-[#FE8B02]  bg-[#FE8B02] text-[white]'
                : 'border-[#FE8B02]  text-[#474747]'
            } w-[10rem] cursor-pointer rounded-md border p-2 text-center`}
          >
            {`${getCustomDateFormat(
              data?.startTime,
              'hh:mm A'
            )} - ${getCustomDateFormat(data?.endTime, 'hh:mm A')}`}
          </div>
        ))}
      </div>
      <div className="center border-t-[1px] border-t-[#D4D4D4] py-5">
        <Button className="w-40" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          disabled={activeTab?.index === -1}
          className="customButton ml-4 w-40"
          onClick={() => handleSave(activeTab)}
        >
          Select Slot
        </Button>
      </div>
    </div>
  );
};

export default AvailableTimeSlot;
