import { RightOutlined } from '@ant-design/icons';
import {
  DashboardOverviewReducer,
  ServiceOverViewReducer,
  UnitAnalyticsReducer,
  UserOverViewReducer,
  VisitorStatsReducer,
} from 'Redux/slices/DashboardSlice';
import { LeaseListingReducer } from 'Redux/slices/LeaseSlice';
import { Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { totalProperty, totalUnit, totalUser } from 'assets/AssetIndex';
import { DataTable } from 'component/CompIndex';
import PropertyTopCard from 'component/PropertyTopCard';
import { useEffect } from 'react';
import { default as Chart, default as ReactApexChart } from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { leaseColumns } from 'staticData/LeaseData';
import { columnsNew } from 'staticData/ServiceData';
import { getCustomDateFormat, getDateFormat } from 'utils/UtilsIndex';
import {
  donutData,
  serviceOverViewFunction,
  unitAnaData,
  visitorStatsFunction,
} from './DynamicData';

const OverView = ({ rows = [], startDate, endDate }) => {
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const {
    leaseListingData: { rows: leaseRow = [] },
  } = useSelector((state) => state.Lease);
  const {
    userOverviewData,
    unitAnalyticsData,
    serviceOverviewData,
    visitorStatsData,
  } = useSelector((state) => state.Dashboard);

  const { buildingAnalyticsData } = useSelector((state) => state.Dashboard);
  const dispatch = useDispatch();
  const propertyTopData = [
    {
      img: totalProperty,
      title: 'Total Properties',
      count: buildingAnalyticsData?.buildings?.total,
      lastContent: `Last Month: ${buildingAnalyticsData?.buildings?.previousMonth} Properties`,
    },
    {
      img: totalUnit,
      title: 'Total Units',
      count: buildingAnalyticsData?.flats?.total,
      lastContent: `Last Month: ${buildingAnalyticsData?.flats?.previousMonth} Units`,
    },
    {
      img: totalUser,
      title: 'Total Users',
      count: buildingAnalyticsData?.users?.total,
      lastContent: `Last Month: ${buildingAnalyticsData?.users?.previousMonth} Users`,
    },
  ];

  const data =
    rows?.length > 0
      ? rows?.map((cur, id) => {
          return {
            key: cur?.id,
            requestId: cur?.requestId,
            status: cur?.statusDetails?.status,
            createdOn: getDateFormat(cur?.createdAt),
            createdDate: cur?.createdAt,
            category: cur?.category.name_en,
            building: cur?.flat?.building?.name_en,
            unitNo: cur?.flat?.name_en,
            requestedFor: cur.isBuilding ? 'Public Area' : 'Unit',
          };
        })
      : [];
  const leaseData = leaseRow?.map((cur, id) => {
    return {
      key: cur?.id,
      contractId: cur?.leaseId,
      propertyName: cur?.building?.name_en,
      leaseTarget: cur?.leaseTarget,
      unitNo: cur?.name_en,
      flatUsage: cur?.flatUsage,
      endDate: getCustomDateFormat(cur?.endDate, 'YYYY/MM/DD'),
      timePeriod: cur?.timePeriod,
      amount: cur?.rentAmount,
      currency: cur?.currency,
      type: cur?.flatUsage,
      status: cur?.status,
    };
  });
  useEffect(() => {
    const params = {
      startDate,
      endDate,
      buildingId: selectedBuildingName,
    };
    const leaseParam = {
      page: 1,
      limit: 5,
      status: 'Expired',
      ...params,
    };
    dispatch(LeaseListingReducer(leaseParam));
    dispatch(DashboardOverviewReducer());
    if (params.startDate && params.endDate) {
      dispatch(UserOverViewReducer(params));
      dispatch(UnitAnalyticsReducer(params));
      dispatch(ServiceOverViewReducer(params));
      dispatch(VisitorStatsReducer(params));
    }
  }, [startDate, endDate, selectedBuildingName, dispatch]);

  return (
    <>
      <PropertyTopCard {...{ propertyTopData }} />
      <Row gutter={10} justify="space-between">
        <Col
          span={10}
          xs={{ span: 10 }}
          md={{ span: 10 }}
          className="shadowOnly"
        >
          <Title level={5}>User Overview</Title>
          <Chart
            options={donutData(userOverviewData).options}
            series={donutData(userOverviewData)?.series}
            type="donut"
            width="400"
          />
        </Col>
        <Col
          span={13}
          xs={{ span: 13 }}
          md={{ span: 13 }}
          className="shadowOnly"
        >
          <Title level={5}>Units Overview</Title>
          <ReactApexChart
            options={unitAnaData(unitAnalyticsData).options}
            series={unitAnaData(unitAnalyticsData).series}
            type="bar"
            height={250}
          />
        </Col>
      </Row>
      <Row gutter={10} justify="space-between" className="my-12">
        <Col
          span={12}
          xs={{ span: 12 }}
          md={{ span: 12 }}
          className="shadowOnly"
        >
          <div className="p-2">
            <div className="inputWidth">
              <Title level={5}>Service Requests</Title>
              <Link to="/admin/services?page=1" className="text-[#008ffb]">
                View All Requests <RightOutlined />
              </Link>
            </div>
            <div className="hiddenScroll h-[18rem] overflow-x-auto">
              <DataTable {...{ columns: columnsNew, data }} />
            </div>
          </div>
        </Col>
        <Col
          span={11}
          xs={{ span: 11 }}
          md={{ span: 11 }}
          className="shadowOnly flex flex-col justify-between"
        >
          <div className="flex items-center justify-between">
            <Title level={5}>Services Overview</Title>
            <div className="flex items-center gap-1 pr-4">
              <span className="font-semibold text-[#FE8B02]">
                {serviceOverViewFunction(serviceOverviewData)?.totalRequest}
              </span>
              <span>Total Requests</span>
            </div>
          </div>
          <ReactApexChart
            options={serviceOverViewFunction(serviceOverviewData)?.options}
            series={serviceOverViewFunction(serviceOverviewData)?.series}
            type="line"
            height={250}
          />
        </Col>
      </Row>
      <Row gutter={10} justify="space-between" className="my-12">
        <Col
          span={12}
          xs={{ span: 12 }}
          md={{ span: 12 }}
          className="shadowOnly"
        >
          <Title level={5}>Visitor Overview</Title>
          <ReactApexChart
            options={visitorStatsFunction(visitorStatsData).options}
            series={visitorStatsFunction(visitorStatsData).series}
            type="line"
            height={250}
          />
        </Col>
        <Col
          span={11}
          xs={{ span: 11 }}
          md={{ span: 11 }}
          className="shadowOnly"
        >
          <div className="p-2">
            <div className="inputWidth">
              <Title level={5}>Expiring Leases</Title>
              <Link
                to={'/admin/lease?page=1'}
                className="cursor-pointer text-[#008ffb]"
              >
                View All Leases <RightOutlined />
              </Link>
            </div>
            <div className="hiddenScroll h-[18rem] overflow-x-auto">
              <DataTable {...{ columns: leaseColumns, data: leaseData }} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OverView;
