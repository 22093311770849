import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addStaffData,
  deleteStaffData,
  editStaffAvailability,
  editStaffData,
  getCalendarData,
  getStaffData,
  getStaffListing,
} from 'Redux/services/property/Staff';
import { initialListingState, initialState } from '../InitialState';

export const StaffListingReducer = createAsyncThunk(
  'staffListing',
  async (data) => {
    const resp = await getStaffListing(data);
    return resp?.data;
  }
);

// add new staff
export const createStaffReducer = createAsyncThunk(
  'createStaff',
  async (payload) => {
    const { navigateTo, values } = payload;
    try {
      const resp = await addStaffData(values, navigateTo);
      return resp?.data
    } catch (error) {

    }
  }
);

// get staff data
export const getStaffDataReducer = createAsyncThunk(
  'getStaffData',
  async (id) => {
    try {
      const resp = await getStaffData(id);
      return resp?.data;
    } catch (error) { }
  }
);

//edit staff data
export const editStaffDataReducer = createAsyncThunk(
  'editStaffData',
  async (payload) => {
    const { navigateTo, values } = payload;
    try {
      const resp = await editStaffData(values, navigateTo);
      return resp;
    } catch (error) { }
  }
);

// delete staff data
export const deleteStaffDataReducer = createAsyncThunk(
  'deleteStaffData',
  async (staffId) => {
    try {
      const resp = await deleteStaffData(staffId);
      return resp;
    } catch (error) { }
  }
);

//get staff availability
export const StaffAvailabilityReducer = createAsyncThunk(
  'getStaffAvailability',
  async ({ userId, date }) => {
    try {
      const resp = await getCalendarData({ userId, date });
      return resp?.data;
    } catch (error) { }
  }
);

//change staff availability status
export const editStaffAvailabilityReducer = createAsyncThunk(
  'editStaffAvailability',
  async (payload) => {
    const resp = await editStaffAvailability(payload);
    return resp;
  }
)

const StaffSlice = createSlice({
  name: 'staffSlice',
  initialState,
  reducers: {},

  extraReducers: {
    [StaffListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [StaffListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.staffListingData = action?.payload?.data ?? initialListingState;
    },
    [StaffListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getStaffDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getStaffDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.staffDetailData = action?.payload?.data;
    },
    [getStaffDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [StaffAvailabilityReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [StaffAvailabilityReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.staffAvailabilityData = action?.payload?.data;
    },
    [StaffAvailabilityReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [editStaffDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editStaffDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editStaffDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [deleteStaffDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteStaffDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteStaffDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [createStaffReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [createStaffReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createStaffReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [editStaffAvailabilityReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editStaffAvailabilityReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editStaffAvailabilityReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export default StaffSlice.reducer;
