import { exportStaffData } from 'Redux/services/ExportCsv/Export';
import {
  StaffListingReducer,
  deleteStaffDataReducer,
} from 'Redux/slices/property/StaffSlice';
import Title from 'antd/es/typography/Title';
import {
  DeleteRequest,
  MixedHeadContent,
  ModalComp,
  TabComponent,
} from 'component/CompIndex';
import StaffFilter from 'component/filter/StaffFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  StaffColumnData,
  StaffItemData,
  StaffRowsData,
} from 'staticData/StaffData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const StaffDataListing = () => {
  const {
    staffListingData: { rows, count },
    isLoadAgain,
  } = useSelector((state) => state.Staff);
  const dispatch = useDispatch();
  const [staffId, setStaffId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParam] = useSearchParams();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [allFilters, setAllFilters] = useState({
    availability: '',
    department: '',
    designation: '',
  });
  const handleDeleteModalOpen = (staffId) => {
    setStaffId(staffId);
    setIsModalOpen(true);
  };
  const data = StaffRowsData(rows);
  const columns = StaffColumnData(handleDeleteModalOpen);
  const items = StaffItemData(
    columns,
    data,
    count,
    pageNo,
    setPageNo,
    setSearchParam
  );

  const handleClose = () => {
    setStaffId(null);
    setIsModalOpen(false);
  };
  const handleDeleteStaffData = () => {
    dispatch(deleteStaffDataReducer(staffId));
    handleClose();
  };
  const handleFilter = () => {
    toast('filter');
  };
  const handleExport = async () => {
    const params = {
      search,
      department: allFilters?.department,
      designation: allFilters?.designation,
    };
    return await exportStaffData(params);
  };

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      // availability: allFilters?.availability,
      department: allFilters?.department,
      designation: allFilters?.designation,
      search,
    };
    dispatch(StaffListingReducer(params));
  }, [defaultPageNo, search, allFilters, isLoadAgain, dispatch]);
  return (
    <div>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <DeleteRequest
            text={`Are you sure you want to delete Staff ?`}
            handleSave={handleDeleteStaffData}
            handleClose={handleClose}
          />
        }
      />
      <div className="relative">
        <Title level={3}>My Properties</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            filterComp=<StaffFilter
              filter={allFilters}
              setFilter={setAllFilters}
            />
            AddNew={'Add Staff'}
            route="/admin/myproperties/staff/add"
            {...{
              setSearch,
              handleFilter,
              handleExport,
              exportFileName: 'Staff Data',
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'4'} />
      </div>
    </div>
  );
};

export default StaffDataListing;
