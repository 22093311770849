import {
  ApproveRenewalRequestReducer,
  DenyRenewalRequestReducer,
} from 'Redux/slices/LeaseSlice';
import { AcceptLoginReq, DataTable, DeleteRequest, ModalComp } from 'component/CompIndex';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { renewalLeaseTableData } from 'staticData/LeaseData';

const RenewalRequestData = () => {
  const {
    renewalListingData: { rows = [], count },
  } = useSelector((state) => state.Lease);
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [renewalRequestData, setRenewalRequestData] = useState({});
  const [searchParams, setSearchParam] = useSearchParams();

  const handleDeleteModalOpen = (data) => {
    setIsDeleteModalOpen(true);
    setRenewalRequestData(data);
  };
  const handleApproveModalOpen = (data) => {
    setIsApproveModalOpen(true);
    setRenewalRequestData(data);
  };
  const handleClose = () => {
    if (isDeleteModalOpen) {
      setIsDeleteModalOpen(false);
    } else if (isApproveModalOpen) {
      setIsApproveModalOpen(false);
    }
    setRenewalRequestData({});
  };

  const handleDeniedRequest = () => {
    dispatch(DenyRenewalRequestReducer(renewalRequestData?.id));
    handleClose();
  };
  const handleApproveRequest = (values) => {
    const payload = {
      requestId: renewalRequestData?.id,
      data: values,
    };
    dispatch(ApproveRenewalRequestReducer(payload));
    handleClose();
  };

  const { data, columns } = renewalLeaseTableData(
    rows,
    handleApproveModalOpen,
    handleDeleteModalOpen
  );

  return (
    <div>
      <ModalComp
        {...{ isModalOpen: isDeleteModalOpen || isApproveModalOpen }}
        component={
          isApproveModalOpen ? (
            <AcceptLoginReq
              {...{
                handleClose,
                handleSave: handleApproveRequest,
                requestData: renewalRequestData,
              }}
            />
          ) : (
            <DeleteRequest
              text={`Are you sure you want to delete request ${renewalRequestData?.contractId} ?`}
              handleSave={handleDeniedRequest}
              handleClose={handleClose}
            />
          )
        }
      />
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </div>
  );
};

export default RenewalRequestData;
