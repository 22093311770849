import { headersForMultiForm } from 'utils/UtilsIndex';
import { axiosInstance } from '../AxiosClient';
export const getUnitListing = async (params) => {
  const resp = await axiosInstance.get(`/flats/admin/list?limit=10`, {
    params,
  });
  return resp;
};
export const createUnit = async (data, navigateTo) => {
  const resp = await axiosInstance.post(`/flats/admin`, data, { navigateTo });
  return resp;
};
export const getUnitData = async (id) => {
  const resp = await axiosInstance.get(`/flats/admin/${id}`);
  return resp;
};
export const editUnitData = async ({ data, navigateTo, id }) => {
  const resp = await axiosInstance.patch(`/flats/admin/${id}`, data, { navigateTo });
  return resp;
};
export const deleteUnitData = async (id) => {
  const resp = await axiosInstance.delete(`/flats/${id}`);
  return resp;
};
export const getUnitNameData = async (id) => {
  const resp = await axiosInstance.get(`/flats/admin/all?buildingId=${id}`);
  return resp;
};
export const getVacantUnitData = async (id) => {
  const resp = await axiosInstance.get(`/floors/flats/vacant/${id}`);
  return resp;
};
export const getOwnerData = async (id) => {
  const resp = await axiosInstance.get(`/flats/admin/owner/${id}`);
  return resp;
};
export const getTenantData = async () => {
  const resp = await axiosInstance.get(`/master-users/admin/potential-tenants`);
  return resp;
};
export const getUnitExportData = async (params) => {
  const resp = axiosInstance.get(`flats/admin/export`, { params });
  return resp;
};

export const getSubUnitExportData = async (params) => {
  const resp = axiosInstance.get(`sub-flats/admin/export`, { params });
  return resp;
};

export const addCsvData = async ({ id, data }) => {
  const resp = await axiosInstance.post(
    `/flats/csv?buildingId=${id}`,
    data,
    headersForMultiForm()
  );
  return resp;
};
export const getVacantSubUnits = async (params) => {
  const resp = await axiosInstance.get(`/sub-flats/admin/vacant`, {
    params,
  });
  return resp;
};

export const getSubUnitListing = async (params) => {
  const resp = await axiosInstance.get(`/sub-flats/admin?limit=10`, {
    params,
  });
  return resp;
};
export const createSubUnit = async (data, navigateTo) => {
  const resp = await axiosInstance.post(`/sub-flats/admin`, data, {
    navigateTo,
  });
  return resp;
};
export const getSubUnitData = async (id) => {
  const resp = await axiosInstance.get(`/sub-flats/admin/${id}`);
  return resp;
};
export const editSubUnitData = async (id, data, navigateTo) => {
  const resp = await axiosInstance.patch(`/sub-flats/admin/${id}`, data, {
    navigateTo,
  });
  return resp;
};
export const deleteSubUnitData = async (subUnitId) => {
  const resp = await axiosInstance.delete(`/sub-flats/admin/${subUnitId}`);
  return resp;
};

export const addNewAmenities = async (data) => {
  const resp = await axiosInstance.post(`/amenities/admin`, data);
  return resp;
};

export const getAmenitiesListing = async (params) => {
  const resp = await axiosInstance.get(`/amenities/admin`, { params });
  return resp;
};

export const getAllAmenities = async (raisedFor) => {
  const resp = await axiosInstance.get(`/amenities/list/admin?raisedFor=${raisedFor}`);
  return resp;
};

export const editAmenitiesStatus = async (id) => {
  const resp = await axiosInstance.patch(`/amenities/visibility/admin?amenityId=${id}`);
  return resp;
}

export const editAmenities = async ({id,data}) => {
  const resp = await axiosInstance.patch(`/amenities/admin?amenityId=${id}`,data);
  return resp;
}
