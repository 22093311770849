import { Form, Radio } from 'antd';
import React from 'react';

const RadioField = ({
  label,
  radioArr,
  name,
  required,
  width,
  selectedName,
  selectedValue,
  onChange,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      style={{ width: width }}
      rules={[
        {
          required: required ? true : false,
          message: 'This field is required.',
        },
      ]}
    >
      <Radio.Group onChange={onChange}>
        {radioArr?.map((cur, index) => (
          <Radio value={selectedValue ? cur?.[selectedValue] : cur} key={index}>
            {selectedName ? cur?.[selectedName] : cur}
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default React.memo(RadioField);
