import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addProductInMaintenances,
  addServiceStaff,
  createService,
  deleteProductInMaintenances,
  editServiceData,
  editServiceStaff,
  editServiceStatus,
  getAvailableStaffData,
  getServiceCategory,
  getServiceData,
  getServiceListing,
  getServiceRequestCount,
  getServiceStatuses,
  getStaffAvailableTime,
  updateProductInMaintenances,
  uploadServiceFile,
} from 'Redux/services/service/Service';
import { initialListingState, initialState } from './InitialState';

// create
export const CreateServiceReducer = createAsyncThunk(
  '/createService',
  async (payload) => {
    try {
      const resp = await createService(payload);
      return resp;
    } catch (error) { }
  }
);

// get listing page data
export const ServiceListingReducer = createAsyncThunk(
  '/serviceListing',
  async (data) => {
    try {
      const resp = await getServiceListing(data);
      return resp?.data;
    } catch (error) { }
  }
);

// get service request count
export const ServiceRequestCountReducer = createAsyncThunk(
  '/serviceCount',
  async (data) => {
    try {
      const resp = await getServiceRequestCount(data);
      return resp?.data;
    } catch (error) { }
  }
);
//getServiceData
export const getServiceDataReducer = createAsyncThunk(
  'getServiceData',
  async (id) => {
    try {
      const resp = await getServiceData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);

// get service Category data
export const ServiceCategoryDataReducer = createAsyncThunk(
  'getServiceCategory',
  async () => {
    try {
      const resp = await getServiceCategory();
      const adminCategoryData = resp?.data?.data?.rows;
      const userCategoryData = resp?.data?.data?.rows?.filter(
        (userCat) => userCat?.isVisible
      );
      return { adminCategoryData, userCategoryData };
    } catch (error) { }
  }
);

//editServiceData
export const editServiceDataReducer = createAsyncThunk(
  'editServiceData',
  async (payload) => {
    try {
      const resp = await editServiceData(payload);
      return resp;
    } catch (error) { }
  }
);

//upload files in service request = 
export const uploadServiceFileReducer = createAsyncThunk(
  'uploadServiceFile',
  async (payload) => {
    try {
      const resp = await uploadServiceFile(payload);
      return resp;
    } catch (error) { }
  }
);

//getServiceStatuses
export const getServiceStatusesReducer = createAsyncThunk(
  'getServiceStatuses',
  async (id) => {
    try {
      const resp = await getServiceStatuses(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);

// get Available Staff Data
export const AvailableStaffDataReducer = createAsyncThunk(
  'getAvailableStaffData',
  async () => {
    try {
      const resp = await getAvailableStaffData();
      return resp?.data?.data;
    } catch (error) { }
  }
);

// get staff available time slots
export const StaffTimeSlotReducer = createAsyncThunk(
  'getAvailableTime',
  async (payload) => {
    try {
      const resp = await getStaffAvailableTime(payload);
      return resp?.data?.data;
    } catch (error) { }
  }
);

// edit service status
export const editServiceStatusReducer = createAsyncThunk(
  'editServiceStatus',
  async (payload) => {
    try {
      const resp = await editServiceStatus(payload);
      return resp;
    } catch (error) { }
  }
);

//add staff for a service
export const addServiceStaffReducer = createAsyncThunk(
  'addServiceStaff',
  async (payload) => {
    try {
      const resp = await addServiceStaff(payload);
      return resp?.data?.data;
    } catch (error) { }
  }
);

// edit staff for a service
export const editServiceStaffReducer = createAsyncThunk(
  'editServiceStaff',
  async (payload) => {
    try {
      const resp = await editServiceStaff(payload);
      return resp?.data;
    } catch (error) { }
  }
);

// add products in maintenances
export const addProductsReducer = createAsyncThunk(
  '/addMaintenancesProducts',
  async (payload) => {
    try {
      const resp = addProductInMaintenances(payload);
      return resp;
    } catch (error) { }
  }
)

//edit product in maintenance
export const updateProductReducer = createAsyncThunk(
  '/deleteMaintenancesProducts',
  async (payload) => {
    try {
      const resp = await updateProductInMaintenances(payload);
      return resp;
    } catch (error) { }
  }
)

//delete product in maintenance
export const deleteProductReducer = createAsyncThunk(
  '/deleteMaintenancesProducts',
  async (payload) => {
    try {
      const resp = await deleteProductInMaintenances(payload);
      return resp;
    } catch (error) { }
  }
)

const ServiceSlice = createSlice({
  name: 'serviceSlice',
  initialState,
  reducers: {},

  extraReducers: {
    //addService
    [CreateServiceReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateServiceReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [CreateServiceReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // ServiceListing
    [ServiceListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ServiceListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.serviceListingData = action?.payload?.data ?? initialListingState;
    },
    [ServiceListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // Service Request Count
    [ServiceRequestCountReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ServiceRequestCountReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.serviceRequestCountData = action?.payload?.data;
    },
    [ServiceRequestCountReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get Service data
    [getServiceDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getServiceDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.serviceDetailData = action?.payload;
    },
    [getServiceDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get Service data
    [uploadServiceFileReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [uploadServiceFileReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [uploadServiceFileReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get Service CategoryData
    [ServiceCategoryDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ServiceCategoryDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.adminCategoryData = action?.payload?.adminCategoryData ?? [];
      state.userCategoryData = action?.payload?.userCategoryData ?? [];
    },
    [ServiceCategoryDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editServiceData
    [editServiceDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editServiceDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editServiceDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //getServiceStatusesData
    [getServiceStatusesReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getServiceStatusesReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.serviceStatusesData = action?.payload;
      state.isSuccess = true;
    },
    [getServiceStatusesReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editServiceStatusesData
    [editServiceStatusReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editServiceStatusReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editServiceStatusReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //getAvailableStaffDetailData
    [AvailableStaffDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [AvailableStaffDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.availableStaffData = action?.payload;
      state.isSuccess = true;
    },
    [AvailableStaffDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //getStaffAvailableTime
    [StaffTimeSlotReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [StaffTimeSlotReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.staffAvailabilityData = action?.payload;
      state.isSuccess = true;
    },
    [StaffTimeSlotReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // add service staff
    [addServiceStaffReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addServiceStaffReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [addServiceStaffReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // edit service staff
    [editServiceStaffReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editServiceStaffReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editServiceStaffReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // add Products
    [addProductsReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addProductsReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [addProductsReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // edit Products
    [updateProductReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [updateProductReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [updateProductReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // delete Products
    [deleteProductReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteProductReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteProductReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default ServiceSlice.reducer;
