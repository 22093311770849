export const serviceOverViewFunction = (serviceData={}) => {
  const categories = Object.keys(serviceData);
  const series = Object.values(serviceData);
  const serviceOverviewData = {
    totalRequest: series?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.open + currentValue?.inProcess;
    }, 0),
    series: [
      {
        name: 'Open',
        data: series?.map((data) => data?.open),
      },
      {
        name: 'In-Process',
        data: series?.map((data) => data?.inProcess),
      },
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        // text: 'Product Trends by Month', top title
        align: 'left',
      },
      colors: ['#229EEF', '#FE8B02'],
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories,
        labels: {
          rotate: -45, // Adjust the rotation angle as needed
        },
      },
    },
  };
  return serviceOverviewData;
};
export const serviceOverviewData = {
  series: [
    {
      name: 'Desktops',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
    {
      name: 'Laptop',
      data: [16, 61, 42, 55, 70, 81, 92, 94, 170],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      // text: 'Product Trends by Month', top title
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        '00:00-03:00',
        '03:00-06:00',
        '06:00-09:00',
        '09:00-12:00',
        '12:00-15:00',
        '15:00-18:00',
        '18:00-21:00',
        '21:00-00:00',
        '00:00-02:00',
      ],
    },
  },
};

const noDataDonut = {
  options: {
    labels: ['No Data Found'],
  },
  series: [1],
};

export function donutData(userData={}) {
  const labels = Object.keys(userData);
  const series = Object.values(userData);
  const total = series?.reduce((accumulator, data) => accumulator + data, 0);
  const userOverViewData = {
    options: {
      labels,
    },
    series,
  };
  return total ? userOverViewData : noDataDonut;
}

export function donutCompanyData(userData) {
  const labels = userData?.map((cur, id) => cur.company);
  const series = userData?.map((cur, id) => cur.count);
  const total = series?.reduce((accumulator, data) => accumulator + data, 0);
  const userOverViewData = {
    options: {
      labels,
    },
    series,
  };
  return total ? userOverViewData : noDataDonut;
}

export function donutServiceData(userData) {
  const labels = userData?.map((cur, id) => cur.label);
  const series = userData?.map((cur, id) => cur.count);
  const total = series?.reduce((accumulator, data) => accumulator + data, 0);
  const userOverViewData = {
    options: {
      labels,
    },
    series,
  };
  return total ? userOverViewData : noDataDonut;
}
export function donutLeaseData(userData = {}) {
  // const labels = [];
  // const series = [];
  // const total = 0;
  const labels = Object.keys(userData);
  const series = Object.values(userData);
  const total = series?.reduce((accumulator, data) => accumulator + data, 0);
  const userOverViewData = {
    options: {
      labels,
    },
    series,
  };
  return total ? userOverViewData : noDataDonut;
}

export function leaseBiData(data = {}) {
  const serviceBifurcation = {
    series: [
      {
        data: Object.values(data),
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: Object.keys(data),
      },
      // tooltip: {
      //   theme: 'dark',
      //   x: {
      //     show: false,
      //   },
      //   y: {
      //     title: {
      //       formatter: function () {
      //         return '';
      //       },
      //     },
      //   },
      // },
    },
  };
  return serviceBifurcation;
}

export const userOverViewData = {
  options: {},
  series: [44, 55, 41, 17, 15],
  labels: ['X', 'B', 'C', 'D', 'E'],
};
export function serviceBiData(serviceBifurcationData = {}) {
  const serviceBifurcation = {
    series: [
      {
        data: Object.values(serviceBifurcationData),
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: Object.keys(serviceBifurcationData),
      },
      // tooltip: {
      //   theme: 'dark',
      //   x: {
      //     show: false,
      //   },
      //   y: {
      //     title: {
      //       formatter: function () {
      //         return '';
      //       },
      //     },
      //   },
      // },
    },
  };
  return serviceBifurcation;
}
export const unitOverViewData = {
  series: [
    {
      name: 'Net Profit',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
    },
    {
      name: 'Revenue',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      height: 250,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
      ],
    },
    yaxis: {
      title: {
        text: '$ (thousands)',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val + ' thousands';
        },
      },
    },
  },
};
export function unitAnaData(unitAnalyticsData = {}) {
  const unitOverViewData = {
    series: [
      {
        name: 'Total',
        data: Object.values(unitAnalyticsData)?.map((cur, id) => cur.total),
      },
      {
        name: 'Occupied',
        data: Object.values(unitAnalyticsData)?.map((cur, id) => cur.occupied),
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 250,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: Object.keys(unitAnalyticsData),
      },
      yaxis: {
        title: {
          text: 'Units',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' units';
          },
        },
      },
    },
  };
  return unitOverViewData;
}

export function visitorStatsFunction(data = {}) {
  const visitorOverViewData = {
    series: [
      {
        name: 'Total',
        data: Object.values(data)?.map((cur, id) => cur.total),
      },
    ],
    options: {
      chart: {
        height: 250,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        // text: 'Average High & Low Temperature', for top title
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: Object.keys(data),
        title: {
          text: 'Visitor Visiting statistics',
        },
      },
      yaxis: {
        title: {
          text: 'Visitor Visitings',
        },
        // min: 0,
        // max: 10,
        y: {
          formatter: function (val) {
            return val + ' units';
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  };
  return visitorOverViewData;
}

function generateRandomColorsForObject(obj) {
  const numColors = Object.keys(obj).length;
  const colors = [];
  for (let i = 0; i < numColors; i++) {
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    colors.push(randomColor);
  }
  return colors;
}

export function visitorStatsOverviewFunction(data = {}) {
  const visitorOverViewData = {
    series: [
      {
        name: 'Total',
        data: Object.values(data)?.map((cur, id) => cur),
      },
    ],
    options: {
      chart: {
        height: 250,
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      // colors: generateRandomColorsForObject(data), // Add more colors as needed
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      title: {
        // text: 'Average High & Low Temperature', for top title
        align: 'left',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: Object.keys(data),
        title: {
          text: 'Visitor Visiting statistics',
        },
      },
      yaxis: {
        title: {
          text: 'Visitor Visitings',
        },
        // min: 0,
        // max: 10,
        y: {
          formatter: function (val) {
            return val + ' units';
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  };
  return visitorOverViewData;
}