import { axiosInstance, baseURL } from '../AxiosClient';

export const getStaffListing = async (params) => {
  const resp = await axiosInstance.get(`${baseURL}/staffs?limit=10`, {
    params,
  });
  return resp;
};

export const addStaffData = async (data, navigateTo) => {
  const resp = await axiosInstance.post(`/staffs`, data, { navigateTo });
  return resp;
}

export const getStaffData = async (id) => {
  const resp = await axiosInstance.get(`/staffs/${id}`)
  return resp;
}

export const editStaffData = async (data, navigateTo) => {
  const resp = await axiosInstance.patch(`/staffs`, data, { navigateTo });
  return resp;
}

export const deleteStaffData = async (id) => {
  const resp = await axiosInstance.delete(`/staffs/${id}`);
  return resp;
}

export const getCalendarData = ({ userId, date }) => {
  const resp = axiosInstance.get(`/staffs/calender/${userId}?date=${date}`);
  return resp;
};

export const getStaffExportData = async (params) => {
  const resp = axiosInstance.get(`/staffs/admin/export`, { params });
  return resp;
}

export const editStaffAvailability = ({ id, data }) => {
  const resp = axiosInstance.patch(`/staffs/slots/${id}`, data);
  return resp;
}