const InvoiceTopCard = ({ setFilters, filters }) => {
  const obj = {
    unpaid: {
      overdue: 11000,
      notDue: 4000,
    },
    paid: {
      deposited: 12000,
      notDeposited: 15000,
    },
  };

  const handleStatusChange = (value) => {
    setFilters({ ...setFilters, status: value });
  };

  const calculatePercentage = (val1, val2) => {
    const total = val1 + val2;
    const val1Percentage = parseInt((val1 * 100) / total);
    const val2Percentage = parseInt((val2 * 100) / total);
    return [`${val1Percentage}%`, `${val2Percentage}%`];
  };
  const [notDepositedWidth, depositedWidth] = calculatePercentage(
    obj.paid.notDeposited,
    obj.paid.deposited
  );
  const [overDueWidth, notDueWidth] = calculatePercentage(
    obj.unpaid.overdue,
    obj.unpaid.notDue
  );
  return (
    <div className="flex w-full items-center justify-between p-3">
      {/* un paid div */}
      <div className="flex w-[45%] flex-col items-center justify-between gap-2">
        <div className="flex w-full items-center justify-start gap-5">
          <p className="font-semibold">
            ${obj.unpaid.overdue + obj.unpaid.notDue} Unpaid
          </p>
          <p className="font-thin">Last 365 Days</p>
        </div>
        <div className="flex w-full items-center justify-between gap-5">
          <div className="flex flex-col">
            <p className="text-2xl font-bold">${obj.unpaid.overdue}</p>
            <p className="font-thin">Overdue</p>
          </div>
          <div className="flex flex-col">
            <p className="text-end text-2xl font-bold">${obj.unpaid.notDue}</p>
            <p className="font-thin">Not due yet</p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p className={`flex h-8 items-end`} style={{ width: overDueWidth }}>
            <p
              onClick={() => handleStatusChange('overDue')}
              className={`h-6 w-full cursor-pointer rounded-md bg-[#E20F0F] transition-all duration-300 ease-in-out hover:h-8`}
            ></p>
          </p>
          <p className={`flex h-8 items-end`} style={{ width: notDueWidth }}>
            <p
              onClick={() => handleStatusChange('notDue')}
              className={`h-6 w-full cursor-pointer  rounded-md bg-[#FEAD3F] transition-all duration-300 ease-in-out hover:h-8`}
            ></p>
          </p>
        </div>
      </div>
      {/* paid div */}
      <div className="flex w-[45%] flex-col items-center justify-between gap-4">
        <div className="flex w-full items-center justify-start gap-5">
          <p className="font-semibold">
            ${obj.paid.notDeposited + obj.paid.deposited} Paid
          </p>
          <p className="font-thin">Last 365 Days</p>
        </div>
        <div className="flex w-full items-center justify-between gap-5">
          <div className="flex flex-col">
            <p className="text-2xl font-bold">${obj.paid.notDeposited}</p>
            <p className="font-thin">Not deposited</p>
          </div>
          <div className="flex flex-col">
            <p className="text-end text-2xl font-bold">${obj.paid.deposited}</p>
            <p className="font-thin">Deposited</p>
          </div>
        </div>
        <div className="flex w-full justify-between">
          <p
            className={`flex h-8 items-end`}
            style={{ width: notDepositedWidth }}
          >
            <p
              onClick={() => handleStatusChange('notDeposited')}
              className="h-6 w-full cursor-pointer rounded-md bg-[#229EEF] transition-all duration-300 ease-in-out hover:h-8"
            ></p>
          </p>
          <p className={`flex h-8 items-end`} style={{ width: depositedWidth }}>
            <p
              onClick={() => handleStatusChange('deposited')}
              className="h-6 w-full cursor-pointer rounded-md bg-[#2BB256] transition-all duration-300 ease-in-out hover:h-8"
            ></p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvoiceTopCard;
