import { DashboardLayout } from 'component/CompIndex';
import { ErrorBoundary } from 'pages/login/ErrorBoundary';
import { lazy } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PrivateRouteLogin from './PrivateRouteLogin';
import AccessRoute from './AccessRoute';

const ServiceCategoryListing = lazy(() =>
  import('pages/category/serviceCategory/ServiceCategoryListing')
);
const Dashboard = lazy(() => import('pages/dashboard/Dashboard'));
const Profile = lazy(() => import('pages/profile/Profile'));
const Setting = lazy(() => import('pages/profile/Setting'));
const LoginRequest = lazy(() => import('pages/loginRequest/LoginRequest'));
const Login = lazy(() => import('pages/login/Login'));
const PropertyListing = lazy(() =>
  import('pages/myProperties/property/PropertyListing')
);
const InventoryListing = lazy(() =>
  import('pages/myProperties/catalog/CatalogListing')
);
const Details = lazy(() => import('pages/myProperties/property/Details'));
const AddProperty = lazy(() =>
  import('pages/myProperties/property/AddProperty')
);
const RenewalListing = lazy(() =>
  import('pages/leaseManagement/RenewalListing')
);
const ReminderListing = lazy(() =>
  import('pages/leaseManagement/ReminderListing')
);
const AddReminder = lazy(() => import('pages/leaseManagement/AddReminder'));
const VisitorDetails = lazy(() => import('pages/visitorManagement/Details'));
const VisitorListing = lazy(() =>
  import('pages/visitorManagement/VisitorListing')
);
const HelplineListing = lazy(() => import('pages/helpline/HelplineListing'));
const AddService = lazy(() => import('pages/services/AddService'));
const ServiceDetail = lazy(() => import('pages/services/ServiceDetail'));
const ServiceListing = lazy(() => import('pages/services/ServiceListing'));
const AddNotice = lazy(() => import('pages/Notices/AddNotice'));
const NoticeDetails = lazy(() => import('pages/Notices/NoticeDetails'));
const NoticeListing = lazy(() => import('pages/Notices/NoticeListing'));
const AddGuard = lazy(() => import('pages/guardManagement/AddGuard'));
const GuardDetail = lazy(() => import('pages/guardManagement/GuardDetail'));
const GuardListing = lazy(() => import('pages/guardManagement/GuardListing'));
const LeadListing = lazy(() => import('pages/leadManagement/LeadListing'));
const LeadDetail = lazy(() => import('pages/leadManagement/LeadDetails'));
const AddUser = lazy(() => import('pages/myProperties/user/AddUser'));
const AddUnit = lazy(() => import('pages/myProperties/unit/AddUnit'));
const AddSubUnit = lazy(() => import('pages/myProperties/unit/AddSubUnit'));
const AddStaff = lazy(() => import('pages/myProperties/staff/AddStaff'));
const AddCompany = lazy(() => import('pages/myProperties/user/AddCompany'));
const AddLease = lazy(() => import('pages/leaseManagement/AddLease'));
const LeaseDetail = lazy(() => import('pages/leaseManagement/LeaseDetail'));
const LeaseListing = lazy(() => import('pages/leaseManagement/LeaseListing'));
const AddInventory = lazy(() =>
  import('pages/myProperties/catalog/AddCatalog')
);
const CompanyDetail = lazy(() =>
  import('pages/myProperties/user/CompanyDetail')
);
const CompanyListing = lazy(() =>
  import('pages/myProperties/user/CompanyListing')
);
const DetailInventory = lazy(() =>
  import('pages/myProperties/catalog/CatalogDetails')
);
const UserDetails = lazy(() => import('pages/myProperties/user/UserDetails'));
const StaffDetails = lazy(() =>
  import('pages/myProperties/staff/StaffDetails')
);
const UserDataListing = lazy(() =>
  import('pages/myProperties/user/UserDataListing')
);
const UnitDetail = lazy(() => import('pages/myProperties/unit/UnitDetail'));
const StaffDataListing = lazy(() =>
  import('pages/myProperties/staff/StaffDataListing')
);
const SubUnitDetails = lazy(() =>
  import('pages/myProperties/unit/SubUnitDetails')
);
const SubUnitListing = lazy(() =>
  import('pages/myProperties/unit/SubUnitListing')
);
const UnitDataListing = lazy(() =>
  import('pages/myProperties/unit/UnitDataListing')
);
const InvoicesListing = lazy(() => import('pages/invoices/InvoicesListing'));
const AddInvoice = lazy(() => import('pages/invoices/AddInvoice'));

export const AllRoute = createBrowserRouter([
  {
    path: '/login',
    element: (
      <PrivateRouteLogin>
        <Login />
      </PrivateRouteLogin>
    ),
  },
  {
    path: '/admin',
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: [
      {
        path: '',
        element: (
          <AccessRoute label={'Dashboard'}>
            <Dashboard />
          </AccessRoute>
        ),
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'profile',
        element: <Profile />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'setting',
        element: <Setting />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'myproperties',
        element: (
          <AccessRoute label={'My Properties'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: 'property',
            element: (
              <AccessRoute label={'My Properties'}>
                <PropertyListing />,
              </AccessRoute>
            ),
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'add',
            element: <AddProperty />,
          },
          {
            path: 'detail/:id',
            element: <Details />,
          },
          {
            path: 'unit',
            element: <UnitDataListing />,
          },
          {
            path: 'unit/add',
            element: <AddUnit />,
          },
          {
            path: 'user/add',
            element: <AddUser />,
          },
          {
            path: 'company',
            element: <CompanyListing />,
          },
          {
            path: 'company/add',
            element: <AddCompany />,
          },
          {
            path: 'company/detail/:id',
            element: <CompanyDetail />,
          },
          {
            path: 'unit/detail/:id',
            element: <UnitDetail />,
          },
          {
            path: 'subunit',
            element: <SubUnitListing />,
          },
          {
            path: 'subunit/add',
            element: <AddSubUnit />,
          },
          {
            path: 'subunit/detail/:id',
            element: <SubUnitDetails />,
          },
          {
            path: 'user',
            element: <UserDataListing />,
          },
          {
            path: 'user/add',
            element: <AddUser />,
          },
          {
            path: 'user/detail/:id',
            element: <UserDetails />,
          },
          {
            path: 'staff',
            element: <StaffDataListing />,
          },
          {
            path: 'staff/add',
            element: <AddStaff />,
          },
          {
            path: 'staff/detail/:id',
            element: <StaffDetails />,
          },
          {
            path: 'Catalog',
            element: <InventoryListing />,
          },
          {
            path: 'Catalog/add',
            element: <AddInventory />,
          },
          {
            path: 'Catalog/detail/:id',
            element: <DetailInventory />,
          },
        ],
      },
      {
        path: 'services',
        element: (
          <AccessRoute label={'Services'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <ServiceListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'add',
            element: <AddService />,
          },
          {
            path: 'detail/:id',
            element: <ServiceDetail />,
          },
        ],
      },
      {
        path: 'category',
        element: (
          <AccessRoute label={'Configuration'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <ServiceCategoryListing />,
          },
        ],
      },
      {
        path: 'lease',
        element: (
          <AccessRoute label={'Lease Management'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <LeaseListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'add',
            element: <AddLease />,
          },
          {
            path: 'detail/:id',
            element: <LeaseDetail />,
          },
          {
            path: 'renew',
            element: <RenewalListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'reminder',
            element: <ReminderListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'reminder/add',
            element: <AddReminder />,
          },
          {
            path: 'reminder/detail/:id',
            element: <LeaseDetail />,
          },
        ],
      },
      {
        path: 'visitor',
        element: (
          <AccessRoute label={'Visitor Management'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <VisitorListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'detail/:id',
            element: <VisitorDetails />,
          },
        ],
      },
      {
        path: 'leads',
        element: (
          <AccessRoute label={'Lead Management'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <LeadListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'add',
            element: <AddGuard />,
          },
          {
            path: 'detail/:id',
            element: <LeadDetail />,
          },
        ],
      },
      {
        path: 'guard',
        element: (
          <AccessRoute label={'Guard Management'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <GuardListing />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: 'add',
            element: <AddGuard />,
          },
          {
            path: 'detail/:id',
            element: <GuardDetail />,
          },
        ],
      },
      {
        path: 'request',
        element: <LoginRequest />,
      },
      {
        path: 'notices',
        element: (
          <AccessRoute label={'Notices'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <NoticeListing />,
          },
          {
            path: 'add',
            element: <AddNotice />,
          },
          {
            path: 'detail/:id',
            element: <NoticeDetails />,
          },
        ],
      },
      {
        path: 'helpline',
        element: (
          <AccessRoute label={'Helpline'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <HelplineListing />,
          },
        ],
      },
      {
        path: 'invoices',
        element: (
          <AccessRoute label={'Invoice Management'}>
            <Outlet />
          </AccessRoute>
        ),
        children: [
          {
            path: '',
            element: <InvoicesListing />,
          },
          {
            path: 'add',
            element: <AddInvoice />,
          },
          {
            path: 'detail/:id',
            element: <AddInvoice />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to="/admin" replace />,
      },
      {
        path: '',
        element: <Navigate to="/admin" replace />,
      },
    ],
  },

  {
    path: '*',
    element: (
      <PrivateRoute>
        <Navigate to="/admin" />
      </PrivateRoute>
    ),
  },
]);
