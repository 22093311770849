import Title from 'antd/es/typography/Title';
import { MixedHeadContent, TabComponent } from 'component/CompIndex';
import { useState } from 'react';
import { inventoryTabItem } from 'staticData/PropertyData';

const InventoryListing = () => {
  const [search, setSearch] = useState('');
  const items = inventoryTabItem(search);
  return (
    <div>
      <div className="relative">
        <Title level={3}>My Properties</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            AddNew="Add Catalog"
            route="/admin/myproperties/Catalog/add"
            {...{
              setSearch,
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'5'} />
      </div>
    </div>
  );
};

export default InventoryListing;
