import { Form } from 'antd';
import { EditTopChanger, RadioField } from 'component/CompIndex';
import React, { useState } from 'react';

const Notifications = () => {
  const [handleEditMode, setHandleEditMode] = useState({
    serviceDetails: false,
    LeaseDetails: false,
    loginRequestDetails: false,
  });
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };
  const onFinish = (values) => {};
  return (
    <Form onFinish={onFinish} className="flex flex-col gap-5">
      <div className="rounded-xl shadow-sm">
        <EditTopChanger
          handleEditChanges={handleEditChanges}
          state="serviceDetails"
          title="Services"
          {...{ setHandleEditMode, handleEditMode }}
        />
        <div
          style={{
            pointerEvents: !handleEditMode.serviceDetails ? 'none' : '',
          }}
          className="grid grid-cols-3 justify-between gap-5 p-4"
        >
          <div>
            <div className="text-[#BDBDBD]">
              Notify me when a new request is raised
              <RadioField name="discountType1" radioArr={['Yes', 'No']} />
            </div>
          </div>
          <div>
            <div className="text-[#BDBDBD]">
              Notify me when a request is due
              <RadioField name="discountType2" radioArr={['Yes', 'No']} />
            </div>
          </div>
          <div>
            <div className="text-[#BDBDBD]">
              Notify me when a request is completed
            </div>
            <RadioField name="discountType3" radioArr={['Yes', 'No']} />
          </div>
        </div>
      </div>
      <div className="rounded-xl shadow-sm">
        <EditTopChanger
          handleEditChanges={handleEditChanges}
          state="LeaseDetails"
          title="Leases"
          {...{ setHandleEditMode, handleEditMode }}
        />
        <div
          style={{
            pointerEvents: !handleEditMode.LeaseDetails ? 'none' : '',
          }}
          className="grid grid-cols-3 justify-between gap-5 p-4"
        >
          <div>
            <div className="text-[#BDBDBD]">
              Notify me when a new request is raised
              <RadioField name="discountType4" radioArr={['Yes', 'No']} />
            </div>
          </div>
          <div>
            <div className="text-[#BDBDBD]">
              Notify me when a new request is raised
              <RadioField name="discountType5" radioArr={['Yes', 'No']} />
            </div>
          </div>
        </div>
      </div>
      <div className="rounded-xl shadow-sm">
        <EditTopChanger
          handleEditChanges={handleEditChanges}
          state="loginRequestDetails"
          title="Login Request"
          {...{ setHandleEditMode, handleEditMode }}
        />
        <div
          style={{
            pointerEvents: !handleEditMode.loginRequestDetails ? 'none' : '',
          }}
          className="grid grid-cols-3 justify-between gap-5 p-4"
        >
          <div>
            <div className="text-[#BDBDBD]">
              Notify me when a new request is raised
              <RadioField name="discountType8" radioArr={['Yes', 'No']} />
            </div>
          </div>
          <div>
            <div className="text-[#BDBDBD]">
              Notify me when a new request is raised
            </div>
            <RadioField name="discountType9" radioArr={['Yes', 'No']} />
          </div>
        </div>
      </div>
    </Form>
  );
};

export default Notifications;
