import {
  AvailableStaffDataReducer,
  StaffTimeSlotReducer,
  addServiceStaffReducer,
  editServiceStaffReducer,
} from 'Redux/slices/ServiceSlice';
import { Col, Form, Row } from 'antd';
import {
  DateField,
  EditTopChanger,
  ModalComp,
  SelectField,
} from 'component/CompIndex';
import { AvailableTimeSlot } from 'component/CompIndex';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  dateToDayJsObj,
  disablePastDates,
  getCustomDateFormat,
  isValidUUID,
  timeStampToDate,
} from 'utils/UtilsIndex';
import StatusTimeline from './StatusTimeline';

const EditStaffForm = () => {
  const { serviceDetailData, availableStaffData, staffAvailabilityData } =
    useSelector((state) => state.Service);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [staffTimeSlot, setStaffTimeSlot] = useState({
    id: '',
    staffTime: '',
  });
  const [handleEditMode, setHandleEditMode] = useState({
    assignTask: false,
  });
  const [form] = Form.useForm();

  const staffCategory = Form.useWatch('staffCategory', form);
  const staffDate = Form.useWatch('staffDate', form);
  const sId = Form.useWatch('staffId', form);
  const staffId = isValidUUID(sId) ? sId : serviceDetailData?.staff?.id;

  const handleStaffTime = (startTime, endTime, id) => {
    const staffTime = `${timeStampToDate(startTime).formattedTime || ''}  ${
      timeStampToDate(endTime).formattedTime || ''
    }`;
    setStaffTimeSlot({ id, staffTime });
  };

  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };
  const handleSelectTimeSlot = (value) => {
    handleStaffTime(value?.startTime, value?.endTime, value?.staffTimeSlotId);
    setIsModalOpen(false);
  };
  const handleOpenModal = () => {
    if (staffAvailabilityData?.length) {
      setIsModalOpen(true);
    } else if (staffDate) {
      toast.error('No slots available');
      setStaffTimeSlot({ ...staffTimeSlot, staffTime: '' });
    }
  };

  const handleEditField = (statusArr) => {
    for (let i = 0; i < statusArr.length; i++) {
      if (serviceDetailData?.statusDetails?.[0]?.status === statusArr[i]) {
        return true;
      }
    }
    return false;
  };

  const onFinish = (values) => {
    values.staffId = isValidUUID(values?.staffId)
      ? values?.staffId
      : serviceDetailData?.staff?.id;
    if (!staffTimeSlot?.id) {
      toast.error('Please Select a time slot');
      return;
    }
    values.requestId = serviceDetailData?.id;
    values.staffTimeSlots = [staffTimeSlot?.id];
    if (
      !serviceDetailData?.staffId ||
      values.staffId !== serviceDetailData?.staffId
    ) {
      const { staffId, staffTimeSlots, requestId } = values;
      dispatch(addServiceStaffReducer({ staffId, staffTimeSlots, requestId }));
    } else {
      const payload = {
        id: values.requestId,
        data: {
          staffTimeSlots: values?.staffTimeSlots,
        },
      };
      dispatch(editServiceStaffReducer(payload));
    }
    setHandleEditMode({ ...handleEditMode, assignTask: false });
  };

  useEffect(() => {
    var initialObj = {
      staffId: serviceDetailData?.staff?.name,
      staffTimeSlot: serviceDetailData?.staffTimeSlot?.staffTimeSlotIds?.[0],
      staffDate:
        serviceDetailData?.staffTimeSlot &&
        dateToDayJsObj(serviceDetailData?.staffTimeSlot?.start),
      staffTime:
        serviceDetailData?.staffTimeSlot &&
        `${
          timeStampToDate(serviceDetailData?.staffTimeSlot?.start).formattedTime
        } - ${
          timeStampToDate(serviceDetailData?.staffTimeSlot?.end).formattedTime
        }`,
      staffCategory: serviceDetailData?.staff?.department,
    };
    if (!availableStaffData?.[staffCategory]) {
      dispatch(AvailableStaffDataReducer());
    }
    form.setFieldsValue(initialObj);
    handleStaffTime(
      serviceDetailData?.staffTimeSlot?.start,
      serviceDetailData?.staffTimeSlot?.end,
      serviceDetailData?.staffTimeSlot?.staffTimeSlotIds?.[0]
    );
  }, [form, serviceDetailData, dispatch]);

  useEffect(() => {
    if (staffDate && handleEditMode.assignTask) {
      const payload = {
        id: staffId || serviceDetailData?.staff?.id,
        params: { date: getCustomDateFormat(staffDate, 'DD-MM-YYYY') },
      };
      dispatch(StaffTimeSlotReducer(payload));
      setStaffTimeSlot({
        id: '',
        staffTime: '',
      });
    }
  }, [staffDate, dispatch]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      className="w-full"
      form={form}
    >
      <ModalComp
        {...{ isModalOpen }}
        component={
          <AvailableTimeSlot
            {...{
              isModalOpen,
              setIsModalOpen,
              handleSave: handleSelectTimeSlot,
            }}
          />
        }
      />
      <Row justify="space-between" gutter={10}>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state={
                  handleEditField([
                    'Open',
                    'Re-open',
                    'Re-Assigned',
                    'Assigned',
                    'In-Process',
                    // 'In-Process',
                  ])
                    ? 'assignTask'
                    : ''
                }
                title="Assign Task"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.assignTask ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <SelectField
                    label="Select Category"
                    placeholder="Staff Category"
                    name="staffCategory"
                    width="15rem"
                    selectArr={Object?.keys(availableStaffData || {})}
                    disabled={
                      !handleEditField([
                        'Open',
                        'Re-open',
                        // 'Assigned',
                        // 'Re-Assigned',
                        'In-Process',
                      ])
                    }
                    // selectedName="name_en"
                    // selectedValue="id"
                  />
                  <SelectField
                    label="Select Staff"
                    placeholder="Staff Name"
                    name="staffId"
                    required
                    width="15rem"
                    selectArr={availableStaffData?.[staffCategory]}
                    disabled={
                      !handleEditField([
                        'Open',
                        'Re-open',
                        // 'Assigned',
                        // 'Re-Assigned',
                        'In-Process',
                      ])
                    }
                    selectedName="name"
                    selectedValue="id"
                  />
                </div>
                <div className="inputWidth my-4">
                  <DateField
                    label="Select Date Preference"
                    name="staffDate"
                    width="15rem"
                    required
                    isDisabledDate={true}
                    disabledDate={disablePastDates}
                  />
                  <div className="mt-2 flex flex-col" onClick={handleOpenModal}>
                    <div>Select Time Slot</div>
                    <div className="mt-2 h-8 w-[15rem] cursor-pointer border-b-[1px] border-b-[#D4D4D4] pb-2 text-[#BDBDBD]">
                      {staffTimeSlot?.staffTime}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger title="Status Log" />
              <div className="m-2 h-[13rem] overflow-auto p-4">
                <StatusTimeline statusArr={serviceDetailData?.statusDetails} />
                <span className="flex justify-center text-sm font-light text-[#BDBDBD]">
                  Request Created on &nbsp;
                  {getCustomDateFormat(
                    serviceDetailData?.createdAt,
                    'DD/MM/YYYY, hh:mm A'
                  )}
                </span>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default React.memo(EditStaffForm);
