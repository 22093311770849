import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  InputField,
  MultiSelect,
  RadioField,
  SelectField,
} from 'component/CompIndex';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unitField } from 'staticData/CatalogData';
import { currencyList } from 'staticData/LeaseData';
import { yesOrNoArr } from 'staticData/PropertyData';
import { billedForArr } from 'staticData/ServiceData';

const AddProduct = ({
  handleSave,
  productDetails,
  closeModal,
  isInventory,
}) => {
  const {
    inventoryListingData: { rows: productTypeArr = [] },
  } = useSelector((state) => state.Inventory);
  const initialObj = useMemo(
    () => ({
      inventoryId: productDetails?.inventoryId,
      rate: productDetails?.rate,
      quantity: productDetails?.quantity,
      billedFor: productDetails?.billedFor,
      amount: productDetails?.quantity
        ? productDetails?.quantity * productDetails?.rate
        : null,
      description: productDetails?.description,
      unit: productDetails?.unit,
    }),
    [productDetails]
  );
  const [form] = Form.useForm();
  const inventoryId = Form.useWatch('inventoryId', form);
  const unit = Form.useWatch('unit', form);
  const quantity = Form.useWatch('quantity', form);
  const currency = Form.useWatch('currency', form);
  const isForAllBuildings = Form.useWatch('isForAllBuildings', form);
  const { buildingsName } = useSelector((state) => state.Property);
  const handleCloseModal = () => {
    form.resetFields();
    closeModal();
  };
  const getSelectedProduct = (data) => {
    const selectedProducts = productTypeArr?.filter((product) => {
      if (product?.id === data?.inventoryId) {
        return {
          rate: product?.rate,

          inventoryId: product?.id,
          unit: product?.unit,
        };
      }
    })?.[0];
    if (selectedProducts) {
      return {
        ...data,
        inventoryId: selectedProducts?.id,
        availableQuantity: selectedProducts?.availableQuantity,
        rate: selectedProducts?.rate,
        unit: selectedProducts?.unit,
      };
    }
    return data;
  };
  const onFinish = (values) => {
    handleSave(values);
    handleCloseModal();
  };
  useEffect(() => {
    form.setFieldsValue(initialObj);
  }, [productDetails, form]);
  useEffect(() => {
    if (inventoryId && productDetails?.inventoryId !== inventoryId) {
      const { unit, rate } = getSelectedProduct({ inventoryId, quantity });
      form.setFieldsValue({ unit, rate });
    }
  }, [inventoryId]);
  useEffect(() => {
    if (productDetails?.quantity && productDetails?.rate) {
      form.setFieldsValue({
        amount: quantity * productDetails?.rate,
      });
    }
  }, [quantity]);
  return (
    <div className="p-4">
      {/* <EditTopChanger title="Available Time Slot" /> */}
      <Title level={5}>
        {productDetails?.inventoryId ? 'Edit ' : 'Add '}Inventory
      </Title>
      <Form
        layout="vertical"
        name="dynamic_form_nest_item"
        size="large"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        className="w-full"
      >
        <div className="inputWidth items-end">
          {productDetails?.inventoryId ? (
            <>
              <SelectField
                label="Product Type"
                name="inventoryId"
                placeholder="Product Type"
                selectArr={productTypeArr}
                selectedName="name_en"
                selectedValue="id"
                width="10rem"
                disabled
              />
              <SelectField
                label="Billed At"
                name="billedFor"
                placeholder="Billed At"
                selectArr={billedForArr}
                width="10rem"
                required
              />
            </>
          ) : (
            <>
              <InputField
                label="Product Name"
                name="name_en"
                placeholder="Product Name"
                width="10rem"
                required
              />
              <SelectField
                label="Currency"
                name="currency"
                placeholder="Select Currency"
                selectArr={currencyList}
                width="10rem"
                selectedName="country"
                secondName="currency_code"
                selectedValue="currency_code"
                required
              />
            </>
          )}
          <InputField
            label={`${
              !productDetails?.inventoryId ? `Total Quantity ` : `Quantity`
            }`}
            name={`${
              !productDetails?.inventoryId ? 'totalQuantity' : 'quantity'
            }`}
            placeholder={`${
              !productDetails?.inventoryId ? 'Total ' : ''
            }Quantity`}
            width="10rem"
            type
            min={0}
            required={isInventory}
          />
        </div>

        <div className="inputWidth -mt-2 items-end">
          <SelectField
            label="Unit"
            name="unit"
            placeholder="Unit"
            selectArr={unitField}
            width="10rem"
            required={productDetails?.inventoryId ? false : true}
            disabled={productDetails?.inventoryId ? true : false}
          />
          {productDetails?.inventoryId ? (
            <>
              <InputField
                label={`Rate ${
                  currency ? '(in ' + currency?.toUpperCase() + ')' : ''
                }`}
                name="rate"
                placeholder={`Rate /${unit || 'unit'}`}
                width="10rem"
                type
                disabled
              />
              <InputField
                label={`Amount ${
                  currency ? '(in ' + currency?.toUpperCase() + ')' : ''
                }`}
                name="amount"
                placeholder="Amount"
                type
                width="10rem"
                disabled
              />
            </>
          ) : (
            <>
              <InputField
                label={`Rate ${
                  currency ? '(in ' + currency?.toUpperCase() + ')' : ''
                }`}
                name="rate"
                placeholder={`Rate /${unit || 'unit'}`}
                width="10rem"
                type
                required
              />
              <RadioField
                label="For All Buildings?"
                name="isForAllBuildings"
                radioArr={yesOrNoArr}
                selectedName="name"
                selectedValue="value"
                width="10rem"
              />
            </>
          )}
        </div>
        <div className="inputWidth -mt-2">
          {productDetails?.inventoryId
            ? // <InputField
              //   label="Description"
              //   name="description"
              //   placeholder="Description"
              //   width="30rem"
              // />
              ''
            : !isForAllBuildings && (
                <MultiSelect
                  label="Select Building"
                  placeholder="Building Name"
                  name="buildings"
                  required={!isForAllBuildings}
                  width="30rem"
                  selectArr={buildingsName?.rows}
                  selectedName="name_en"
                  selectedValue="id"
                  disabled={isForAllBuildings}
                />
              )}
        </div>
        <div className="center py-5">
          <Button className="w-40" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className="customButton ml-4 w-40" htmlType="submit">
            Save {productDetails?.inventoryId && 'Changes'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(AddProduct);
