import {
  LeaseAnalyticsDataReducer,
  RenewalListingReducer,
} from 'Redux/slices/LeaseSlice';
import Title from 'antd/es/typography/Title';
import { MixedHeadContent, TabComponent } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { renewalItems } from 'staticData/LeaseData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const RenewalListing = () => {
  const { isLoadAgain } = useSelector((state) => state.Lease);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const items = renewalItems({});

  //useEffect
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      search,
      limit: 10,
      buildingId: selectedBuildingName,
    };
    dispatch(RenewalListingReducer(params));
  }, [defaultPageNo, search, selectedBuildingName, isLoadAgain, dispatch]);

  useEffect(() => {
    dispatch(LeaseAnalyticsDataReducer());
  }, [dispatch]);

  return (
    <div>
      <div className="relative">
        <Title level={3}>Lease Management</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            {...{
              setSearch,
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'2'} />
      </div>
    </div>
  );
};

export default RenewalListing;
