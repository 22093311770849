import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  approveRenewalRequest,
  createBulkReminder,
  createLease,
  createSingleReminder,
  denyRenewalRequest,
  getAnalyticsData,
  getLeaseData,
  getLeaseListing,
  getMasterUserData,
  reminderListingData,
  renewalListingData,
  terminateLease,
  updateDraftLease,
  updateLeaseStatus,
} from 'Redux/services/Lease/Lease';
import { initialListingState, initialState } from './InitialState';
import ReminderListingData from 'pages/leaseManagement/ReminderListingData';

// create
export const CreateLeaseReducer = createAsyncThunk(
  '/createLease',
  async (payload) => {
    try {
      const resp = await createLease(payload);
      return resp;
    } catch (error) { }
  }
);

// get listing page data
export const LeaseListingReducer = createAsyncThunk(
  '/leaseListing',
  async (data) => {
    try {
      const resp = await getLeaseListing(data);
      return resp?.data;
    } catch (error) { }
  }
);

//getLeaseData
export const getLeaseDataReducer = createAsyncThunk(
  'getLeaseData',
  async (id) => {
    try {
      const resp = await getLeaseData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);

// edit draft lease
export const updateDraftLeaseReducer = createAsyncThunk(
  'updateDraftLease',
  async (payload) => {
    try {
      const resp = await updateDraftLease(payload);
      return resp;
    } catch (error) { }
  }
);

//cancel/approve LeaseData
export const updateLeaseStatusReducer = createAsyncThunk(
  'updateLeaseData',
  async (payload) => {
    try {
      const resp = await updateLeaseStatus(payload);
      return resp;
    } catch (error) { }
  }
);

//terminate LeaseData
export const terminateLeaseDataReducer = createAsyncThunk(
  'cancelLeaseData',
  async (payload) => {
    try {
      const resp = await terminateLease(payload);
      return resp;
    } catch (error) { }
  }
);

export const LeaseAnalyticsDataReducer = createAsyncThunk(
  '/analyticsLeaseData',
  async (params) => {
    try {
      const resp = await getAnalyticsData(params);
      return resp?.data;
    } catch (error) { }
  }
);

export const RenewalListingReducer = createAsyncThunk(
  '/renewal-requests',
  async (params) => {
    try {
      const resp = await renewalListingData(params);
      return resp?.data;
    } catch (error) { }
  }
);

export const ApproveRenewalRequestReducer = createAsyncThunk(
  'renewal-requests/approve',
  async (payload) => {
    try {
      const resp = await approveRenewalRequest(payload);
      return resp?.data;
    } catch (error) { }
  }
);

export const DenyRenewalRequestReducer = createAsyncThunk(
  'renewal-requests/deniedRequests',
  async (requestId) => {
    try {
      const resp = await denyRenewalRequest(requestId);
      return resp?.data;
    } catch (error) { }
  }
);

export const ReminderListingReducer = createAsyncThunk(
  '/renewal-requests',
  async (params) => {
    try {
      const resp = await reminderListingData(params);
      return resp?.data;
    } catch (error) { }
  }
);

export const CreateSingleReminderReducer = createAsyncThunk(
  'createSingleReminder',
  async (payload) => {
    try {
      const resp = await createSingleReminder(payload);
      return resp?.data;
    } catch (error) { }
  }
)

export const CreateBulkReminderReducer = createAsyncThunk(
  'createBulkReminder',
  async (payload) => {
    try {
      const resp = await createBulkReminder(payload);
      return resp?.data;
    } catch (error) { }
  }
)

const LeaseSlice = createSlice({
  name: 'leaseSlice',
  initialState,
  reducers: {},

  extraReducers: {
    //addLease
    [CreateLeaseReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateLeaseReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [CreateLeaseReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // leaseListing
    [LeaseListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [LeaseListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.leaseListingData = action?.payload?.data ?? initialListingState;
    },
    [LeaseListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get building data
    [getLeaseDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getLeaseDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.leaseDetailData = action?.payload;
    },
    [getLeaseDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //update draft lease
    [updateDraftLeaseReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [updateDraftLeaseReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [updateDraftLeaseReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //update lease status lease
    [updateLeaseStatusReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [updateLeaseStatusReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [updateLeaseStatusReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //terminate lease
    [terminateLeaseDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [terminateLeaseDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [terminateLeaseDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // LeaseAnalytics
    [LeaseAnalyticsDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [LeaseAnalyticsDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.leaseAnalyticsData = action?.payload?.data;
    },
    [LeaseAnalyticsDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // renewalListing
    [RenewalListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [RenewalListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.renewalListingData = action?.payload?.data ?? initialListingState;
    },
    [RenewalListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //approve renewal request
    [ApproveRenewalRequestReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ApproveRenewalRequestReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [ApproveRenewalRequestReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //deny renewal request
    [DenyRenewalRequestReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [DenyRenewalRequestReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [DenyRenewalRequestReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // reminderListing
    [ReminderListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ReminderListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.reminderListingData = action?.payload?.data ?? initialListingState;
    },
    [ReminderListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //create single reminder
    [CreateSingleReminderReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateSingleReminderReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [CreateSingleReminderReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //create bulk reminder
    [CreateBulkReminderReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateBulkReminderReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [CreateBulkReminderReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default LeaseSlice.reducer;
