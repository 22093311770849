import { Checkbox, Collapse, Select } from 'antd';
import { useSearchParams } from 'react-router-dom';
import {
  contractStatus,
  unitTypeStatus,
  RentalTypeArr,
  furnishings,
  subunitRentalTypeArr,
} from 'staticData/PropertyData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUnitNameDataReducer } from 'Redux/slices/property/UnitSlice';
import { masterUserDataReducer } from 'Redux/slices/property/UserSlice';

const UnitFilter = ({ filter, setFilter, subUnitFilter }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const { masterUserData } = useSelector((store) => store.User);
  const { buildingsName } = useSelector((state) => state.Property);
  const { unitNameData } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const handleFilterChange = (isChecked, name, value) => {
    let updatedValue = value;

    if (Array.isArray(value)) {
      updatedValue = value.join(',');
    }

    if (isChecked) {
      setFilter({ ...filter, [name]: updatedValue });
    } else {
      setFilter({ ...filter, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };

  useEffect(() => {
    dispatch(masterUserDataReducer());
  }, []);

  useEffect(() => {
    if (filter.buildingId) {
      dispatch(getUnitNameDataReducer(filter.buildingId));
    } else {
      handleFilterChange(true, 'flatIds', []);
    }
  }, [filter.buildingId]);

  return (
    <div className="flex w-80 flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel header={<h2 className="font-bold">Type</h2>}>
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {unitTypeStatus?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={filter?.flatType === data ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(e.target?.checked, 'flatType', data)
                  }
                >
                  <label htmlFor={data}>{data}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Lease Status</h2>}>
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {contractStatus?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={filter?.contractStatus === data ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(
                      e.target?.checked,
                      'contractStatus',
                      data
                    )
                  }
                >
                  <label htmlFor={data}>{data}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Property</h2>}>
          <Select
            placeholder="Select Property"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'buildingId', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {buildingsName?.rows?.map((cur, index, key) => (
              <Select.Option value={cur?.id} key={cur?.id}>
                {cur?.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Unit</h2>}>
          <Select
            placeholder="Select Unit"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'flatIds', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            mode="multiple" // Enable multiple selection
            disabled={!filter.buildingId}
          >
            {unitNameData?.rows?.map((cur, index, key) => (
              <Select.Option value={cur?.id} key={cur?.id}>
                {cur?.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Owner</h2>}>
          <Select
            placeholder="Select Owner"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'ownerIds', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            mode="multiple" // Enable multiple selection
          >
            {masterUserData?.rows?.map((owner) => (
              <Select.Option
                key={owner?.id}
                value={owner?.id}
                buildingName={owner?.name}
              >
                {owner?.name}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Furnishing</h2>}>
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {furnishings?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={filter?.furnishing === data ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(e.target?.checked, 'furnishing', data)
                  }
                >
                  <label htmlFor={data}>{data}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        {subUnitFilter ? (
          <Collapse.Panel header={<h2 className="font-bold">Rental Type</h2>}>
            <div className="grid grid-cols-2 items-center justify-between gap-2">
              {subunitRentalTypeArr?.map((data, index) => (
                <div key={index} className="flex items-baseline">
                  <Checkbox
                    id={data}
                    checked={filter?.rentalType === data ? true : false}
                    style={{ margin: '0px' }}
                    onChange={(e) =>
                      handleFilterChange(e.target?.checked, 'rentalType', data)
                    }
                  >
                    <label htmlFor={data}>{data}</label>
                  </Checkbox>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        ) : (
          <Collapse.Panel header={<h2 className="font-bold">Rental Type</h2>}>
            <div className="grid grid-cols-2 items-center justify-between gap-2">
              {RentalTypeArr?.map((data, index) => (
                <div key={index} className="flex items-baseline">
                  <Checkbox
                    id={data}
                    checked={filter?.rentalType === data ? true : false}
                    style={{ margin: '0px' }}
                    onChange={(e) =>
                      handleFilterChange(e.target?.checked, 'rentalType', data)
                    }
                  >
                    <label htmlFor={data}>{data}</label>
                  </Checkbox>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        )}
      </Collapse>
    </div>
  );
};

export default UnitFilter;
