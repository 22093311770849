import { Space, Tag } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { Link } from 'react-router-dom';

const NoticeCard = ({
  noticeTitle,
  status,
  category,
  createdDate,
  propertyName,
  toFrom,
  id,
}) => {
  const handleStatus = () => {};
  return (
    <div className="cardCss h-[14rem] max-h-[14rem] w-[30%] overflow-hidden">
      <div className="flex w-full flex-col justify-start">
        <div className="flex w-full justify-between gap-2">
          <Link
            to={`/admin/notices/detail/${id}`}
            className="w-[80%] overflow-hidden"
          >
            <Title level={4} className="truncate">
              {noticeTitle}
            </Title>
          </Link>
          <Tag
            color={
              status == 'Active' ? 'green' : status == 'Future' ? 'blue' : 'red'
            }
            className="max-h-6 rounded-xl px-4 text-[10px]"
          >
            {status}
          </Tag>
        </div>
        <Space className="bVariant leading-3">{toFrom}</Space>
        {/* <div
          className="bVariant overflow-hidden text-ellipsis whitespace-nowrap text-sm"
          dangerouslySetInnerHTML={{
            __html: descriptions,
          }}
        /> */}
        <div className="my-5 flex flex-col gap-3">
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Space className="colorFontSm">Created At</Space>
              <Space className="bVariant">{createdDate}</Space>
            </div>
            <div className="flex flex-col">
              <Space className="colorFontSm">Category</Space>
              <Space className="bVariant">{category}</Space>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Space className="colorFontSm">Building</Space>
              <Space className="bVariant">{propertyName}</Space>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NoticeCard);
