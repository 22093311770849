import { NoticeListingReducer } from 'Redux/slices/NoticeSlice';
import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  MixedHeadContent,
  NoticeCard,
  TabComponent,
} from 'component/CompIndex';
import NoticeFilter from 'component/filter/NoticeFilter';
import dayjs from 'dayjs';
import NoData from 'layouts/NoData';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomDateFormat,
  getDateFormat,
  getFirstPropertyAndCount,
} from 'utils/UtilsIndex';

const NoticeListing = () => {
  const {
    noticeListingData: { rows, count },
    isLoadAgain,
    isSuccess,
  } = useSelector((state) => state.Notice);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [allFilters, setAllFilters] = useState({
    buildingId: '',
    status: '',
    noticeType: '',
    noticeFor: '',
    validFrom: '',
    validTill: '',
  });
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [noticeData, setNoticeData] = useState([]);

  const filterNotifications = (type) => {
    return noticeData?.filter((data, index) => data?.category === type);
  };
  const NoticeTabData = ({ noticeData }) => {
    return (
      <div className="my-8 flex flex-wrap gap-8">
        {noticeData?.length > 0
          ? noticeData.map((cur, index) => (
              <NoticeCard
                key={index}
                noticeTitle={cur?.title}
                id={cur.id}
                descriptions={cur?.description}
                // descriptions="Notice Description, lorem ipsum do..."
                category={cur?.category}
                createdDate={getDateFormat(cur?.createdAt)}
                propertyName={getFirstPropertyAndCount(cur?.noticeBuildings)}
                status={handleStatus(cur?.validFrom, cur?.validTill)}
                toFrom={`${getCustomDateFormat(
                  cur?.validFrom,
                  'MM/DD'
                )} - ${getCustomDateFormat(cur?.validTill, 'MM/DD')}`}
              />
            ))
          : isSuccess && <NoData text="Add Notice" navigate="add" />}
      </div>
    );
  };
  const items = [
    {
      key: '1',
      label: `All `,
      children: <NoticeTabData noticeData={noticeData} />,
    },
    {
      key: '2',
      label: `Announcements`,
      children: (
        <NoticeTabData noticeData={filterNotifications('Announcement')} />
      ),
    },
    {
      key: '3',
      label: `Events`,
      children: <NoticeTabData noticeData={filterNotifications('Event')} />,
    },
  ];

  const handleLoad = () => {
    setPageNo(pageNo + 1);
  };
  const handleStatus = (validFrom, validTill) => {
    const from = new Date(validFrom);
    const too = new Date(validTill);
    const curr = new Date();
    if (from > curr) {
      return 'Future';
    } else if (too < curr) {
      return 'In-Active';
    } else {
      return 'Active';
    }
  };
  const handleFilter = () => {};
  const fetchData = () => {
    const finalValidTill = allFilters?.validTill
      ? dayjs(allFilters?.validTill).format('YYYY-MM-DD')
      : '';
    const finalValidFrom = allFilters?.validFrom
      ? dayjs(allFilters?.validFrom).format('YYYY-MM-DD')
      : '';

    const param = {
      limit: 9 * pageNo,
      title: search,
      status: allFilters.status,
      buildingId: allFilters?.buildingId ?? selectedBuildingName,
      noticeType: allFilters?.noticeType,
      noticeFor: allFilters?.noticeFor,
      validFrom: finalValidFrom,
      validTill: finalValidTill,
    };
    dispatch(NoticeListingReducer(param));
  };
  useEffect(() => {
    fetchData();
  }, [selectedBuildingName, search, pageNo, allFilters, isLoadAgain]);

  useEffect(() => {
    const limit = 9 * pageNo;
    if (limit < count) {
      setShowLoadMore(true);
    } else if (showLoadMore) {
      setShowLoadMore(false);
    }
    setNoticeData(rows);
  }, [rows]);

  return (
    <div>
      <div className="relative">
        <Title level={3}>Notices</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            filterComp=<NoticeFilter
              allFilters={allFilters}
              setAllFilters={setAllFilters}
            />
            AddNew={'Add Notice'}
            route="/admin/notices/add"
            {...{
              setSearch,
              handleFilter,
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'1'} />
      </div>
      {showLoadMore && (
        <div className="center">
          <Button onClick={handleLoad}>Load More</Button>
        </div>
      )}
    </div>
  );
};

export default NoticeListing;
