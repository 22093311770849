import { Action, Chip, DataTable } from 'component/CompIndex';
import { Link, Navigate } from 'react-router-dom';

export const availabilityType = ['Available', 'Occupied'];

export const departmentType = [
  {
    name: 'Daily Help',
    value: 'DAILY_HELP',
  },
  {
    name: 'House Keeping',
    value: 'HOUSEKEEPING',
  },
  {
    name: 'Engineering',
    value: 'ENGINEERING',
  },
  {
    name: 'Security',
    value: 'SECURITY',
  },
  {
    name: 'Contractual',
    value: 'CONTRACTUAL',
  },
  {
    name: 'Front Desk',
    value: 'FRONT_DESK',
  },
];

export const designationType = [
  {
    name: 'Associate',
    value: 'ASSOCIATE',
  },
  {
    name: 'Assistant',
    value: 'ASSISTANT',
  },
  {
    name: 'Contractual',
    value: 'CONTRACTUAL',
  },
  {
    name: 'Manager',
    value: 'MANAGER',
  },
];

export const timeArr = [
  { time: '12:00 AM', value: 0 },
  { time: '1:00 AM', value: 1 },
  { time: '2:00 AM', value: 2 },
  { time: '3:00 AM', value: 3 },
  { time: '4:00 AM', value: 4 },
  { time: '5:00 AM', value: 5 },
  { time: '6:00 AM', value: 6 },
  { time: '7:00 AM', value: 7 },
  { time: '8:00 AM', value: 8 },
  { time: '9:00 AM', value: 9 },
  { time: '10:00 AM', value: 10 },
  { time: '11:00 AM', value: 11 },
  { time: '12:00 PM', value: 12 },
  { time: '1:00 PM', value: 13 },
  { time: '2:00 PM', value: 14 },
  { time: '3:00 PM', value: 15 },
  { time: '4:00 PM', value: 16 },
  { time: '5:00 PM', value: 17 },
  { time: '6:00 PM', value: 18 },
  { time: '7:00 PM', value: 19 },
  { time: '8:00 PM', value: 20 },
  { time: '9:00 PM', value: 21 },
  { time: '10:00 PM', value: 22 },
  { time: '11:00 PM', value: 23 },
];

export const workingDaysArr = [
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
  {
    label: 'Sunday',
    value: 7,
  },
];

export const departmentArr = [
  {
    key: 'FRONT_DESK',
    value: 'Front Desk',
  },
  {
    key: 'HOUSEKEEPING',
    value: 'Housekeeping',
  },
  {
    key: 'ENGINEERING',
    value: 'Engineering',
  },
  {
    key: 'SECURITY',
    value: 'Security',
  },
  {
    key: 'DAILY_HELP',
    value: 'Daily Help',
  },
  {
    key: 'CONTRACTUAL',
    value: 'Contractual',
  },
];

export const designationArr = [
  {
    key: 'MANAGER',
    value: 'Manager',
  },
  {
    key: 'ASSOCIATE',
    value: 'Associate',
  },
  {
    key: 'ASSISTANT',
    value: 'Assistant',
  },
  {
    key: 'CONTRACTUAL',
    value: 'Contractual',
  },
];

export const appointmentArr = [
  {
    key: 'FULL_TIME',
    value: 'Full Time',
  },
  {
    key: 'CONTRACTUAL',
    value: 'Contractual',
  },
];

export function StaffRowsData(rows) {
  const data = rows?.map((cur, id) => {
    return {
      key: cur.id,
      staffName: cur?.name,
      phoneNo: `+${cur?.mobileNumber}`,
      department: cur.department,
      designation: cur.designation,
      appointment: cur.appointment,
      availability: 'Available',
    };
  });
  return data;
}

export function StaffColumnData(handleDeleteModalOpen) {
  const columns = [
    {
      title: 'Staff Name',
      dataIndex: 'staffName',
      key: 'staffName',
      render: (text, key) => (
        <Link to={`/admin/myproperties/staff/detail/${key.key}`}>{text}</Link>
      ),
    },
    {
      title: 'Phone No.',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'Appointment',
      dataIndex: 'appointment',
      key: 'appointment',
    },
    {
      title: 'Availability',
      dataIndex: 'availability',
      key: 'availability',
      render: (_, { availability }) => (
        <>
          <Chip
            color={availability === 'Available' ? 'green' : 'red'}
            name="Coming soon"
          />
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, { key }) => (
        <Action data={key} handleDelete={handleDeleteModalOpen} />
      ),
    },
  ];
  return columns;
}

export function StaffItemData(
  columns,
  data,
  count,
  pageNo,
  setPageNo,
  setSearchParam
) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: (
        <>
          <Navigate to="/admin/myproperties/property?page=1" />
        </>
      ),
    },
    {
      key: '2',
      label: `Units`,
      children: (
        <>
          <Navigate to="/admin/myproperties/unit?page=1" />
        </>
      ),
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: (
        <>
          <Navigate to="/admin/myproperties/subunit?page=1" />
        </>
      ),
    },
    {
      key: '3',
      label: `Users`,
      children: (
        <>
          <Navigate to="/admin/myproperties/user?page=1" />
        </>
      ),
    },
    {
      key: '7',
      label: `Company`,
      children: <Navigate to="/admin/myproperties/company?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: (
        <>
          <DataTable
            {...{ columns, data, count, pageNo, setPageNo, setSearchParam }}
          />
        </>
      ),
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}
