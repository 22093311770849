import { removeCountryCode, timeStampToDate } from 'utils/UtilsIndex';
import { getLeaseExportData } from '../Lease/Lease';
import { getStaffExportData } from '../property/Staff';
import { getSubUnitExportData, getUnitExportData } from '../property/Unit';
import { getCompanyExportData, getUserExportData } from '../property/User';
import { getServiceExportData } from '../service/Service';
import { getVisitorExportData } from '../visitor/Visitor';
import { getPropertyExportData } from '../property/Property';
import { getGuardExportData } from '../Guard/Guard';

export const exportPropertyData = async (params) => {
  const resp = await getPropertyExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp.data?.data?.map((item) => ({
      PropertyName: item?.name_en ?? 'NA',
      Locality: item?.locality?.name_en ?? 'NA',
      City: item?.locality?.city?.name_en ?? 'NA',
      Type: item?.buildingType ?? 'NA',
      TotalUnits: item?.totalFlats ?? 'NA',
      TotalVacant: item?.totalVacantFlats ?? 'NA',
      Address: `${item?.locality?.name_en ?? ''}, ${
        item?.locality?.city?.name_en ?? ''
      }`,
      PocName: item?.pocContact?.name ?? 'NA',
      PocEmail: item?.pocContact?.email ?? 'NA',
      PocNumber: `+${
        item?.pocContact?.mobileNumber?.replace(/\D/g, '') ?? 'NA'
      }`,
      PropertyId: item?.propertyId ?? 'NA',
    }));
    return csvFile;
  }
  return [];
};
export const exportUnitData = async (params) => {
  const resp = await getUnitExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp.data?.data?.map((item) => {
      return {
        FlatNumber: item.name_en,
        PropertyName: item.building.name_en,
        FlatStatus: `${item?.flatStatus}`,
        FlatType: item.flatType,
        LeaseStatus: `${item?.leaseStatus}`,
        Period: item?.period ? `${item?.period} Months` : '',
        Owner: item?.owner?.name,
        Floor: item.floor,
        Size: item.size ? item.size + 'sq.ft.' : '',
        Bathroom: item.flatInfo.bathroom,
        Bedroom: item.flatInfo.bedroom,
        ContractStartDate: item.startDate,
        ContractEndDate: item?.contractEndDate,
      };
    });
    return csvFile;
  }
  return [];
};

export const exportSubUnitData = async (params) => {
  const resp = await getSubUnitExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp.data?.data?.map((item) => {
      return {
        SubFlatId: item?.subFlatId,
        SubUnitName: item.name,
        UnitName: item?.flat?.name,
        PropertyName: item?.flat?.building?.name,
        SubFlatStatus: `${item?.subFlatStatus}`,
        LeaseStatus: `${item?.leaseStatus}`,
        Owner: item?.owner?.name,
        Size: item.size ? item.size + 'sq.ft.' : '',
        RentalType: item?.rentalType,
        furnishing: item?.furnishing,
      };
    });
    return csvFile;
  }
  return [];
};

export const exportCompanyData = async (params) => {
  const resp = await getCompanyExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp.data?.data?.map((item) => {
      return {
        Company_Name: item?.name,
        Mobile_Number: `+${item?.mobileNumber}` ?? 'NA',
        CompanyType: item.companyType,
        licenseNumber: item?.licenseNumber,
        tradeLicense: item?.tradeLicense,
        companyCountry: item?.companyCountry,
        cities: item?.cities?.name_en,
      };
    });
    return csvFile;
  }
  return [];
};

export const exportUserData = async (params) => {
  const resp = await getUserExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp?.data?.data?.map((item) => ({
      Name: item.name,
      Email: item.email,
      CountryCode: `+${item.countryCode}`,
      MobileNumber: removeCountryCode(item?.mobileNumber, item?.countryCode)
        .number,
      Gender: item.gender,
      Nationality: item.nationality,
      profilePicture: item.profilePicture,
      Flat: item.tenantedFlat,
      Property: item.tenantedBuilding,
      DateOfBirth: item.dateOfBirth,
      UserType: item.userType,
      DocumentId: item.documentId,
      DocumentType: item.documentType,
      // Documents:
      //   item?.documentType === 'Passport'
      //     ? item?.passport?.[0] || ''
      //     : item?.govId?.[0] || '',
    }));
    return csvFile;
  }
  return [];
};

export const exportStaffData = async (params) => {
  const resp = await getStaffExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp?.data?.data?.map((item) => ({
      Name: item.name,
      // Email: item.email,
      CountryCode: `+${item.countryCode}`,
      MobileNumber: removeCountryCode(item?.mobileNumber, item?.countryCode)
        .number,
      Department: item?.department,
      Designation: item?.designation,
      Appointment: item?.appointment,
      // profilePicture: item.profilePicture,
    }));
    return csvFile;
  }
  return [];
};

export const exportServiceData = async (params) => {
  const resp = await getServiceExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp?.data?.data?.map((item) => ({
      RequestId: item?.requestId,
      Property: item?.building,
      Flat: item?.flat?.name_en,
      UserName: item?.username,
      CreationDate: item?.createdAt?.split('T')[0],
      CreationTime: timeStampToDate(item?.createdAt, 'YYYY/MM/DD')
        ?.formattedTime,
      RequestStatus: item?.status,
      Category: item?.type,
      Description: item?.description,
      RequestedFor: item?.isBuilding ? 'Public Area' : 'Unit',
      Type: item?.isUrgent ? 'Urgent' : 'Normal',
      UpdateDate: item?.updatedAt?.split('T')?.[0],
      UpdateTime: timeStampToDate(item?.updatedAt, 'YYYY/MM/DD').formattedDate,
      StaffName: item?.staff && item?.staff?.name,
      VisitInTime: timeStampToDate(item?.staffTimeSlot?.start, 'YYYY/MM/DD')
        ?.formattedTime,
      VisitInDate: item?.staffTimeSlot?.start.split('T')?.[0],
      VisitEndTime: timeStampToDate(item?.staffTimeSlot?.end, 'YYYY/MM/DD')
        ?.formattedTime,
      VisitEndDate: item?.staffTimeSlot?.end?.split('T')?.[0],
      FilesName: item?.files && item?.files?.map((item) => item?.name),
      FileUrl: item?.files && item?.files?.map((item) => item?.url),
    }));
    return csvFile;
  }
  return [];
};

export const exportLeaseData = async (params) => {
  const resp = await getLeaseExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp?.data?.data?.map((item) => ({
      leaseId: item?.leaseId,
      Property: item?.flat?.building?.name_en,
      UnitNo: item?.flat?.name_en,
      Owner: item?.owner?.name ?? 'NA',
      Owner_Email: item?.owner?.email ?? 'NA',
      Owner_Mobile: `+${item?.owner?.mobileNumber}` ?? 'NA',
      Tenant: item?.resident?.name ?? 'NA',
      Tenant_Mobile: `+${item?.resident?.mobileNumber}` ?? 'NA',
      Tenant_Email: item?.resident?.email ?? 'NA',
      Start_Date: item.startDate?.split('T')?.[0],
      End_Date: item?.endDate?.split('T')?.[0],
      Move_In_Date: item?.moveInDate?.split('T')?.[0],
      Move_Out_Date: item?.moveOutDate?.split('T')?.[0],
      NoticePeriod: item?.noticePeriod,
      LeaseType: item?.flatUsage,
      GovCharges: item?.activationFee,
      RentAmount: item?.rentAmount,
      SecurityDeposit: item?.securityDeposit,
      paymentFrequency: item?.paymentFrequency,
      PaymentMode: item?.paymentMode,
      discount_Applicable: item?.discount?.discountApplicability,
      Discount_Type: item?.discount?.discountType,
      Discount_Value: item?.discount?.discountValue,
      grace: item?.discount?.grace,
    }));
    return csvFile;
  }
  return [];
};

export const exportVisitorData = async (params) => {
  const resp = await getVisitorExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp?.data?.data?.map((item) => ({
      VisitorName: item?.name,
      MobileNumber: item?.visitorNumber,
      Email: item?.email,
      Property: item?.building,
      Floor: item?.floor,
      Unit: item?.flatName ?? 'NA',
      Address: item?.additionalDetails?.address ?? 'NA',
      Category: item?.category,
      CompanyName: item?.visitorCompanyName ?? 'NA',
      InDate: item?.checkIn && item?.checkIn?.slice(0, 10),
      InTime: item?.checkIn && item?.checkIn?.slice(11, 19),
      OutDate: item?.checkOut && item?.checkOut?.slice(0, 10),
      OutTime: item?.checkOut && item?.checkOut?.slice(11, 19),
      VisitorsCount: item?.visitingsCount ?? 'NA',
      Gender: item?.additionalDetails?.gender ?? 'NA',
      DateOfBirth: item?.dateOfBirth ?? 'NA',
      Age: item?.additionalDetails?.age ?? 'NA',
      Nationality: item?.documentCountry ?? 'NA',
      DocumentCountry: item?.documentCountry ?? 'NA',
      DocumentId: item?.documentId ?? 'NA',
      NameOnDocument: item?.nameOnDocument ?? 'NA',
      NumberOnDocument: item?.numberOnDocument ?? 'NA',
      DocumentImage: item?.documentImage ?? 'NA',
      DocumentIssueState: item?.documentIssueState ?? 'NA',
      DocumentIssueDate: item?.documentIssueDate ?? 'NA',
      DocumentExpiry: item?.documentExpiry ?? 'NA',
      PassportNumber: item?.passportNumber ?? 'NA',
      DocumentIssueState: item?.documentIssueState ?? 'NA',
      DocumentExpiry: item?.documentExpiry ?? 'NA',
      VehicleNumber: item?.vehicleNumber ?? 'NA',
      LicenseIssueAuthority:
        item?.additionalDetails?.licenseIssueAuthority ?? 'NA',
      Description: item?.description !== '' ? item?.description : 'NA',
      ApprovedBy: item.approvedBy ?? 'NA',
    }));
    return csvFile;
  }
  return [];
};

export const exportGuardData = async (params) => {
  const resp = await getGuardExportData(params);
  if (resp?.data?.status === 'success') {
    let csvFile = resp?.data?.data?.map((item) => ({
      Name: item?.name,
      Gender: item?.gender ?? 'NA',
      Nationality: item?.nationality ?? 'NA',
      Building_Name: item?.buildingName,
      Status: item?.status,
      Company: item?.company ?? 'NA',
      Document_Type: item?.documentType ?? 'NA',
      CountryCode: `+${item.countryCode}`,
      MobileNumber: removeCountryCode(item?.mobileNumber, item?.countryCode)
        .number,
      Alternate_Number: item?.alternateContact?.contact ?? 'NA',
      PocNumber: item?.pocContact?.mobileNumber ?? 'NA',
    }));
    return csvFile;
  }
  return [];
};