import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loginService } from 'Redux/services/login';
import { toast } from 'react-toastify';
import { setAdminInfo } from 'utils/UtilsIndex';

const initialState = {
  isLoading: false,
  isSuccess: false,
  loginCred: {},
};

export const loginReducer = createAsyncThunk('/login', async (data) => {
  try {
    const { values, navigate } = data;
    const resp = await loginService(values);
    // todo - need to fix \\
    if (resp?.data?.status === 'success') {
      setAdminInfo('token', resp.data.data.accessToken);
      setAdminInfo('reToken', resp.data.data.refreshToken);
      navigate('/admin');
    } else {
      toast.error(resp?.data?.msg);
    }
  } catch (error) {
    toast.error(error?.response?.data?.msg);
  }
});

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},

  extraReducers: {
    [loginReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [loginReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [loginReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export default loginSlice.reducer;
