import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { exportServiceData } from 'Redux/services/ExportCsv/Export';
import {
  ServiceCategoryDataReducer,
  ServiceListingReducer,
  ServiceRequestCountReducer,
} from 'Redux/slices/ServiceSlice';
import { Tag } from 'antd';
import Title from 'antd/es/typography/Title';
import { DataTable, MixedHeadContent } from 'component/CompIndex';
import ServiceFilter from 'component/filter/ServiceFilter';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getServiceTableData } from 'staticData/ServiceData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const ServiceListing = () => {
  const {
    serviceListingData: { rows, count },
    serviceRequestCountData,
    adminCategoryData,
  } = useSelector((state) => state.Service);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const scrollRef = useRef(null);
  const [activeTab, setActiveTab] = useState(null);
  const [arrowDisable, setArrowDisable] = useState(true);
  const [searchParams, setSearchParam] = useSearchParams();
  const [allFilters, setAllFilters] = useState({
    adminCategory: '',
    userCategory: '',
    priorityType: '',
    date: null,
  });
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const { data, columns } = getServiceTableData(rows);
  const handleFilter = () => {};
  const handleExport = async () => {
    const updatedDate = allFilters.date
      ? dayjs(allFilters.date).format('YYYY-MM-DD')
      : '';
    const params = {
      status: activeTab,
      categoryId: allFilters.adminCategory,
      buildingId: selectedBuildingName,
      serviceRequestDate: updatedDate,
      subCategoryId: allFilters.userCategory,
      isUrgent: allFilters.priorityType,
      search,
    };
    return await exportServiceData(params);
  };
  const handleHorizontalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const handleTabChange = (key) => {
    setSearchParam({
      page: 1,
    });
    setActiveTab(key);
  };
  useEffect(() => {
    const updatedDate = allFilters.date
      ? dayjs(allFilters.date).format('YYYY-MM-DD')
      : '';

    const params = {
      page: defaultPageNo,
      limit: 10,
      search,
      categoryId: allFilters.adminCategory,
      serviceRequestDate: updatedDate,
      subCategoryId: allFilters.userCategory,
      isUrgent: allFilters.priorityType,
      status: activeTab,
      buildingId: selectedBuildingName,
    };
    dispatch(ServiceListingReducer(params));
  }, [
    defaultPageNo,
    search,
    selectedBuildingName,
    allFilters,
    activeTab,
    dispatch,
  ]);
  useEffect(() => {
    dispatch(ServiceCategoryDataReducer());
  }, [dispatch]);

  useEffect(() => {
    const updatedDate = allFilters.date
      ? dayjs(allFilters.date).format('YYYY-MM-DD')
      : '';
    const params = {
      buildingId: selectedBuildingName,
      list: 'new',
      search,
      categoryId: allFilters.adminCategory,
      serviceRequestDate: updatedDate,
      subCategoryId: allFilters.userCategory,
      isUrgent: allFilters.priorityType,
    };
    dispatch(ServiceRequestCountReducer(params));
  }, [selectedBuildingName, dispatch, allFilters, search]);

  return (
    <div>
      <Title level={3}>Service Requests</Title>
      <div className="flex h-full items-center justify-between gap-8">
        <div className="flex w-[32rem] items-center justify-between gap-4">
          {!arrowDisable && (
            <LeftCircleOutlined
              onClick={() => {
                handleHorizontalScroll(scrollRef.current, 25, 100, -10);
              }}
              className="cursor-pointer rounded-full p-1 text-[#fe8b02] shadow-md"
            />
          )}
          <div
            className="flex w-[30rem] items-center justify-between gap-4 overflow-hidden"
            ref={scrollRef}
          >
            {serviceRequestCountData?.map((data, index) => (
              <div
                key={index}
                className={`${
                  index === 0 && 'border-none'
                } flex items-center justify-start gap-4 whitespace-nowrap  border-l-[1px] border-[#e9e9e9] pl-3`}
              >
                <span
                  className={`${
                    activeTab === data?.key && 'text-[#fe8b02] '
                  } flex  cursor-pointer`}
                  onClick={() => handleTabChange(data?.key)}
                >
                  {data?.label}
                </span>
                <Tag
                  color={`${activeTab === data?.key ? 'orange' : ''}`}
                  className={`${
                    activeTab === data?.key && 'text-[#fe8b02]'
                  } rounded-lg px-2`}
                >
                  {data?.count || 0}
                </Tag>
              </div>
            ))}
          </div>
          <RightCircleOutlined
            onClick={() => {
              handleHorizontalScroll(scrollRef.current, 25, 100, 10);
            }}
            className="cursor-pointer rounded-full p-1 text-[#fe8b02] shadow-md"
          />
        </div>
        <MixedHeadContent
          filterComp=<ServiceFilter
            allFilters={allFilters}
            setAllFilters={setAllFilters}
            categoryArr={adminCategoryData}
          />
          AddNew={'Add Request'}
          route="/admin/services/add"
          {...{
            setSearch,
            handleFilter,
            handleExport,
            exportFileName: 'Service Requests',
          }}
        />
      </div>
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </div>
  );
};

export default ServiceListing;
