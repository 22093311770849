import { Button, Form, Space } from 'antd';
import {
  DateField,
  InputField,
  RadioField,
  SelectField,
  TextField,
} from 'component/CompIndex';
import { bulkRadioArr } from 'staticData/LeaseData';
import { timeIntervalArr } from 'staticData/ServiceData';
import { disablePastDates, getIsoFormatFromDate } from 'utils/UtilsIndex';

const BulkReminders = ({
  setIsModalOpen,
  handleSave,
  totalSelectedTitle,
  leaseId,
}) => {
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    values.scheduledTime = getIsoFormatFromDate(
      values?.requestedDate?.$d,
      values?.requestedTime
    );
    if (leaseId) {
      values.leaseId = leaseId;
    }
    delete values?.requestedDate;
    delete values?.requestedTime;
    handleSave(values);
    handleCancel();
  };
  const [form] = Form.useForm();
  const scheduledFor = Form.useWatch('scheduledFor', form);
  return (
    <div className="p-2">
      <div className="text-center text-lg font-semibold">
        Create Bulk Reminders
      </div>
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        className="w-full px-1 py-2"
        initialValues={{ scheduledFor: 'E_MAIL' }}
        form={form}
      >
        <div>
          <RadioField
            radioArr={bulkRadioArr}
            name="scheduledFor"
            selectedName="label"
            selectedValue="value"
          />
          <div className="flex items-center justify-start gap-10">
            <DateField
              label="Schedule Date"
              name="requestedDate"
              isDisabledDate={true}
              disabledDate={disablePastDates}
              width="13rem"
              required
            />
            <SelectField
              label="Schedule Time"
              name="requestedTime"
              placeholder="Slot Preference Time"
              selectArr={timeIntervalArr}
              selectedName="singleName"
              selectedValue="id"
              width="13rem"
              required
              restFields
            />
          </div>
        </div>
        <div className="text-md my-2 -mt-4 text-center font-semibold">
          {totalSelectedTitle}
        </div>
        <Space className="center">
          <Button onClick={handleCancel} className="h-10">
            Cancel
          </Button>
          <Button className="customButton h-10 w-auto px-10" htmlType="submit">
            Create
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default BulkReminders;
