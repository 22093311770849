import { StyleProvider } from '@ant-design/cssinjs';
import 'App.css';
import { ConfigProvider } from 'antd';
import AppLoader from 'component/AppLoader';
import { getMessaging, onMessage } from 'firebase/messaging';
import { AllRoute } from 'pages/routing/AllRoute';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'style/main.css';

function App() {
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {});

  return (
    <>
      <ConfigProvider>
        <StyleProvider>
          <Suspense fallback={<AppLoader />}>
            <RouterProvider router={AllRoute} />
          </Suspense>
          <ToastContainer />
        </StyleProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
