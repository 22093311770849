import Title from 'antd/es/typography/Title';
import { TabComponent } from 'component/CompIndex';
import { useSelector } from 'react-redux';
import { companyDataTabItem } from 'staticData/UserData';

const CompanyDetail = () => {
  const { companyDetailData } = useSelector((state) => state.User);
  const items = companyDataTabItem(companyDetailData);
  return (
    <>
      <Title level={3}>Details</Title>
      <TabComponent {...{ items }} activeTabCount={'1'} />
    </>
  );
};

export default CompanyDetail;
