import { Tabs } from 'antd';
import React from 'react';

const TabComponent = ({ items, onChangeTab, activeTabCount }) => {
  return (
    <div className="mt-2">
      <Tabs
        defaultActiveKey={activeTabCount}
        items={items}
        onChange={onChangeTab}
      />
    </div>
  );
};

export default React.memo(TabComponent);
