import { createStaffReducer } from 'Redux/slices/property/StaffSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  CheckBoxField,
  DocumentUpload,
  ImageUpload,
  InputField,
  PasswordField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import countries from 'layouts/localData/CountryCode';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import {
  appointmentArr,
  departmentArr,
  designationArr,
  timeArr,
  workingDaysArr,
} from 'staticData/StaffData';
import { encrypt } from 'utils/Encryption';
import { addCountryInMob, defaultCountryCode } from 'utils/UtilsIndex';

const AddStaff = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [docList, setDocList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);
  const onFinish = (values) => {
    values = addCountryInMob(values, 'countryCode', 'mobileNumber');
    const encryptValue = encrypt(values.password);
    values['password'] = encryptValue;
    values.profilePicture = fileList?.[0]?.url ?? null;
    values.documents = docList;
    dispatch(
      createStaffReducer({
        values,
        navigateTo: { navigate, url: routeObj?.staff },
      })
    );
  };
  const handleCancelData = () => {
    navigate('/admin/myproperties/staff?page=1');
  };
  useEffect(() => {
    const initialValues = {
      countryCode: defaultCountryCode,
    };
    form.setFieldsValue(initialValues);
  }, []);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      initialValues={{
        remember: true,
      }}
      form={form}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New Staff"
        saveButtonText="Add Staff"
      />
      <Row justify="space-between">
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Title level={5}>Staff Details</Title>
          <Row justify="space-between">
            <div className="flex w-full justify-between">
              <InputField
                label="Full Name"
                name="name"
                placeholder="Jack Dem"
                message="This field is required"
                required="true"
                width="18rem"
              />
              <MobileNumber
                label1="Phone Number"
                countryCodeName="countryCode"
                mobilePlaceHolder="Enter Your Number"
                mobileNumberName="mobileNumber"
                countryCode={countryCode}
                required
              />
            </div>
            <div className="flex w-full justify-between">
              <InputField
                label="Email"
                name="email"
                placeholder="poc@email.com"
                required="true"
                pattern={/.*@.*/}
                width="18rem"
              />
              <div className="w-[18rem]">
                <PasswordField
                  label="Password"
                  name="password"
                  type="password"
                  pattern={
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/
                  }
                  patternMessage="Should be atleast 8 characters long and include at least one lowercase, one uppercase,one digit,one special character"
                  placeholder="Create password"
                  width="18rem"
                  required
                />
              </div>
            </div>
            <Title level={5}>ID Details</Title>
            <div className="flex w-full justify-between">
              <SelectField
                label="Nationality"
                name="nationality"
                placeholder="Nationality"
                width="18rem"
                selectArr={countries}
                selectedName="label"
                selectedValue="label"
              />
              <InputField
                label="ID Number"
                name="emiratesId"
                placeholder="ID Number"
                width="18rem"
                required={true}
              />
            </div>
            {/* <div className="flex w-full justify-between">
              <SelectField
                label="Nationality"
                name="nationality"
                placeholder="Nationality"
                width="18rem"
                selectArr={countries}
                selectedName="label"
                selectedValue="label"
              />
              <SelectField
                width="18rem"
                label="Gender"
                name="gender"
                placeholder="Gender"
                selectArr={genderList}
              />
            </div> */}
            <div className="w-full">
              <Title level={5}>Job Details</Title>
              <div className="flex w-full justify-between">
                <SelectField
                  width="18rem"
                  label="Department"
                  name="department"
                  placeholder="Department"
                  required="true"
                  selectedName="value"
                  selectedValue="key"
                  selectArr={departmentArr}
                />
                <SelectField
                  width="18rem"
                  label="Designation"
                  name="designation"
                  placeholder="Designation"
                  selectedName="value"
                  selectedValue="key"
                  selectArr={designationArr}
                />
              </div>
              <div className="flex w-full justify-between">
                <SelectField
                  width="18rem"
                  label="Appointment"
                  name="appointment"
                  placeholder="Appointment"
                  required="true"
                  selectedName="value"
                  selectedValue="key"
                  selectArr={appointmentArr}
                />
              </div>
            </div>
            <div className="w-full">
              <Title level={5}>Working Time</Title>
              <div className="flex w-full justify-between">
                <SelectField
                  width="18rem"
                  label="In Time"
                  name="workingHoursStart"
                  placeholder="In Time"
                  required
                  selectedName="time"
                  selectedValue="value"
                  selectArr={timeArr}
                />
                <SelectField
                  width="18rem"
                  label="Out Time"
                  name="workingHoursEnd"
                  placeholder="Out Time"
                  required="true"
                  selectedName="time"
                  selectedValue="value"
                  selectArr={timeArr}
                />
              </div>
            </div>
            <div>
              <CheckBoxField
                title="Working Days"
                name="workingDays"
                width="60rem"
                selectedName={'label'}
                selectedValue="value"
                required
                options={workingDaysArr}
              />
            </div>
            <div className="w-full">
              <Title level={5}>Lunch Time</Title>
              <div className="flex w-full justify-between">
                <SelectField
                  width="18rem"
                  label="Start Time"
                  name="breakStartHours"
                  placeholder="Start Time"
                  required="true"
                  selectedName="time"
                  selectedValue="value"
                  selectArr={timeArr}
                />
                <SelectField
                  width="18rem"
                  label="End Time"
                  name="breakEndHours"
                  placeholder="End Time"
                  required="true"
                  selectedName="time"
                  selectedValue="value"
                  selectArr={timeArr}
                />
              </div>
            </div>
          </Row>
        </Col>
        <Col span={7} xs={{ span: 7 }} md={{ span: 7 }}>
          <Title level={5}>Add Profile Image</Title>
          <ImageUpload
            {...{
              previewOpen,
              setPreviewOpen,
              previewImage,
              setPreviewImage,
              previewTitle,
              setPreviewTitle,
              fileList,
              setFileList,
              length: 1,
            }}
          />
          <Title level={5}>Attach Documents</Title>
          <DocumentUpload {...{ docList, setDocList }} />
        </Col>
      </Row>
    </Form>
  );
};

export default AddStaff;
