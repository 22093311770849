import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addCsvData,
  addNewAmenities,
  createSubUnit,
  createUnit,
  deleteSubUnitData,
  deleteUnitData,
  editAmenities,
  editAmenitiesStatus,
  editSubUnitData,
  editUnitData,
  getAllAmenities,
  getAmenitiesListing,
  getOwnerData,
  getSubUnitData,
  getSubUnitListing,
  getTenantData,
  getUnitData,
  getUnitListing,
  getUnitNameData,
  getVacantSubUnits,
  getVacantUnitData,
} from 'Redux/services/property/Unit';
import { initialListingState, initialState } from '../InitialState';

export const UnitListingReducer = createAsyncThunk(
  '/unitListing',
  async (data) => {
    const resp = await getUnitListing(data);
    return resp?.data;
  }
);

export const CreateUnitReducer = createAsyncThunk(
  '/createUnit',
  async (payload) => {
    const { navigateTo, values } = payload;
    try {
      const resp = await createUnit(values, navigateTo);
      return resp;
    } catch (error) { }
  }
);

//getUnitData
export const getUnitDataReducer = createAsyncThunk(
  'getUnitData',
  async (id) => {
    try {
      const resp = await getUnitData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);
//getVacantUnitData
export const getVacantUnitReducer = createAsyncThunk(
  'getVacantUnitData',
  async (id) => {
    try {
      const resp = await getVacantUnitData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);

//editUnitData
export const editUnitDataReducer = createAsyncThunk(
  'editUnitData',
  async (payload) => {
    try {
      const resp = await editUnitData(payload);
      return resp;
    } catch (error) { }
  }
);

//deleteUnitData
export const deleteUnitDataReducer = createAsyncThunk(
  'deleteUnitData',
  async (unitId) => {
    try {
      const resp = await deleteUnitData(unitId);
      return resp;
    } catch (error) { }
  }
);

//getUnitNameData
export const getUnitNameDataReducer = createAsyncThunk(
  'getUnitNameData',
  async (id) => {
    try {
      const resp = await getUnitNameData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);

//getOwnerListData
export const getOwnerDataReducer = createAsyncThunk(
  'getOwnerListData',
  async (id) => {
    try {
      const resp = await getOwnerData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);
export const getTenantDataReducer = createAsyncThunk(
  'getTenantListData',
  async (id) => {
    try {
      const resp = await getTenantData();
      return resp?.data?.data;
    } catch (error) { }
  }
);
export const importCsvDataReducer = createAsyncThunk(
  'importCsv',
  async (payload) => {
    try {
      const resp = await addCsvData(payload);
      return resp?.data;
    } catch (error) { }
  }
);

export const getVacantSubUnitReducer = createAsyncThunk(
  '/vacantSubUnit',
  async (data) => {
    try {
      const resp = await getVacantSubUnits(data);
      return resp?.data?.data;
    } catch (error) { }
  }
);

export const SubUnitListingReducer = createAsyncThunk(
  '/subUnitListing',
  async (data) => {
    const resp = await getSubUnitListing(data);
    return resp?.data;
  }
);

export const CreateSubUnitReducer = createAsyncThunk(
  '/createSubUnit',
  async (payload) => {
    const { values, navigateTo } = payload;
    try {
      const resp = await createSubUnit(values, navigateTo);
      return resp;
    } catch (error) { }
  }
);

//getSubUnitData
export const getSubUnitDataReducer = createAsyncThunk(
  'getSubUnitData',
  async (id) => {
    try {
      const resp = await getSubUnitData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);

//editUnitData
export const editSubUnitDataReducer = createAsyncThunk(
  'editSubUnitData',
  async (payload) => {
    const { navigateTo, id, values } = payload;
    try {
      const resp = await editSubUnitData(id, values, navigateTo);
      return resp;
    } catch (error) { }
  }
);

//deleteSubUnitData
export const deleteSubUnitDataReducer = createAsyncThunk(
  'deleteSubUnitData',
  async (subUnitId) => {
    try {
      const resp = await deleteSubUnitData(subUnitId);
      return resp;
    } catch (error) { }
  }
);

//add new amenities 
export const addNewAmenitiesReducer = createAsyncThunk(
  'addNewAmenities',
  async (payload) => {
    const resp = await addNewAmenities(payload);
    return resp;
  }
)
//amenities listing
export const amenitiesListingDataReducer = createAsyncThunk(
  'amenitiesListing',
  async (params) => {
    const resp = await getAmenitiesListing(params);
    return resp?.data?.data;
  }
)

//amenities listing
export const allAmenitiesDataReducer = createAsyncThunk(
  'allAmenities',
  async (raisedFor) => {
    const resp = await getAllAmenities(raisedFor);
    return resp?.data?.data;
  }
)
//edit amenities status
export const editAmenitiesStatusReducer = createAsyncThunk(
  'editAmenitiesStatus',
  async (id) => {
    const resp = await editAmenitiesStatus(id);
    return resp;
  }
)
//edit amenities 
export const editAmenitiesReducer = createAsyncThunk(
  'editAmenities',
  async (payload) => {
    const resp = await editAmenities(payload);
    return resp;
  }
)

const UnitSlice = createSlice({
  name: 'unitSlice',
  initialState,
  reducers: {
    clearAmenitiesReducer(state) {
      state.amenities = initialListingState;
    }
  },

  extraReducers: {
    //listing
    [UnitListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [UnitListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.unitListingData = action?.payload?.data ?? initialListingState;
    },
    [UnitListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //create
    [CreateUnitReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateUnitReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [CreateUnitReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get unit data
    [getUnitDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getUnitDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.unitDetailData = action?.payload;
    },
    [getUnitDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get vacant unit data
    [getVacantUnitReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getVacantUnitReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.vacantUnitData = action?.payload;
    },
    [getVacantUnitReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editUnitData
    [editUnitDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editUnitDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editUnitDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //deleteUnitData
    [deleteUnitDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteUnitDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteUnitDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get unit name data
    [getUnitNameDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getUnitNameDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.unitNameData = action?.payload;
    },
    [getUnitNameDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get owner list data
    [getOwnerDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getOwnerDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.ownerListData = action?.payload;
    },
    [getOwnerDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get tenant list data
    [getTenantDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getTenantDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.tenantData = action?.payload;
    },
    [getTenantDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //Import csv file data
    [importCsvDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [importCsvDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [importCsvDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get vacant sub-unit data
    [getVacantSubUnitReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getVacantSubUnitReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.vacantSubUnitData = action?.payload;
    },
    [getVacantSubUnitReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //subUnitListing
    [SubUnitListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [SubUnitListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.subUnitListingData = action?.payload?.data ?? initialListingState;
    },
    [SubUnitListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //sub unit create
    [CreateSubUnitReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateSubUnitReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [CreateSubUnitReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get subUnit data
    [getSubUnitDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getSubUnitDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.subUnitDetailData = action?.payload;
    },
    [getSubUnitDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editSubUnitData
    [editSubUnitDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editSubUnitDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editSubUnitDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //deleteSubUnitData
    [deleteSubUnitDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteSubUnitDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteSubUnitDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //add new amenities
    [addNewAmenitiesReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [addNewAmenitiesReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [addNewAmenitiesReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //amenities Listing
    [amenitiesListingDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [amenitiesListingDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.amenities = action.payload ?? initialListingState
    },
    [amenitiesListingDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //all amenities dropdown
    [allAmenitiesDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [allAmenitiesDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.amenities = action.payload ?? initialListingState
    },
    [allAmenitiesDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // edit amenities status
    [editAmenitiesStatusReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editAmenitiesStatusReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editAmenitiesStatusReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // edit amenities
    [editAmenitiesReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editAmenitiesReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editAmenitiesReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export const { clearAmenitiesReducer } = UnitSlice.actions;
export default UnitSlice.reducer;
