import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getDashboardOverview,
  getDeliveryOverview,
  getLeaseBiCountStats,
  getLeaseStatsDonut,
  getLeaseStatusStats,
  getMostRequested,
  getServiceAnalyticsDataReducer,
  getServiceBifurcation,
  getServiceOverView,
  getUnitAnalytics,
  getUserOverView,
  getVisitorAnalyticsData,
  getVisitorLiveData,
  getVisitorOverView,
  getVisitorStats,
  getVisitorStatsDaily,
  getVisitorStatsHour,
  getVisitorStatsWeek,
} from 'Redux/services/Dashboard/dashboard';
import { initialState } from './InitialState';

export const DashboardOverviewReducer = createAsyncThunk(
  'dashboardOverview',
  async () => {
    const resp = await getDashboardOverview();
    return resp?.data;
  }
);
export const ServiceAnalyticsDataReducer = createAsyncThunk(
  'serviceOverview',
  async (params) => {
    const resp = await getServiceAnalyticsDataReducer(params);
    return resp?.data;
  }
);
export const VisitorAnalyticsDataReducer = createAsyncThunk(
  'visitorOverview',
  async (params) => {
    try {
      const resp = await getVisitorAnalyticsData(params);
      return resp?.data;
    } catch (error) {}
  }
);

export const ServiceOverViewReducer = createAsyncThunk(
  'serviceOverView',
  async (params) => {
    const resp = await getServiceOverView(params);
    return resp?.data;
  }
);
export const VisitorOverViewReducer = createAsyncThunk(
  'visitorOverView',
  async (params) => {
    const resp = await getVisitorOverView(params);
    return resp?.data;
  }
);

export const DeliveryByCompaniesReducer = createAsyncThunk(
  'getDeliveryOverview',
  async (params) => {
    const resp = await getDeliveryOverview(params);
    return resp?.data;
  }
);

export const VisitorAnalyticsLiveReducer = createAsyncThunk(
  'getVisitorLiveData',
  async (params) => {
    const resp = await getVisitorLiveData(params);
    return resp;
  }
);

export const UserOverViewReducer = createAsyncThunk(
  'userOverView',
  async (params) => {
    const resp = await getUserOverView(params);
    return resp?.data;
  }
);
export const UnitAnalyticsReducer = createAsyncThunk(
  'unitAnalytics',
  async (params) => {
    const resp = await getUnitAnalytics(params);
    return resp?.data;
  }
);
export const ServiceBifurcationReducer = createAsyncThunk(
  'serviceBifurcation',
  async (params) => {
    const resp = await getServiceBifurcation(params);
    return resp?.data;
  }
);
export const MostRequestedReducer = createAsyncThunk(
  'mostRequested',
  async (params) => {
    const resp = await getMostRequested(params);
    return resp?.data;
  }
);

export const VisitorStatsReducer = createAsyncThunk(
  'visitorStats',
  async (params) => {
    const resp = await getVisitorStats(params);
    return resp?.data;
  }
);

export const VisitorStatsHourReducer = createAsyncThunk(
  'visitorStatsHour',
  async (params) => {
    const resp = await getVisitorStatsHour(params);
    return resp?.data;
  }
);
export const VisitorStatsWeekReducer = createAsyncThunk(
  'visitorStatsWeek',
  async (params) => {
    const resp = await getVisitorStatsWeek(params);
    return resp?.data;
  }
);
export const VisitorStatsDailyReducer = createAsyncThunk(
  'visitorStatsDaily',
  async (params) => {
    const resp = await getVisitorStatsDaily(params);
    return resp?.data;
  }
);
export const LeaseStatsDonutReducer = createAsyncThunk(
  'leaseStatsDonut',
  async (params) => {
    const resp = await getLeaseStatsDonut(params);
    return resp?.data;
  }
);
export const LeaseStatusStatsReducer = createAsyncThunk(
  'leaseStatusStats',
  async (params) => {
    const resp = await getLeaseStatusStats(params);
    return resp?.data;
  }
);
export const LeaseBiCountStatsReducer = createAsyncThunk(
  'leaseBiCountStats',
  async (params) => {
    const resp = await getLeaseBiCountStats(params);
    return resp?.data;
  }
);
const DashboardSlice = createSlice({
  name: 'DashboardSlice',
  initialState,
  reducers: {},
  extraReducers: {
    // dashboard overview
    [DashboardOverviewReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [DashboardOverviewReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.buildingAnalyticsData = action?.payload?.data;
    },
    [DashboardOverviewReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // service overview
    [ServiceAnalyticsDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ServiceAnalyticsDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.serviceAnalyticsData = action?.payload?.data;
    },
    [ServiceAnalyticsDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // visitor analytics
    [VisitorAnalyticsDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorAnalyticsDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorAnalyticsData = action?.payload?.data;
    },
    [VisitorAnalyticsDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },

    [VisitorAnalyticsLiveReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorAnalyticsLiveReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorAnalyticsLiveData = action?.payload?.data;
    },
    [VisitorAnalyticsLiveReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // service overview
    [ServiceOverViewReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ServiceOverViewReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.serviceOverviewData = action?.payload?.data;
    },
    [ServiceOverViewReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // visitor overview
    [VisitorOverViewReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorOverViewReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.mostVisitedVisitors = action?.payload?.data;
    },
    [VisitorOverViewReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },

    [DeliveryByCompaniesReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [DeliveryByCompaniesReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.deliveryAnalyticsData = action?.payload?.data;
    },
    [DeliveryByCompaniesReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // user overview
    [UserOverViewReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [UserOverViewReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.userOverviewData = action?.payload?.data;
    },
    [UserOverViewReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // UnitAnalytics
    [UnitAnalyticsReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [UnitAnalyticsReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.unitAnalyticsData = action?.payload?.data;
    },
    [UnitAnalyticsReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // UnitAnalytics
    [ServiceBifurcationReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ServiceBifurcationReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.serviceBifurcationData = action?.payload?.data;
    },
    [ServiceBifurcationReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // MostRequested
    [MostRequestedReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [MostRequestedReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.mostRequestedData = action?.payload?.data;
    },
    [MostRequestedReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // visitorStats
    [VisitorStatsReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorStatsReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorStatsData = action?.payload?.data;
    },
    [VisitorStatsReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [VisitorStatsHourReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorStatsHourReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorStatsDataHour = action?.payload?.data;
    },
    [VisitorStatsHourReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [VisitorStatsWeekReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorStatsWeekReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorStatsDataWeek = action?.payload?.data;
    },
    [VisitorStatsWeekReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [VisitorStatsDailyReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorStatsDailyReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorStatsDataDaily = action?.payload?.data;
    },
    [VisitorStatsDailyReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // LeaseStatsDonut
    [LeaseStatsDonutReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [LeaseStatsDonutReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.LeaseStatsDonutData = action?.payload?.data;
    },
    [LeaseStatsDonutReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // LeaseStatusStats
    [LeaseStatusStatsReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [LeaseStatusStatsReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.LeaseStatusStatsData = action?.payload?.data;
    },
    [LeaseStatusStatsReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // LeaseBiCountStats
    [LeaseBiCountStatsReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [LeaseBiCountStatsReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.LeaseBiCountData = action?.payload?.data;
    },
    [LeaseBiCountStatsReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export default DashboardSlice.reducer;
