import {
  LocalityReducer,
  cityNameReducer,
  countryNameReducer,
} from 'Redux/slices/property/PropertySlice';
import { Checkbox, Collapse, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { selectArr } from 'staticData/PropertyData';

const PropertyFilter = ({ filter, setFilter }) => {
  const { localityData, countryNameData, cityNameData } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();
  const [searchParams, setSearchParam] = useSearchParams();
  const handleFilterChange = (isChecked, name, value) => {
    if (isChecked) {
      setFilter({ ...filter, [name]: value });
    } else {
      setFilter({ ...filter, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };

  useEffect(() => {
    if (countryNameData.length < 1) dispatch(countryNameReducer());

    if (filter.country) {
      dispatch(cityNameReducer(filter.country));
    }
  }, [filter.country]);

  useEffect(() => {
    if (filter.cityId) {
      dispatch(LocalityReducer(filter.cityId));
    }
  }, [filter.cityId]);

  return (
    <div className="flex w-60 flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel header={<h2 className="font-bold">Property Type</h2>}>
          <div className="flex flex-col justify-start">
            {selectArr?.map((data, index) => (
              <div key={index} className="flex items-baseline justify-start">
                <Checkbox
                  id={data}
                  checked={filter?.propertyType === data ? true : false}
                  style={{ margin: '0px' }}
                  onClick={(e) =>
                    handleFilterChange(e.target?.checked, 'propertyType', data)
                  }
                >
                  <label htmlFor={data}>{data}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Country</h2>}>
          <Select
            placeholder="Select Country"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'country', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {countryNameData?.map((cur, index, key) => (
              <Select.Option value={cur} key={index}>
                {cur}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">City</h2>}>
          <Select
            placeholder="Select City"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'cityId', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            disabled={filter.country ? false : true}
          >
            {cityNameData?.map((cur, index, key) => (
              <Select.Option value={cur.id} key={cur.id}>
                {cur.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Locality</h2>}>
          <Select
            placeholder="Select Locality"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'localityId', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            disabled={filter.cityId ? false : true}
          >
            {localityData?.map((cur, index, key) => (
              <Select.Option value={cur.id} key={cur.id}>
                {cur.name}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default PropertyFilter;
