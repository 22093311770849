import { ServiceListingReducer } from 'Redux/slices/ServiceSlice';
import Title from 'antd/es/typography/Title';
import { ButtonWithDropDown, TabComponent } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OverView from './OverView';
import ServiceStat from './ServiceStat';
import VisitorStat from './VisitorStat';

const Dashboard = () => {
  const {
    serviceListingData: { rows = [] },
  } = useSelector((state) => state.Service);
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const items = [
    {
      key: '1',
      label: `Overview`,
      children: <OverView {...{ rows, startDate, endDate }} />,
    },
    {
      key: '2',
      label: `Service Statistics`,
      children: <ServiceStat {...{ rows, startDate, endDate }} />,
    },
    {
      key: '3',
      label: `Visitor Statistics`,
      children: <VisitorStat {...{ startDate, endDate }} />,
    },
    // {
    //   key: '4',
    //   label: `Lease Statistics`,
    //   children: <LeaseStat {...{ startDate, endDate }} />,
    // },
  ];

  useEffect(() => {
    const params = {
      page: 1,
      isUrgent: true,
      limit: 5,
      tabName: 'incomplete',
    };
    dispatch(ServiceListingReducer(params));
  }, [dispatch]);

  return (
    <>
      <Title level={3}>Dashboard</Title>
      <div className="relative flex justify-between">
        <div className="w-full">
          <TabComponent {...{ items }} activeTabCount={'1'} />
        </div>
        <div className="absolute right-0 z-10">
          <ButtonWithDropDown
            {...{ startDate, endDate, setStartDate, setEndDate }}
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
