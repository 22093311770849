import { Action, Chip, ListingFieldWithIcon } from 'component/CompIndex';
import { Link } from 'react-router-dom';
import { dateDaysCount, timeStampToDate } from 'utils/UtilsIndex';

export const getInvoicesTableData = (invoiceListingData, handleDelete) => {
  return {
    data: invoiceListingData?.length
      ? invoiceListingData?.map((cur, id) => {
        return {
          key: cur?.id,
          invoiceId: cur?.invoiceId,
          userInfo: { text: cur?.userInfo?.name, smText: cur?.userInfo?.email },
          createdAt: timeStampToDate(cur?.createdAt, 'YYYY/MM/DD').formattedDate,
          dueDate: timeStampToDate(cur?.dueDate, 'YYYY/MM/DD').formattedDate,
          overDueBy: cur?.overDueBy,
          amount: `${cur?.currency} ${cur?.finalAmount}`,
          status: cur?.status,
        };
      })
      : [],
    columns: [
      {
        title: 'User',
        dataIndex: 'userInfo',
        key: 'userInfo',
        render: (text, { key }) => (
          <Link to={`/admin/invoices/detail/${key}`}>
            <ListingFieldWithIcon {...{ ...text }} />
          </Link>
        ),
      },
      {
        title: 'Invoice Id',
        dataIndex: 'invoiceId',
        key: 'invoiceId',
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
      },
      {
        title: 'Due Date',
        dataIndex: 'dueDate',
        key: 'dueDate',
        render: (dueDate, { overDueBy }) => {
          const { daysCount } = dateDaysCount(
            dueDate
          );
          return (
            <div className={` flex flex-col items-center justify-center`}>
              <span className={` font-semibold ${overDueBy ? 'text-[#E20F0F]' : 'text-[#FEAD3F]'}`}>{dueDate}</span>
              <span className={`font-light whitespace-nowrap `}>
                {`${overDueBy ? 'due by' : 'due in'} ${daysCount} days`}
              </span>
            </div>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => (
          <div className="flex justify-center">
            <Chip color={text} name={text} />
          </div>
        ),
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, { key }) => (
          <Action data={key} handleDelete={handleDelete} />
        ),
      },
    ],
  };
};
