import {
  HelplineListingReducer,
  deleteHelplineDataReducer,
} from 'Redux/slices/HelplineSlice';
import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  DeleteRequest,
  HelplineCard,
  MixedHeadContent,
  ModalComp
} from 'component/CompIndex';
import NoData from 'layouts/NoData';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddHelpline from './AddHelpline';

const HelplineListing = () => {
  const {
    helplineListingData: { rows, count },
    isHelplineUpdated,
    isSuccess,
  } = useSelector((state) => state.Helpline);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [helplineId, setHelplineId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editDataForModal, setEditDataForModal] = useState(null);
  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };
  const handleDeleteModalOpen = (id) => {
    setHelplineId(id);
    setIsDeleteModalOpen(true);
  };
  const handleClose = () => {
    setHelplineId(null);
    setIsDeleteModalOpen(false);
  };
  const handleDelete = () => {
    dispatch(deleteHelplineDataReducer(helplineId));
    handleClose();
  };
  const handleLoad = () => {
    setPageNo(pageNo + 1);
  };
  const fetchData = () => {
    const param = {
      limit: 9 * pageNo,
      search: search,
      buildingId: selectedBuildingName,
    };
    if (param.limit < count) {
      setShowLoadMore(true);
    } else if (showLoadMore) {
      setShowLoadMore(false);
    }
    dispatch(HelplineListingReducer(param));
  };
  useEffect(() => {
    fetchData();
  }, [selectedBuildingName, search, pageNo, isHelplineUpdated]);

  useEffect(() => {
    const limit = 9 * pageNo;
    if (limit < count) {
      setShowLoadMore(true);
    } else if (showLoadMore) {
      setShowLoadMore(false);
    }
  }, [rows]);

  useEffect(() => {
    if (!isEditModalOpen) {
      setEditDataForModal(null);
    }
  }, [isEditModalOpen]);

  useEffect(() => {
    if (editDataForModal && !isEditModalOpen) {
      setIsEditModalOpen(true);
    }
  }, [editDataForModal]);

  return (
    <div className="relative">
      <ModalComp
        {...{ isModalOpen: isDeleteModalOpen || isEditModalOpen }}
        component={
          isEditModalOpen ? (
            <AddHelpline
              {...{
                isModalOpen: isEditModalOpen,
                setIsModalOpen: setIsEditModalOpen,
                helpLineDetails: editDataForModal,
              }}
            />
          ) : (
            <DeleteRequest
              text={`Are you sure you want to delete helpline number ?`}
              handleSave={handleDelete}
              handleClose={handleClose}
            />
          )
        }
      />
      <Title level={3}>Helplines</Title>
      <div className="absolute right-0 top-0 z-10">
        <MixedHeadContent
          AddNew={'Add Contact'}
          {...{
            handleOpen: handleEditModalOpen,
            setSearch,
          }}
        />
      </div>
      <div className="my-8 flex flex-wrap gap-8">
        {rows?.length ? (
          <>
            {rows?.map((cur, index) => (
              <HelplineCard
                key={index}
                helpLineData={cur}
                title={cur?.name_en}
                subtitle="Lifeguard"
                setEditDataForModal={setEditDataForModal}
                number={('' + cur?.countryCode || '') + '' + cur?.contactNumber}
                propertyName="Dune"
                handleDeleteModal={handleDeleteModalOpen}
              />
            ))}
            {showLoadMore && (
              <div className=" mx-auto">
                <Button onClick={handleLoad}>Load More</Button>
              </div>
            )}
          </>
        ) : (
          isSuccess && <NoData />
        )}
      </div>
    </div>
  );
};

export default HelplineListing;
