import CryptoJS from 'crypto-js';

const SECRET_KEY =  process.env.REACT_APP_API_SECRET_KEY;
const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
const key = CryptoJS.SHA256(PROJECT_NAME + SECRET_KEY).toString();

export const decrypt = (encryptedText) => {
  if (encryptedText) {
    const bytes = CryptoJS?.AES?.decrypt(encryptedText, key);
    return bytes?.toString(CryptoJS.enc.Utf8);
  } else {
    return '';
  }
};

export const encrypt = (plainText) => {
  return CryptoJS?.AES?.encrypt(plainText, key).toString();
};
