import { sendTokenReducer, tokenReducer } from 'Redux/slices/BasicSlice';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { setLocalKeyInfo } from 'utils/UtilsIndex';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);

export function requestPermission(dispatch) {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_V_API_ID_KEY,
      })
        .then(async (currentToken) => {
          if (currentToken) {
            setLocalKeyInfo('fcmToken', currentToken);
            dispatch(tokenReducer(currentToken));
            const payload = {
              token: currentToken,
            };
            dispatch(sendTokenReducer(payload));
          } else {
          }
        })
        .catch((err) => { });
    }
  });
}
