import { editUserDataReducer } from 'Redux/slices/property/UserSlice';
import { Col, Form, Row } from 'antd';
import {
  DateField,
  DocumentUpload,
  EditTopChanger,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import countries from 'layouts/localData/CountryCode';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { genderList, idType } from 'staticData/PropertyData';
import {
  addCountryInMob,
  dateToDayJsObj,
  defaultCountryCode,
  getCustomDateFormat,
  removeCountryCode,
} from 'utils/UtilsIndex';

const EditUserForm = ({ userDetailData, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [handleEditMode, setHandleEditMode] = useState({
    userDetails: false,
    personalDetails: false,
    bankDetails: false,
    documentDetails: false,
  });
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState([]);
  //for document
  const [docList, setDocList] = useState([]);

  const [initial] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', initial);
  const alternateCountryCode = Form.useWatch('alternateCountryCode', initial);
  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'countryCode',
      'mobileNumber',
      'alternateCountryCode',
      'alternateMobileNumber'
    );
    values.dateOfBirth = getCustomDateFormat(
      values?.dateOfBirth?.$d || values?.dateOfBirth?._i,
      'YYYY/MM/DD'
    );
    values.profilePicture = fileList?.[0]?.url ?? null;
    values.documents = docList;
    dispatch(
      editUserDataReducer({
        id,
        data: values,
      })
    );
    setHandleEditMode({
      userDetails: false,
      personalDetails: false,
      bankDetails: false,
      documentDetails: false,
    });
  };
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    var initialObj = {
      name: userDetailData?.name,
      countryCode: userDetailData?.countryCode || defaultCountryCode,
      mobileNumber: removeCountryCode(
        userDetailData?.mobileNumber,
        userDetailData?.countryCode
      ).number,
      alternateCountryCode:
        userDetailData?.alternateContact?.countryCode || defaultCountryCode,
      alternateMobileNumber: removeCountryCode(
        userDetailData?.alternateContact?.mobileNumber,
        userDetailData?.alternateContact?.countryCode
      ).number,
      email: userDetailData?.email,
      dateOfBirth: dateToDayJsObj(userDetailData?.dateOfBirth),
      nationality: userDetailData?.nationality,
      gender: userDetailData?.gender,
      documentType: userDetailData?.documentType,
      documentId: userDetailData?.documentId,
      // alternateMobileNumber: userDetailData?.alternateContact?.mobileNumber,
      alternateEmail: userDetailData?.alternateContact?.email,
      accountHolderName: userDetailData?.bankDetails?.accountHolderName,
      accountNumber: userDetailData?.bankDetails?.accountNumber,
      bankName: userDetailData?.bankDetails?.bankName,
      swiftCode: userDetailData?.bankDetails?.swiftCode,
    };
    initial.setFieldsValue(initialObj);

    userDetailData?.profilePicture &&
      setDefaultFileList([userDetailData?.profilePicture]);
  }, [initial, userDetailData]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      preserve={true}
      className="w-full"
      form={initial}
    >
      <Row justify="space-between" gutter={10}>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="userDetails"
                title="User Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div className="p-4">
                <ImageUpload
                  {...{
                    previewOpen,
                    setPreviewOpen,
                    previewImage,
                    setPreviewImage,
                    previewTitle,
                    setPreviewTitle,
                    fileList,
                    setFileList,
                    defaultFileList,
                    setDefaultFileList,
                    isUserImage: !userDetailData?.profilePicture,
                    showUploadButton: handleEditMode?.userDetails,
                    length: 1,
                    circleImage: true,
                  }}
                />
              </div>
              <div
                style={{
                  pointerEvents: !handleEditMode.userDetails ? 'none' : '',
                }}
                className="p-4"
              >
                <InputField
                  label="Full Name"
                  name="name"
                  placeholder="Jack Dem"
                  message="This field is required"
                  required="true"
                  // defaultValue={userDetailData?.name}
                  width="23rem"
                />
                <div className="mt-12">
                  <MobileNumber
                    label1="Phone Number"
                    countryCodeName="countryCode"
                    mobilePlaceHolder="Enter Your Number"
                    mobileNumberName="mobileNumber"
                    countryCode={countryCode}
                    required
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="personalDetails"
                title="Personal Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.personalDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="POC Email ID"
                    name="email"
                    placeholder="poc@email.com"
                    pattern={/.*@.*/}
                    required="true"
                    width="15rem"
                  />
                  <DateField
                    label="Date of  Birth"
                    name="dateOfBirth"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Nationality"
                    name="nationality"
                    placeholder="Nationality"
                    width="15rem"
                    selectArr={countries}
                    selectedName="label"
                    selectedValue="label"
                  />
                  <SelectField
                    label="Gender"
                    name="gender"
                    placeholder="Gender"
                    selectArr={genderList}
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Id Type"
                    name="documentType"
                    selectArr={idType}
                    width="15rem"
                  />
                  <InputField
                    label="ID Number"
                    name="documentId"
                    placeholder="123"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <MobileNumber
                    label1="Alternate Phone Number"
                    countryCodeName="alternateCountryCode"
                    mobilePlaceHolder="Alternate Phone Number"
                    mobileNumberName="alternateMobileNumber"
                    width="15rem"
                    countryCode={alternateCountryCode}
                  />
                  <InputField
                    label="Alternate Email (Optional)"
                    name="alternateEmail"
                    placeholder="poc@email.com"
                    pattern={/.*@.*/}
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" gutter={10}>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="bankDetails"
                title="Bank Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.bankDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="Account Holder Name"
                    name="accountHolderName"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                  <InputField
                    label="Bank Name"
                    name="bankName"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Account Number/IBAN"
                    name="accountNumber"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                  <InputField
                    label="Swift Code"
                    name="swiftCode"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="documentDetails"
                title="Attached Documents"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <DocumentUpload
                {...{ docList, setDocList, isDetailPage: true }}
                showUploadButton={handleEditMode?.documentDetails}
                defaultFileList={userDetailData?.documents}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EditUserForm;
