import Title from 'antd/es/typography/Title';
import { MixedHeadContent } from 'component/CompIndex';
import { useState } from 'react';
import InvoiceListingData from './InvoiceListingData';
import InvoiceTopCard from './InvoiceTopCard';

const InvoicesListing = () => {
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({});
  return (
    <>
      <div className="relative">
        <Title level={3}>Invoices & Payments</Title>
        <div className="absolute right-0 top-0 z-10">
          <MixedHeadContent
            AddNew={'Add Invoices'}
            route="/admin/invoices/add"
            {...{
              setSearch,
            }}
          />
        </div>
      </div>
      <InvoiceTopCard {...{ setFilters, filters }} />
      <InvoiceListingData {...{ search, filters }} />
    </>
  );
};

export default InvoicesListing;
