import { StarFilled } from '@ant-design/icons';
import {
  ServiceCategoryDataReducer,
  editServiceDataReducer,
  getServiceDataReducer,
  uploadServiceFileReducer,
} from 'Redux/slices/ServiceSlice';
import { getUnitNameDataReducer } from 'Redux/slices/property/UnitSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  Chip,
  DateField,
  DocumentUpload,
  EditTopChanger,
  InputField,
  SelectField,
  TextField,
} from 'component/CompIndex';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { areaArr, priorityArr, timeIntervalArr } from 'staticData/ServiceData';
import {
  dateToDayJsObj,
  disablePastDates,
  getIdFromUrl,
  getIsoFormatFromDate,
  isValidUUID,
} from 'utils/UtilsIndex';
import AttachedProducts from './AttachedProducts';
import EditStaffForm from './EditStaffForm';

const ServiceDetails = () => {
  const {
    serviceDetailData,
    adminCategoryData,
    userCategoryData,
    isLoadAgain,
  } = useSelector((state) => state.Service);
  const { buildingsName } = useSelector((state) => state.Property);
  const { unitNameData } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [handleEditMode, setHandleEditMode] = useState({
    requestDetails: false,
    additionalDetails: false,
    assignTask: false,
    productDetails: false,
    documentDetails: false,
  });
  //for document
  const [docList, setDocList] = useState([]);

  const [form] = Form.useForm();
  const buildingId = Form.useWatch('buildingId', form);
  const requestedDate = Form.useWatch('requestedDate', form);
  const timeSlotDropDown = useMemo(() => {
    const dateSlotArr = [];
    for (let i = 0; i < timeIntervalArr?.length; i++) {
      const currentDate = new Date();
      if (
        requestedDate?.isSame(currentDate, 'day') &&
        handleEditMode?.additionalDetails
      ) {
        const currentHours = new Date().getHours();
        if (timeIntervalArr?.[i]?.id >= currentHours) {
          dateSlotArr.push(timeIntervalArr?.[i]);
        }
      } else {
        dateSlotArr.push(timeIntervalArr?.[i]);
      }
    }
    return dateSlotArr;
  }, [requestedDate, serviceDetailData, handleEditMode]);
  const onFileSave = () => {
    const payload = {
      id: serviceDetailData?.id,
      // data: { files: [...fileList, ...docList] },
      data: { files: docList },
    };
    dispatch(uploadServiceFileReducer(payload));
    setHandleEditMode({ ...handleEditMode, documentDetails: false });
  };
  const onFinish = (values) => {
    values.time = getIsoFormatFromDate(
      values?.requestedDate?.$d || values?.requestedDate?._i,
      values?.requestedTime + 1
    );
    if (!isValidUUID(values?.categoryId)) {
      delete values?.categoryId;
    }
    if (!isValidUUID(values?.subCategoryId)) {
      delete values?.subCategoryId;
    }
    let data = {};
    const {
      description,
      categoryId,
      flatId,
      subCategoryId,
      buildingId,
      time,
      isBuilding,
      isUrgent,
    } = values;
    if (handleEditMode?.requestDetails && handleEditMode?.additionalDetails) {
      data = {
        description,
        categoryId,
        flatId,
        subCategoryId,
        buildingId,
        time,
        isBuilding,
        isUrgent,
      };
      setHandleEditMode({
        ...handleEditMode,
        requestDetails: false,
        additionalDetails: false,
      });
    } else {
      if (handleEditMode?.requestDetails) {
        data = {
          description,
          categoryId,
          flatId,
          subCategoryId,
        };
        setHandleEditMode({ ...handleEditMode, requestDetails: false });
      } else if (handleEditMode?.additionalDetails) {
        if (serviceDetailData?.user) {
          data = { time };
        } else {
          data = { buildingId, flatId, time, isBuilding, isUrgent };
        }
        setHandleEditMode({ ...handleEditMode, additionalDetails: false });
      }
    }
    dispatch(
      editServiceDataReducer({
        id,
        data,
      })
    );
  };
  const showFeedback = serviceDetailData?.statusDetails?.filter(
    (data) => data?.status === 'Completed'
  );
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    dispatch(getServiceDataReducer(id));
    if (!adminCategoryData?.length) {
      dispatch(ServiceCategoryDataReducer());
    }
  }, [isLoadAgain, dispatch, id]);

  useEffect(() => {
    if (buildingId) {
      dispatch(getUnitNameDataReducer(buildingId));
    }
  }, [buildingId, dispatch]);

  useEffect(() => {
    var initialObj = {
      name: serviceDetailData?.name,
      description: serviceDetailData?.description,
      categoryId: serviceDetailData?.category?.name_en,
      subCategoryId: serviceDetailData?.subCategory?.name_en,
      buildingId: serviceDetailData?.flat?.building?.id,
      flatId: serviceDetailData?.flat?.id,
      requestedBy: serviceDetailData?.user
        ? serviceDetailData?.user?.name
        : serviceDetailData?.generatedBy,
      dateRequest: dateToDayJsObj(serviceDetailData?.createdAt),
      requestedDate: dateToDayJsObj(serviceDetailData?.preferredTime?.start),
      requestedTime:
        new Date(serviceDetailData?.preferredTime?.start).getHours() || 0,
      isUrgent: serviceDetailData?.isUrgent,
      isBuilding: serviceDetailData?.isBuilding,
    };
    form.setFieldsValue(initialObj);
  }, [form, serviceDetailData]);
  return (
    <>
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        preserve={true}
        className="w-full"
        form={form}
      >
        <Title level={3}>{`Service Request Details #${
          serviceDetailData?.requestId ?? ''
        }`}</Title>
        {serviceDetailData?.id && (
          <div className="mb-4 flex justify-start gap-4">
            <div className="w-[8rem] text-center">
              <Chip
                color={serviceDetailData?.statusDetails?.[0]?.status}
                name={serviceDetailData?.statusDetails?.[0]?.status}
              />
            </div>
            <div className="w-[8rem] text-center">
              <Chip
                color={serviceDetailData?.isUrgent ? 'red' : 'yellow'}
                name={serviceDetailData?.isUrgent ? 'Urgent' : 'Regular'}
              />
            </div>
            <div className="w-[8rem] text-center">
              <Chip
                color={'blue'}
                name={serviceDetailData?.isBuilding ? 'Unit' : 'Public Area'}
              />
            </div>
            <div className="w-[8rem] text-center">
              <Chip
                color={'yellow'}
                name={serviceDetailData?.category?.name_en}
              />
            </div>
          </div>
        )}
        <Row justify="space-between" gutter={16}>
          <Col span={8} xs={{ span: 24 }} md={{ span: 10 }}>
            <Row justify="space-between" className="p-2">
              <div className="detailPageCard mb-8 min-w-[25rem]">
                <EditTopChanger
                  handleEditChanges={handleEditChanges}
                  state={
                    serviceDetailData?.statusDetails?.[0]?.status === 'Open' &&
                    serviceDetailData?.generatedBy === 'Admin'
                      ? 'requestDetails'
                      : ''
                  }
                  title="Request Details"
                  {...{ setHandleEditMode, handleEditMode }}
                />
                <div
                  style={{
                    pointerEvents: !handleEditMode.requestDetails ? 'none' : '',
                  }}
                  className="p-4 pb-0"
                >
                  <div className="inputWidth">
                    <SelectField
                      label="Admin Category"
                      name="subCategoryId"
                      placeholder="Admin Category"
                      required
                      width="10rem"
                      // disabled={buildingId ? false : true}
                      selectArr={adminCategoryData}
                      selectedName="name_en"
                      selectedValue="id"
                    />
                    <SelectField
                      label="User Category"
                      name="categoryId"
                      placeholder={
                        serviceDetailData?.category?.name_en ||
                        'Request Category'
                      }
                      width="10rem"
                      // disabled={buildingId ? false : true}
                      selectArr={userCategoryData}
                      selectedName="name_en"
                      selectedValue="id"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Requested By"
                      name="requestedBy"
                      disabled
                      placeholder="Requested By"
                      width="10rem"
                    />
                    <DateField
                      label="Date of Request"
                      name="dateRequest"
                      disabled
                      width="10rem"
                    />
                  </div>
                </div>
                <div className="px-4">
                  <TextField
                    className="p-4"
                    label="Description"
                    name="description"
                    required
                    minRows={1}
                    maxRows={2}
                    width="23rem"
                    disabled={!handleEditMode.requestDetails}
                    bordered={handleEditMode.requestDetails}
                  />
                </div>
              </div>
            </Row>
          </Col>
          <Col span={16} xs={{ span: 24 }} md={{ span: 14 }}>
            <Row justify="space-between" className="p-2">
              <div className="detailPageCard w-[36rem] ">
                <EditTopChanger
                  handleEditChanges={handleEditChanges}
                  {...{ setHandleEditMode, handleEditMode }}
                  state={
                    serviceDetailData?.statusDetails?.[0]?.status === 'Open'
                      ? 'additionalDetails'
                      : ''
                  }
                  title="Additional Details"
                />
                <div
                  className="wap"
                  style={{
                    pointerEvents: !handleEditMode.additionalDetails
                      ? 'none'
                      : '',
                  }}
                >
                  <div className="inputWidth">
                    <SelectField
                      label="Building Name"
                      placeholder="Building Name"
                      name="buildingId"
                      required
                      width="15rem"
                      disabled={serviceDetailData?.user ? true : false}
                      selectArr={buildingsName?.rows}
                      selectedName="name_en"
                      selectedValue="id"
                    />
                    <SelectField
                      label="Unit Number"
                      name="flatId"
                      placeholder="Unit Number"
                      required
                      disabled={serviceDetailData?.user ? true : false}
                      selectArr={unitNameData?.rows}
                      selectedName="name_en"
                      selectedValue="id"
                      width="15rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <DateField
                      label="Date Slot"
                      name="requestedDate"
                      isDisabledDate={true}
                      disabledDate={disablePastDates}
                      required
                      width="15rem"
                    />
                    <SelectField
                      label="Time Slot"
                      name="requestedTime"
                      placeholder="Time Slot"
                      width="15rem"
                      selectArr={timeSlotDropDown}
                      selectedName="name"
                      selectedValue="id"
                    />
                  </div>
                  <div className="inputWidth">
                    <SelectField
                      label="Area"
                      name="isBuilding"
                      placeholder="Area"
                      width="15rem"
                      disabled={serviceDetailData?.user ? true : false}
                      selectedName="name"
                      selectedValue="key"
                      selectArr={areaArr}
                    />
                    <SelectField
                      label="Priority"
                      name="isUrgent"
                      placeholder="Priority"
                      width="15rem"
                      disabled={serviceDetailData?.user ? true : false}
                      selectedName="name"
                      selectedValue="key"
                      selectArr={priorityArr}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
      <EditStaffForm />
      <Row justify="space-between" gutter={16}>
        <Col span={16} xs={{ span: 24 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <AttachedProducts />
          </Row>
        </Col>
        <Col span={8} xs={{ span: 24 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard min-w-[25rem]">
              <Form
                size="large"
                preserve={true}
                layout="vertical"
                onFinish={onFileSave}
              >
                <EditTopChanger
                  handleEditChanges={handleEditChanges}
                  {...{ setHandleEditMode, handleEditMode }}
                  state="documentDetails"
                  title="Attached Documents"
                />
                <DocumentUpload
                  {...{
                    docList,
                    setDocList,
                    isDetailPage: true,
                    showUploadButton: handleEditMode.documentDetails,
                    defaultFileList: serviceDetailData?.files,
                  }}
                />
              </Form>
            </div>
          </Row>
          {showFeedback?.length ? (
            <Row justify="space-between" className="p-2">
              <Form
                size="large"
                preserve={true}
                initialValues={{ feedback: serviceDetailData?.feedback }}
                className="detailPageCard min-w-[25rem]"
                layout="vertical"
              >
                <EditTopChanger title="Feedback Details" />
                <div className="px-2 py-1">
                  <div className="flex items-center gap-3">
                    <span>Rating </span>
                    <div className="flex items-center gap-1 text-lg">
                      {Array(5)
                        .fill('')
                        .map((_, index) => (
                          <StarFilled
                            key={index}
                            className={`${
                              index + 1 <= serviceDetailData?.rating
                                ? 'text-[#FFD700]'
                                : 'text-[#aaaaa5]'
                            }`}
                          />
                        ))}
                    </div>
                  </div>
                  <TextField
                    label="Feedback"
                    placeholder={
                      serviceDetailData?.status === 'COMPLETED'
                        ? 'No feedback provided.'
                        : 'Feedback and rating will be visible after the request is successfully completed.'
                    }
                    name="feedback"
                    width="23rem"
                    disabled
                    minRows={2}
                    maxRows={2}
                  />
                </div>
              </Form>
            </Row>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </>
  );
};

export default ServiceDetails;
