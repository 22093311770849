import {
  editNoticeDataReducer,
  getNoticeDataReducer,
} from 'Redux/slices/NoticeSlice';
import { Col, Form, Row, Typography } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  DateField,
  DocumentUpload,
  EditTopChanger,
  InputField,
  MultiSelect,
  SelectField,
} from 'component/CompIndex';
import TextEditor from 'component/TextEditor';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routeObj } from 'staticData/Data';
import { noticeCategories, targetUser } from 'staticData/PropertyData';
import {
  dateToDayJsObj,
  disablePastDates,
  getBuildingIdFromArray,
  getCustomDateFormat,
  getIdFromUrl,
} from 'utils/UtilsIndex';

const NoticeDetails = () => {
  const { noticeDetailData } = useSelector((state) => state.Notice);
  const { buildingsName } = useSelector((state) => state.Property);
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);

  const [handleEditMode, setHandleEditMode] = useState({
    buildingDetails: false,
    documentDetails: false,
  });

  //for document
  const [docList, setDocList] = useState([]);

  const [initial] = Form.useForm();
  const onFinish = (values) => {
    if (description?.replace(/<(.|\n)*?>/g, '')?.trim()?.length === 0) {
      toast.error('Please enter description');
      return;
    }
    values.noticeId = id;
    values.validFrom = getCustomDateFormat(values?.validFrom, 'DD-MM-YYYY');
    values.validTill = getCustomDateFormat(values?.validTill, 'DD-MM-YYYY');
    values.description = description;
    values.documents = docList;
    const payload = {
      data: { ...values },
      navigateTo: { navigate, url: routeObj?.notices },
    };
    dispatch(editNoticeDataReducer(payload));
  };

  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    dispatch(getNoticeDataReducer(id));
  }, [dispatch,id]);

  useEffect(() => {
    const initialObj = {
      title: noticeDetailData?.title,
      category: noticeDetailData?.category,
      targetUser: noticeDetailData?.targetUser,
      validFrom: dateToDayJsObj(noticeDetailData?.validFrom),
      validTill: dateToDayJsObj(noticeDetailData?.validTill),
      buildings: getBuildingIdFromArray(noticeDetailData?.noticeBuildings),
    };
    initial.setFieldsValue(initialObj);
    setDescription(noticeDetailData?.description);
  }, [initial, noticeDetailData]);
  return (
    <>
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        preserve={true}
        className="w-full"
        form={initial}
      >
        <Title level={3}>Details</Title>
        <Row justify="space-between">
          <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
            <Row justify="space-between">
              <div className="detailPageCard w-[40rem] ">
                <EditTopChanger
                  title="Notice Details"
                  handleEditChanges={handleEditChanges}
                  state="buildingDetails"
                  {...{ setHandleEditMode, handleEditMode }}
                />
                <div
                  className="wap"
                  style={{
                    pointerEvents: !handleEditMode.buildingDetails
                      ? 'none'
                      : '',
                  }}
                >
                  <div className="inputWidth">
                    <InputField
                      label="Title"
                      name="title"
                      placeholder="Title"
                      required
                    />
                    <SelectField
                      label="Notice Type"
                      name="category"
                      placeholder="Notice Type"
                      required
                      selectArr={noticeCategories}
                    />
                  </div>
                  {handleEditMode.buildingDetails && (
                    <div className="inputWidth">
                      <TextEditor
                        description={description}
                        setDescription={setDescription}
                        name="Description"
                        required={true}
                      />
                    </div>
                  )}
                  <div className="inputWidth">
                    <DateField
                      label="Valid From"
                      name="validFrom"
                      placeholder="Valid From"
                      disabledDate={disablePastDates}
                      isDisabledDate={true}
                      required
                    />
                    <DateField
                      label="Valid Till"
                      name="validTill"
                      placeholder="Valid Till"
                      disabledDate={disablePastDates}
                      isDisabledDate={true}
                      required
                    />
                  </div>
                  <div className="inputWidth">
                    <MultiSelect
                      label="Notice for"
                      name="targetUser"
                      placeholder="Notice for"
                      selectArr={targetUser}
                    />
                    <MultiSelect
                      label="Select Building"
                      placeholder="Building Name"
                      name="buildings"
                      required
                      selectArr={buildingsName?.rows}
                      selectedName="name_en"
                      selectedValue="id"
                    />
                  </div>
                </div>
                {!handleEditMode.buildingDetails && description && (
                  <div className="wap inputWidth pb-5">
                    <div className="flex flex-col justify-start gap-3">
                      <Typography>Notice</Typography>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                        className="max-h-60 min-h-[4rem] w-[38rem] overflow-y-auto rounded-lg border-[1px] border-[#D4D4D4] p-[1px_0px_2px_4px]"
                      />
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </Col>
          <Col span={8} xs={{ span: 8 }} md={{ span: 8 }}>
            <div className="detailPageCard">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                title="Attach Documents"
                state="documentDetails"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <DocumentUpload
                {...{
                  docList,
                  setDocList,
                  isDetailPage: true,
                  showUploadButton: handleEditMode?.documentDetails,
                  defaultFileList: noticeDetailData?.documents,
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default NoticeDetails;
