import {
  CreateBulkReminderReducer,
  LeaseListingReducer,
} from 'Redux/slices/LeaseSlice';
import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import AddNew from 'component/AddNew';
import {
  BulkReminders,
  DataTable,
  DateFilter,
  MixedHeadContent,
  ModalComp,
} from 'component/CompIndex';
import LeaseFilter from 'component/filter/LeaseFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BulkReminderLeaseTableData } from 'staticData/LeaseData';

const AddReminder = () => {
  const {
    leaseListingData: { rows = [], count },
  } = useSelector((state) => state.Lease);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadMoreLimit, setLoadMoreLimit] = useState(10);
  const [dateFilter, setDateFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState({ status: 'Active' });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleLoadMoreButton = () => {
    setLoadMoreLimit(loadMoreLimit + 10);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleCancelData = () => {
    navigate('/admin/lease/reminder?page=1');
  };
  const handleModalOpen = () => {
    if (!selectedRowKeys?.length) {
      toast.error('Please Select at least 1 lease');
      return;
    }
    setIsModalOpen(true);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const { data, columns } = BulkReminderLeaseTableData(rows);
  const onFinish = () => {};
  const handleCreateBulkReminder = (data) => {
    data.leaseIds = selectedRowKeys;
    const payload = {
      data,
      navigateTo: { navigate, url: '/admin/lease/reminder?page=1' },
    };
    dispatch(CreateBulkReminderReducer(payload));
  };
  useEffect(() => {
    const params = {
      status: statusFilter?.status,
      limit: loadMoreLimit,
      sortByExpiry: true,
      buildingId: selectedBuildingName,
    };
    if (loadMoreLimit > 10) {
      dispatch(LeaseListingReducer(params));
    }
  }, [loadMoreLimit, dispatch, selectedBuildingName, statusFilter]);
  useEffect(() => {
    const params = {
      status: statusFilter?.status,
      limit: 10,
      sortByExpiry: true,
      startDate: dateFilter?.from,
      endDate: dateFilter?.to,
      buildingId: selectedBuildingName,
    };
    dispatch(LeaseListingReducer(params));
    setSelectedRowKeys([]);
  }, [dateFilter, selectedBuildingName, statusFilter, dispatch]);
  return (
    <>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <BulkReminders
            {...{
              setIsModalOpen,
              handleSave: handleCreateBulkReminder,
              totalSelectedTitle: `You have selected total (${selectedRowKeys?.length}) Leases`,
            }}
          />
        }
      />
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        form={form}
        preserve={true}
        className="w-full"
      >
        <AddNew
          {...{ handleCancelData }}
          title="Create Bulk Reminder"
          saveButtonText="Create Reminder"
          onFinish={handleModalOpen}
        />
        <Row justify="space-between" className="mt-4">
          <Col span={24} xs={{ span: 24 }} md={{ span: 24 }}>
            <div className={`my-2 flex items-center justify-between`}>
              <Title level={5}>Select Lease</Title>
              <MixedHeadContent
                dateFilterComp=<DateFilter
                  setDateFilter={setDateFilter}
                  dateFilter={dateFilter}
                  title="Lease Expiry Date"
                />
                filterComp={
                  <LeaseFilter
                    filter={statusFilter}
                    setFilter={setStatusFilter}
                    statusOnly={true}
                  />
                }
                handleFilter={() => {}}
                {...{}}
              />
            </div>
            <div
              className={`${
                rows?.length ? 'h-[25rem]' : 'h-[16rem]'
              } overflow-y-auto `}
            >
              <div className="-mt-8">
                <DataTable
                  {...{
                    columns,
                    data,
                    count,
                    rowSelection,
                    //   pageNo,
                    //   setPageNo,
                    //   searchParams,
                    //   setSearchParam,
                  }}
                />
              </div>
              <div className="mt-8">
                {count >= loadMoreLimit ? (
                  <div className="center">
                    <Button onClick={handleLoadMoreButton}>Load More</Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddReminder;
