import { DataTable } from 'component/CompIndex';
import PropertyTopCard from 'component/PropertyTopCard';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { leaseTableData, leaseTopCardData } from 'staticData/LeaseData';

const LeaseListingData = ({ handleDelete, hideTopCard,handleReminder }) => {
  const {
    leaseListingData: { rows = [], count },
    leaseAnalyticsData,
  } = useSelector((state) => state.Lease);
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();
  const { propertyTopData } = leaseTopCardData(leaseAnalyticsData);
  const { data, columns } = leaseTableData(rows, handleDelete, handleReminder);
  return (
    <div>
      {propertyTopData && !hideTopCard ? (
        <PropertyTopCard {...{ propertyTopData }} />
      ) : (
        ''
      )}
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </div>
  );
};

export default LeaseListingData;
