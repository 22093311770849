import { DeleteOutlined } from '@ant-design/icons';
import {
  CreateServiceReducer,
  ServiceCategoryDataReducer,
} from 'Redux/slices/ServiceSlice';
import { getUnitNameDataReducer } from 'Redux/slices/property/UnitSlice';
import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  DateField,
  DocumentUpload,
  SelectField,
  TextField,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { areaArr, priorityArr, timeIntervalArr } from 'staticData/ServiceData';
import { disablePastDates, getIsoFormatFromDate } from 'utils/UtilsIndex';

const AddService = () => {
  const { buildingsName } = useSelector((state) => state.Property);
  const { adminCategoryData, userCategoryData } = useSelector(
    (state) => state.Service
  );
  const { unitNameData } = useSelector((state) => state.Unit);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  //for document
  const [allDocuments, setAllDocuments] = useState([[]]);
  const [selectedFormIndex, setSelectedFormIndex] = useState(0);
  const buildingId = Form.useWatch('buildingId', form);
  const requests = Form.useWatch('requests', form);
  const dateSlotDropDown = (requestedDate) => {
    const dateSlotArr = [];
    for (let i = 0; i < timeIntervalArr?.length; i++) {
      const currentDate = new Date();
      if (requestedDate?.isSame(currentDate, 'day')) {
        const currentHours = new Date().getHours();
        if (timeIntervalArr?.[i]?.id >= currentHours) {
          dateSlotArr.push(timeIntervalArr?.[i]);
        }
      } else {
        dateSlotArr.push(timeIntervalArr?.[i]);
      }
    }
    return dateSlotArr;
  };
  const onFinish = (values) => {
    values.requests = values?.requests?.map((data, index) => {
      data.time = getIsoFormatFromDate(
        data?.requestedDate?.$d,
        data?.requestedTime + 1
      );
      delete data?.requestedTime;
      delete data?.requestedDate;
      data.files = allDocuments?.[index];
      return data;
    });
    const payload = {
      data: { ...values },
      navigateTo: { navigate, url: routeObj?.services },
    };
    if (values?.buildingId) {
      dispatch(CreateServiceReducer(payload));
    }
  };
  const handleCancelData = () => {
    navigate('/admin/services?page=1');
  };
  const handleAddDoc = (addDocFun) => {
    const updatedDocs = [...allDocuments];
    updatedDocs.push([]);
    setAllDocuments(updatedDocs);
    addDocFun();
  };
  const handleRemoveDoc = (removeDocFun, name, index) => {
    let updatedDocs = [...allDocuments];
    updatedDocs = updatedDocs.filter((_, idx) => idx !== index);
    setAllDocuments([...updatedDocs]);
    removeDocFun(name);
  };
  const setDocList = (docList) => {
    let updatedDocs = [...allDocuments];
    updatedDocs[selectedFormIndex] = [...docList];
    setAllDocuments(updatedDocs);
  };
  const handleSelectIndex = (index) => {
    if (selectedFormIndex !== index) {
      setSelectedFormIndex(index);
    }
  };

  useEffect(() => {
    if (buildingId) {
      dispatch(getUnitNameDataReducer(buildingId));
    }
  }, [buildingId, dispatch]);
  const initialObj = {
    requests: [
      {
        subCategoryId: null,
        categoryId: null,
        isBuilding: null,
        isUrgent: null,
        requestedDate: null,
        requestedTime: null,
        description: null,
      },
    ],
  };
  useEffect(() => {
    if (!adminCategoryData?.length) {
      dispatch(ServiceCategoryDataReducer());
    }
  }, [dispatch, adminCategoryData]);
  return (
    <Form
      layout="vertical"
      name="dynamic_form_nest_item"
      size="large"
      onFinish={onFinish}
      form={form}
      autoComplete="off"
      initialValues={initialObj}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New Request"
        saveButtonText="Save Requests"
      />
      <Row>
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Title level={5}>Building Details</Title>

          <div className="inputWidth">
            <SelectField
              label="Property Name"
              name="buildingId"
              placeholder="Property Name"
              selectArr={buildingsName?.rows}
              required
              selectedName="name_en"
              selectedValue="id"
            />
            <SelectField
              label="Unit Number"
              name="flatId"
              placeholder="Unit Number"
              required
              selectArr={unitNameData?.rows}
              disabled={!buildingId}
              selectedName="name_en"
              selectedValue="id"
            />
          </div>
        </Col>
      </Row>
      <Form.List name="requests">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Form.Item key={key}>
                <Row justify="space-between my-4">
                  <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
                    <Title level={5}>Request Details</Title>
                    <Row justify="space-between">
                      <div className="inputWidth">
                        <SelectField
                          label="Admin Category"
                          name={[name, 'subCategoryId']}
                          placeholder="Admin category"
                          selectArr={adminCategoryData}
                          selectedName="name_en"
                          selectedValue="id"
                          restField
                          required
                        />
                        <SelectField
                          label="User Category"
                          name={[name, 'categoryId']}
                          placeholder="User category"
                          selectArr={userCategoryData}
                          selectedName="name_en"
                          selectedValue="id"
                          required
                          restFields
                        />
                      </div>
                      <div className="inputWidth">
                        <SelectField
                          label="Request Area"
                          name={[name, 'isBuilding']}
                          placeholder="Public space"
                          selectArr={areaArr}
                          selectedName="name"
                          selectedValue="key"
                          required
                          restFields
                        />
                        <SelectField
                          label="Request Priority"
                          name={[name, 'isUrgent']}
                          placeholder="Priority"
                          selectArr={priorityArr}
                          selectedName="name"
                          selectedValue="key"
                          required
                          restFields
                        />
                      </div>
                      <div className="inputWidth">
                        <DateField
                          label="Slot Preference date"
                          name={[name, 'requestedDate']}
                          placeholder="Slot Preference date"
                          disabledDate={disablePastDates}
                          isDisabledDate={true}
                          required
                        />
                        <SelectField
                          label="Slot Preference Time"
                          name={[name, 'requestedTime']}
                          placeholder="Slot Preference Time"
                          selectArr={dateSlotDropDown(
                            requests?.[index]?.requestedDate
                          )}
                          selectedName="name"
                          selectedValue="id"
                          required
                          restFields
                        />
                      </div>
                      <div className="inputWidth">
                        <TextField
                          className="w-full p-4"
                          label="Description"
                          required
                          name={[name, 'description']}
                          placeholder="Explain your service request..."
                          minRows={3}
                          maxRows={3}
                          width="45rem"
                          bordered={true}
                          maxLength={500}
                          showCount={true}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col
                    span={7}
                    xs={{ span: 7 }}
                    md={{ span: 7 }}
                    className="flex flex-col space-y-4"
                    onMouseEnter={() => handleSelectIndex(index)}
                  >
                    <div>
                      <Title level={5}>Attach Documents</Title>
                      <DocumentUpload
                        {...{
                          docList: allDocuments?.[index],
                          setDocList,
                          isMultipleForm: true,
                        }}
                      />
                    </div>
                    {allDocuments?.length !== 1 && (
                      <Button
                        size="large"
                        className="customButton"
                        htmlType="submit"
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveDoc(remove, name, index)}
                      >
                        Delete Form
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                size="large"
                className="customButton w-[46rem]"
                onClick={() => handleAddDoc(add)}
              >
                Add Another Request
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default AddService;
