import { ListingFieldWithIcon } from 'component/CompIndex';
import { Link } from 'react-router-dom';

export const columns = [
  {
    title: 'User Name',
    dataIndex: 'name',
    key: 'id',
    render: (text, key) => (
      <Link to={`/admin/visitor/detail/${key.key}`}>
        <ListingFieldWithIcon text={text} smText={`+${key.mobileNumber}`} />
      </Link>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category_en',
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: 'Building',
    dataIndex: 'building',
    key: 'buildingId',
  },
  {
    title: 'Unit No.',
    dataIndex: 'unitNo',
    key: 'flatName_en',
  },
  {
    title: 'Checked In',
    dataIndex: 'checkedIn',
    key: 'inTime',
    render: (text, key) => (
      <>
        <p>{text}</p>
        <p>{key.checkedInTime}</p>
      </>
    ),
  },
  {
    title: 'Checked Out',
    dataIndex: 'checkedOut',
    key: 'outTime',
    render: (text, key) => (
      <>
        <p>{text}</p>
        <p>{key.checkedOutTime}</p>
      </>
    ),
  },
];

export const visitorType = [
  'Guest',
  'Delivery',
  'Cab',
  'Home Services',
  'Daily Help',
  'Viewing',
  'Others',
];

export const visitorStatus = ['Active', 'InActive'];