import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

const DateField = ({
  label,
  name,
  required,
  width,
  disabled,
  disabledDate,
  isDisabledDate = false,
  acceptAllDate,
  border,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      className="p-0"
      style={{ borderBottom: '1px solid #D4D4D4' }}
      rules={[
        {
          required: required ? true : false,
          message: 'This field is required.',
        },
      ]}
    >
      <DatePicker
        bordered={border ? true : false}
        format={'YYYY/MM/DD'}
        disabledDate={(current) => {
          if (acceptAllDate) {
            return false;
          }
          else if (isDisabledDate) {
            return disabledDate(current);
          } else {
            let customDate = dayjs().format('YYYY/MM/DD');
            return current && current > dayjs(customDate, 'YYYY/MM/DD');
          }
        }}
        style={{ padding: '0', width: width ? width : '18rem' }}
        disabled={disabled ? true : false}
      />
    </Form.Item>
  );
};

export default React.memo(DateField);
