import { exportLeaseData } from 'Redux/services/ExportCsv/Export';
import {
  CreateBulkReminderReducer,
  LeaseAnalyticsDataReducer,
  LeaseListingReducer,
} from 'Redux/slices/LeaseSlice';
import Title from 'antd/es/typography/Title';
import {
  BulkReminders,
  DateFilter,
  EndLease,
  MixedHeadContent,
  ModalComp,
  TabComponent,
} from 'component/CompIndex';
import LeaseFilter from 'component/filter/LeaseFilter';
import { LeaseListingData } from 'pages/PagesIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { leaseItems } from 'staticData/LeaseData';
import { getIdFromUrl, getUrlPageNo } from 'utils/UtilsIndex';
const LeaseListing = () => {
  const { isLoadAgain } = useSelector((state) => state.Lease);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const { pathname } = useLocation();
  const flatId = getIdFromUrl(pathname);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [leaseId, setLeaseId] = useState(null);
  const [dateFilter, setDateFilter] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [allFilters, setAllFilters] = useState({
    type: '',
    status: '',
  });
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);

  const handleFilter = () => {};
  const handleExport = async () => {
    const params = {
      search,
      flatId,
      flatUsage: allFilters?.type,
      startDate: dateFilter?.from,
      endDate: dateFilter?.from,
      status: allFilters?.status,
      buildingId: selectedBuildingName,
    };
    return await exportLeaseData(params);
  };

  const handleDeleteModalOpen = (data) => {
    setLeaseId(data);
    setIsDeleteModalOpen(true);
  };
  const handleReminderModalOpen = (data) => {
    setLeaseId(data);
    setIsReminderModalOpen(true);
  };

  const handleSendReminder = (data) => {
    data.leaseIds = [data?.leaseId];
    delete data.leaseId;
    const payload = {
      data,
    };
    dispatch(CreateBulkReminderReducer(payload));
  };
  const items = leaseItems({
    handleDelete: handleDeleteModalOpen,
    handleReminder: handleReminderModalOpen,
  });
  //useEffect
  useEffect(() => {
    if (flatId) {
      return;
    }
    const params = {
      page: defaultPageNo,
      flatId,
      search,
      limit: 10,
      status: allFilters?.status,
      startDate: dateFilter?.from,
      endDate: dateFilter?.to,
      flatUsage: allFilters?.type,
      buildingId: selectedBuildingName,
      // sortByExpiry: true,
    };
    dispatch(LeaseListingReducer(params));
  }, [
    defaultPageNo,
    search,
    selectedBuildingName,
    allFilters,
    isLoadAgain,
    dispatch,
    dateFilter,
    flatId,
  ]);

  useEffect(() => {
    if (!isLoadAgain && !flatId) {
      dispatch(LeaseAnalyticsDataReducer({ buildingId: selectedBuildingName }));
    }
  }, [dispatch, isLoadAgain, selectedBuildingName, flatId]);

  return (
    <div>
      <ModalComp
        {...{
          isModalOpen: isDeleteModalOpen
            ? isDeleteModalOpen
            : isReminderModalOpen,
        }}
        component={
          isDeleteModalOpen ? (
            <EndLease
              {...{
                isModalOpen: isDeleteModalOpen,
                setIsModalOpen: setIsDeleteModalOpen,
                leaseId: leaseId?.key,
                isDraft: leaseId?.status === 'Draft',
              }}
            />
          ) : (
            <BulkReminders
              {...{
                isModalOpen: isReminderModalOpen,
                setIsModalOpen: setIsReminderModalOpen,
                leaseId: leaseId?.key,
                handleSave: handleSendReminder,
              }}
            />
          )
        }
      />
      {!flatId ? (
        <>
          <div className="relative">
            <Title level={3}>Lease Management</Title>
            <div className="absolute right-0 z-10">
              <MixedHeadContent
                // dateFilterComp=<DateFilter
                //   dateFilter={dateFilter}
                //   setDateFilter={setDateFilter}
                //   title="Lease Expiry Date"
                // />
                {...{
                  setSearch,
                  handleFilter,
                  handleExport,
                  exportFileName: 'Lease Data',
                }}
                filterComp=<LeaseFilter
                  filter={allFilters}
                  setFilter={setAllFilters}
                />
                AddNew={'Add Lease'}
                route="/admin/lease/add"
              />
            </div>
          </div>
          <div>
            <TabComponent {...{ items }} activeTabCount={'1'} />
          </div>
        </>
      ) : (
        <LeaseListingData
          handleDelete={handleDeleteModalOpen}
          hideTopCard={true}
        />
      )}
    </div>
  );
};

export default LeaseListing;
