import { ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Form, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import { DateField, InputField, SelectField } from 'component/CompIndex';
import dayjs from 'dayjs';
import { useState } from 'react';
import { paymentModes } from 'staticData/PropertyData';
import DeleteRequest from './DeleteRequest';
import { getCustomDateFormat } from 'utils/UtilsIndex';

const SaveInvoice = ({
  isReceivePayment = true,
  saveModal,
  closeModal,
  invoiceDetails,
}) => {
  const [step, setStep] = useState(0);
  const [receivePaymentData, setReceivePaymentData] = useState({});
  const [form] = Form.useForm();
  const initialObject = {
    paymentMode: invoiceDetails?.paymentMode,
    paidDate: dayjs(),
  };
  const handleClose = () => {
    setReceivePaymentData({});
    form.resetFields();
    setStep(0);
    closeModal();
  };
  const handleStep = async (step) => {
    try {
      if (step === 1) {
        const values = await form.validateFields();
        values.paidDate = getCustomDateFormat(
          values?.paidDate?.$d,
          'MM-DD-YYYY'
        );
        setReceivePaymentData(values);
      }
      if (step == 2) {
        invoiceDetails?.sendEmail ? setStep(step) : handleSave();
        return;
      }
      setStep(step);
      return;
    } catch (error) {}
  };
  const handleSave = async () => {
    saveModal({ ...invoiceDetails, ...receivePaymentData });
    handleClose();
    form.resetFields();
  };

  const transactionText = (
    <div className="flex items-center justify-between gap-5 p-2">
      <WarningOutlined className="text-6xl text-[#ff9966]" />
      <p>
        The transaction you are editing is linked to others. Are you sure you
        want to modify it?
      </p>
    </div>
  );
  const sendEmailText = (
    <div className="flex items-center justify-between gap-5 p-2">
      <ExclamationCircleOutlined className="text-6xl text-[#ff9966]" />
      <div>
        <p>
          Every time you save an invoice, your customer will see it updated on
          their email.
        </p>
        <p className="flex pt-3">
          <span>send email to:&nbsp;</span>
          <span className="font-semibold">
            {invoiceDetails?.userInfo?.email}
          </span>
        </p>
      </div>
    </div>
  );
  return (
    <div>
      {step === 0 && isReceivePayment ? (
        <Form
          layout="vertical"
          form={form}
          initialValues={initialObject}
          size="large"
          className="p-5"
        >
          <Title level={5}>Receive Payment</Title>
          <div className="inputWidth">
            <SelectField
              label="Payment Mode"
              name="paymentMode"
              placeholder="Payment Mode"
              width="15rem"
              selectArr={paymentModes}
              required
            />
            <DateField
              label="Paid Date"
              name="paidDate"
              placeholder="Paid Date"
              width="15rem"
              acceptAllDate
              required
            />
          </div>
          <div className="inputWidth">
            <InputField
              label="Payment Transaction Number"
              name="transactionId"
              placeholder="Payment Transaction No."
              width="15rem"
              required
            />
            <div className="mt-4 flex w-[15rem] flex-col justify-start">
              <div className="flex items-start justify-between gap-2 font-semibold">
                <p>Paid by</p>
                <p>{invoiceDetails?.userInfo?.name}</p>
              </div>
              <div className="flex items-start justify-between gap-2 font-semibold">
                <p>Amount Received</p>
                <p>
                  {invoiceDetails?.currency}&nbsp;
                  {invoiceDetails?.amountReceived}
                </p>
              </div>
            </div>
          </div>
          <Space className="center">
            <Button className="w-auto" onClick={handleClose}>
              Cancel
            </Button>
            <Button className="customButton" onClick={() => handleStep(1)}>
              Continue
            </Button>
          </Space>
        </Form>
      ) : (
        step === 0 && (
          <DeleteRequest
            text={transactionText}
            handleSave={() => handleStep(2)}
            handleClose={handleClose}
            anotherTitle={invoiceDetails?.sendEmail ? 'Continue' : 'Save'}
          />
        )
      )}
      {step === 1 && (
        <DeleteRequest
          text={transactionText}
          handleSave={() => handleStep(2)}
          handleClose={handleClose}
          anotherTitle={invoiceDetails?.sendEmail ? 'Continue' : 'Save'}
        />
      )}
      {step === 2 && (
        <DeleteRequest
          text={sendEmailText}
          handleSave={handleSave}
          handleClose={handleClose}
          anotherTitle="Save"
        />
      )}
    </div>
  );
};

export default SaveInvoice;
