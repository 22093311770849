import { PlusOutlined } from '@ant-design/icons';
import { createTag } from 'Redux/services/Invoices/Invoice';
import { tagDataListingReducer } from 'Redux/slices/InvoiceSlice';
import { InventoryDropdownReducer } from 'Redux/slices/property/CatalogSlice';
import {
  CreateUserReducer,
  masterUserDataReducer,
} from 'Redux/slices/property/UserSlice';
import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  CustomSelectField,
  DateField,
  EditTopChanger,
  InputField,
  InvoiceTable,
  InvoiceTimeline,
  MobileNumber,
  ModalComp,
  SelectField,
  ShortNewUser,
} from 'component/CompIndex';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { currencyList } from 'staticData/LeaseData';
import { paymentModes, userType } from 'staticData/PropertyData';
import {
  addCountryInMob,
  defaultCountryCode,
  fetchSelectedUser,
  getIdFromUrl,
  removeCountryCode,
} from 'utils/UtilsIndex';

const InvoiceForm = ({ id, form }) => {
  const { masterUserData, isLoadAgain: isLoadAgainUser } = useSelector(
    (state) => state.User
  );
  const {
    tagListingData,
    isLoadAgain: isLoadAgainInvoice,
    invoiceDetailData,
  } = useSelector((state) => state.Invoice);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userArr, setUserArr] = useState([]);
  const invoiceFor = Form.useWatch('invoiceFor', form) ?? true;
  const masterUserId = Form.useWatch('masterUserId', form);
  const countryCode = Form.useWatch('countryCode', form);
  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  const handleSave = (values) => {
    values = addCountryInMob(values, 'countryCode', 'mobileNumber');
    dispatch(
      CreateUserReducer({
        values,
      })
    );
    handleClose();
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const fetchInventory = (buildingId = '') => {
    if (buildingId) {
      const params = { buildingId };
      dispatch(InventoryDropdownReducer(params));
    }
  };

  const createNewTag = async (name) => {
    const payload = { name };
    const data = await createTag(payload);
    return data ?? {};
  };
  useEffect(() => {
    const selectedUser = fetchSelectedUser(userArr, masterUserId);
    form.setFieldsValue({
      email: selectedUser?.email,
      mobileNumber: removeCountryCode(
        selectedUser?.mobileNumber,
        selectedUser?.countryCode
      )?.number,
      countryCode: selectedUser?.countryCode ?? defaultCountryCode,
      billingAddress: `${
        selectedUser?.flatname
          ? selectedUser?.flatname + ', ' + selectedUser?.buildingName
          : selectedUser?.buildingName ?? ''
      }`,
    });
    fetchInventory(selectedUser?.buildingId);
  }, [masterUserId]);
  useEffect(() => {
    dispatch(masterUserDataReducer());
  }, [isLoadAgainUser, dispatch]);
  useEffect(() => {
    dispatch(tagDataListingReducer());
  }, [isLoadAgainInvoice, dispatch]);
  useEffect(() => {
    if (masterUserData?.length) {
      if (invoiceFor === 'Company') {
        const users = masterUserData?.filter(
          (data) => data?.userType === 'Company'
        );
        setUserArr(users);
      } else {
        const users = masterUserData?.filter(
          (data) => data?.userType !== 'Company'
        );
        setUserArr(users);
      }
    }
    if (
      (invoiceFor && invoiceFor !== invoiceDetailData?.invoiceFor) ||
      (masterUserId && masterUserId !== invoiceDetailData?.masterUserId)
    ) {
      form.setFieldsValue({
        email: null,
        mobileNumber: null,
        masterUserId: null,
        countryCode: defaultCountryCode,
        billingAddress: null,
      });
    }
  }, [invoiceFor, masterUserData]);
  return (
    <>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <ShortNewUser
            {...{
              handleSave,
              handleClose,
              userType,
              isCompany: invoiceFor === 'Company',
            }}
          />
        }
      />
      <Row justify="space-between">
        {id ? null : (
          <Title level={5} className="mt-4">
            Invoice Details
          </Title>
        )}
        <div className="inputWidth">
          {id ? (
            <InputField
              name="invoiceId"
              label="Invoice Number"
              placeholder="Invoice Number"
              disabled
            />
          ) : (
            <SelectField
              label="Select Currency"
              name="currency"
              placeholder="Select Currency"
              selectArr={currencyList}
              selectedName="country"
              secondName="currency_code"
              selectedValue="currency_code"
              required
            />
          )}
          <SelectField
            label="Customer Type"
            name="invoiceFor"
            placeholder="Customer Type"
            selectArr={userType}
            required
            selectedName="name"
            selectedValue="value"
          />
        </div>
        <div className="inputWidth">
          <DateField
            label="Invoice Date"
            name="invoiceDate"
            acceptAllDate
            required
          />
          <DateField label="Due Date" name="dueDate" acceptAllDate required />
        </div>
        <div className="inputWidth">
          <SelectField
            label="Payment Mode"
            name="paymentMode"
            placeholder="Payment Mode"
            selectArr={paymentModes}
            required
          />
          <CustomSelectField
            label="Tags"
            name="tagIds"
            selectArr={tagListingData}
            addItemFunction={createNewTag}
            multiple
            selectedName="name"
            selectedValue="id"
            placeholder="tags"
          />
        </div>
        {id && (
          <div className="inputWidth">
            <SelectField
              label="Select Currency"
              name="currency"
              placeholder="Select Currency"
              selectArr={currencyList}
              selectedName="country"
              secondName="currency_code"
              selectedValue="currency_code"
              required
              disabled
            />
          </div>
        )}
        <div className="inputWidth">
          <Title level={5}>Customer Details</Title>
          <h2
            className="mr-[10rem] cursor-pointer text-[#229EEF]"
            onClick={handleModalOpen}
          >
            <PlusOutlined /> Add New&nbsp;
            {invoiceFor === 'Company' ? 'Company' : 'Customer'}
          </h2>
        </div>
        <div className="inputWidth">
          <SelectField
            label={`${invoiceFor === 'Company' ? 'Company' : 'Customer'} Name`}
            name="masterUserId"
            placeholder={`${
              invoiceFor === 'Company' ? 'Company' : 'Customer'
            } Name`}
            selectArr={userArr}
            required
            selectedName="name"
            selectedValue="id"
          />
          <InputField
            label={`${invoiceFor === 'Company' ? 'Company' : 'Customer'} Email`}
            name="email"
            placeholder={`${
              invoiceFor === 'Company' ? 'Company' : 'Customer'
            } Email`}
          />
        </div>
        <div className="inputWidth">
          <MobileNumber
            label1="Phone Number"
            countryCodeName="countryCode"
            mobilePlaceHolder="Enter Your Number"
            mobileNumberName="mobileNumber"
            required
            countryCode={countryCode}
          />
        </div>
        <div className="inputWidth">
          <InputField
            label="Billing Address"
            name="billingAddress"
            placeholder="Billing Address"
            width="42rem"
            required
          />
        </div>
      </Row>
    </>
  );
};
const AddInvoice = () => {
  const { isLoadAgain: isLoadAgainInventory } = useSelector(
    (state) => state.Inventory
  );
  const { invoiceDetailData } = useSelector((state) => state.Invoice);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [form] = Form.useForm();
  const currency = Form.useWatch('currency', form);
  const invoiceDate = Form.useWatch('invoiceDate', form);
  const [isUiAnimated, setIsUiAnimated] = useState(false);
  const [dueAmount, setDueAmount] = useState(null);
  const [isReceivePayment, setIsReceivePayment] = useState(false);
  const initialFormValues = {
    invoiceDate: dayjs(),
    tagIds: [],
    countryCode: defaultCountryCode,
  };
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setIsUiAnimated(true);
    }, 0);
    return () => {
      clearInterval(timeOutId);
    };
  }, []);

  useEffect(() => {
    if (invoiceDate) {
      const dueDate = invoiceDate.add(30, 'day');
      form.setFieldsValue({ dueDate });
    } else {
      form.setFieldsValue({ dueDate: null });
    }
  }, [invoiceDate]);

  useEffect(() => {
    const params = {};
    dispatch(InventoryDropdownReducer(params));
  }, [dispatch, isLoadAgainInventory]);
  return (
    <div>
      <Form
        layout="vertical"
        size="large"
        form={form}
        initialValues={initialFormValues}
        className={`relative w-full ${
          isUiAnimated ? 'right-0 opacity-100' : '-right-[100%] opacity-0'
        } transition-all duration-500 ease-in-out`}
      >
        <Title level={4}>{id ? 'Invoice #2233' : 'Add Invoices'}</Title>
        <Row justify="space-between" className="pt-4">
          <Col span={14}>
            {id ? (
              <div className="detailPageCard w-[43rem]">
                <EditTopChanger title="Invoice Details" />
                <div className="px-2">
                  <InvoiceForm id={id} form={form} />
                </div>
              </div>
            ) : (
              <InvoiceForm id={id} form={form} />
            )}
          </Col>
          <Col span={8} className="item flex flex-col">
            <div className="flex flex-col items-end gap-2 py-2">
              <p className="text-xl font-semibold">
                {/* {dueAmount ? ' BALANCE DUE' : 'PAYMENT STATUS'} */}
                BALANCE DUE
              </p>
              <p className="text-5xl font-bold">
                {dueAmount
                  ? `${currency ?? ''} ${dueAmount}`
                  : `${currency ?? ''} 0.0`}
              </p>
              {id && !isReceivePayment && dueAmount ? (
                <a href="#discount123">
                  <Button
                    size="large"
                    className="customButton mt-2"
                    onClick={() => setIsReceivePayment(true)}
                  >
                    Receive Payment
                  </Button>
                </a>
              ) : null}
            </div>
            {id ? (
              <div className="mr-2 mt-4 flex flex-1 items-start justify-end">
                <InvoiceTimeline
                  statusArr={invoiceDetailData?.paymentstatuses}
                />
              </div>
            ) : null}
          </Col>
        </Row>
      </Form>
      <div className="h-auto w-full">
        <InvoiceTable
          {...{
            dueAmount,
            setDueAmount,
            isReceivePayment,
            setIsReceivePayment,
            invoiceForm: form,
            currency,
          }}
        />
      </div>
    </div>
  );
};

export default AddInvoice;
