import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import { ImageUpload, InputField, SelectField } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { allAmenitiesArr } from 'staticData/UnitData';

const NewAmenities = ({ handleSave, handleClose, amenitiesDetail }) => {
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();
  const resetFormValues = () => {
    form.resetFields();
    handleClose();
  };
  const onFinish = (values) => {
    values.image = fileList?.[0]?.url ?? '';
    handleSave(values);
    resetFormValues();
  };
  useEffect(() => {
    const initialObj = {
      name: amenitiesDetail?.name ?? '',
      raisedFor: amenitiesDetail?.raisedFor ?? '',
    };
    form.setFieldsValue(initialObj);
    if (amenitiesDetail?.image) {
      setFileList([amenitiesDetail.image]);
    }
  }, [amenitiesDetail]);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      className="w-full"
    >
      <Title level={4} className="bor w-full rounded bg-[#F6F6F6] p-4">
        Add Amenities
      </Title>
      <div className="flex items-center justify-between px-5">
        <div>
          <ImageUpload
            {...{
              previewOpen,
              setPreviewOpen,
              previewImage,
              setPreviewImage,
              previewTitle,
              setPreviewTitle,
              fileList,
              setFileList,
              length: 1,
              multiple: false,
              circleImage: true,
            }}
          />
        </div>
        <div>
          <InputField
            label="Amenities Name"
            name="name"
            placeholder="Amenities Name"
            width="20rem"
            required
          />
          <SelectField
            label="Created For"
            name="raisedFor"
            placeholder="Created for"
            selectArr={allAmenitiesArr}
            width="20rem"
            required
          />
        </div>
      </div>
      <div className="center p-4">
        <Button className="w-40" onClick={resetFormValues}>
          Cancel
        </Button>
        <Button className="customButton ml-4 w-40" htmlType="submit">
          Add Amenities
        </Button>
      </div>
    </Form>
  );
};

export default NewAmenities;
