import { Space } from 'antd';
import { listingPageIcon } from 'assets/AssetIndex';
import React from 'react';

const ListingFieldWithIcon = ({ text, smText, img }) => {
  return (
    <Space className="flex items-center justify-start">
      <img src={img || listingPageIcon} alt="..." className="profile-icon" />
      <div className="text-start">
        <p>{text}</p>
        <p>{smText}</p>
      </div>
    </Space>
  );
};

export default React.memo(ListingFieldWithIcon);
