import { BellOutlined, MessageOutlined } from '@ant-design/icons';
import {
  allNotificationReducer,
  readAllNotificationReducer,
  readNotificationReducer,
} from 'Redux/slices/BasicSlice';
import { Dropdown } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertToAmPm, timeStampToDate } from 'utils/UtilsIndex';

function ItemData(allNotificationData, setHandleNotification) {
  const navigate = useNavigate();
  const { isNewNotification } = useSelector((state) => state.Basic);
  const dispatch = useDispatch();
  const markRead = () => {
    dispatch(readAllNotificationReducer());
    setHandleNotification(true);
  };
  const redirectToOtherModule = (id, sourceType) => {
    if (sourceType === 'services') {
      navigate(`/admin/services/detail/${id}`);
    } else if (sourceType === 'lease') {
      navigate(`/admin/lease/detail/${id}`);
    } else if (sourceType === 'login_request') {
      navigate(`/admin/request?page=1`);
    }
  };
  const Item = (
    <div className="hiddenScroll h-[80vh] w-[30rem] overflow-y-auto overflow-x-hidden p-10">
      <div className="flex items-end justify-between">
        <div className="text-2xl font-semibold text-[#0C344E]">
          Notification
        </div>
        <div
          className={`cursor-pointer text-sm text-[#229EEF] underline ${
            isNewNotification ? '' : 'hidden'
          }`}
          onClick={() => markRead()}
        >
          Mark all as read
        </div>
      </div>
      <div className="mt-4">
        {allNotificationData?.map((cur, id) => (
          <div className="flex items-center justify-start gap-x-4" key={id}>
            <div className="flex items-center justify-center rounded-full bg-[#FFF7EC] p-3 text-xl text-[#FCCC64]">
              <MessageOutlined />
            </div>
            <div className="flex-1 border-b-[1px] border-[#D4D4D4] px-3 py-4">
              <div
                className={`whitespace-wrap max-h-[2rem] w-full cursor-pointer overflow-hidden text-ellipsis text-xl ${
                  !cur.isRead ? 'text-[#f44250]' : 'text-[#0C344E]'
                } `}
                // style={{ pointerEvents: cur.isRead ? 'none' : '' }}
                // onClick={() => dispatch(readNotificationReducer(cur?.id))}
                onClick={() => {
                  dispatch(readNotificationReducer(cur?.id));
                  redirectToOtherModule(cur?.sourceId, cur?.sourceType);
                }}
              >
                {cur?.content}
              </div>
              <div className="cursor-pointer text-sm leading-3 text-[#BDBDBD]">
                {timeStampToDate(cur?.createdAt).formattedDate}, &nbsp;
                {convertToAmPm(cur?.createdAt)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  return Item;
}

const Notifications = () => {
  const {
    allNotificationData,
    // checkUnreadNotificationData,
    isNewNotification,
    isLoadAgain,
  } = useSelector((state) => state.Basic);
  const [handleNotification, setHandleNotification] = useState(false);
  const dispatch = useDispatch();
  const items = [
    {
      label: <div>{ItemData(allNotificationData, setHandleNotification)}</div>,
      key: 1,
    },
  ];
  useEffect(() => {
    dispatch(allNotificationReducer());
    // dispatch(checkUnreadNotificationReducer());
  }, [handleNotification, isLoadAgain, dispatch]);

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottom"
      destroyPopupOnHide={true}
    >
      <div className="relative flex h-8 w-8 cursor-pointer items-center justify-center rounded-md bg-[#000E17] text-[#FFFFFF]">
        {isNewNotification && (
          <div className="absolute -right-1 -top-1 z-10 flex h-3 w-3 items-center justify-center overflow-hidden rounded-full bg-[#C90F0F] text-[0.6rem] text-[#FFFFFF]"></div>
        )}
        <BellOutlined className="text-xl" />
      </div>
    </Dropdown>
  );
};

export default Notifications;
