import { SubUnitListingReducer } from 'Redux/slices/property/UnitSlice';
import { DataTable } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SubUnitData, SubUnitItemsData } from 'staticData/UnitData';
import { getIdFromUrl, getUrlPageNo } from 'utils/UtilsIndex';

const SubUnitListingData = ({ search, setIsModalOpen, setSubUnitId }) => {
  // const { selectedBuildingName } = useSelector((state) => state.Property);
  const {
    subUnitListingData: { rows = [], count },
    isLoadAgain,
  } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const { search: searchUrl, pathname } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const flatId = getIdFromUrl(pathname);
  const [searchParams, setSearchParam] = useSearchParams();
  const [pageNo, setPageNo] = useState(1);
  const handleDeleteModalOpen = (key) => {
    setSubUnitId(key);
    setIsModalOpen(true);
  };
  const columns = SubUnitItemsData(handleDeleteModalOpen);
  const data = SubUnitData(rows);


  return (
    <DataTable
      {...{
        columns,
        data,
        count,
        pageNo,
        setPageNo,
        searchParams,
        setSearchParam,
      }}
    />
  );
};

export default SubUnitListingData;
