import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { getIdFromUrl } from 'utils/UtilsIndex';

const SaveInvoiceButton = ({ handleSave }) => {
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const navigate = useNavigate();
  const handleSaveButton = ({key}) => {
    handleSave(key==='Email');
  };
  const handleClose = () => {
    navigate(routeObj?.invoices);
  };
  const items = [
    {
      label: 'Save and Send',
      key: 'Email',
    },
    {
      label: 'Save and Close',
      key: 'Close',
    },
  ];
  const menuProps = {
    items,
    onClick: handleSaveButton,
  };
  return (
    <div className=" flex justify-end gap-3">
      {id && <Button size="large">Print or Preview</Button>}
      <Button size="large" onClick={handleClose}>
        Cancel
      </Button>
      <Dropdown menu={menuProps}>
        <Button size="large" className="customButton">
          <Space>
            Save Invoice
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </div>
  );
};

export default SaveInvoiceButton;
