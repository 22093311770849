import { DeleteOutlined, EditOutlined, MailOutlined } from '@ant-design/icons';
import React from 'react';

const Action = ({ data, handleEdit, handleDelete, handleReminder }) => {
  return (
    <div className="flex justify-center gap-4 text-lg">
      {handleEdit && (
        <EditOutlined
          onClick={() => handleEdit(data)}
          className="cursor-pointer text-[#2BB256]"
        />
      )}
      {handleDelete && (
        <DeleteOutlined
          onClick={() => handleDelete(data)}
          className="cursor-pointer text-[#E20F0F]"
        />
      )}
      {handleReminder && (
        <MailOutlined
          onClick={() => handleReminder(data)}
          className="cursor-pointer text-[#229EEF]"
        />
      )}
    </div>
  );
};

export default Action;
