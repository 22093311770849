import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getVisitorCategories,
  getVisitorData,
  getVisitorListing,
  toggleVisitorCategory,
} from 'Redux/services/visitor/Visitor';
import { initialListingState, initialState } from './InitialState';

// get listing page data
export const VisitorListingReducer = createAsyncThunk(
  '/visitorListing',
  async (data) => {
    try {
      const resp = await getVisitorListing(data);
      return resp?.data;
    } catch (error) {}
  }
);
//getVisitorData
export const getVisitorDataReducer = createAsyncThunk(
  'getVisitorData',
  async (id) => {
    try {
      const resp = await getVisitorData(id);
      return resp?.data?.data;
    } catch (error) {}
  }
);

export const getVisitorCategoriesReducer = createAsyncThunk(
  'getVisitorCategories',
  async (id) => {
    try {
      const resp = await getVisitorCategories();
      return resp?.data?.data;
    } catch (error) {}
  }
);

export const ToggleVisitorCategoryVisibilityReducer = createAsyncThunk(
  'toggleVisitorCategory',
  async (id) => {
    const resp = await toggleVisitorCategory(id);
    return resp;
  }
);

const VisitorSlice = createSlice({
  name: 'visitorSlice',
  initialState,
  reducers: {},

  extraReducers: {
    // visitorListing
    [VisitorListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [VisitorListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorListingData = action?.payload?.data ?? initialListingState;
    },
    [VisitorListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get visitor data
    [getVisitorDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getVisitorDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorDetailData = action?.payload;
    },
    [getVisitorDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [getVisitorCategoriesReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getVisitorCategoriesReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.visitorCategories = action?.payload;
    },
    [getVisitorCategoriesReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [ToggleVisitorCategoryVisibilityReducer.pending]: (state, action) => {
      state.isLoading = true;
    },
    [ToggleVisitorCategoryVisibilityReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [ToggleVisitorCategoryVisibilityReducer.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default VisitorSlice.reducer;
