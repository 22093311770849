import { Chip, DataTable } from 'component/CompIndex';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const FilterUnitListing = ({ unitListingData: { rows, count } }) => {
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();

  const data = rows?.length
    ? rows?.map((cur, id) => {
        return {
          key: cur?.id,
          unitNo: cur?.name_en,
          propertyName: cur?.building?.name_en,
          status: cur?.flatStatus,
          type: cur?.flatType,
          size: cur?.size,
          leaseStatus: cur?.leaseStatus,
          period: cur?.period,
          owner: cur?.owner?.name,
        };
      })
    : [];
  const columns = [
    {
      title: 'Unit No.',
      dataIndex: 'unitNo',
      key: 'unitNo',
      render: (text, key) => (
        <Link to={`/admin/myproperties/unit/detail/${key.key}`}>{text}</Link>
      ),
    },
    {
      title: 'Property Name',
      dataIndex: 'propertyName',
      key: 'propertyName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => (
        <>
          <Chip color={status === 'Vacant' ? 'red' : 'blue'} name={status} />
        </>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Size (sqft)',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Lease Status',
      dataIndex: 'leaseStatus',
      key: 'leaseStatus',
      render: (_, { leaseStatus }) => (
        <>
          <Chip
            color={leaseStatus === 'Active' ? 'green' : 'red'}
            name={leaseStatus}
          />
        </>
      ),
    },
    {
      title: 'Period',
      dataIndex: 'period',
      key: 'period',
      render: (_, { period }) => (
        <div>
          {period >= 12
            ? Math.floor(period / 12) + ' Year'
            : period
            ? period + ' Month'
            : ''}
        </div>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
    },
  ];
  return (
    <div>
      <DataTable
        {...{ columns, data, count, pageNo, setPageNo, setSearchParam }}
      />
    </div>
  );
};

export default FilterUnitListing;
