import { SimpleCard } from 'component/CompIndex';
import NoData from 'layouts/NoData';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const UnitTenant = ({ userDetailData }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-wrap gap-8">
      {userDetailData?.leases?.length ? (
        userDetailData?.leases?.map((cur, id, key) => (
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate(`/admin/myproperties/unit/detail/${cur?.flat?.id}`);
            }}
          >
            <SimpleCard
              key={id}
              address1={`${cur?.flat?.name_en} , ${cur?.flat?.building?.name_en}`}
              address2={cur?.building?.address_en}
              tag={cur?.flat?.flatType}
              status={cur?.status}
            />
          </div>
        ))
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default UnitTenant;
