import { CreateUserReducer } from 'Redux/slices/property/UserSlice';
import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import { InputField } from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCountryInMob, defaultCountryCode } from 'utils/UtilsIndex';

const ShortOwner = ({
  isModalOpen,
  setIsModalOpen,
  handleModal,
  setHandleModal,
  setRefreshOwnerRed,
}) => {
  const { isSuccess } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);
  const initialValues = {
    countryCode: defaultCountryCode,
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setHandleModal({
      ...handleModal,
      owner: false,
    });
    form.resetFields();
    form.setFieldsValue(initialValues);
  };
  const onFinish = async (values) => {
    values = addCountryInMob(values, 'countryCode', 'mobileNumber');
    handleCloseModal();
    await dispatch(CreateUserReducer({ values }));
    setRefreshOwnerRed(true);
    {
      isSuccess && handleCloseModal();
    }
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      initialValues={{
        remember: true,
      }}
      className="w-full"
    >
      <div className="p-8">
        <Title level={3}>Add Owner </Title>
        <InputField
          label="Owner Name"
          name="name"
          placeholder="Owner Name"
          width="28rem"
          message="This field is required"
          required
        />
        <InputField
          label="Owner Email"
          name="email"
          width="28rem"
          placeholder="poc@email.com"
          pattern={/.*@.*/}
          required
        />
        <MobileNumber
          label1="Phone Number"
          countryCodeName="countryCode"
          mobilePlaceHolder="Enter Your Number"
          mobileNumberName="mobileNumber"
          countryCode={countryCode}
          required="true"
        />
        <div className="center">
          <Button className="w-40" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className="customButton ml-4 w-40" htmlType="submit">
            Add Owner
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ShortOwner;
