import { InvoiceListingReducer } from 'Redux/slices/InvoiceSlice';
import { DataTable } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getInvoicesTableData } from 'staticData/Invoices&Payments';
import { getUrlPageNo } from 'utils/UtilsIndex';

const InvoiceListingData = ({ search, filters }) => {
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const {
    invoiceListingData: { count, rows },
  } = useSelector((state) => state.Invoice);
  const dispatch = useDispatch();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();

  const { data, columns } = getInvoicesTableData(rows);
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      limit: 10,
      buildingId: selectedBuildingName,
      search,
    //   status: filters?.status,
    };
    dispatch(InvoiceListingReducer(params));
  }, [dispatch, search, defaultPageNo, selectedBuildingName, filters]);

  return (
    <>
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </>
  );
};

export default InvoiceListingData;
