import { Form } from 'antd';
import { InputField, SelectField } from 'component/CompIndex';
import React, { useEffect, useState } from 'react';
import SaveInvoiceButton from './SaveInvoiceButton';
import { useLocation } from 'react-router-dom';
import { getIdFromUrl } from 'utils/UtilsIndex';
import useSelection from 'antd/es/table/hooks/useSelection';
import { useSelector } from 'react-redux';

const InvoiceDiscount = ({
  itemsArr,
  depositedAmount,
  dueAmount,
  setDueAmount,
  isReceivePayment,
  handleSave,
  currency,
  form,
}) => {
  const { invoiceDetailData } = useSelector((state) => state.Invoice);
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [totalAmount, setTotalAmount] = useState(null);
  const [netAmount, setNetAmount] = useState(null);
  const [subTotalAmount, setSubTotalAmount] = useState(null);
  const [totalDiscount, setTotalDiscount] = useState(null);
  const [taxAmount, setTaxAmount] = useState(null);
  const discountType = Form.useWatch('discountType', form);
  const discountValue = Form.useWatch('discountValue', form);
  const deposit = Form.useWatch('deposit', form);
  const amountReceived = Form.useWatch('amountReceived', form);
  const taxValue = Form.useWatch('taxValue', form);

  const calculateTotal = () => {
    let total = parseFloat(totalAmount)?.toFixed(2);
    let discount = 0;
    if (discountValue && discountType) {
      if (discountType === 'Fixed') {
        discount += discountValue;
      } else {
        discount += +parseFloat((+total * +discountValue) / 100)?.toFixed(2);
      }
      total = +parseFloat(+total - +discount)?.toFixed(2);
    }
    setTotalDiscount(parseFloat(+discount)?.toFixed(2));
    setNetAmount(parseFloat(total)?.toFixed(2));
    if (taxValue) {
      const totalTax = parseFloat((total * taxValue) / 100)?.toFixed(2);
      total = +parseFloat(+total + +totalTax)?.toFixed(2);
      setTaxAmount(parseFloat(totalTax)?.toFixed(2));
    } else {
      setTaxAmount(null);
    }
    setSubTotalAmount(+parseFloat(+total)?.toFixed(2));
    if (id && depositedAmount) {
      total = parseFloat(total - depositedAmount)?.toFixed(2);
    }
    if (isReceivePayment) {
      total = +total - +amountReceived;
    }
    setDueAmount(+parseFloat(total)?.toFixed(2));
  };
  const handleSaveInvoice = async (modalKey) => {
    try {
      const values = await form.validateFields();
      values.totalAmount = +totalAmount;
      values.netAmount = +netAmount;
      values.subTotalAmount = +subTotalAmount;
      values.finalAmount = +dueAmount;
      values.sendEmail = modalKey;
      handleSave(values);
    } catch (error) {}
  };
  useEffect(() => {
    calculateTotal();
  }, [
    discountType,
    discountValue,
    deposit,
    totalAmount,
    amountReceived,
    taxValue,
    invoiceDetailData,
  ]);
  useEffect(() => {
    if (isReceivePayment) {
      form.setFieldsValue({
        amountReceived: parseFloat(
          subTotalAmount - (depositedAmount ?? 0)
        ).toFixed(2),
      });
    }
  }, [isReceivePayment]);
  useEffect(() => {
    const subAmount = itemsArr.reduce((acc, data) => {
      const { quantity, rate } = data;
      if (quantity && rate) {
        return (acc += quantity * rate);
      }
      return acc;
    }, 0);
    setTotalAmount(parseFloat(subAmount)?.toFixed(2));
  }, [itemsArr]);
  return (
    <>
      <Form
        form={form}
        initialValues={{ taxType: 'taxPercentage', taxValue: 5 }}
        className="flex w-full flex-col gap-3 text-[1rem] font-semibold"
      >
        <div
          className="flex w-full items-center justify-between text-end"
          id="discount123"
        >
          <p className="w-[60%]">Total Amount</p>
          <p>
            {currency ?? ''} {totalAmount ?? '0.0'}
          </p>
        </div>
        <div className="-mt-1 flex w-full items-center justify-between">
          <div className="mt-2 flex w-[60%] justify-end gap-2 text-xl">
            <SelectField
              selectArr={[
                { name: 'Discount percentage', value: 'Percentage' },
                { value: 'Fixed', name: 'Discount fixed' },
              ]}
              name="discountType"
              width="10rem"
              selectedName="name"
              selectedValue="value"
              placeholder="Discount type"
            />
            {discountType && (
              <InputField
                name="discountValue"
                width="4rem"
                placeholder="0.0"
                type
                min={0}
                max={discountType === 'Percentage' ? 100 : null}
              />
            )}
          </div>
          <p>
            {currency ?? ''} {totalDiscount ?? '0.0'}
          </p>
        </div>
        <div className="-mt-3 flex w-full items-center justify-between text-end">
          <p className="w-[60%]">Net Amount</p>
          <p>
            {currency ?? ''} {netAmount ?? '0.0'}
          </p>
        </div>
        <div className="-mt-1 flex w-full items-center justify-between">
          <div className="mt-2 flex w-[60%] justify-end gap-2 text-xl">
            {/* <SelectField
              selectArr={[
                { name: 'tax in percentage (%)', value: 'taxPercentage' },
              ]}
              name="taxType"
              disabled
              width="10rem"
              selectedName="name"
              selectedValue="value"
              placeholder="Tax type"
            /> */}
            <p className="mt-2 text-sm">Tax (%)&nbsp;</p>
            <InputField
              name="taxValue"
              width="4rem"
              placeholder="0.0"
              type
              min={0}
              max={100}
            />
          </div>
          <p>
            {currency ?? ''} {taxAmount ? taxAmount : '0.0'}
          </p>
        </div>
        <div className="-mt-3 flex w-full items-center justify-between text-end">
          <p className="w-[60%]">Sub Total Amount</p>
          <p>
            {currency ?? ''} {subTotalAmount ?? '0.0'}
          </p>
        </div>
        {id && (
          <div className="mt-2 flex w-full items-center justify-between text-end">
            <p className="w-[60%]">Deposited</p>
            <p>
              {currency ?? ''}&nbsp;
              {depositedAmount ? depositedAmount : '0.0'}
            </p>
          </div>
        )}
        {isReceivePayment && (
          <div
            className={`-mt-3 flex w-full items-center justify-between text-end`}
          >
            <p className="w-[60%]">Amount Received</p>
            <div className={`mt-4 flex w-[30%] items-start justify-end gap-2`}>
              {amountReceived && (
                <span className="-mr-2 pt-0.5">
                  {currency ?? ''}&nbsp;&nbsp;
                </span>
              )}
              <InputField
                name="amountReceived"
                placeholder="0.0"
                width={'6rem'}
                type
                min={0}
              />
            </div>
          </div>
        )}
        <div className="mt-1 flex w-full items-center justify-between text-end text-2xl">
          <p className="w-[60%]">Balance Due</p>
          <p>
            {currency ?? ''} {dueAmount ?? '0.0'}
          </p>
        </div>
      </Form>
      <div className="pb-1">
        <SaveInvoiceButton handleSave={handleSaveInvoice} />
      </div>
    </>
  );
};

export default InvoiceDiscount;
