import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  FileOutlined,
  Loading3QuartersOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { uploadFiles } from 'Redux/services/BasicService';
import { removeFileReducer } from 'Redux/slices/BasicSlice';
import { Button, Form, Input, Tooltip, Upload, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { acceptedDocs } from 'staticData/Data';
import {
  getExtensionFromUrl,  
  getFileNameFromUrl
} from 'utils/UtilsIndex';

const DocumentUpload = ({
  docList: fileList,
  setDocList: setFileList,
  showUploadButton = true,
  isDetailPage,
  isMultipleForm,
  defaultFileList,
  length = 6,
}) => {
  const dispatch = useDispatch();
  const [isEditName, setIsEditName] = useState({
    id: -1,
    state: true,
    name: '',
    placeholder: '',
  });
  const onEditNameClick = (id, placeholder) => {
    if (showUploadButton) {
      setIsEditName({ state: true, id, placeholder, name: '' });
    }
  };
  const cancelEditName = () => {
    setIsEditName({ ...isEditName, state: false, id: -1 });
  };
  const handleEditNameChange = (e) => {
    const name = e?.target?.value;
    setIsEditName({ ...isEditName, name });
  };
  const handleEditNameSave = () => {
    const { id, name } = isEditName;
    if (!name) {
      cancelEditName();
      return;
    }
    let updateFileList = [...fileList];
    updateFileList[id] = { ...updateFileList[id], name };
    setFileList(updateFileList);
    cancelEditName();
  };
  const handleRemove = async (file) => {
    await dispatch(removeFileReducer(file));
    const newFileList = fileList.filter((item) => item?.uid !== file?.uid);
    setFileList([...newFileList]);
  };
  const CustomFileList = ({ fileList }) => {
    return fileList?.map((file, index) => {
      return (
        <Tooltip
          key={file?.uid}
          placement="bottom"
          title={showUploadButton && 'Click to edit name'}
          open={index <= 1 && null}
          className={`mx-auto my-1 flex w-[18.5rem] items-center justify-between  rounded-md border border-[#b9b6b6] px-3 py-2`}
        >
          {file?.status === 'uploading' ? (
            <Loading3QuartersOutlined className="animate-spin text-2xl text-[#b9b6b6]" />
          ) : file?.type?.includes('image/') ? (
            <img src={file?.url} className="h-10 w-10 bg-cover" alt="..." />
          ) : (
            <FileOutlined className="text-3xl text-[#1677ff]" />
          )}
          {showUploadButton && isEditName?.id === index && isEditName?.state ? (
            <Input
              placeholder={isEditName?.placeholder}
              bordered={false}
              value={isEditName?.name}
              onChange={handleEditNameChange}
              style={{
                borderBottom: '1px solid #D4D4D4',
                borderRadius: '0',
                padding: '0 0 0px 0',
                width: '10rem',
              }}
              autoFocus={true}
            />
          ) : (
            <span
              onClick={() => onEditNameClick(index, file?.name)}
              className="max-w-[11rem] cursor-default truncate"
            >
              {file?.name}
            </span>
          )}
          <div className={`flex items-center justify-between`}>
            {showUploadButton ? (
              isEditName?.id !== index ? (
                <>
                  {!defaultFileList?.length && (
                    <a
                      target="_blank"
                      href={file?.url}
                      className="cursor-pointer text-lg"
                    >
                      {file?.url && (
                        <EyeOutlined className="mx-1 cursor-pointer rounded-md p-1 text-[#1677ff] hover:bg-[#E4EEFC]" />
                      )}
                    </a>
                  )}
                  <DeleteOutlined
                    onClick={() => handleRemove(file)}
                    className="mx-1 cursor-pointer rounded-md p-1 text-[#E20F0F] hover:bg-[#FFF0F2]"
                  />
                </>
              ) : (
                <>
                  <CheckOutlined
                    onClick={handleEditNameSave}
                    className="mr-1 cursor-pointer rounded-md p-1 text-[#2BB256] hover:bg-[#EDFFF2]"
                  />
                  <CloseOutlined
                    onClick={cancelEditName}
                    className="cursor-pointer rounded-md p-1 text-[#E20F0F] hover:bg-[#FFF0F2]"
                  />
                </>
              )
            ) : (
              <a
                target="_blank"
                href={file?.url}
                className="cursor-pointer text-lg"
              >
                {file?.url && (
                  <EyeOutlined className="cursor-pointer rounded-md p-1 text-[#1677ff] hover:bg-[#E4EEFC]" />
                )}
              </a>
            )}
          </div>
        </Tooltip>
      );
    });
  };
  const beforeUpload = (file) => {
    let fileType = file?.type?.split?.('/');
    fileType = fileType?.[fileType?.length - 1];
    if (!acceptedDocs?.includes?.(fileType)) {
      message.error(
        `File format is not accepted please upload (pdf, doc, png, jpg, jpeg, svg, webp, jfif) only`
      );
      return false;
    }
    if (fileList?.length - 1 > length) {
      message.error(`you can not upload more then ${length} documents`);
      return false;
    }
    const isLt1M = file.size / 1024 / 1024 < 5;
    if (!isLt1M) {
      message.error(
        'Document must be less than 5MB! Upload image less than 5Mb'
      );
    }
    return isLt1M;
  };
  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      setFileList([{ name: file.name, status: 'uploading' }, ...fileList]);
      const formData = new FormData();
      formData.append('file', file);
      const resp = await uploadFiles(formData);
      const newFiles = resp?.data?.data?.map((data) => {
        const updatedFile = {
          url: data.location,
          name: data.originalName,
          type: data.contentType,
        };
        return updatedFile;
      });
      setFileList([...newFiles, ...fileList]);
      onSuccess('ok', file);
    } catch (error) {
      setFileList([...fileList]);
      onError(error);
    }
  };
  useEffect(() => {
    if (!isMultipleForm) {
      let defaultFiles = [];
      if (defaultFileList?.length) {
        defaultFiles = defaultFileList?.map((file) => {
          if (file?.url) {
            if (!file?.type) {
              const type = getExtensionFromUrl(file?.url);
              return { ...file, type };
            }
            return file;
          } else {
            return {
              url: file,
              name: getFileNameFromUrl(file),
              type: getExtensionFromUrl(file),
            };
          }
        });
        setFileList(defaultFiles);
      } else {
        setFileList([]);
      }
    }
  }, [defaultFileList]);

  return (
    <Form.Item className="flex items-center justify-center">
      <Upload
        listType="picture"
        defaultFileList={[...fileList]}
        accept="image/png, image/jpg, image/jpeg, image/webp, image/svg, image/svg+xml, application/pdf, application/msword"
        className="upload-list-inline customDocStyle"
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        showUploadList={false}
        fileList={fileList}
        // multiple
      >
        {showUploadButton && (
          <Button
            type="dashed"
            className="mt-2 w-[18.5rem]"
            icon={<PlusOutlined />}
            onClick={cancelEditName}
          >
            Attach Documents
          </Button>
        )}
      </Upload>
      {!fileList?.length && isDetailPage && !showUploadButton ? (
        <div className="mt-4 flex items-center justify-center">
          <span className="font-light">No Documents found</span>
        </div>
      ) : (
        <div className="my-2 max-h-[9.5rem] w-[20rem] overflow-auto pr-2">
          <CustomFileList fileList={fileList} />
        </div>
      )}
    </Form.Item>
  );
};

export default React.memo(DocumentUpload);
