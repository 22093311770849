import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getLeaseDataReducer,
  updateDraftLeaseReducer,
  updateLeaseStatusReducer,
} from 'Redux/slices/LeaseSlice';
import {
  getOwnerDataReducer,
  getTenantDataReducer,
  getVacantUnitReducer,
} from 'Redux/slices/property/UnitSlice';
import { Button, Col, Form, Row, Switch, Timeline } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  CheckBoxField,
  DateField,
  DocumentUpload,
  EditTopChanger,
  EndLease,
  InputField,
  ModalComp,
  SelectField,
} from 'component/CompIndex';
import RentalDiscount from 'component/RentalDiscount';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { currencyList } from 'staticData/LeaseData';
import { paymentFrequency, paymentModes } from 'staticData/PropertyData';
import { statusColors } from 'staticData/ServiceData';
import {
  calculateDiscount,
  dateToDayJsObj,
  defaultCountryCode,
  disablePastDates,
  getCustomDateFormat,
  getIdFromUrl,
  removeCountryCode,
  removeUnwantedPayload,
} from 'utils/UtilsIndex';
import { leaseTypeList } from 'staticData/LeaseData';
import { editUnitDataReducer } from 'Redux/slices/property/UnitSlice';
import { masterUserDataReducer } from 'Redux/slices/property/UserSlice';
import { unitAmenitiesList } from 'staticData/UnitData';
import dayjs from 'dayjs';

const LeaseDetail = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const navigate = useNavigate();
  const { buildingsName } = useSelector((state) => state.Property);
  const {
    vacantUnitData,
    tenantData: { rows },
    ownerListData,
  } = useSelector((state) => state.Unit);
  const { leaseDetailData } = useSelector((state) => state.Lease);
  const { masterUserData } = useSelector((state) => state.User);
  const [handleEditMode, setHandleEditMode] = useState({
    leaseDetails: false,
    termsDetails: false,
    allocatedDetails: false,
    documentDetails: false,
    propertyDetails: false,
    ownerDetails: false,
    tenantDetails: false,
    amenitiesDetails: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amenities, setAmenities] = useState([]);
  const [docList, setDocList] = useState([]);
  const [form] = Form.useForm();
  const currency = Form.useWatch('currency', form)?.toLowerCase();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);
  const moveInDate = Form.useWatch('moveInDate', form);
  const moveOutDate = Form.useWatch('moveOutDate', form);
  const discountApplicability = Form.useWatch('discountApplicability', form);
  const rentAmount = Form.useWatch('rentAmount', form);
  const securityDeposit = Form.useWatch('securityDeposit', form);
  const discountValue = Form.useWatch('discountValue', form);
  const discountType = Form.useWatch('discountType', form);
  const buildingId = Form.useWatch('buildingId', form);
  const ownerId = Form.useWatch('owner', form);
  const flatId = Form.useWatch('flatId', form);
  const [firstUnitName, setFirstUnitName] = useState('');
  const [firstUnitNumber, setFirstUnitNumber] = useState('');
  const [unitUpdated, setUnitUpdated] = useState(false);
  const [firstBuildingNumber, setFirstBuildingNumber] = useState('');
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const [firstMasterUser, setFirstMasterUser] = useState('');
  // const [checkMaster, setCheckMaster] = useState('');
  const tenantId = Form.useWatch('tenantName', form);
  const isDiscountRequired =
    Form.useWatch('isDiscountRequired', form)?.[0] || false;
  const leaseStatusArr = leaseDetailData?.statuses || [];
  const remindersArr = leaseDetailData?.reminders || [];
  const currentStatus = leaseDetailData?.statuses?.[0]?.status;
  const handleClick = () => {
    setIsModalOpen(true);
  };
  const [terms, setTerms] = useState([]);

  const updateLeaseStatusToActive = () => {
    const payload = {
      data: {
        leaseId: id,
        status: 'Active',
      },
      navigateTo: { navigate, url: routeObj?.lease },
    };
    dispatch(updateLeaseStatusReducer(payload));
  };
  const addDescriptionToTerms = (termsArr) => {
    return termsArr?.map((data) => ({ description: data }));
  };

  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  const handleOwnerNameChange = (value) => {
    const selectedOwner = masterUserData?.find((owner) => owner.id === value);

    if (selectedOwner) {
      const selectedUserObj = {
        ownerEmail: selectedOwner?.email,
        ownerMobileNumber: `+${
          selectedOwner?.countryCode || defaultCountryCode
        }-${selectedOwner?.mobileNumber}`,
        // ownerCountryCode: selectedOwner.countryCode || defaultCountryCode,
      };

      form.setFieldsValue(selectedUserObj);
    }
  };

  const handleTenantNameChange = (value) => {
    const selectedTenant = rows?.find((tenant) => tenant.id === value);

    if (selectedTenant) {
      const selectedUserObj = {
        tenantEmail: selectedTenant?.email,
        tenantMobileNumber: `+${
          selectedTenant?.countryCode || defaultCountryCode
        }-${selectedTenant?.mobileNumber}`,
        // tenantCountryCode: selectedTenant.countryCode || defaultCountryCode,
      };

      form.setFieldsValue(selectedUserObj);
    }
  };

  const convertAllocatedDetailsToObj = (allocateArr) => {
    if (!allocateArr || !allocateArr?.length) {
      return [{ name: '' }];
    }
    return allocateArr?.map((data) => {
      return { name: data };
    });
  };

  const removeNameFromAllocatedDetails = (allocateArr) => {
    if (!allocateArr?.[0]?.name) {
      return [];
    }
    return allocateArr?.map((data) => data?.name);
  };

  const handlePaymentDetailsToggle = () => {
    setShowPaymentDetails(!showPaymentDetails);
  };

  const onFinish = (values) => {
    values = removeUnwantedPayload(
      values,
      'leaseTarget',
      'totalDiscount',
      'tenantEmail',
      'tenantCountryCode',
      'tenantMobileNumber',
      'ownerMobileNumber',
      'ownerEmail',
      'ownerCountryCode',
      'buildingId',
      'owner',
      'tenantName'
    );
    values['startDate'] = getCustomDateFormat(
      values?.startDate?.$d || values?.startDate?._d,
      'YYYY/MM/DD'
    );
    values['endDate'] = getCustomDateFormat(
      values?.endDate?.$d || values?.endDate?._d,
      'YYYY/MM/DD'
    );
    values['moveInDate'] = getCustomDateFormat(
      values?.moveInDate?.$d || values?.moveInDate?._d,
      'YYYY/MM/DD'
    );
    values['moveOutDate'] = getCustomDateFormat(
      values?.moveOutDate?.$d || values?.moveOutDate?._d,
      'YYYY/MM/DD'
    );
    values.documents = docList;
    values.isDiscountRequired = isDiscountRequired;
    if (values?.terms?.length > 0) {
      values.terms = values?.terms?.map((term) => term.description) || [];
    } else {
      // If terms are not present or have length 0, set it as an empty array
      values.terms = [];
    }
    setTerms(values.terms);
    values.masterUserId = firstMasterUser;
    values.flatId = firstUnitNumber ?? flatId;
    values.accessCards = removeNameFromAllocatedDetails(values?.accessCards);
    values.parkingLots = removeNameFromAllocatedDetails(values?.parkingLots);

    const payload = {
      id,
      data: { ...values },
    };

    if (handleEditMode?.ownerDetails && firstUnitNumber) {
      dispatch(
        editUnitDataReducer({
          id: firstUnitNumber, // Assuming flatId is the correct identifier
          data: { ownerId: ownerId }, // Modify this based on your data structure
        })
      );
      setHandleEditMode({
        ownerDetails: false,
      });
    } else if (handleEditMode?.ownerDetails && flatId) {
      dispatch(
        editUnitDataReducer({
          id: flatId, // Assuming flatId is the correct identifier
          data: { ownerId: ownerId }, // Modify this based on your data structure
        })
      );
      setHandleEditMode({
        ownerDetails: false,
      });
    } else if (handleEditMode?.propertyDetails) {
      values = removeUnwantedPayload(values, 'masterUserId');
      const payload = {
        id,
        data: { ...values },
        // navigateTo: { navigate, url: routeObj?.lease },
      };
      dispatch(updateDraftLeaseReducer(payload));
      setHandleEditMode({
        propertyDetails: false,
      });
    } else if (handleEditMode?.tenantDetails) {
      dispatch(updateDraftLeaseReducer(payload));
      setHandleEditMode({
        tenantDetails: false,
      });
    } else {
      values = removeUnwantedPayload(values, 'masterUserId');
      const payload = {
        id,
        data: { ...values, terms: values.terms },
        // navigateTo: { navigate, url: routeObj?.lease },
      };
      dispatch(updateDraftLeaseReducer(payload));
      setHandleEditMode({
        leaseDetails: false,
        termsDetails: false,
        allocatedDetails: false,
        documentDetails: false,
        amenitiesDetails: false,
      });
    }
  };

  const disableBeforeContractStartDate = (current) => {
    if (startDate) {
      const date = dayjs(startDate);
      return current && current <= date;
    }
    return current && current > dayjs();
  };

  useEffect(() => {
    let initialDateObj = {};
    if (!moveInDate && startDate) {
      initialDateObj.moveInDate = startDate;
    }
    if (!moveOutDate && endDate) {
      initialDateObj.moveOutDate = endDate;
    }
    form.setFieldsValue(initialDateObj);
  }, [startDate, endDate, form]);

  useEffect(() => {
    dispatch(getLeaseDataReducer(id));
    dispatch(getTenantDataReducer());
    dispatch(masterUserDataReducer());
  }, [dispatch, id]);

  useEffect(() => {
    if (buildingId) {
      dispatch(getVacantUnitReducer(buildingId));
    }

    if (buildingId && buildingId !== firstBuildingNumber) {
      form.setFieldsValue({
        owner: null,
        ownerEmail: null,
        ownerMobileNumber: null,
        flatId: null,
      });
      setFirstUnitNumber('');
    }
  }, [dispatch, buildingId]);

  useEffect(() => {
    if (flatId && currentStatus === 'Draft') {
      if (flatId !== firstUnitName && unitUpdated) {
        dispatch(getOwnerDataReducer(flatId));
        setFirstUnitNumber('');
      }
    } else if (!firstUnitNumber && unitUpdated && flatId) {
      dispatch(getOwnerDataReducer(flatId));
    }
  }, [dispatch, flatId]);

  useEffect(() => {
    if (ownerListData && ownerListData.owner) {
      form.setFieldsValue({
        owner: ownerListData.owner.name,
        ownerEmail: ownerListData.owner.email,
        ownerMobileNumber: `+${
          ownerListData.owner.countryCode ?? defaultCountryCode
        }-${
          removeCountryCode(
            ownerListData.owner.mobileNumber,
            ownerListData.owner.countryCode
          ).number
        }`,
      });
    } else {
      // Set default values or 'NA' when ownerListData.owner is null
      form.setFieldsValue({
        owner: null,
        ownerEmail: 'NA',
        ownerMobileNumber: 'NA',
      });
    }
  }, [ownerListData]);

  useEffect(() => {
    if (ownerId) {
      handleOwnerNameChange(ownerId);
    }
  }, [ownerId]);

  useEffect(() => {
    if (tenantId) {
      handleTenantNameChange(tenantId);
    }

    setFirstMasterUser(tenantId);
  }, [tenantId]);

  useEffect(() => {
    if ((rentAmount || securityDeposit) && discountValue) {
      form.setFieldsValue({
        totalDiscount: calculateDiscount(
          rentAmount,
          securityDeposit,
          discountApplicability,
          discountValue,
          discountType
        ),
      });
    }
  }, [
    rentAmount,
    securityDeposit,
    discountApplicability,
    discountValue,
    discountType,
    form,
  ]);

  useEffect(() => {
    var initialObj = {
      flatUsage: leaseDetailData?.flatUsage,
      buildingId: buildingId ?? leaseDetailData?.building?.id,
      leaseTarget: leaseDetailData?.leaseTarget,
      flatId: leaseDetailData?.name_en,
      owner: ownerId ?? leaseDetailData?.owner?.name,
      startDate: dateToDayJsObj(leaseDetailData?.startDate, 'YYYY/MM/DD'),
      moveInDate: dateToDayJsObj(leaseDetailData?.moveInDate, 'YYYY/MM/DD'),
      endDate: dateToDayJsObj(leaseDetailData?.endDate, 'YYYY/MM/DD'),
      moveOutDate: dateToDayJsObj(leaseDetailData?.moveOutDate, 'YYYY/MM/DD'),
      dueDate:
        leaseDetailData?.contractPayments?.length > 0
          ? dateToDayJsObj(leaseDetailData?.contractPayments[0]?.date)
          : '',
      rentAmount: leaseDetailData?.rentAmount,
      activationFee: leaseDetailData?.activationFee,
      discountValue: leaseDetailData?.discount?.discountValue,
      discountType: leaseDetailData?.discount?.discountType,
      discountApplicability: leaseDetailData?.discount?.discountApplicability,
      grace: leaseDetailData?.discount?.grace,
      isDiscountRequired: [
        leaseDetailData?.discount?.discountApplicability ? true : false,
      ],
      description: leaseDetailData?.description,
      securityDeposit: leaseDetailData?.securityDeposit,
      currency: leaseDetailData?.currency,
      paymentMode: leaseDetailData?.paymentMode,
      noticePeriod: leaseDetailData?.noticePeriod,
      paymentFrequency: leaseDetailData?.paymentFrequency,
      terms: addDescriptionToTerms(leaseDetailData?.terms),
      tenantName: leaseDetailData?.tenant?.id,
      tenantEmail: leaseDetailData?.tenant?.email,
      amenities: leaseDetailData?.amenities || [],
      accessCards: convertAllocatedDetailsToObj(
        leaseDetailData?.flatInfo?.accessCards
      ),
      parkingLots: convertAllocatedDetailsToObj(
        leaseDetailData?.flatInfo?.parkingLots
      ),
      tenantMobileNumber: `+${
        leaseDetailData?.tenant?.countryCode ?? defaultCountryCode
      }-${
        removeCountryCode(
          leaseDetailData?.tenant?.mobileNumber,
          leaseDetailData?.tenant?.countryCode
        ).number
      }`,
      owner: leaseDetailData?.owner?.id,
      ownerEmail: leaseDetailData?.owner?.email,
      ownerMobileNumber: `+${
        leaseDetailData?.owner?.countryCode ?? defaultCountryCode
      }-${
        removeCountryCode(
          leaseDetailData?.owner?.mobileNumber,
          leaseDetailData?.owner?.countryCode
        ).number
      }`,
    };

    setFirstUnitNumber(leaseDetailData?.flat?.id);
    setUnitUpdated(true);
    setFirstUnitName(leaseDetailData?.name_en);
    setFirstMasterUser(leaseDetailData?.tenant?.id);
    setFirstBuildingNumber(leaseDetailData?.building?.id);
    form.setFieldsValue(initialObj);
    setAmenities(leaseDetailData?.amenities);
    setTerms(leaseDetailData?.terms);
    if (
      leaseDetailData?.paymentFrequency ||
      leaseDetailData?.paymentMode ||
      leaseDetailData?.currency ||
      leaseDetailData?.securityDeposit ||
      leaseDetailData?.rentAmount ||
      leaseDetailData?.activationFee
    ) {
      setShowPaymentDetails(true);
    } else {
      setShowPaymentDetails(false);
    }
  }, [form, leaseDetailData, dispatch]);

  return (
    <Form
      layout="vertical"
      size="large"
      preserve={true}
      className="w-full"
      onFinish={onFinish}
      form={form}
    >
      <ModalComp
        {...{ isModalOpen }}
        component={
          <EndLease
            {...{
              isModalOpen,
              setIsModalOpen,
              isDraft: currentStatus === 'Draft',
            }}
          />
        }
      />

      <div className="inputWidth mb-6">
        <Title level={3}>
          {`Lease Details #${
            leaseDetailData?.leaseId ? leaseDetailData?.leaseId : ' '
          }`}
        </Title>
        {currentStatus === 'Draft' && (
          <div className=" ml-auto mr-2 flex gap-2">
            <Button
              type="primary"
              className="greenColorBtn h-10 px-10"
              onClick={updateLeaseStatusToActive}
            >
              Approve Lease
            </Button>
            <Button
              type="primary"
              className="redColorBtn h-10 px-10"
              onClick={handleClick}
            >
              Cancel Lease
            </Button>
          </div>
        )}

        {currentStatus === 'Active' && (
          <div className=" ml-auto mr-2 flex gap-2">
            <Button type="primary" className="greenColorBtn h-10 px-10">
              Renew Lease
            </Button>
            <Button
              type="primary"
              className="redColorBtn h-10 px-10"
              onClick={handleClick}
            >
              Terminate Lease
            </Button>
          </div>
        )}
      </div>

      <Row justify="space-between">
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Row justify="space-between">
            <div className="detailPageCard w-[40rem] ">
              <EditTopChanger
                title="Property Details"
                state={currentStatus === 'Draft' ? 'propertyDetails' : null}
                {...{ setHandleEditMode, handleEditMode, handleEditChanges }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: handleEditMode?.propertyDetails ? '' : 'none',
                }}
              >
                <div className="inputWidth">
                  <SelectField
                    label="Flat Usage"
                    name="flatUsage"
                    placeholder="Flat Usage"
                    selectArr={leaseTypeList || []}
                    required
                  />
                  <SelectField
                    label="Property Name"
                    name="buildingId"
                    placeholder="Property Name"
                    selectArr={buildingsName?.rows || []}
                    selectedName="name_en"
                    selectedValue="id"
                    required
                  />
                </div>
                <div className="inputWidth">
                  <InputField label="Lease Targeted For" name="leaseTarget" />
                  <SelectField
                    label="Unit"
                    name="flatId"
                    placeholder="Unit"
                    selectArr={vacantUnitData || []}
                    selectedName="name"
                    selectedValue="id"
                  />
                </div>
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[40rem]">
              <EditTopChanger
                title="Owner Details"
                state={currentStatus === 'Draft' ? 'ownerDetails' : null}
                {...{ setHandleEditMode, handleEditMode, handleEditChanges }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: handleEditMode?.ownerDetails ? '' : 'none',
                }}
              >
                <div className="inputWidth">
                  <SelectField
                    label="Owner Name"
                    name="owner"
                    placeholder="Owner Name"
                    selectArr={masterUserData || []}
                    selectedName="name"
                    selectedValue="id"
                    required
                    onChange={handleOwnerNameChange}
                  />
                  <InputField
                    label="Owner Email ID"
                    name="ownerEmail"
                    disabled
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Owner Phone Number"
                    name="ownerMobileNumber"
                    placeholder="Enter Owner Phone Number"
                    disabled // Always disabled
                  />
                </div>
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[40rem]">
              <EditTopChanger
                title="Lease Details"
                state={currentStatus === 'Draft' ? 'leaseDetails' : null}
                {...{ setHandleEditMode, handleEditMode, handleEditChanges }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: handleEditMode?.leaseDetails ? '' : 'none',
                }}
              >
                <div className="inputWidth">
                  <DateField
                    label="Contract Start Date"
                    name="startDate"
                    acceptAllDate={true}
                    required
                  />
                  <DateField
                    label="Contract End Date"
                    name="endDate"
                    isDisabledDate={true}
                    disabledDate={disableBeforeContractStartDate}
                    required
                  />
                </div>
                <div className="inputWidth">
                  <DateField
                    label="Move In Date"
                    name="moveInDate"
                    isDisabledDate={true}
                    disabledDate={disablePastDates}
                  />
                  <DateField
                    label="Move Out Date"
                    name="moveOutDate"
                    isDisabledDate={true}
                    disabledDate={disablePastDates}
                  />
                </div>
                <div className="my-3 flex gap-4">
                  <Title level={5}>Payment Details</Title>
                  <Switch
                    style={{
                      backgroundColor: showPaymentDetails ? '#007FFF' : 'grey',
                    }}
                    onChange={handlePaymentDetailsToggle}
                    checked={showPaymentDetails}
                  />
                </div>
                {showPaymentDetails && (
                  <>
                    <div className=" inputWidth">
                      {/* <InputField label="First Payment Due" name="dueDate" /> */}
                      <SelectField
                        label="Currency"
                        name="currency"
                        placeholder="Select Your Currency"
                        selectArr={currencyList || []}
                        selectedName="country"
                        secondName="currency_code"
                        selectedValue="currency_code"
                        required
                      />
                    </div>
                    <div className=" inputWidth">
                      <InputField
                        label={`Rent Amount (in ${
                          currency?.toUpperCase() ?? 'selected currency'
                        })`}
                        name="rentAmount"
                        type
                        required
                      />
                      <InputField
                        label={`Lease Activation Fee (in ${
                          currency?.toUpperCase() ?? 'selected currency'
                        })`}
                        name="activationFee"
                        type
                      />
                    </div>
                    <div className=" inputWidth">
                      <InputField
                        label={`Security Deposit (in ${
                          currency?.toUpperCase() ?? 'selected currency'
                        })`}
                        name="securityDeposit"
                        type
                        required
                      />
                      <SelectField
                        label="Payment Mode"
                        name="paymentMode"
                        placeholder="Payment Mode"
                        selectArr={paymentModes || []}
                        required
                      />
                    </div>
                    <div className="inputWidth">
                      <SelectField
                        label="Payment Frequency"
                        name="paymentFrequency"
                        placeholder="Payment Frequency"
                        selectArr={paymentFrequency || []}
                        required
                      />
                    </div>
                  </>
                )}
                <div className="inputWidth">
                  <InputField
                    label="Notice Period (in Months)"
                    name="noticePeriod"
                    type
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Description"
                    name="description"
                    width="37rem"
                  />
                </div>
                {showPaymentDetails && (
                  <RentalDiscount
                    {...{
                      discountApplicability,
                      isDiscountRequired,
                      discountType,
                      rentAmount,
                      currency,
                      securityDeposit,
                      discountValue,
                      discountValueWidth: '18rem',
                      totalAmountWidth: '18rem',
                    }}
                  />
                )}
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[40rem]">
              <EditTopChanger
                title="Terms & Conditions"
                state={currentStatus === 'Draft' ? 'termsDetails' : null}
                {...{ setHandleEditMode, handleEditMode, handleEditChanges }}
              />
              <div
                style={{
                  pointerEvents: handleEditMode?.termsDetails ? '' : 'none',
                }}
              >
                {handleEditMode?.termsDetails ? (
                  <Form.List name="terms">
                    {(fields, { add, remove }) => (
                      <>
                        {fields?.map(({ key, name, ...restField }, index) => (
                          <Form.Item key={key}>
                            <div
                              className={`${index !== 0 ? '-mt-6' : ''} px-1`}
                            >
                              <div className="flex items-center gap-2">
                                <div className="-mt-5">{index + 1}.</div>

                                <InputField
                                  name={[name, 'description']}
                                  placeholder="Write term & condition here"
                                  width="36rem"
                                  restField={restField}
                                  required
                                  max={200}
                                />
                                {handleEditMode?.termsDetails && (
                                  <div
                                    className="-mt-3 cursor-pointer text-[#E20F0F]"
                                    onClick={() => remove(name)}
                                  >
                                    <MinusCircleOutlined />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Form.Item>
                        ))}
                        {handleEditMode?.termsDetails && (
                          <Form.Item>
                            <h1
                              onClick={() => add('')}
                              className={`${
                                fields?.length ? '' : ''
                              } cursor-pointer text-[16px] text-[#229EEF] underline`}
                            >
                              <PlusOutlined /> Add Terms & Conditions
                            </h1>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </Form.List>
                ) : (
                  <div>
                    {leaseDetailData.terms?.length ? (
                      <div>
                        {terms?.map((term, index) => (
                          <div className="flex items-center gap-2 pb-[0.5rem] pl-[1rem] pt-[1rem]">
                            <div>{index + 1}.</div>
                            <div
                              style={{
                                width: '37rem',
                                wordBreak: 'break-all',
                              }}
                            >
                              {term}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="mt-2 h-10 text-center">No Data Found</div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[40rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state={currentStatus === 'Draft' ? 'allocatedDetails' : null}
                title="Allocated Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode?.allocatedDetails
                    ? 'none'
                    : '',
                }}
              >
                <div className="inputWidth py-3">
                  <Form.List name="accessCards">
                    {(fields, { add, remove }) => {
                      return (
                        <div className="flex flex-col">
                          {fields?.map(
                            ({ key, name, ...restFields }, index) => (
                              <div
                                key={key}
                                className="inputWidth items-center"
                              >
                                <div className="w-[16rem]">
                                  <div className="flex items-center justify-between">
                                    <InputField
                                      width="15rem"
                                      label={`Access Card (${index + 1})`}
                                      name={[name, 'name']}
                                      placeholder="Access Card Number"
                                      required={fields?.length !== 1}
                                      restFields={restFields}
                                    />
                                    {fields?.length !== 1 &&
                                    handleEditMode?.allocatedDetails ? (
                                      <MinusCircleOutlined
                                        className="cursor-pointer text-[#E20F0F]"
                                        onClick={() => remove(name)}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          <div className="w-[16rem]">
                            {handleEditMode?.allocatedDetails && (
                              <Button
                                size="md"
                                className="-mt-2 w-[15rem]"
                                onClick={() => add({ name: '' })}
                              >
                                Add Access Card
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Form.List>
                  <Form.List name="parkingLots">
                    {(fields, { add, remove }) => {
                      return (
                        <div className="flex flex-col">
                          {fields?.map(
                            ({ key, name, ...restFields }, index) => (
                              <div
                                key={key}
                                className="inputWidth items-center"
                              >
                                <div className="w-[16rem]">
                                  <div className="flex items-center justify-between">
                                    <InputField
                                      width="15rem"
                                      label={`Parking Space (${index + 1})`}
                                      name={[name, 'name']}
                                      placeholder="Parking Slot Number"
                                      restFields={restFields}
                                      required={fields?.length !== 1}
                                    />
                                    {fields?.length !== 1 &&
                                    handleEditMode?.allocatedDetails ? (
                                      <MinusCircleOutlined
                                        className="cursor-pointer text-[#E20F0F]"
                                        onClick={() => remove(name)}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          <div className="w-[16rem]">
                            {handleEditMode?.allocatedDetails && (
                              <Button
                                size="md"
                                className="-mt-2 w-[15rem]"
                                onClick={() => add({ name: '' })}
                              >
                                Add Parking Space
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Form.List>
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={8} xs={{ span: 8 }} md={{ span: 8 }}>
          <Row justify="space-between" className="nspace-y-8">
            <div className="detailPageCard w-[40rem]">
              <EditTopChanger
                title="Tenant Details"
                state={currentStatus === 'Draft' ? 'tenantDetails' : null}
                {...{ setHandleEditMode, handleEditMode, handleEditChanges }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: handleEditMode?.tenantDetails ? '' : 'none',
                }}
              >
                <SelectField
                  label="Tenant Name"
                  name="tenantName"
                  placeholder="Tenant Name"
                  selectArr={rows || []}
                  required
                  selectedName="name"
                  selectedValue="id"
                  width="20rem"
                  onChange={handleTenantNameChange}
                />
                <InputField
                  label="Tenant Email ID"
                  name="tenantEmail"
                  placeholder="poc@gmail.com"
                  width="20rem"
                  disabled
                />
                <div>
                  <InputField
                    label="Tenant Phone Number"
                    name="tenantMobileNumber"
                    placeholder="Enter Tenant Phone Number"
                    width="20rem"
                    disabled // Always disabled
                  />
                </div>
              </div>
            </div>

            <div className="detailPageCard mt-8 w-[40rem]">
              <EditTopChanger title="Status Log" />
              <div className="mx-auto max-h-[15rem]  overflow-y-auto pt-8">
                <div className="mx-auto w-[20rem]">
                  <Timeline
                    items={[
                      ...leaseStatusArr?.map?.((data, index) => {
                        return {
                          dot: (
                            <div
                              key={index}
                              className="h-[0.5rem] w-[0.5rem] rounded-full bg-[#0C344E]"
                            ></div>
                          ),
                          color: '#0C344E',
                          children: (
                            <div className="pl-1">
                              <div className="flex flex-col items-start justify-start">
                                <span className="text-[#0C344E]">
                                  Status Updated to &nbsp;
                                  <span
                                    className={`${
                                      statusColors?.[data?.status]?.textColor
                                    } font-semibold`}
                                  >
                                    {data?.status}
                                  </span>
                                </span>
                                {data?.status === 'Terminated' ? (
                                  <span className="py-1 text-sm text-[#717171]">
                                    {data?.comment}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                <span className="text-xs font-light text-[#BDBDBD]">
                                  {getCustomDateFormat(
                                    data?.createdAt,
                                    'YYYY/MM/DD,  hh:mm A'
                                  )}
                                </span>
                              </div>
                            </div>
                          ),
                        };
                      }),
                    ]}
                  />
                </div>
              </div>
            </div>
            {/* <div className="detailPageCard mt-8 w-[40rem]">
              <EditTopChanger title="Amenities" />
              <div className="mt-2">
                {leaseDetailData?.amenities?.length ? (
                  <ul className="list-disc pl-6">
                    {leaseDetailData.amenities.map((amenity, index) => (
                      <li key={index}>
                        <div className="flex items-center gap-2">
                          <div className="-mt-5">{index + 1}.</div>
                          <Tooltip title={amenity}>
                            <InputField
                              name={`amenities[${index}]`}
                              placeholder="Enter amenity here"
                              width="35rem"
                              disabled
                            />
                          </Tooltip>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="mt-2 h-10 text-center">
                    No Amenities Found
                  </div>
                )}
              </div>
            </div> */}
            {remindersArr?.length ? (
              <div className="detailPageCard mt-8 w-[40rem]">
                <EditTopChanger title="Lease Reminders Log" />
                <div className="mx-auto max-h-[15rem]  overflow-y-auto pt-8">
                  <div className="mx-auto w-[20rem]">
                    <Timeline
                      items={[
                        ...remindersArr?.map?.((data, index) => {
                          return {
                            dot: (
                              <div
                                key={index}
                                className="h-[0.5rem] w-[0.5rem] rounded-full bg-[#0C344E]"
                              ></div>
                            ),
                            color: '#0C344E',
                            children: (
                              <div className="pl-1">
                                <div className="flex flex-col items-start justify-start">
                                  <span className="text-[#0C344E]">
                                    Scheduled For:&nbsp;&nbsp;
                                    {data?.scheduledFor}
                                  </span>
                                  <span className="text-[#0C344E]">
                                    Status:&nbsp;&nbsp;
                                    <span
                                      className={`${
                                        statusColors?.[data?.status]?.textColor
                                      } font-semibold`}
                                    >
                                      {data?.status}
                                    </span>
                                  </span>
                                  <span className="text-sm text-[#BDBDBD]">
                                    {getCustomDateFormat(
                                      data?.cronTime,
                                      'YYYY/MM/DD,  hh:mm A'
                                    )}
                                  </span>
                                </div>
                              </div>
                            ),
                          };
                        }),
                      ]}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="detailPageCard mt-8 w-[40rem]">
              <EditTopChanger
                title="Attach Documents"
                state={currentStatus === 'Draft' ? 'documentDetails' : ''}
                {...{ setHandleEditMode, handleEditMode, handleEditChanges }}
              />
              <DocumentUpload
                {...{
                  docList,
                  setDocList,
                  showUploadButton:
                    currentStatus === 'Draft'
                      ? handleEditMode?.documentDetails
                      : false,
                  isDetailPage: true,
                  defaultFileList: leaseDetailData?.documents,
                }}
              />
            </div>
            {/* <div className="detailPageCard mt-8">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state={currentStatus === 'Draft' ? 'amenitiesDetails' : ''}
                title="Amenities Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode?.amenitiesDetails
                    ? 'none'
                    : '',
                }}
              >
                <div className="w-[23.5rem]">
                  <CheckBoxField name="amenities" options={unitAmenitiesList} />
                </div>
              </div>
            </div> */}
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default LeaseDetail;
