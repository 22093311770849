import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import React from 'react';

const PropertyTopCard = ({ propertyTopData: data, vertical = false }) => {
  const cardCssClass = vertical ? 'mb-4' : 'mr-4'; // Adjust spacing based on vertical or horizontal layout
  const containerCssClass = vertical
    ? 'flex flex-col gap-[2rem]'
    : 'flex flex-row'; // Adjust flex direction based on vertical or horizontal layout
  const widthClass = vertical ? 'w-full' : 'w-[30%]'; // Set width to 100% if vertical, otherwise 30%

  return (
    <div className={`m-6 ${containerCssClass}  justify-between`}>
      {data?.map((cur, key) => (
        <div key={key} className={`cardCss ${cardCssClass} ${widthClass}`}>
          <div>
            <img
              src={cur.img}
              alt="..."
              fetchpriority="high"
              className="h-20 w-20 object-contain"
            />
          </div>
          <div className="flex flex-col justify-center align-middle">
            <p className="colorFont overflow-wrap-break-word text-center">
              {cur.title}
            </p>
            <p className="colorFontYel mt-2 w-full text-center">
              {cur.count ?? 0}
            </p>
          </div>
          {cur?.increased && (
            <div>
              {cur.increased ? <CaretUpOutlined /> : <CaretDownOutlined />}
              {cur.percentage}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default React.memo(PropertyTopCard);
