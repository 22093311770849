import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  DocumentUpload,
  EditTopChanger,
  SelectField,
  TextField,
} from 'component/CompIndex';
import { useState } from 'react';

const UpdateServiceStatus = ({ setIsModalOpen, handleSave, statusArr }) => {
  const [form] = Form.useForm();
  const [docList, setDocList] = useState([]);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  const onFinish = (values) => {
    values.documents = docList;
    handleSave(values);
    handleCloseModal();
  };
  return (
    <div>
      <EditTopChanger title="Update Status" />
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        form={form}
        className="w-full p-4"
      >
        <div className="flex justify-center">
          <SelectField
            label="Update Status To"
            placeholder="Update Status To"
            name="status"
            required
            width="30rem"
            selectArr={statusArr}
            selectedName="status"
            selectedValue="key"
          />
        </div>
        <div>
          <p className="my-1">Attach Documents (Optional)</p>
          <DocumentUpload {...{ docList, setDocList }} />
        </div>
        <div className="flex justify-center">
          <TextField
            label="Comments (Optional)"
            name="comment"
            minRows={4}
            maxRows={4}
            width="30rem"
          />
        </div>
        <div className="center border-t-[1px] border-t-[#D4D4D4] py-5">
          <Button className="w-40" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className="customButton ml-4 w-40" htmlType="submit">
            Save Changes
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default UpdateServiceStatus;
