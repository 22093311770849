import { CreateUnitReducer } from 'Redux/slices/property/UnitSlice';
import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  CustomSelectField,
  InputField,
  SelectField,
} from 'component/CompIndex';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flatType } from 'staticData/PropertyData';

const ShortUnit = ({
  isModalOpen,
  setIsModalOpen,
  buildingId,
  setHandleModal,
  handleModal,
  setRefreshUnitRed,
}) => {
  const { isSuccess } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setHandleModal({
      ...handleModal,
      unit: false,
    });
    form.resetFields();
  };
  const onFinish = async (values) => {
    values.buildingId = buildingId;
    handleCloseModal();
    await dispatch(CreateUnitReducer({ values }));
    if (isSuccess) {
      setRefreshUnitRed(true);
    }
  };
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      initialValues={{
        remember: true,
      }}
      className="w-full"
    >
      <div className="p-8">
        <Title level={3}>Add Unit</Title>
        <InputField
          label="Unit Number"
          name="name_en"
          placeholder="Unit Number"
          width="28rem"
          message="This field is required"
          required="true"
        />
        <CustomSelectField
          label="Unit Type"
          name="flatType"
          placeholder="Unit Type"
          required="true"
          width="28rem"
          selectArr={flatType}
          selectedName={'name'}
          selectedValue={'name'}
          addItemFunction={() => {}}
        />
        <div className="center">
          <Button className="w-40" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className="customButton ml-4 w-40" htmlType="submit">
            Add Unit
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default React.memo(ShortUnit);
