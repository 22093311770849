import { Divider, Form, Select } from 'antd';
import React from 'react';

const SelectField = ({
  selectArr,
  selectedValue,
  selectedName,
  name,
  label,
  width,
  placeholder,
  required,
  disabled,
  secondName,
  isAddNew,
  AddNewDropDown,
}) => {
  const checkType = selectArr?.length > 0 ? typeof selectArr[0] : '';
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required ? true : false,
          message: 'This field is required',
        },
      ]}
    >
      {checkType === 'string' ? (
        <Select
          showSearch
          allowClear
          defaultActiveFirstOption
          bordered={false}
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
          style={{
            borderBottom: '1px solid #D4D4D4',
            borderRadius: '0',
            marginTop: '-1rem',
            marginLeft: '0px',
            width: width ? width : '18rem',
          }}
          dropdownRender={isAddNew ? AddNewDropDown : null}
        >
          <>
            {selectArr?.map((cur, index, key) => (
              <Select.Option value={cur} key={index}>
                {cur}
              </Select.Option>
            ))}
          </>
        </Select>
      ) : (
        <Select
          showSearch
          allowClear
          defaultActiveFirstOption
          bordered={false}
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
          style={{
            borderBottom: '1px solid #D4D4D4',
            borderRadius: '0',
            marginTop: '-1rem',
            marginLeft: '0px',
            width: width ? width : '18rem',
          }}
          filterOption={(inputValue, option) =>
            option?.children
              ?.join('')
              ?.toLowerCase()
              ?.includes(inputValue?.toLowerCase())
          }
          dropdownRender={isAddNew ? AddNewDropDown : null}
        >
          <>
            {selectArr?.map((cur, index) => (
              <Select.Option value={cur?.[selectedValue]} key={index}>
                {cur?.[selectedName]} {secondName && `(${cur?.[secondName]})`}
              </Select.Option>
            ))}
          </>
        </Select>
      )}
    </Form.Item>
  );
};

export default React.memo(SelectField);
