import { SimpleCard } from 'component/CompIndex';
import NoData from 'layouts/NoData';
import { useNavigate } from 'react-router-dom';

const UnitOwned = ({ userDetailData }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-wrap gap-8">
        {userDetailData?.ownedFlats?.length ? (
          userDetailData?.ownedFlats?.map((cur, id, key, index) => (
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate(`/admin/myproperties/unit/detail/${cur.id}`);
              }}
            >
              <SimpleCard
                key={index}
                address1={`${cur?.name_en ?? ''}, ${
                  cur?.building?.name_en ?? ''
                }`}
                address2={`${cur?.building?.address_en ?? ''}`}
                tag={`${cur?.flatType ?? ''}`}
              />
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </>
  );
};

export default UnitOwned;
