import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createNotice,
  editNoticeData,
  getNoticeData,
  getNoticeListing,
} from 'Redux/services/Notice/Notice';
import { initialListingState, initialState } from './InitialState';

// create
export const CreateNoticeReducer = createAsyncThunk(
  '/createNotice',
  async (payload) => {
    try {
      const resp = await createNotice(payload);
      return resp;
    } catch (error) {}
  }
);

// get listing page data
export const NoticeListingReducer = createAsyncThunk(
  '/NoticeListing',
  async (data) => {
    try {
      const resp = await getNoticeListing(data);
      return resp?.data;
    } catch (error) {}
  }
);
//getNoticeData
export const getNoticeDataReducer = createAsyncThunk(
  'getNoticeData',
  async (id) => {
    try {
      const resp = await getNoticeData(id);
      return resp?.data?.data;
    } catch (error) {}
  }
);
//editNoticeData
export const editNoticeDataReducer = createAsyncThunk(
  'editNoticeData',
  async (payload) => {
    try {
      const resp = await editNoticeData(payload);
      return resp;
    } catch (error) {}
  }
);

const NoticeSlice = createSlice({
  name: 'noticeSlice',
  initialState,
  reducers: {},

  extraReducers: {
    //addNotice
    [CreateNoticeReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateNoticeReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [CreateNoticeReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // NoticeListing
    [NoticeListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isLoadAgain = false;
    },
    [NoticeListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.noticeListingData = action?.payload?.data ?? initialListingState;
    },
    [NoticeListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get Notice data
    [getNoticeDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getNoticeDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.noticeDetailData = action?.payload;
    },
    [getNoticeDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editNoticeData
    [editNoticeDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editNoticeDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editNoticeDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default NoticeSlice.reducer;
