import {
  CreateHelplineReducer,
  editHelplineDataReducer,
} from 'Redux/slices/HelplineSlice';
import { Button, Checkbox, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import { InputField, MultiSelect } from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { defaultCountryCode, getBuildingIdFromArray } from 'utils/UtilsIndex';
import addImage from '../../assets/addImage.png';
import AddImage from './AddImage';

const AddHelpline = ({ isModalOpen, setIsModalOpen, helpLineDetails }) => {
  const { buildingsName } = useSelector((state) => state.Property);
  const [isTollFreeNumber, setIsTollFreeNumber] = useState(false);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [imageData, setImageData] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);

  const initialObj = {
    countryCode: helpLineDetails?.countryCode || defaultCountryCode,
    contactNumber: helpLineDetails?.contactNumber,
    isTollFree: helpLineDetails?.isTollFree,
    name_en: helpLineDetails?.name_en,
    buildings: getBuildingIdFromArray(helpLineDetails?.helplineBuildings),
    image: helpLineDetails?.image,
  };

  const onFinish = (values) => {
    const payload = {
      values: {
        ...values,
        image: imageData,
        isTollFree: isTollFreeNumber,
      },
      navigate,
    };
    //Api call for edit data
    if (helpLineDetails) {
      payload.values.helplineId = helpLineDetails?.id;
      dispatch(editHelplineDataReducer(payload));
    } else {
      // api call for add new helpline
      dispatch(CreateHelplineReducer(payload));
    }
    handleCloseModal();
  };

  const handleAddImage = (image) => {
    setImageData(image);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setImageData('');
    form.resetFields();
    form.setFieldsValue(initialObj);
  };

  useEffect(() => {
    setImageData(helpLineDetails?.image);
    setIsTollFreeNumber(helpLineDetails?.isTollFree ? true : false);
    form.setFieldsValue(initialObj);
  }, [form, helpLineDetails]);

  return (
    <>
      {!isAddImageModalOpen ? (
        <Form
          layout="vertical"
          size="large"
          onFinish={onFinish}
          form={form}
          className="w-full"
        >
          <Title level={4} className="bor w-full rounded bg-[#F6F6F6] p-4">
            {helpLineDetails ? 'Edit' : 'Add'} Contact
          </Title>
          <div className="p-4">
            <div className="flex">
              <div
                className="flex h-[10rem] w-[10rem] items-center justify-center rounded-full bg-[#F6F6F6]"
                onClick={() => setIsAddImageModalOpen(true)}
              >
                {imageData || helpLineDetails?.image ? (
                  <img
                    src={imageData || helpLineDetails?.image}
                    alt="helpLine"
                    className="h-full w-full rounded-full"
                  />
                ) : (
                  <img src={addImage} alt="helpLine"/>
                )}
              </div>
              <div className="ml-10 flex-1">
                <InputField
                  label="Full Name"
                  name="name_en"
                  placeholder="Full Name"
                  width="100%"
                />
                <MultiSelect
                  label="Select Building"
                  placeholder="Building Name"
                  name="buildings"
                  required="true"
                  width="100%"
                  selectArr={buildingsName?.rows}
                  selectedName="name_en"
                  selectedValue="id"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center gap-4">
                <label htmlFor="tollFree">Toll Free</label>
                <Checkbox
                  checked={isTollFreeNumber}
                  id="tollFree"
                  onChange={(e) => setIsTollFreeNumber(e.target.checked)}
                />
              </div>
              {isTollFreeNumber ? (
                <InputField
                  pattern="^[0-9-+/s]{3,}$"
                  name="contactNumber"
                  required={isTollFreeNumber ? true : false}
                  width={'100%'}
                  label="POC Phone Number"
                  max={12}
                  PlaceHolder="Enter Your Number"
                />
              ) : (
                <MobileNumber
                  label1="POC Phone Number"
                  countryCodeName="countryCode"
                  mobilePlaceHolder="Enter Your Number"
                  mobileNumberName="contactNumber"
                  countryCode={countryCode}
                  required
                />
              )}
            </div>
            {/* onClick={onFinish} */}
            <div className="center">
              <Button className="w-40" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button className="customButton ml-4 w-40" htmlType="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <AddImage
          setIsModalOpen={setIsAddImageModalOpen}
          handleAddImage={handleAddImage}
        />
      )}
    </>
  );
};

export default AddHelpline;
