import { Menu } from 'antd';
import { homeTherapyLogo, livoWhiteLogo } from 'assets/AssetIndex';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { itemsSideNav, routeObj } from 'staticData/Data';
import { forOpenTab } from 'utils/UtilsIndex';

const LeftMenu = () => {
  const navigate = useNavigate();
  const { sideBarData } = useSelector((state) => state?.Basic?.profileData);
  const { pathname } = useLocation();
  const redirection = (path) => {
    navigate(path);
  };
  function GetItem(label, key, icon, children, type) {
    if (sideBarData?.includes(label)) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }
    return null;
  }
  const items = itemsSideNav(GetItem);
  const projectName = process.env.REACT_APP_PROJECT_NAME;
  let projectLogo =
    projectName === 'HOME_THERAPY' ? homeTherapyLogo : livoWhiteLogo;
  return (
    <div className="h-full bg-[#001529]">
      <div
        className="center h-[15%]"
        style={{
          backgroundColor: '#001529',
        }}
      >
        <img
          src={projectLogo}
          alt="logo"
          className={projectName === 'HOME_THERAPY' ? 'h-40' : 'h-12'}
          fetchpriority="high"
        />
      </div>

      <div className="scrollbar-hidden hiddenScroll h-[85%] overflow-y-auto">
        <Menu
          defaultSelectedKeys={forOpenTab(routeObj, pathname)}
          selectedKeys={forOpenTab(routeObj, pathname)}
          defaultOpenKeys={forOpenTab(routeObj, pathname)}
          mode="inline"
          theme="dark"
          style={{
            height: '100%',
            padding: '1.5rem',
          }}
          items={items}
          onClick={({ key }) => redirection(key)}
        />
      </div>
    </div>
  );
};

export default LeftMenu;
