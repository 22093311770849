import { LeftMenu, TopNav } from 'layouts/LayoutIndex';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import AppLoader from './AppLoader';

const DashboardLayout = () => {
  const { isLoading } = useSelector((state) => state.Property);
  const { isLoading: isLoadingDashboard } = useSelector(
    (state) => state.Dashboard
  );
  const { isLoading: isLoadingUnit } = useSelector((state) => state.Unit);
  const { isLoading: isLoadingUser } = useSelector((state) => state.User);
  const { isLoading: isLoadingStaff } = useSelector((state) => state.Staff);
  const { isLoading: isLoadingInventory } = useSelector(
    (state) => state.Inventory
  );
  const { isLoading: isLoadingInvoice } = useSelector((state) => state.Invoice);
  const { isLoading: isLoadingGuard } = useSelector((state) => state.Guard);
  const { isLoading: isLoadingLease } = useSelector((state) => state.Lease);
  const { isLoading: isLoadingNotice } = useSelector((state) => state.Notice);
  const { isLoading: isLoadingHelpline } = useSelector(
    (state) => state.Helpline
  );
  const { isLoading: isLoadingReq } = useSelector((state) => state.Basic);
  const { isLoading: isLoadingVisitor } = useSelector((state) => state.Visitor);
  const { isLoading: isLoadingService } = useSelector((state) => state.Service);
  const { isLoading: isLoadingServiceCategory } = useSelector(
    (state) => state.serviceCategory
  );

  return (
    <>
      {(isLoading ||
        isLoadingDashboard ||
        isLoadingUnit ||
        isLoadingUser ||
        isLoadingStaff ||
        isLoadingInventory ||
        isLoadingInvoice ||
        isLoadingGuard ||
        isLoadingLease ||
        isLoadingNotice ||
        isLoadingHelpline ||
        isLoadingReq ||
        isLoadingVisitor ||
        isLoadingService ||
        isLoadingServiceCategory) && <AppLoader />}
      <div className="flex h-screen w-screen">
        <div className="h-full w-1/5" style={{ minWidth: '13rem' }}>
          <LeftMenu />
        </div>
        <div className="h-full w-4/5">
          <TopNav />
          <div className="varyHeight p-8">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
