import { axiosInstance } from '../AxiosClient';

export const createGuard = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.post(`/guards/sign-up`, data, { navigateTo });
  return resp;
};
export const getGuardListing = async (params) => {
  const resp = await axiosInstance.get(`/guards/admin?limit=10`, {
    params: params,
  });
  return resp;
};
export const getGuardData = async (id) => {
  const resp = await axiosInstance.get(`/guards/admin/${id}`);
  return resp;
};

export const editGuardData = async ({ data, navigateTo,id }) => {
  const resp = await axiosInstance.patch(
    `/guards/admin/${id}`,
    data, { navigateTo }
  ); 
  return resp;
};
export const editGuardStatus = async (guardId) => {
  const resp = await axiosInstance.patch(`guards/admin/status/${guardId}`);
  return resp;
};

export const deleteGuardData = async (id) => {
  const resp = await axiosInstance.delete(`/guards/${id}`);
  return resp;
};

export const guardCompanyData = async () => {
  const resp = await axiosInstance.get(`guards/admin/companies`);
  return resp;
};

export const getGuardExportData = async (params) => {
  const resp = await axiosInstance.get(`guards/admin/export`, { params });
  return resp;
};
