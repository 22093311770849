import {
  editSubUnitDataReducer,
  getSubUnitDataReducer,
  getUnitNameDataReducer,
} from 'Redux/slices/property/UnitSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  DocumentUpload,
  EditTopChanger,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { routeObj } from 'staticData/Data';
import { furnishings, rentalType } from 'staticData/PropertyData';
import { convertToUrlArray, getIdFromUrl } from 'utils/UtilsIndex';

const SubUnitDetails = () => {
  const { subUnitDetailData, unitNameData } = useSelector(
    (state) => state.Unit
  );
  const { buildingsName } = useSelector((state) => state.Property);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  // for docs
  const [docList, setDocList] = useState([]);

  const [handleEditMode, setHandleEditMode] = useState({
    unitDetails: false,
    ownerDetails: false,
    imagesDetails: false,
    documentDetails: false,
    amenitiesDetails: false,
  });
  const buildingId = Form.useWatch('buildingName', form);

  const onFinish = (values) => {
    values.documents = docList;
    values.images = convertToUrlArray(fileList);
    delete values?.buildingName;
    const payload = {
      values,
      id,
    };

    dispatch(editSubUnitDataReducer(payload));

    setHandleEditMode({
      unitDetails: false,
      ownerDetails: false,
      imagesDetails: false,
      documentDetails: false,
      amenitiesDetails: false,
    });
  };

  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    if (buildingId) {
      dispatch(getUnitNameDataReducer(buildingId));
    }
  }, [buildingId, dispatch]);

  useEffect(() => {
    dispatch(getSubUnitDataReducer(id));
  }, [dispatch, id]);

  useEffect(() => {
    var initialObj = {
      buildingName: subUnitDetailData?.flat?.building?.id,
      flatId: subUnitDetailData?.flat?.id,
      name_en: subUnitDetailData?.name_en,
      size: subUnitDetailData?.size,
      furnishing: subUnitDetailData?.furnishing,
      rentalType: subUnitDetailData?.rentalType,
      description: subUnitDetailData?.description,
    };
    form.setFieldsValue(initialObj);
  }, [subUnitDetailData, form]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      preserve={true}
      className="w-full"
      form={form}
    >
      <Title level={3}>Details</Title>
      <Row justify="space-between">
        <Col span={15} xs={{ span: 15 }} md={{ span: 15 }}>
          <Row justify="space-between">
            <div className="detailPageCard w-[38rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="unitDetails"
                title="Unit Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.unitDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <SelectField
                    label="Property Name"
                    name={buildingsName?.rows?.length && 'buildingName'}
                    placeholder="Property Name"
                    required="true"
                    selectArr={buildingsName?.rows}
                    selectedValue="id"
                    selectedName="name_en"
                    width="17rem"
                  />
                  <SelectField
                    label="Unit Number"
                    name={unitNameData?.rows?.length && 'flatId'}
                    placeholder="Unit Number"
                    required
                    selectArr={unitNameData?.rows}
                    selectedName="name_en"
                    selectedValue="id"
                    width="17rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Sub Unit Name"
                    name="name_en"
                    placeholder="Sub Unit Name"
                    restField
                    required
                    width="17rem"
                  />
                  <InputField
                    label="Size"
                    name="size"
                    placeholder="Enter size"
                    required="true"
                    width="17rem"
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Furnishing"
                    name="furnishing"
                    placeholder="Select Furnishing"
                    required
                    selectArr={furnishings}
                    width="17rem"
                  />
                  <SelectField
                    label="Rental Type"
                    name="rentalType"
                    placeholder="Select Rental Type"
                    selectArr={rentalType}
                    width="17rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Description"
                    name="description"
                    placeholder="Enter Description"
                    width="35rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={9} xs={{ span: 9 }} md={{ span: 9 }}>
          <div className="detailPageCard mb-8">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="imagesDetails"
              title="Attach Images"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <div>
              <ImageUpload
                {...{
                  previewOpen,
                  setPreviewOpen,
                  previewImage,
                  setPreviewImage,
                  previewTitle,
                  setPreviewTitle,
                  fileList,
                  setFileList,
                  defaultFileList: subUnitDetailData?.images,
                  showUploadButton: handleEditMode.imagesDetails,
                  isDetailPage: true,
                }}
              />
            </div>
          </div>
          <div className="detailPageCard">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="documentDetails"
              title="Attach Documents"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <DocumentUpload
              {...{
                docList,
                setDocList,
                isDetailPage: true,
                showUploadButton: handleEditMode?.documentDetails,
                defaultFileList: subUnitDetailData?.documents,
              }}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default SubUnitDetails;
