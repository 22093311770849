import { addLocality } from 'Redux/services/property/Property';
import {
  LocalityReducer,
  cityNameReducer,
  countryNameReducer,
  editBuildingDataReducer,
  getBuildingDataReducer,
} from 'Redux/slices/property/PropertySlice';
import {
  allAmenitiesDataReducer,
  clearAmenitiesReducer,
} from 'Redux/slices/property/UnitSlice';
import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { cancelButton, editIcon } from 'assets/AssetIndex';
import {
  CheckBoxField,
  CustomSelectField,
  DocumentUpload,
  EditTopChanger,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { propertyAmenitiesList, selectArr } from 'staticData/PropertyData';
import {
  addCountryInMob,
  convertToUrlArray,
  defaultCountryCode,
  getIdFromUrl,
  removeCountryCode,
} from 'utils/UtilsIndex';

const PropertyDetails = () => {
  const { propertyDetailData, localityData, countryNameData, cityNameData } =
    useSelector((state) => state.Property);
  const { amenities } = useSelector((state) => state.Unit);
  const { pathname } = useLocation();
  const buildingId = getIdFromUrl(pathname);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [handleEditMode, setHandleEditMode] = useState({
    buildingDetails: false,
    contactDetails: false,
    imagesDetails: false,
    documentDetails: false,
  });
  const [localityInfo, setLocalityInfo] = useState(null);
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  //for document
  const [docList, setDocList] = useState([]);

  const [initial] = Form.useForm();
  const countryCode = Form.useWatch('contactCountryCode', initial);
  const countryName = Form.useWatch('country', initial);
  const cityId = Form.useWatch('city', initial);
  const handleAddLocality = async (name_en) => {
    const payload = { name_en, cityId };
    try {
      const resp = await addLocality(payload);
      return {
        id: resp?.data?.data?.id,
        name: resp?.data?.data?.name_en,
      };
    } catch (error) {
      return null;
    }
  };
  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'contactCountryCode',
      'contactMobileNumber'
    );
    values.buildingId = buildingId;
    values.documents = docList;
    values.images = convertToUrlArray(fileList);
    dispatch(editBuildingDataReducer({ values }));
    setHandleEditMode({
      buildingDetails: false,
      contactDetails: false,
      imagesDetails: false,
      documentDetails: false,
    });
  };
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    var initialObj = {
      name_en: propertyDetailData?.name_en,
      buildingType: propertyDetailData?.buildingType,
      address_en: propertyDetailData?.address_en,
      localityId: propertyDetailData?.locality?.id,
      country: propertyDetailData?.locality?.city?.country_en,
      city: propertyDetailData?.locality?.city?.id,
      amenities: propertyDetailData?.amenities,
      governmentPropertyId: propertyDetailData?.governmentPropertyId,
      description_en: propertyDetailData?.description_en,
      contactName: propertyDetailData?.primaryContact?.name,
      contactEmail: propertyDetailData?.primaryContact?.email,
      contactCountryCode:
        '' +
        (propertyDetailData?.primaryContact?.countryCode ?? defaultCountryCode),
      contactMobileNumber: removeCountryCode(
        propertyDetailData?.primaryContact?.mobileNumber,
        propertyDetailData?.primaryContact?.countryCode
      ).number,
    };
    setLocalityInfo({
      cityId: propertyDetailData?.locality?.city?.id,
      countryName: propertyDetailData?.locality?.city?.country_en,
    });
    initialObj?.city && dispatch(LocalityReducer(initialObj?.city));
    initialObj?.country && dispatch(cityNameReducer(initialObj?.country));
    initial.setFieldsValue(initialObj);
  }, [initial, propertyDetailData, dispatch]);
  useEffect(() => {
    if (cityId && cityId !== localityInfo?.cityId) {
      dispatch(LocalityReducer(cityId));
      initial.setFieldsValue({ localityId: null });
    }
    if (countryName && countryName !== localityInfo?.countryName) {
      dispatch(cityNameReducer(countryName));
      initial.setFieldsValue({ localityId: null, city: null });
    }
    setLocalityInfo({ cityId, countryName });
  }, [countryName, cityId, dispatch, initial]);
  useEffect(() => {
    dispatch(getBuildingDataReducer(buildingId));
    dispatch(countryNameReducer());
    dispatch(allAmenitiesDataReducer('Properties'));
    return () => {
      dispatch(clearAmenitiesReducer());
    };
  }, [dispatch, buildingId]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      preserve={true}
      className="w-full"
      form={initial}
    >
      <Row justify="space-between">
        <Col span={13} xs={{ span: 13 }} md={{ span: 13 }}>
          <Row justify="space-between">
            <div className="detailPageCard w-[40rem] ">
              <div className="detailTopStyle">
                <Title level={5}>Building Details</Title>
                <div className="cursor-pointer">
                  {!handleEditMode.buildingDetails && (
                    <div onClick={() => handleEditChanges('buildingDetails')}>
                      <img src={editIcon} alt="editIcon" fetchpriority="high" />{' '}
                    </div>
                  )}
                  {handleEditMode.buildingDetails && (
                    <div className="flex">
                      <div className="mr-4 mt-3 h-4 w-4">
                        <img
                          src={cancelButton}
                          alt="..."
                          fetchpriority="high"
                          onClick={() =>
                            setHandleEditMode({
                              ...handleEditMode,
                              buildingDetails: false,
                            })
                          }
                        />
                      </div>
                      <Button htmlType="submit" className="customButton">
                        Save
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.buildingDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="Property Name"
                    name="name_en"
                    placeholder="Property Name"
                    message="This field is required"
                    width="16rem"
                    // defaultValue={propertyDetailData?.name_en}
                    required="true"
                  />
                  <SelectField
                    label="Property Type"
                    name="buildingType"
                    placeholder="Property Type"
                    width="16rem"
                    selectArr={selectArr}
                  />
                </div>
                <InputField
                  label="Full Address"
                  name="address_en"
                  placeholder="Full Address"
                  width="100%"
                />
                <div className="inputWidth">
                  <SelectField
                    label="Country"
                    name={'country'}
                    required="true"
                    placeholder="Country Name"
                    width="16rem"
                    selectArr={countryNameData}
                  />
                  <SelectField
                    label="City"
                    name={'city'}
                    required="true"
                    placeholder="City Name"
                    selectedValue="id"
                    selectedName="name_en"
                    width="16rem"
                    selectArr={cityNameData}
                  />
                </div>
                <div className="inputWidth">
                  <CustomSelectField
                    label="Locality"
                    name={'localityId'}
                    required="true"
                    placeholder="Locality"
                    selectedValue="id"
                    selectedName="name"
                    selectArr={localityData}
                    addItemFunction={handleAddLocality}
                    width="16rem"
                    disabled={cityId ? false : true}
                  />
                  <InputField
                    label="Property ID"
                    name="governmentPropertyId"
                    placeholder="123456789"
                    width="16rem"
                  />
                </div>
                <InputField
                  label="Description (Optional)"
                  name="description_en"
                  placeholder="Description"
                  width="100%"
                />
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[40rem]">
              <div className="detailTopStyle">
                <Title level={5}>Point of Contact Details</Title>
                <div className="cursor-pointer">
                  {!handleEditMode.contactDetails && (
                    <div onClick={() => handleEditChanges('contactDetails')}>
                      <img src={editIcon} alt="editIcon" fetchpriority="high" />{' '}
                    </div>
                  )}
                  {handleEditMode.contactDetails && (
                    <div className="flex">
                      <div className="mr-4 mt-3 h-4 w-4">
                        <img
                          src={cancelButton}
                          fetchpriority="high"
                          alt="..."
                          onClick={() => setHandleEditMode(false)}
                        />
                      </div>
                      <Button htmlType="submit" className="customButton">
                        Save
                      </Button>{' '}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.contactDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="POC Name"
                    name="contactName"
                    placeholder="Full Name"
                    width="16rem"
                  />
                  <InputField
                    label="POC Email ID"
                    name="contactEmail"
                    placeholder="poc@email.com"
                    width="16rem"
                    pattern={/.*@.*/}
                  />
                </div>
                <div className="inputWidth">
                  <MobileNumber
                    label1="POC Phone Number"
                    countryCodeName="contactCountryCode"
                    mobilePlaceHolder="Enter Your Number"
                    mobileNumberName="contactMobileNumber"
                    width="16rem"
                    // paddingLeft="1rem"
                    countryCode={countryCode}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={10} xs={{ span: 10 }} md={{ span: 10 }}>
          <div className="detailPageCard mb-8">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="imagesDetails"
              title="Add Images"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <div>
              <ImageUpload
                {...{
                  previewOpen,
                  setPreviewOpen,
                  previewImage,
                  setPreviewImage,
                  previewTitle,
                  setPreviewTitle,
                  fileList,
                  setFileList,
                  defaultFileList: propertyDetailData?.images,
                  showUploadButton: handleEditMode.imagesDetails,
                  isDetailPage: true,
                }}
              />
            </div>
          </div>
          <div className="detailPageCard">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="documentDetails"
              title="Attach Documents"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <div className="center py-4">
              <DocumentUpload
                {...{
                  docList,
                  setDocList,
                  defaultFileList: propertyDetailData?.documents,
                  isDetailPage: true,
                  showUploadButton: handleEditMode.documentDetails,
                }}
              />
            </div>
          </div>
          <div className="detailPageCard mt-8">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="amenitiesDetails"
              title="Amenities Details"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <div
              className="wap"
              style={{
                pointerEvents: !handleEditMode?.amenitiesDetails ? 'none' : '',
              }}
            >
              <div className="-mt-5">
                {amenities?.length ? (
                  <CheckBoxField
                    name="amenities"
                    selectedName="name"
                    selectedValue="name"
                    options={amenities}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default PropertyDetails;
