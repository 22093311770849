import { getStaffDataReducer } from 'Redux/slices/property/StaffSlice';
import Title from 'antd/es/typography/Title';
import {
  DateFilter,
  MixedHeadContent,
  TabComponent,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getIdFromUrl } from 'utils/UtilsIndex';
import EditStaffForm from './EditStaffForm';
import StaffAvailability from './StaffAvailability';

const StaffDetails = () => {
  const { staffDetailData } = useSelector((state) => state.Staff);
  const [dateFilter, setDateFilter] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const items = [
    {
      key: 1,
      label: `Staff Details`,
      children: <EditStaffForm {...{ staffDetailData }} />,
    },
    {
      key: 2,
      label: `Availability`,
      children: <StaffAvailability {...{ staffDetailData, dateFilter }} />,
    },
  ];
  useEffect(() => {
    dispatch(getStaffDataReducer(id));
  }, [id,dispatch]);
  return (
    <div className="relative">
      <Title level={3}>Details</Title>
      <div className="absolute right-0 z-10">
        <MixedHeadContent
          dateFilterComp={
            activeTab === 2 ? (
              <DateFilter
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                isSingleDate={true}
                title="Select Slot"
              />
            ) : null
          }
        />
      </div>
      <TabComponent
        {...{ items }}
        activeTabCount={'1'}
        onChangeTab={handleTabChange}
      />
    </div>
  );
};

export default StaffDetails;
