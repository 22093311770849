import { Checkbox, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';

const CheckBoxField = ({
  options,
  onChange,
  selectedValue,
  selectedName,
  name,
  title,
  message,
  required,
  label,
  setCheckValue,
}) => {
  return (
    <Form.Item
      name={name}
      className="m-0"
      label={<Title level={5}>{title}</Title>}
      rules={[
        {
          required: required ? true : false,
          message: message ? message : 'This field is required.',
        },
      ]}
    >
      {options?.length ? (
        <Checkbox.Group className='flex justify-start items-center'>
          {options?.map((data, index) => {
            return (
              <Checkbox
                key={index}
                value={selectedValue ? data?.[selectedValue] : data}
              >
                <span>
                  {selectedName ? data?.[selectedName] : data}
                </span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      ) : (
        <Checkbox name={label} />
      )}
    </Form.Item>
  );
};

export default React.memo(CheckBoxField);
