import { ClearOutlined, InboxOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import { useState } from 'react';
import AddProduct from './AddProduct';
import { useDispatch } from 'react-redux';
import { AddInventoryReducer } from 'Redux/slices/property/CatalogSlice';
import { updateProductReducer } from 'Redux/slices/ServiceSlice';

const AddServiceProduct = ({
  setIsModalOpen,
  closeModal,
  editProductDetails,
}) => {
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState('Both');
  const handleProductSelect = (key) => {
    setSelectedProduct(key);
  };
  const handleAddProduct = (values) => {
    if (!values?.isForAllBuildings) {
      delete values?.isForAllBuildings;
    }
    if (editProductDetails?.name) {
      const { billedFor, quantity } = values;
      dispatch(
        updateProductReducer({
          id: editProductDetails?.key,
          body: { billedFor, quantity },
        })
      );
    } else {
      values.inventoryType = selectedProduct;
      dispatch(AddInventoryReducer({ values }));
    }
    handleClose();
  };
  const handleClose = () => {
    setSelectedProduct('Both');
    if (closeModal) {
      closeModal();
    } else {
      setIsModalOpen(false);
    }
  };
  return (
    <>
      {selectedProduct === 'Both' && (
        <div className="p-3">
          <Title level={5}>Add Product/Service For</Title>
          <div className="flex flex-col">
            <div
              className="flex cursor-pointer items-center justify-start gap-5 py-2 hover:bg-grey"
              onClick={() => handleProductSelect('Inventory')}
            >
              <InboxOutlined className="text-6xl" />
              <div className="space-y-1">
                <p className="text-lg font-semibold">Inventory</p>
                <p>
                  {' '}
                  Products you buy and/or sell and track quantities of, for
                  example, parts used in an installation.
                </p>
              </div>
            </div>
            <Divider className="m-0 p-0" />
            <div
              className="flex cursor-pointer items-center justify-start gap-5 py-2 hover:bg-grey"
              onClick={() => handleProductSelect('Non Inventory')}
            >
              <ClearOutlined className="text-6xl" />
              <div className="space-y-1">
                <p className="text-lg font-semibold">Non Inventory</p>
                <p>
                  Products that you provide and don't need to (or can't) track
                  quantities of, to customers, for example, Rental Payments or
                  tax preparation services.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedProduct === 'Inventory' && (
        <AddProduct
          {...{
            closeModal: handleClose,
            handleSave: handleAddProduct,
            isInventory: true,
          }}
        />
      )}
      {selectedProduct === 'Non Inventory' && (
        <AddProduct
          {...{
            closeModal: handleClose,
            handleSave: handleAddProduct,
            isInventory: false,
          }}
        />
        // <AddServiceInvoice
        //   {...{
        //     isModalOpen,
        //     closeModal,
        //     handleSave,
        //     isInventory: false,
        //   }}
        // />
      )}
    </>
  );
};

export default AddServiceProduct;
