import { axiosInstance } from '../AxiosClient';

export const getLocalityList = async (cityId) => {
  const resp = await axiosInstance.get(`/localities?cityId=${cityId}`);
  return resp;
};

export const getCountryList = async () => {
  const resp = await axiosInstance.get(`/cities/admin/countries`);
  return resp;
};

export const getCityList = async (countryName) => {
  const resp = await axiosInstance.get(
    `/cities/admin/list?country=${countryName}`
  );
  return resp;
};

export const addLocality = async (payload) => {
  const resp = await axiosInstance.post(`/localities`, payload);
  return resp;
}

export const getBuildingListing = async (params) => {
  const resp = await axiosInstance.get(`/buildings/admin?limit=10`, {
    params,
  });
  return resp;
};

export const createBuilding = async ({data,navigateTo}) => {
  const resp = await axiosInstance.post(`/buildings`, data, {navigateTo});
  return resp;
};

export const getBuildingsName = async () => {
  const resp = await axiosInstance.get(`/buildings/admin/all`);
  return resp;
};

export const getBuildingData = async (id) => {
  const resp = await axiosInstance.get(`/buildings/admin/${id}`);
  return resp;
};

export const editBuildingData = async (data) => {
  const resp = await axiosInstance.patch(`/buildings`, data);
  return resp;
};

export const deleteBuildingData = async (buildingId) => {
  const resp = await axiosInstance.delete(`/buildings/admin/${buildingId}`);
  return resp;
}

export const getAnalyticsData = async () => {
  const resp = await axiosInstance.get(`/buildings/admin/statistics`);
  return resp;
};

export const getPropertyExportData = async (params) => {
  const resp = axiosInstance.get(`buildings/admin/export`, { params });
  return resp;
};
