import { Form } from 'antd';
import { useEffect } from 'react';
import { disablePastDates, getDateFormat } from 'utils/UtilsIndex';
import DateField from './DateField';

const DateFilter = ({ dateFilter, setDateFilter, title, isSingleDate }) => {
  const [form] = Form.useForm();
  const from = Form.useWatch('from', form) ?? '';
  const to = Form.useWatch('to', form) ?? '';
  const onlyDate = Form.useWatch('onlyDate', form) ?? '';

  const disableBeforeStartDate = (current) => {
    const todayDate = new Date();
    if (from) {
      const date = new Date(from);
      return current && (current < date || current < todayDate);
    }
    return current && current < todayDate;
  };

  useEffect(() => {
    const filteredDate = {
      date: getDateFormat(onlyDate),
    };
    if (onlyDate) {
      setDateFilter(filteredDate);
    }
    if (!onlyDate && dateFilter) {
      setDateFilter({ date: '' });
    }
  }, [onlyDate]);
  useEffect(() => {
    const filteredDate = {
      from: getDateFormat(from),
      to: getDateFormat(to),
    };
    if (to && from) {
      setDateFilter(filteredDate);
    }
    if (!to && !from && dateFilter) {
      dateFilter &&
        setDateFilter({
          to: '',
          from: '',
        });
    }
  }, [from, to]);
  return (
    <>
      {isSingleDate ? (
        <Form
          form={form}
          className="relative top-2 flex flex-col justify-between gap-x-4"
        >
          {title ? <div className="pb-1 font-semibold">{title}</div> : ''}
          <div className="flex">
            <DateField
              name="onlyDate"
              width="12rem"
              disabledDate={disablePastDates}
              isDisabledDate={true}
            />
          </div>
        </Form>
      ) : (
        <Form form={form} className="relative top-2 flex flex-col gap-x-4">
          {title ? <div className="pb-1 font-semibold">{title}</div> : ''}
          <div className="flex items-center justify-between gap-3">
            <div className="flex flex-col">
              <p>From</p>
              <DateField
                name="from"
                width="8rem"
                disabledDate={disablePastDates}
                isDisabledDate={true}
              />
            </div>
            <div className="flex flex-col">
              <p>To</p>
              <DateField
                name="to"
                width="8rem"
                disabledDate={disableBeforeStartDate}
                isDisabledDate={true}
              />
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default DateFilter;
