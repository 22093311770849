import { Space, Tag } from 'antd';
import { simpleCardIcon } from 'assets/AssetIndex';
import React from 'react';

const SimpleCard = ({ address1, address2, tag, status }) => {
  return (
    <Space className="cardCss w-[30%] min-w-[18rem]">
      <Space>
        <img src={simpleCardIcon} alt="..." fetchpriority="high" />
      </Space>
      <Space direction="vertical">
        {address1?<Space>{address1}</Space>:<></>}
        {address2?<Space>{address2}</Space>:<></>}
        <Space>
          <Tag className="rounded-xl px-4 text-[10px]">{tag}</Tag>
          {status && (
            <Tag
              color={status == 'Active' ? 'green' : 'red'}
              className="rounded-xl px-4 text-[10px]"
            >
              {status}
            </Tag>
          )}
        </Space>
      </Space>
    </Space>
  );
};

export default React.memo(SimpleCard);
