import { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Select, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

const CustomSelectField = ({
  label,
  name,
  required,
  placeholder,
  disabled,
  width,
  selectArr,
  selectedValue,
  selectedName,
  addItemFunction,
  multiple = false,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    const name = event.target.value;
    const updatedItems = allItems.filter((data) => {
      if (selectedName) {
        return data?.[selectedName]
          ?.toLowerCase()
          ?.includes(name?.toLowerCase());
      } else {
        return data?.toLowerCase()?.includes(name?.toLowerCase());
      }
    });
    setSelectedItems(updatedItems);
    setSearchText(name);
  };
  const addItem = async (e) => {
    e.preventDefault();
    if (searchText && selectedName && addItemFunction) {
      const data = await addItemFunction(searchText);
      data && setSelectedItems([data, ...allItems]);
      setAllItems([data, ...allItems]);
    }
    setSearchText('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const checkType = selectArr?.length > 0 ? typeof selectArr[0] : '';
  useEffect(() => {
    setAllItems(selectArr);
    setSelectedItems(selectArr);
  }, [selectArr]);
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required ? true : false,
          message: 'This field is required',
        },
      ]}
    >
      {checkType !== 'string' ? (
        <Select
          bordered={false}
          disabled={disabled ? disabled : false}
          placeholder={placeholder}
          mode={multiple ? 'multiple' : null}
          className="max-h-[2.5rem] overflow-y-auto overflow-x-hidden"
          style={{
            borderBottom: '1px solid #D4D4D4',
            borderRadius: '0',
            marginTop: '-1rem',
            marginLeft: '0px',
            width: width ? width : '18rem',
          }}
          dropdownRender={(menu) => (
            <>
              <Space
                style={{
                  padding: '2px',
                }}
              >
                <Input
                  placeholder={`Add/Search ${label}`}
                  ref={inputRef}
                  value={searchText}
                  onChange={onNameChange}
                />
                <Button
                  className="customButton"
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={addItem}
                >
                  {/* {label} */}
                  Add
                </Button>
              </Space>
              <div className="relative -top-5">
                <Divider />
                {menu}
              </div>
            </>
          )}
          options={selectedItems.map((item) => ({
            label: selectedName ? item?.[selectedName] : item,
            value: selectedValue ? item?.[selectedValue] : item,
          }))}
        />
      ) : (
        <Select
          showSearch
          allowClear
          defaultActiveFirstOption
          bordered={false}
          placeholder={placeholder}
          disabled={disabled ? disabled : false}
          style={{
            borderBottom: '1px solid #D4D4D4',
            borderRadius: '0',
            marginTop: '-1rem',
            marginLeft: '0px',
            width: width ? width : '18rem',
          }}
          filterOption={(inputValue, option) =>
            option?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
          }
        >
          <>
            {allItems?.map((cur, index, key) => (
              <Select.Option
                value={cur?.[selectedValue] || cur}
                key={cur?.[selectedValue] || cur}
                name={cur?.[selectedName] || cur}
              >
                {cur?.[selectedName] || cur}
              </Select.Option>
            ))}
          </>
        </Select>
      )}
    </Form.Item>
  );
};

export default CustomSelectField;
