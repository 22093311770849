import React, { useEffect, useState } from 'react';
import { RenewalDiscountList, discountList, discountListWay } from 'staticData/LeaseData';
import {
  CheckBoxField,
  InputField,
  RadioField,
  SelectField,
} from './CompIndex';

const RentalDiscount = ({
  discountApplicability,
  isDiscountRequired,
  discountType,
  currency,
  discountValueWidth,
  totalAmountWidth,
  isForRenewal
}) => {
  return (
    <div className="relative -top-8">
      <div className="flex flex-col justify-between">
        <CheckBoxField name="isDiscountRequired" selectedName='label' selectedValue='value' options={discountList} />
        {isDiscountRequired && (
          <SelectField
            label="Applicable On"
            name="discountApplicability"
            placeholder="Applicable On"
            selectArr={isForRenewal ? RenewalDiscountList : discountListWay}
          />
        )}
      </div>
      {isDiscountRequired && discountApplicability && (
        <div className="flex flex-col">
          {discountApplicability === 'Grace Period' ? (
            <InputField
              label="Grace Period (in Months)"
              name="grace"
              type="number"
              placeholder="Grace Period"
              width="18rem"
            />
          ) : (
            <>
              <RadioField
                name="discountType"
                radioArr={['Percentage', 'Fixed']}
              />
              <div className="inputWidth">
                <InputField
                  label={
                    discountType === 'Fixed'
                      ? `Amount ( in ${currency?.toUpperCase() ?? 'currency'} )`
                      : 'Percentage (%)'
                  }
                  name="discountValue"
                  placeholder={
                    discountType === 'Fixed' ? `Amount` : 'Percentage'
                  }
                  type
                  min={0}
                  max={discountType === 'Fixed' ? null : 100}
                  width={discountValueWidth || '12rem'}
                />
                <InputField
                  label={`Total ${discountApplicability} after Discount`}
                  name="totalDiscount"
                  type
                  placeholder={`Total ${discountApplicability} after Discount`}
                  width={totalAmountWidth || '12rem'}
                  disabled
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default RentalDiscount;
