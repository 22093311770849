import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form } from 'antd';
import {
  DataTable,
  InputField,
  MultiSelect,
  SelectField
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const AddAllocatedDetails = ({
  amenities,
  setAmenities,
  unitId,
  isEditData = true,
  isDetailPage,
}) => {
  const { ownerListData } = useSelector((state) => state.Unit);
  const [editKeyIndex, setEditKeyIndex] = useState(-1);
  const [showForm, setShowForm] = useState(false);
  const [form] = Form.useForm();
  const itemNameWatch = Form.useWatch('itemName', form);
  const itemIdsWatch = Form.useWatch('itemIds', form);
  const initialObj = {
    itemName: '',
    itemIds: [],
    description: '',
  };
  const allocatedItems = [
    { name: 'Access Card', value: 'accessCards' },
    { name: 'Parking card', value: 'parkingLots' },
    { name: 'Other', value: 'Other' },
  ];
  const handleDeleteItem = (index) => {
    const updatedItems = amenities?.filter((_, id) => id !== index);
    setAmenities([...updatedItems]);
    if (!updatedItems?.length) {
      form.resetFields();
      setEditKeyIndex(-1);
      setShowForm(false);
    } else if (form.getFieldsValue() != {}) {
      setEditKeyIndex(editKeyIndex - 1);
    } else {
      setEditKeyIndex(-1);
    }
  };
  const handleEditItem = (index) => {
    if (Object.keys(form?.getFieldsValue())?.length) {
      form.submit();
      return;
    }
    if (index !== -1) {
      const values = form.getFieldsValue();
      handleSaveItem(values);
    }
    form.setFieldsValue({ ...amenities?.[index] });
    setEditKeyIndex(index);
  };
  const handleSaveItem = (values) => {
    const updatedItems = amenities;
    if (values?.itemName === 'Other') {
      values.itemIds = [values?.itemIds];
    }
    if (!values?.quantity) {
      values.quantity = values?.itemIds?.length;
    }
    updatedItems[editKeyIndex] = { ...values };
    setAmenities([...updatedItems]);
    setEditKeyIndex(-1);
    form.resetFields();
  };
  const handleAddNewItem = () => {
    if (!showForm) {
      setShowForm(true);
    }
    if (editKeyIndex !== -1) {
      form.submit();
    }
    if (editKeyIndex === -1) {
      setEditKeyIndex(amenities?.length);
      setAmenities([...amenities, { ...initialObj }]);
    }
  };
  const data = amenities;
  const columns = [
    {
      title: <div className="w-[7rem]">Name</div>,
      dataIndex: 'itemName',
      key: 'itemName',
      render: (_, { itemName }, index) =>
        editKeyIndex === index ? (
          <SelectField
            selectArr={allocatedItems}
            name="itemName"
            selectedName="name"
            selectedValue="value"
            width="7rem"
            required
            placeholder="Item Name"
          />
        ) : (
          <div className="w-[7rem] truncate">
            {
              allocatedItems?.filter((data) => data?.value === itemName)?.[0]
                ?.name
            }
          </div>
        ),
    },
    {
      title: <div className="w-[10rem]">Item Id</div>,
      dataIndex: 'itemIds',
      key: 'itemIds',
      render: (_, { itemIds }, index) =>
        editKeyIndex === index ? (
          itemNameWatch === 'Other' ? (
            <InputField
              name="itemIds"
              width="10rem"
              required
              placeholder="Enter Item ID"
            />
          ) : (
            <MultiSelect
              selectArr={ownerListData?.flatInfo?.[itemNameWatch]}
              name="itemIds"
              width="10rem"
              required
              placeholder="Select Item ID"
            />
          )
        ) : (
          <div className="flex w-[10rem] gap-1 truncate">
            {itemIds?.map((data, index) => (
              <span key={index} className="rounded-lg border border-[gray] p-1">
                {data}
              </span>
            ))}
          </div>
        ),
    },
    {
      title: <div className="w-[4rem]">Quantity</div>,
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_, { quantity }, index) =>
        editKeyIndex === index && itemNameWatch === 'Other' ? (
          <InputField
            name="quantity"
            width="4rem"
            placeholder="Quantity"
            type
            required
          />
        ) : (
          <div
            className={`w-[4rem] truncate ${
              editKeyIndex === index ? 'mb-4' : ''
            }`}
          >
            {editKeyIndex === index ? itemIdsWatch?.length : quantity}
          </div>
        ),
    },
    {
      title: <div className="w-[10rem]">Description</div>,
      dataIndex: 'description',
      key: 'description',
      render: (_, { description }, index) =>
        editKeyIndex === index ? (
          <InputField
            name="description"
            width="10rem"
            placeholder="Enter Description..."
          />
        ) : (
          <div className="w-[10rem] truncate">{description}</div>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, __, index) => {
        return (
          isEditData && (
            <>
              <div className=" z-10 mx-auto flex w-[1rem] items-center justify-center gap-2 text-lg">
                {index !== editKeyIndex ? (
                  <EditOutlined
                    onClick={() => handleEditItem(index)}
                    className="cursor-pointer text-[#2BB256]"
                  />
                ) : (
                  <CheckCircleOutlined
                    onClick={() => form.submit()}
                    className="cursor-pointer text-[#2BB256]"
                  />
                )}
                <DeleteOutlined
                  onClick={() => handleDeleteItem(index)}
                  className="cursor-pointer text-[#E20F0F]"
                />
              </div>
            </>
          )
        );
      },
    },
  ];
  useEffect(() => {
    setShowForm(false);
    setAmenities([]);
    form.resetFields();
  }, [unitId]);
  return (
    <div className="w-full">
      {showForm || isDetailPage ? (
        <Form
          layout="vertical"
          form={form}
          size="large"
          className="w-[12rem]"
          onFinish={handleSaveItem}
        >
          <DataTable
            {...{
              columns,
              data,
            }}
          />

          <h2
            onClick={isEditData ? handleAddNewItem : null}
            className="mt-10 cursor-pointer p-1 text-[#229EEF]"
          >
            {isEditData && (
              <span>
                <PlusOutlined /> Add New Item
              </span>
            )}
          </h2>
        </Form>
      ) : (
        isEditData && (
          <Button
            type="text"
            onClick={handleAddNewItem}
            disabled={unitId ? false : true}
            className="flex items-center text-[16px] text-[#229EEF] underline"
          >
            <PlusOutlined /> Add Allocated Items
          </Button>
        )
      )}
    </div>
  );
};

export default AddAllocatedDetails;
