import { addLocality } from 'Redux/services/property/Property';
import {
  CreateBuildingReducer,
  LocalityReducer,
  cityNameReducer,
  countryNameReducer,
} from 'Redux/slices/property/PropertySlice';
import {
  allAmenitiesDataReducer,
  clearAmenitiesReducer,
} from 'Redux/slices/property/UnitSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  CheckBoxField,
  CustomSelectField,
  DocumentUpload,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { selectArr } from 'staticData/PropertyData';
import {
  addCountryInMob,
  convertToUrlArray,
  defaultCountryCode,
} from 'utils/UtilsIndex';

const AddProperty = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localityData, countryNameData, cityNameData } = useSelector(
    (state) => state.Property
  );
  const { amenities } = useSelector((state) => state.Unit);
  const [localityInfo, setLocalityInfo] = useState(null);
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  //for document

  const [docList, setDocList] = useState([]);
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('contactCountryCode', form);
  const countryName = Form.useWatch('country', form);
  const cityId = Form.useWatch('city', form);

  const onFinish = (data) => {
    data.images = convertToUrlArray(fileList);
    data.documents = docList;
    const value = addCountryInMob(
      data,
      'contactCountryCode',
      'contactMobileNumber'
    );
    const payload = {
      data,
      navigateTo: {
        navigate,
        url: routeObj?.myproperties,
      },
      dispatch,
    };
    dispatch(CreateBuildingReducer(payload));
  };
  const handleCancelData = () => {
    navigate('/admin/myproperties/property?page=1');
  };

  const handleAddLocality = async (name_en) => {
    const payload = { name_en, cityId };
    try {
      const resp = await addLocality(payload);
      return {
        id: resp?.data?.data?.id,
        name: resp?.data?.data?.name_en,
      };
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (cityId && cityId !== localityInfo?.cityId) {
      dispatch(LocalityReducer(cityId));
      form.setFieldsValue({ localityId: null });
    }
    if (countryName && countryName !== localityInfo?.countryName) {
      dispatch(cityNameReducer(countryName));
      form.setFieldsValue({ localityId: null, city: null });
    }
    setLocalityInfo({ cityId, countryName });
  }, [countryName, cityId, dispatch, form]);

  useEffect(() => {
    const initialValues = {
      contactCountryCode: defaultCountryCode,
    };
    form.setFieldsValue(initialValues);
    dispatch(countryNameReducer());
    dispatch(allAmenitiesDataReducer('Properties'));
    return () => {
      dispatch(clearAmenitiesReducer());
    };
  }, [dispatch, form]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New Property"
        saveButtonText="Add Property"
      />
      <Row justify="space-between">
        <Col span={14} xs={{ span: 14 }} md={{ span: 14 }}>
          <Title level={5}>Building Details</Title>
          <Row justify="space-between">
            <div className="inputWidth">
              <InputField
                label="Property Name"
                name="name_en"
                placeholder="Property Name"
                required="true"
              />
              <SelectField
                label="Property Type"
                name="buildingType"
                placeholder="Property Type"
                selectArr={selectArr}
              />
            </div>
            <InputField
              label="Full Address"
              name="address_en"
              placeholder="Full Address"
              width="38rem"
            />
            <div className="inputWidth">
              <SelectField
                label="Country"
                name="country"
                required="true"
                placeholder="Country Name"
                selectArr={countryNameData}
              />
              <SelectField
                label="City"
                name="city"
                required="true"
                placeholder="City Name"
                selectedValue="id"
                selectedName="name_en"
                selectArr={cityNameData}
                disabled={countryName ? false : true}
              />
            </div>
            <div className="inputWidth">
              <CustomSelectField
                label="Locality"
                name="localityId"
                required="true"
                placeholder="Locality"
                selectedValue="id"
                selectedName="name"
                selectArr={localityData}
                addItemFunction={handleAddLocality}
                disabled={cityId ? false : true}
              />
              <InputField
                label="Property ID"
                name="governmentPropertyId"
                placeholder="Property ID"
              />
            </div>
            <InputField
              label="Description (Optional)"
              name="description_en"
              placeholder="Description"
              width="38rem"
            />
            <Title level={5}>Point of Contact Details</Title>
            <div className="inputWidth">
              <InputField
                label="POC Name"
                name="contactName"
                placeholder="Full Name"
              />
              <InputField
                label="POC Email ID"
                name="contactEmail"
                placeholder="Poc Email"
                pattern={/.*@.*/}
              />
            </div>
            <div>
              <MobileNumber
                label1="POC Phone Number"
                countryCodeName="contactCountryCode"
                mobilePlaceHolder="Poc Phone Number"
                mobileNumberName="contactMobileNumber"
                countryCode={countryCode}
              />
            </div>
          </Row>
        </Col>
        <Col span={9} xs={{ span: 9 }} md={{ span: 9 }}>
          <Title level={5}>Add Images</Title>
          <ImageUpload
            {...{
              previewOpen,
              setPreviewOpen,
              previewImage,
              setPreviewImage,
              previewTitle,
              setPreviewTitle,
              fileList,
              setFileList,
            }}
          />
          <Title level={5} className="my-8">
            Attach Documents
          </Title>
          <DocumentUpload {...{ docList, setDocList }} />
          <div>
            {amenities?.length ? (
              <CheckBoxField
                title="Add Amenities"
                name="amenities"
                selectedName="name"
                selectedValue="name"
                options={amenities}
              />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default AddProperty;
