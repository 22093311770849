import { countryNameReducer } from 'Redux/slices/property/PropertySlice';
import { Checkbox, Collapse, Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { userType } from 'staticData/UserData';
import { genderList } from 'staticData/PropertyData';
import countries from 'layouts/localData/CountryCode';

const UserFilter = ({ filter, setFilter }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const { countryNameData } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const handleFilterChange = (isChecked, name, value) => {
    if (isChecked) {
      setFilter({ ...filter, [name]: value });
    } else {
      setFilter({ ...filter, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };

  useEffect(() => {
    if (countryNameData.length < 1) dispatch(countryNameReducer());
  }, []);
  return (
    <div className="flex w-60 flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel header={<h2 className="font-bold">User Role</h2>}>
          <div className="flex flex-col justify-start">
            {userType?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data?.value}
                  checked={filter.userType === data?.value ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(
                      e.target?.checked,
                      'userType',
                      data?.value
                    )
                  }
                >
                  <label htmlFor={data?.value} style={{ whiteSpace: 'nowrap' }}>
                    {data?.name}
                  </label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Nationality</h2>}>
          <Select
            placeholder="Select Nationality"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'nationality', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {countries?.map((cur, index, key) => (
              <Select.Option value={cur.label} key={index}>
                {cur.label}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Gender</h2>}>
          <div className="flex flex-col justify-start">
            {genderList?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={filter.gender === data ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(e.target?.checked, 'gender', data)
                  }
                >
                  <label htmlFor={data}>{data}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default UserFilter;
