import { SearchOutlined } from '@ant-design/icons';
import {
  ApproveLoginRequestReducer,
  RequestOverviewReducer,
  requestDenySlice,
  requestListingReducer,
} from 'Redux/slices/BasicSlice';
import { Input } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AcceptLoginReq,
  DataTable,
  DeleteRequest,
  ModalComp,
} from 'component/CompIndex';
import PropertyTopCard from 'component/PropertyTopCard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  getLoginRequestTableData,
  getLoginRequestTopCardData,
} from 'staticData/LoginRequest';
import { getUrlPageNo } from 'utils/UtilsIndex';

const LoginRequest = () => {
  const {
    requestListingData: { rows, count },
    isLoadAgain,
    buildingAnalyticsData,
  } = useSelector((state) => state.Basic);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState('');
  const [requestData, setRequestData] = useState(null);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [searchParams, setSearchParam] = useSearchParams();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const { propertyTopData } = getLoginRequestTopCardData(buildingAnalyticsData);

  const handleApproveModalOpen = (data) => {
    setRequestData(data);
    setIsApproveModal(true);
  };
  const handleDeleteModalOpen = (data) => {
    setRequestData(data);
    setIsDeleteModal(true);
  };
  const handleClose = () => {
    if (isApproveModal) {
      setIsApproveModal(false);
    } else if (isDeleteModal) {
      setIsDeleteModal(false);
    }
    setRequestData(null);
  };

  const handleApprove = (values) => {
    values.requestedFlat = requestData?.requestId;
    dispatch(ApproveLoginRequestReducer(values));
    handleClose();
  };
  const handleDenied = () => {
    dispatch(requestDenySlice(requestData?.requestId));
    handleClose();
  };

  const handleChange = (e) => {
    setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);
  };

  const { data, columns } = getLoginRequestTableData(
    rows,
    handleApproveModalOpen,
    handleDeleteModalOpen
  );

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      search,
      buildingId: selectedBuildingName,
    };
    dispatch(requestListingReducer(params));
    dispatch(RequestOverviewReducer());
  }, [selectedBuildingName, search, defaultPageNo, isLoadAgain, dispatch]);

  return (
    <>
      <ModalComp
        {...{ isModalOpen: isDeleteModal || isApproveModal }}
        component={
          isApproveModal ? (
            <AcceptLoginReq {...{ handleClose, handleSave: handleApprove }} />
          ) : (
            <DeleteRequest
              text="Are you sure you want to delete User?"
              handleClose={handleClose}
              handleSave={handleDenied}
            />
          )
        }
      />
      <div className="inputWidth">
        <Title level={3}>Login Requests</Title>
        <Input
          placeholder="Search Building"
          prefix={<SearchOutlined className="site-form-item-icon" />}
          className="inputHover h-10 w-48"
          onChange={handleChange}
        />
      </div>
      <PropertyTopCard {...{ propertyTopData }} />
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </>
  );
};

export default LoginRequest;
