import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { getHelpLineImagesReducer } from 'Redux/slices/HelplineSlice';
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const AddImage = ({ setIsModalOpen, handleAddImage }) => {
  const [searchText, setSearchText] = useState('');
  const { helplineImageData } = useSelector((state) => state.Helpline);
  const dispatch = useDispatch();
  useEffect(() => {
    const params = { search: searchText };
    dispatch(getHelpLineImagesReducer(params));
  }, [searchText,dispatch]);
  return (
    <div className="relative max-h-[35rem] overflow-y-auto p-5">
      <div
        className=" absolute right-1.5 top-3"
        onClick={() => setIsModalOpen(false)}
      >
        <CloseOutlined className="cursor-pointer text-xl" />
      </div>
      <div className="p-[10px_20px_50px_20px]">
        <Input
          placeholder="Search"
          prefix={<SearchOutlined className="site-form-item-icon" />}
          className="inputHover h-10"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="mt-4">
          <p>
            {searchText
              ? `${helplineImageData?.count} matching results`
              : `Showing all ${helplineImageData?.count || ''} results`}
          </p>
        </div>
        <div className="mt-2 flex flex-wrap items-center justify-start gap-x-1 gap-y-2">
          {helplineImageData?.rows?.map((icon, index) => (
            <div
              key={index}
              className="flex h-[80px] w-[80px] flex-col items-center justify-between gap-2 overflow-hidden py-1 text-center"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              <img
                src={icon?.image}
                style={{
                  width: '50px',
                  height: '45px',
                  objectFit: 'contain',
                }}
                alt="..."
                onClick={() => handleAddImage(icon?.image)}
              />
              <span>{icon?.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddImage);
