import { axiosInstance } from '../AxiosClient';

export const getDashboardOverview = async () => {
  const resp = await axiosInstance.get('/dashboard/overview');
  return resp;
};
export const getServiceAnalyticsDataReducer = async (params) => {
  const resp = await axiosInstance.get('/maintenances/statuses/statistics', {
    params,
  });
  return resp;
};
export const getVisitorAnalyticsData = async (params) => {
  const resp = await axiosInstance.get('/dashboard/visitor/statistics', {
    params,
  });
  return resp;
};
export const getUserOverView = async (params) => {
  const resp = await axiosInstance.get('/dashboard/user-stats', { params });
  return resp;
};
export const getVisitorOverView = async (params) => {
  const resp = await axiosInstance.get(
    '/dashboard/visitor/category-analytics',
    { params }
  );
  return resp;
};

export const getDeliveryOverview = async (params) => {
  const resp = await axiosInstance.get(
    '/dashboard/visitor/delivery-analytics',
    { params }
  );
  return resp;
};

export const getVisitorLiveData = async (params) => {
  const resp = await axiosInstance.get('/dashboard/visitor/analytics', {
    params,
  });
  return resp.data;
};

export const getServiceOverView = async (params) => {
  const resp = await axiosInstance.get('/dashboard/maintenance-stats', {
    params,
  });
  return resp;
};
export const getUnitAnalytics = async (params) => {
  const resp = await axiosInstance.get('/dashboard/units', { params });
  return resp;
};
export const getServiceBifurcation = async (params) => {
  const resp = await axiosInstance.get(
    '/dashboard/maintenance-bifurcation/statistics',
    { params }
  );
  return resp;
};
export const getMostRequested = async (params) => {
  const resp = await axiosInstance.get(
    '/dashboard/maintenance-area/statistics',
    { params }
  );
  return resp;
};
export const getVisitorStatsHour = async (params) => {
  const resp = await axiosInstance.get('/dashboard/visitor/analytics/hourly', {
    params,
  });
  return resp;
};

export const getVisitorStats = async (params) => {
  const resp = await axiosInstance.get('/dashboard/visitor-stats', {
    params,
  });
  return resp;
};
export const getVisitorStatsWeek = async (params) => {
  const resp = await axiosInstance.get('/dashboard/visitor/analytics/weekly', {
    params,
  });
  return resp;
};
export const getVisitorStatsDaily = async (params) => {
  const resp = await axiosInstance.get('/dashboard/visitor/analytics/daily', {
    params,
  });
  return resp;
};
export const getLeaseStatsDonut = async (params) => {
  const resp = await axiosInstance.get('/dashboard/lease-usage-stats', {
    params,
  });
  return resp;
};
export const getLeaseStatusStats = async (params) => {
  const resp = await axiosInstance.get('/dashboard/lease-statuses', {
    params,
  });
  return resp;
};
export const getLeaseBiCountStats = async (params) => {
  const resp = await axiosInstance.get('/dashboard/lease-types', {
    params,
  });
  return resp;
};
