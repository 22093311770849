import Title from 'antd/es/typography/Title';
import { TabComponent } from 'component/CompIndex';
import Notifications from './settingsTab/Notifications';

const Setting = () => {
  const items = [
    {
      key: '1',
      label: `Notifications`,
      children: <Notifications />,
    },
    // {
    //   key: '2',
    //   label: `General`,
    //   children: <General />,
    // },
  ];
  return (
    <>
      <Title level={3}>Settings</Title>
      <TabComponent {...{ items }} activeTabCount={'1'} />
    </>
  );
};

export default Setting;
