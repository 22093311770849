import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { allTagsData, createInvoice, createTag, editInvoiceDetails, getInvoiceDetails, invoiceListing } from 'Redux/services/Invoices/Invoice';
import { initialListingState, initialState } from './InitialState';

// create Invoice
export const CreateInvoiceReducer = createAsyncThunk(
    '/createInvoice',
    async (payload) => {
        try {
            const resp = await createInvoice(payload);
            return resp;
        } catch (error) { }
    }
);

// invoice listing
export const InvoiceListingReducer = createAsyncThunk(
    '/InvoiceListing',
    async (params) => {
        try {
            const resp = await invoiceListing(params);
            return resp?.data;
        } catch (error) { }
    }
);

// invoice detail
export const InvoiceDetailReducer = createAsyncThunk(
    '/InvoiceDetail',
    async (id) => {
        try {
            const resp = await getInvoiceDetails(id);
            return resp?.data;
        } catch (error) { }
    }
);

//update invoice 
export const EditInvoiceReducer = createAsyncThunk(
    '/editInvoice',
    async (payload) => {
        try {
            const resp = await editInvoiceDetails(payload);
            return resp?.data;
        } catch (error) { }
    }
);

// tags listing
export const tagDataListingReducer = createAsyncThunk(
    '/tagDataListing',
    async () => {
        const resp = await allTagsData();
        return resp?.data;
    }
)
// add tag
export const CreateTagReducer = createAsyncThunk(
    '/createTag',
    async () => {
        const resp = await createTag();
        return resp;
    }
)


const InvoiceSlice = createSlice({
    name: 'InvoiceSlice',
    initialState,
    reducers: {},
    extraReducers: {
        //add invoice
        [CreateInvoiceReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [CreateInvoiceReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        },
        [CreateInvoiceReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        //edit invoice
        [EditInvoiceReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [EditInvoiceReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [EditInvoiceReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        //invoice listing
        [InvoiceListingReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [InvoiceListingReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = false;
            state.invoiceListingData = action?.payload?.data ?? initialListingState;
        },
        [InvoiceListingReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        //invoice detail
        [InvoiceDetailReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [InvoiceDetailReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = false;
            state.invoiceDetailData = action?.payload?.data;
        },
        [InvoiceDetailReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // Add tag
        [CreateTagReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [CreateTagReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [CreateTagReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // tag listing
        [tagDataListingReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [tagDataListingReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = false;
            state.tagListingData = action?.payload?.data;
        },
        [tagDataListingReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default InvoiceSlice.reducer;