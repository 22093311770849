import { totalProperty, totalUnit, totalVacant } from 'assets/AssetIndex';
import { Action, DataTable } from 'component/CompIndex';
import {
  InventoryListingData,
  SubUnitListingData,
} from 'pages/myProperties/PropertyIndex';
import PropertyListingData from 'pages/myProperties/property/PropertyListingData';
import { Navigate } from 'react-router';
import { Link } from 'react-router-dom';

export const selectArr = [
  'Commercial',
  'Co-Living',
  'Co-Working',
  'Residential',
  'Others',
];

export const occupancyStatus = ['Occupied', 'Vacant'];

export const unitTypeStatus = [
  'Storage',
  'Apartment',
  'Beach House',
  'Villa',
  'Farm',
  'Duplex',
  'Studio',
  'Shop',
  'Office',
  'Others',
];

export const contractStatus = ['Active', 'In-Active'];
export const unitStatus = ['Vacant', 'Occupied'];

export const leaseStatus = [
  'Draft',
  'Active',
  'Cancelled',
  'Terminated',
  'Expired',
];

export const flatType = [
  'Apartment',
  'Beach House',
  'Duplex',
  'Farm',
  'Office',
  'Studio',
  'Shop',
  'Villa',
  'Storage',
  'Others',
];

export const noticeType = ['Active', 'In-Active', 'Future'];
export const furnishings = ['Furnished', 'Partially Furnished', 'Unfurnished'];
export const rentalType = ['Mixed', 'Managed', 'Lease', 'Owned'];
export const propertyAmenitiesList = [
  'Pantry',
  'Concierge',
  "Children's Pool",
  'Security',
  'Maid Service',
  'Conference Room',
  'Shared Gym',
  'Shared Pool',
  'Covered Parking',
  'Security alarm',
  'Barbecue Area',
  'Lobby in Building',
  'Shared Spa',
  'Private Garden',
  "Children's Play Area",
];
export const idType = ['Emirates Id', 'Passport'];
export const genderList = ['Male', 'Female', 'Others'];
export const paymentFrequency = [
  'Monthly',
  'Quarterly',
  'Half Yearly',
  'Yearly',
];
export const paymentModes = ['Cheque', 'Cash', 'Online'];
export const noticeCategories = ['Event', 'Announcement'];
export const yesOrNoArr = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];
export const userType = ['Individual', 'Company'];
export const targetUser = ['Owner', 'Resident'];
export const RentalTypeArr = ['Short Term', 'Long Term'];
export const subunitRentalTypeArr = ['Mixed', 'Managed', 'Lease', 'Owned'];
export const leaseTypeArr = ['Managed', 'Mixed', 'Leased', 'Owned'];
export const userArr = ['Individual', 'Company'];

export function inventoryTabItem(search) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <Navigate to="/admin/myproperties/property?page=1" />,
    },
    {
      key: '2',
      label: `Units`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: <Navigate to="/admin/myproperties/subunit?page=1" />,
    },
    {
      key: '3',
      label: `Users`,
      children: <Navigate to="/admin/myproperties/user?page=1" />,
    },
    {
      key: '7',
      label: `Company`,
      children: <Navigate to="/admin/myproperties/company?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: <Navigate to="/admin/myproperties/staff?page=1" />,
    },
    {
      key: '5',
      label: `Catalog`,
      children: <InventoryListingData search={search} />,
    },
  ];
  return items;
}

export function propertyTabItem(search, filter) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <PropertyListingData {...{ search, filter }} />,
    },
    {
      key: '2',
      label: `Units`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: <Navigate to="/admin/myproperties/subunit?page=1" />,
    },
    {
      key: '3',
      label: `Users`,
      children: <Navigate to="/admin/myproperties/user?page=1" />,
    },
    {
      key: '7',
      label: `Company`,
      children: <Navigate to="/admin/myproperties/company?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: <Navigate to="/admin/myproperties/staff?page=1" />,
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}

export function staffTabItem(staffArgs) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <Navigate to="/admin/myproperties/property?page=1" />,
    },
    {
      key: '2',
      label: `Units`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: <Navigate to="/admin/myproperties/subunit?page=1" />,
    },
    {
      key: '3',
      label: `Users`,
      children: <Navigate to="/admin/myproperties/user?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: (
        <>
          <DataTable {...staffArgs} />
        </>
      ),
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}

export function unitTabItem(unitArgs) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <Navigate to="/admin/myproperties/property?page=1" />,
    },
    {
      key: '2',
      label: `Units`,
      children: (
        <>
          <DataTable {...unitArgs} />
        </>
      ),
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: <Navigate to="/admin/myproperties/subunit?page=1" />,
    },
    {
      key: '3',
      label: `Users`,
      children: <Navigate to="/admin/myproperties/user?page=1" />,
    },
    {
      key: '7',
      label: `Company`,
      children: <Navigate to="/admin/myproperties/company?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: <Navigate to="/admin/myproperties/staff?page=1" />,
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}
export function userTabItem(userArgs) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <Navigate to="/admin/myproperties/property?page=1" />,
    },
    {
      key: '2',
      label: `Units`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: <Navigate to="/admin/myproperties/subunit?page=1" />,
    },
    {
      key: '3',
      label: `Users`,
      children: (
        <>
          <DataTable {...userArgs} />
        </>
      ),
    },
    {
      key: '7',
      label: `Company`,
      children: <Navigate to="/admin/myproperties/company?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: <Navigate to="/admin/myproperties/staff?page=1" />,
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}

export function subUnitTabItem(search) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <Navigate to="/admin/myproperties/property?page=1" />,
    },
    {
      key: '2',
      label: `Units`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: <SubUnitListingData {...{ search }} />,
    },
    {
      key: '3',
      label: `Users`,
      children: <Navigate to="/admin/myproperties/user?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: <Navigate to="/admin/myproperties/staff?page=1" />,
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}

export const getPropertyData = (
  buildingAnalyticsData,
  propertyListingData,
  handleDelete
) => {
  return {
    propertyTopData: [
      {
        img: totalProperty,
        title: 'Total Properties',
        count: buildingAnalyticsData?.building?.total,
        lastContent: `Last Month: ${buildingAnalyticsData?.building?.previousMonth} Properties`,
      },
      {
        img: totalUnit,
        title: 'Total Units',
        count: buildingAnalyticsData?.flats?.total,
        lastContent: `Last Month: ${buildingAnalyticsData?.flats?.previousMonth} Units`,
      },
      {
        img: totalVacant,
        title: 'Total Vacant',
        count: buildingAnalyticsData?.vacantFlats?.total,
        lastContent: `Last Month: ${buildingAnalyticsData?.vacantFlats?.previousMonth} Units`,
      },
    ],
    data: propertyListingData?.length
      ? propertyListingData?.map((cur, id) => {
        return {
          key: cur?.id,
          propertyName: cur?.name_en,
          locality: cur?.locality?.name_en,
          city: cur?.city?.name_en,
          type: cur?.buildingType,
          totalUnits: cur?.totalFlats,
          totalVacant: cur?.totalVacantFlats,
        };
      })
      : [],
    columns: [
      {
        title: 'Property Name',
        dataIndex: 'propertyName',
        key: 'propertyName',
        render: (text, key) => (
          <Link to={`/admin/myproperties/detail/${key?.key}`}>{text} </Link>
        ),
      },
      {
        title: 'Locality',
        dataIndex: 'locality',
        key: 'locality',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Total Units',
        dataIndex: 'totalUnits',
        key: 'totalUnits',
      },
      {
        title: 'Total Vacant',
        dataIndex: 'totalVacant',
        key: 'totalVacant',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, { key, totalUnits }) => {
          return totalUnits === '0' ? (
            <Action data={key} handleDelete={handleDelete} />
          ) : (
            '-'
          );
        },
      },
    ],
  };
};
