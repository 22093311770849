import { ReminderListingReducer } from 'Redux/slices/LeaseSlice';
import Title from 'antd/es/typography/Title';
import {
  DateFilter,
  MixedHeadContent,
  TabComponent,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { reminderItems } from 'staticData/LeaseData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const ReminderListing = () => {
  const { isLoadAgain } = useSelector((state) => state.Lease);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { search: searchUrl } = useLocation();
  const [dateFilter, setDateFilter] = useState(null);
  const defaultPageNo = getUrlPageNo(searchUrl);
  const items = reminderItems();

  //useEffect
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      search,
      limit: 10,
      buildingId: selectedBuildingName,
      startDate: dateFilter?.from,
      endDate: dateFilter?.to,
    };
    dispatch(ReminderListingReducer(params));
  }, [
    defaultPageNo,
    search,
    selectedBuildingName,
    isLoadAgain,
    dispatch,
    dateFilter,
  ]);
  return (
    <div>
      <div className="relative">
        <Title level={3}>Lease Management</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            dateFilterComp=<DateFilter
              setDateFilter={setDateFilter}
              title="Schedule Date"
            />
            {...{
              setSearch,
              AddNew: 'Bulk Reminder',
              route: '/admin/lease/reminder/add',
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'3'} />
      </div>
    </div>
  );
};

export default ReminderListing;
