import {
  EditInventoryReducer,
  InventoryDetailReducer,
} from 'Redux/slices/property/CatalogSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  EditTopChanger,
  ImageUpload,
  InputField,
  MultiSelect,
  RadioField,
  SelectField,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { activeDrop, catalogTypeArr, unitField } from 'staticData/CatalogData';
import { currencyList } from 'staticData/LeaseData';
import { yesOrNoArr } from 'staticData/PropertyData';
import {
  convertToUrlArray,
  getBuildingIdFromArray,
  getIdFromUrl,
} from 'utils/UtilsIndex';

const DetailInventory = () => {
  const { inventoryDetailData } = useSelector((state) => state.Inventory);
  const { buildingsName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  //for Images
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const [initial] = Form.useForm();
  const currency = Form.useWatch('currency', initial);
  const isForAllBuildings = Form.useWatch('isForAllBuildings', initial);
  const unit = Form.useWatch('unit', initial);
  const [handleEditMode, setHandleEditMode] = useState({
    inventoryDetails: false,
    documentDetails: false,
  });
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };
  const onFinish = (values) => {
    values.images = convertToUrlArray(fileList);
    if (!values.quantity) {
      values.quantity = 0;
    }
    delete values?.availableQuantity;
    const payload = {
      navigateTo: { navigate, url: routeObj?.catalog },
      id,
      values,
    };
    dispatch(EditInventoryReducer(payload));
    if (handleEditMode?.inventoryDetails) {
      setHandleEditMode({ ...handleEditMode, inventoryDetails: false });
    }
    if (handleEditMode?.documentDetails) {
      setHandleEditMode({ ...handleEditMode, documentDetails: false });
    }
  };
  useEffect(() => {
    dispatch(InventoryDetailReducer(id));
  }, [dispatch, id]);
  useEffect(() => {
    const initialObj = {
      name_en: inventoryDetailData?.name_en,
      inventoryType: inventoryDetailData?.inventoryType,
      description: inventoryDetailData?.description,
      rate: inventoryDetailData?.rate,
      status: inventoryDetailData?.status,
      availableQuantity: inventoryDetailData?.availableQuantity,
      quantity: null,
      currency: inventoryDetailData?.currency,
      unit: inventoryDetailData?.unit,
      isForAllBuildings:
        buildingsName?.rows?.length ===
        inventoryDetailData?.inventoryBuildings?.length
          ? true
          : false,
      buildings: getBuildingIdFromArray(
        inventoryDetailData?.inventoryBuildings
      ),
    };
    initial.setFieldsValue(initialObj);
  }, [inventoryDetailData, initial]);
  return (
    <>
      <Title level={3}>Details</Title>
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        preserve={true}
        className="w-full"
        form={initial}
      >
        <Row justify="space-between">
          <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
            <Row justify="space-between">
              <div className="detailPageCard w-[40rem] ">
                <EditTopChanger
                  handleEditChanges={handleEditChanges}
                  state="inventoryDetails"
                  title="Catalog Details"
                  {...{ setHandleEditMode, handleEditMode }}
                />
                <div
                  style={{
                    pointerEvents: !handleEditMode.inventoryDetails
                      ? 'none'
                      : '',
                  }}
                  className="wap"
                >
                  <div className="inputWidth">
                    <RadioField
                      label="Product Type"
                      name="inventoryType"
                      radioArr={catalogTypeArr}
                      required
                      width="45%"
                    />
                    <InputField
                      label="Name"
                      name="name_en"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Available Quantity"
                      name="availableQuantity"
                      placeholder="Enter Quantity"
                      type
                      disabled
                    />
                    <InputField
                      label="Quantity"
                      name="quantity"
                      placeholder="Enter New Quantity"
                      min={0}
                      type
                    />
                  </div>
                  <div className="inputWidth">
                    <SelectField
                      label="Currency"
                      name="currency"
                      placeholder="Select Currency"
                      selectArr={currencyList}
                      selectedName="country"
                      secondName="currency_code"
                      selectedValue="currency_code"
                      // required
                    />
                    <InputField
                      label={`Rate ${
                        currency ? '(in ' + currency?.toUpperCase() + ')' : ''
                      }`}
                      name="rate"
                      placeholder={`Enter Rate /${unit || 'unit'}`}
                      min={0}
                      type
                      required
                    />
                  </div>
                  <div className="inputWidth">
                    <SelectField
                      label="Status"
                      name="status"
                      placeholder="Status"
                      selectArr={activeDrop}
                    />
                    <SelectField
                      label="Unit"
                      name="unit"
                      placeholder="Unit"
                      selectArr={unitField}
                      required
                    />
                  </div>
                  <div className="inputWidth">
                    <RadioField
                      label="For All Buildings?"
                      name="isForAllBuildings"
                      radioArr={yesOrNoArr}
                      selectedName="name"
                      selectedValue="value"
                      width="45%"
                    />
                  </div>
                  {!isForAllBuildings && (
                    <div className="inputWidth">
                      <MultiSelect
                        label="Select Building"
                        placeholder="Building Name"
                        name="buildings"
                        required="true"
                        width="38rem"
                        selectArr={buildingsName?.rows}
                        selectedName="name_en"
                        selectedValue="id"
                      />
                    </div>
                  )}
                  <InputField
                    label="Description"
                    name="description"
                    placeholder="Description"
                    width="38rem"
                  />
                </div>
              </div>
            </Row>
          </Col>
          <Col span={8} xs={{ span: 8 }} md={{ span: 8 }}>
            <Row justify="space-between">
              <div className="detailPageCard w-[40rem] ">
                <EditTopChanger
                  handleEditChanges={handleEditChanges}
                  state="documentDetails"
                  title="Attach Images"
                  {...{ setHandleEditMode, handleEditMode }}
                />
                <ImageUpload
                  {...{
                    previewOpen,
                    setPreviewOpen,
                    previewImage,
                    setPreviewImage,
                    previewTitle,
                    setPreviewTitle,
                    fileList,
                    setFileList,
                    defaultFileList: inventoryDetailData?.images,
                    showUploadButton: handleEditMode.documentDetails,
                    isDetailPage: true,
                  }}
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default DetailInventory;
