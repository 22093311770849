import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
const TextField = ({
  label,
  name,
  placeholder,
  required,
  message,
  pattern,
  width,
  max,
  type,
  defaultValue,
  disabled,
  maxLength = false,
  minRows = 3,
  maxRows = 5,
  showCount,
  bordered = true,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      className="p-0"
      min="0"
      rules={[
        {
          required: required ? true : false,
          message: message ? message : 'This field is required.',
        },
        {
          pattern: pattern,
          message: 'Enter the valid format',
        },
      ]}
    >
      <TextArea
        placeholder={placeholder}
        bordered={bordered}
        style={{
          borderRadius: '6px',
          cursor: 'default',
          color: '#424242',
          padding: '1px 0 1px 4px',
          width: width ? width : '18rem',
          marginTop: '5px',
        }}
        showCount={showCount ? showCount : false}
        maxLength={maxLength}
        disabled={disabled ? disabled : false}
        // defaultValue={defaultValue}
        type={type ? 'number' : 'text'}
        autoSize={{ minRows: minRows, maxRows: maxRows }}
      />
    </Form.Item>
  );
};

export default React.memo(TextField);
