import {
  terminateLeaseDataReducer,
  updateLeaseStatusReducer,
} from 'Redux/slices/LeaseSlice';
import { Button, Form, Space } from 'antd';
import { TextField } from 'component/CompIndex';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { getParamId } from 'utils/UtilsIndex';

const EndLease = ({ isModalOpen, setIsModalOpen, leaseId, isDraft }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [form] = Form.useForm();
  const onFinish = (data) => {
    const id = leaseId || getParamId(pathname);
    data.leaseId = id;
    const payload = {
      data,
      navigateTo: { navigate, url: routeObj?.lease },
    };
    if (isDraft) {
      payload.data.status = 'Cancelled';
      dispatch(updateLeaseStatusReducer(payload));
    } else {
      dispatch(terminateLeaseDataReducer(payload));
    }
    handleCancel();
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  return (
    <div className="center flex-col p-4">
      <p className="text-3xl">{isDraft ? 'Cancel' : 'Terminate'} Lease</p>
      <br />
      <p className="mb-8 mt-[-0.5rem] text-base">
        Are you sure you wish to {isDraft ? 'cancel' : 'terminate'} this lease?
      </p>
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        form={form}
        className="w-full"
      >
        {isDraft ? (
          <></>
        ) : (
          <TextField
            label="Reason Box"
            name="comment"
            required
            maxLength={250}
            minRows={3}
            maxRows={5}
            width="30rem"
          />
        )}
        <Space className="center">
          <Button className="auto" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="customButton" htmlType="submit">
            {isDraft ? 'Cancel Lease' : 'Terminate'}
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default EndLease;
