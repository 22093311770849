import { axiosInstance } from "../AxiosClient";

export const createInvoice = async ({ data, navigateTo }) => {
    const resp = await axiosInstance.post(`/invoices/add`, data, { navigateTo });
    return resp;
};

export const invoiceListing = async (params) => {
    const resp = await axiosInstance.get(`invoices/list`, { params });
    return resp;
};

export const getInvoiceDetails = async (id) => {
    const resp = await axiosInstance.get(`invoices/details/${id}`);
    return resp;
};
export const editInvoiceDetails = async ({ id, data }) => {
    const resp = await axiosInstance.patch(`invoices/${id}`, data);
    return resp;
};

export const allTagsData = async () => {
    const resp = await axiosInstance.get(`/tags/admin`);
    return resp;
};

export const createTag = async (payload) => {
    try {
        const resp = await axiosInstance.post(`/tags/admin`, payload);
        return resp?.data?.data;
    } catch (error) {
        return {}
    }
};

