import { AddInventoryReducer } from 'Redux/slices/property/CatalogSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  DocumentUpload,
  ImageUpload,
  InputField,
  MultiSelect,
  RadioField,
  SelectField,
} from 'component/CompIndex';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { activeDrop, catalogTypeArr, unitField } from 'staticData/CatalogData';
import { currencyList } from 'staticData/LeaseData';
import { yesOrNoArr } from 'staticData/PropertyData';
import { convertToUrlArray, getUrlsFromFiles } from 'utils/UtilsIndex';

const AddInventory = () => {
  const { buildingsName } = useSelector((state) => state.Property);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // for Images
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const [form] = Form.useForm();
  const currency = Form.useWatch('currency', form);
  const inventoryType = Form.useWatch('inventoryType', form);
  const isForAllBuildings = Form.useWatch('isForAllBuildings', form);
  const unit = Form.useWatch('unit', form);
  const onFinish = (values) => {
    values.images = convertToUrlArray(fileList);
    dispatch(
      AddInventoryReducer({
        values,
        navigateTo: { navigate, url: routeObj?.catalog },
      })
    );
  };
  const handleCancelData = () => {
    navigate(routeObj?.catalog);
  };
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      initialValues={{
        remember: true,
      }}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add Catalog"
        saveButtonText="Add Catalog"
      />
      <Row justify="space-between">
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Row justify="space-between">
            <div className="inputWidth">
              <RadioField
                label="Product Type"
                name="inventoryType"
                radioArr={catalogTypeArr}
                required
                width="45%"
              />
              <InputField
                label="Name"
                name="name_en"
                placeholder="Name"
                required
              />
            </div>
            <div className="inputWidth">
              <SelectField
                label="Unit"
                name="unit"
                placeholder="Unit"
                selectArr={unitField}
                required
              />
              <InputField
                label="Quantity"
                name="totalQuantity"
                placeholder="Enter Quantity"
                type
                min={0}
                required={inventoryType === 'Inventory'}
              />
            </div>
            <div className="inputWidth">
              <SelectField
                label="Currency"
                name="currency"
                placeholder="Select Currency"
                selectArr={currencyList}
                selectedName="country"
                secondName="currency_code"
                selectedValue="currency_code"
                required
              />
              <InputField
                label={`Rate ${
                  currency ? '(in ' + currency?.toUpperCase() + ')' : ''
                }`}
                name="rate"
                placeholder={`Enter Rate /${unit || 'unit'}`}
                type
                min={0}
                required
              />
            </div>
            <div className="inputWidth">
              <SelectField
                label="Status"
                name="status"
                placeholder="Status"
                selectArr={activeDrop}
              />
              <RadioField
                label="For All Buildings?"
                name="isForAllBuildings"
                radioArr={yesOrNoArr}
                selectedName="name"
                selectedValue="value"
                width="40%"
              />
            </div>
            {!isForAllBuildings && (
              <div className="inputWidth">
                <MultiSelect
                  label="Select Building"
                  placeholder="Building Name"
                  name="buildings"
                  required="true"
                  width="43rem"
                  selectArr={buildingsName?.rows}
                  selectedName="name_en"
                  selectedValue="id"
                />
              </div>
            )}
            <InputField
              label="Description"
              name="description"
              placeholder="Description"
              width="43rem"
            />
          </Row>
        </Col>
        <Col span={7} xs={{ span: 7 }} md={{ span: 7 }}>
          <Title level={5} className="my-8">
            Attach Images
          </Title>
          <ImageUpload
            {...{
              previewOpen,
              setPreviewOpen,
              previewImage,
              setPreviewImage,
              previewTitle,
              setPreviewTitle,
              fileList,
              setFileList,
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default AddInventory;
