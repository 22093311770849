import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { totalProperty, totalUnit, totalVacant } from 'assets/AssetIndex';
import { getDateFormat } from 'utils/UtilsIndex';

export const getLoginRequestTopCardData = (buildingAnalyticsData) => {
  return {
    propertyTopData: [
      {
        img: totalProperty,
        title: 'Total Properties',
        count: buildingAnalyticsData?.buildings?.total,
        lastContent: `Last Month: ${buildingAnalyticsData?.buildings?.previousMonth} Properties`,
      },
      {
        img: totalUnit,
        title: 'Total Units',
        count: buildingAnalyticsData?.flats?.total,
        lastContent: `Last Month: ${buildingAnalyticsData?.flats?.previousMonth} Units`,
      },
      {
        img: totalVacant,
        title: 'Total Login Requests',
        count: buildingAnalyticsData?.loginRequests?.total,
        lastContent: `Last Month: ${buildingAnalyticsData?.loginRequests?.previousMonth} Requests`,
      },
    ],
  };
};

export const getLoginRequestTableData = (
  loginRequestData,
  handleApprove,
  handleDelete
) => {
  return {
    data:
      loginRequestData?.length > 0
        ? loginRequestData?.map((cur) => {
            return {
              key: cur?.id,
              requestId: cur?.flatRequested?.id,
              userDetails: cur?.name,
              phoneNo: `+${cur?.mobileNumber}`,
              date: getDateFormat(cur?.createdAt),
              property: cur?.flatRequested?.building?.name_en,
              unitNo: cur?.flatRequested?.name_en,
              role: cur?.role,
            };
          })
        : [],
    columns: [
      {
        title: 'User Details',
        dataIndex: 'userDetails',
        key: 'userDetails',
      },
      {
        title: 'Phone No.',
        dataIndex: 'phoneNo',
        key: 'phoneNo',
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'Property',
        dataIndex: 'property',
        key: 'property',
      },
      {
        title: 'Unit No.',
        dataIndex: 'unitNo',
        key: 'unitNo',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Action',
        render: (_, data) => (
          <Space>
            <CheckCircleOutlined
              onClick={() => handleApprove(data)}
              style={{ fontSize: '30px', color: '#2BB256' }}
            />
            <CloseCircleOutlined
              onClick={() => handleDelete(data)}
              style={{ fontSize: '30px', color: '#E20F0F' }}
            />
          </Space>
        ),
      },
    ],
  };
};
