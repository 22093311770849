import { Action, Chip } from 'component/CompIndex';
import { dateFromNow, getDateFormat, isMoreThanOneMonthAgo } from 'utils/UtilsIndex';

const { Link } = require('react-router-dom');

export const getServiceTableData = (serviceListingData) => {
  return {
    data:
      serviceListingData?.length > 0
        ? serviceListingData?.map((cur, id) => {
          return {
            key: cur?.id,
            requestId: cur?.requestId,
            status: cur?.statusDetails?.status,
            createdOn: getDateFormat(cur?.createdAt),
            createdDate: cur?.createdAt,
            adminCategory: cur?.category.name_en,
            userCategory: cur?.subCategory?.name_en ?? 'NA',
            building: cur?.flat?.building?.name_en,
            unitNo: cur?.flat?.name_en,
            requestedFor: cur.isBuilding ? 'Public Area' : 'Unit',
          };
        })
        : [],
    columns: [
      {
        title: 'ID',
        dataIndex: 'requestId',
        key: 'requestId',
        render: (text, key) => (
          <Link to={`/admin/services/detail/${key.key}`}>{text}</Link>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, { status }) => (
          <div className="flex justify-center">
            <Chip color={status} name={status} />
          </div>
        ),
      },
      {
        title: 'Created on',
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: (_, { createdOn, status, createdDate }) => (
          <div className="flex flex-col items-center justify-center">
            <span>{createdOn}</span>
            {status !== 'Completed' && (
              <span
                className={`${isMoreThanOneMonthAgo(createdOn)
                  ? 'text-[orange]'
                  : 'text-[red]'
                  }`}
              >
                {dateFromNow(createdDate)}
              </span>
            )}
          </div>
        ),
      },
      {
        title: 'User Category',
        dataIndex: 'userCategory',
        key: 'category',
      },
      {
        title: 'Admin Category',
        dataIndex: 'adminCategory',
        key: 'category',
      },
      {
        title: 'Building',
        dataIndex: 'building',
        key: 'building',
      },
      {
        title: 'Unit No.',
        dataIndex: 'unitNo',
        key: 'unitNo',
      },
      {
        title: 'Requested For',
        dataIndex: 'requestedFor',
        key: 'requestedFor',
      },
    ],
  };
};
export const columns = [
  {
    title: 'ID',
    dataIndex: 'requestId',
    key: 'requestId',
    render: (text, key) => (
      <Link to={`/admin/services/detail/${key.key}`}>{text}</Link>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, { status }) => (
      <div className="flex justify-center">
        <Chip color={status} name={status} />
      </div>
    ),
  },
  {
    title: 'Created on',
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: (_, { createdOn, status, createdDate }) => (
      <div className="flex flex-col items-center justify-center">
        <span>{createdOn}</span>
        {status !== 'Completed' && (
          <span
            className={`${isMoreThanOneMonthAgo(createdOn) ? 'text-[orange]' : 'text-[red]'
              }`}
          >
            {dateFromNow(createdDate)}
          </span>
        )}
      </div>
    ),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: 'Building',
    dataIndex: 'building',
    key: 'building',
  },
  {
    title: 'Unit No.',
    dataIndex: 'unitNo',
    key: 'unitNo',
  },
  {
    title: 'Requested For',
    dataIndex: 'requestedFor',
    key: 'requestedFor',
  },
];
export const columnsNew = [
  {
    title: 'ID',
    dataIndex: 'requestId',
    key: 'requestId',
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
  },
  {
    title: <div className=" whitespace-nowrap">Unit No.</div>,
    dataIndex: 'unitNo',
    key: 'unitNo',
  },
  {
    title: 'Building',
    dataIndex: 'building',
    key: 'building',
  },
  {
    title: <div className=" whitespace-nowrap">Created on</div>,
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: (_, { createdOn, status, createdDate }) => (
      <div className="flex flex-col items-center justify-center">
        <span>{createdOn}</span>
        {status !== 'Completed' && (
          <span
            className={`${
              isMoreThanOneMonthAgo(createdOn) ? 'text-[orange]' : 'text-[red]'
            }`}
          >
            {dateFromNow(createdDate)}
          </span>
        )}
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, { status }) => (
      <>
        <Chip color={status} name={status} />
      </>
    ),
  },
];
export const getProductColumns = (handleEdit, handleDelete) => {
  return [
    {
      title: <div className=" whitespace-nowrap">Name</div>,
      dataIndex: 'name',
      render: (_, { name }) => <div className="w-20 truncate">{name}</div>,
    },
    {
      title: <div className=" whitespace-nowrap">Billed For</div>,
      dataIndex: 'billedFor',
      render: (_, { billedFor }) => (
        <div className="w-20 truncate">{billedFor}</div>
      ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (_, { quantity }) => <div>{quantity}</div>,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      render: (_, { rate }) => <div>{rate}</div>,
    },
    {
      title: 'Amount',
      render: (_, { rate, quantity, currency }) => (
        <div className="whitespace-nowrap">
          {currency} {quantity * rate}{' '}
        </div>
      ),
    },
    {
      title: 'Action',
      render: (_, data) => (
        <Action data={data} {...{ handleEdit, handleDelete }} />
      ),
    },
  ];
};

export const categoryType = [
  'Maintenance',
  'AC',
  'Electrical',
  'Furniture',
  'Parking',
  'Painting',
  'Complain',
  'Carpentry',
  'Ceiling work',
  'Glass Work',
  'Amenities',
  'Pool',
  'Plumbing',
];

export const areaType = ['Public Space', 'Unit'];
export const serviceObj = [
  {
    key: 'Public Area',
    value: true,
  },
  {
    key: 'Unit',
    value: false,
  },
];
export const timeData = [
  '08:00 AM- 09:00 AM',
  '09:00 AM- 10:00 AM',
  '10:00 AM- 11:00 AM',
  '11:00 AM- 12:00 AM',
  '12:00 PM -01:00 PM',
  '01:00 PM- 02:00 PM',
  '02:00 PM- 03:00 PM',
  '03:00 PM- 04:00 PM',
  '04:00 PM- 05:00 PM',
  '05:00 PM- 06:00 PM',
];

export const statusColors = {
  Open: { bgColor: 'bg-[#E4EEFC]', textColor: 'text-[#229EEF]' },
  Opened: { bgColor: 'bg-[#E4EEFC]', textColor: 'text-[#229EEF]' },
  'Re-open': { bgColor: 'bg-[#E4EEFC]', textColor: 'text-[#229EEF]' },
  Pending: { bgColor: 'bg-[#E4EEFC]', textColor: 'text-[#229EEF]' },
  Draft: { bgColor: 'bg-[#E4EEFC]', textColor: 'text-[#229EEF]' },
  'In-Process': { bgColor: 'bg-[#FFF6DF]', textColor: 'text-[#FEAD3F]' },
  Assigned: { bgColor: 'bg-[#F5EBFF]', textColor: 'text-[#A455DC]' },
  'Re-Assigned': { bgColor: 'bg-[#F5EBFF]', textColor: 'text-[#A455DC]' },
  Cancelled: { bgColor: 'bg-[#FFF0F2]', textColor: 'text-[#E20F0F]' },
  Expired: { bgColor: 'bg-[#FFF0F2]', textColor: 'text-[#E20F0F]' },
  Terminated: { bgColor: 'bg-[#FFF0F2]', textColor: 'text-[#E20F0F]' },
  Rejected: { bgColor: 'bg-[#FFF0F2]', textColor: 'text-[#E20F0F]' },
  Booked: { bgColor: 'bg-[#FFF0F2]', textColor: 'text-[#FE8B02]' },
  Available: { bgColor: 'bg-[#EDFFF2]', textColor: 'text-[#2BB256]' },
  Completed: { bgColor: 'bg-[#EDFFF2]', textColor: 'text-[#2BB256]' },
  Paid: { bgColor: 'bg-[#EDFFF2]', textColor: 'text-[#2BB256]' },
  Sent: { bgColor: 'bg-[#EDFFF2]', textColor: 'text-[#2BB256]' },
  Active: { bgColor: 'bg-[#EDFFF2]', textColor: 'text-[#2BB256]' },
  'On Hold': { bgColor: 'bg-[#F7F7F7]', textColor: 'text-[#474747]' },
  blue: { bgColor: 'bg-[#E4EEFC]', textColor: 'text-[#229EEF]' },
  red: { bgColor: 'bg-[#FFF0F2]', textColor: 'text-[#E20F0F]' },
  yellow: { bgColor: 'bg-[#FFF6DF]', textColor: 'text-[#FEAD3F]' },
  'Payment received': { bgColor: 'bg-[#FFF6DF]', textColor: 'text-[#FEAD3F]' },
  green: { bgColor: 'bg-[#EDFFF2]', textColor: 'text-[#2BB256]' },
  gray: { bgColor: 'bg-[#F7F7F7]', textColor: 'text-[#474747]' },
  Unavailable: { bgColor: 'bg-[#FFFFFF]', textColor: 'text-[#474747]' },
  Urgent: { bgColor: 'bg-[#FFF0F2]', textColor: 'text-[#E20F0F]' },
  Regular: { bgColor: 'bg-[#FFF6DF]', textColor: 'text-[#FEAD3F]' },
};

export const priorityArr = [
  { key: false, name: 'Regular' },
  { key: true, name: 'Urgent' },
];
export const areaArr = [
  { key: true, name: 'Public Area' },
  { key: false, name: 'Unit' },
];

export const timeIntervalArr = [
  {
    name: '12:00 AM - 01:00 AM',
    singleName: '12:00 AM',
    id: 0,
  },
  {
    name: '01:00 AM - 02:00 AM',
    singleName: '01:00 AM',
    id: 1,
  },
  {
    name: '02:00 AM - 03:00 AM',
    singleName: '02:00 AM',
    id: 2,
  },
  {
    name: '03:00 AM - 04:00 AM',
    singleName: '03:00 AM',
    id: 3,
  },
  {
    name: '04:00 AM - 05:00 AM',
    singleName: '04:00 AM',
    id: 4,
  },
  {
    name: '05:00 AM - 06:00 AM',
    singleName: '05:00 AM',
    id: 5,
  },
  {
    name: '06:00 AM - 07:00 AM',
    singleName: '06:00 AM',
    id: 6,
  },
  {
    name: '07:00 AM - 08:00 AM',
    singleName: '07:00 AM',
    id: 7,
  },
  {
    name: '08:00 AM - 09:00 AM',
    singleName: '08:00 AM',
    id: 8,
  },
  {
    name: '09:00 AM - 10:00 AM',
    singleName: '09:00 AM',
    id: 9,
  },
  {
    name: '10:00 AM - 11:00 AM',
    singleName: '10:00 AM',
    id: 10,
  },
  {
    name: '11:00 AM - 12:00 PM',
    singleName: '11:00 AM',
    id: 11,
  },
  {
    name: '12:00 PM - 01:00 PM',
    singleName: '12:00 PM',
    id: 12,
  },
  {
    name: '01:00 PM - 02:00 PM',
    singleName: '01:00 PM',
    id: 13,
  },
  {
    name: '02:00 PM - 03:00 PM',
    singleName: '02:00 PM',
    id: 14,
  },
  {
    name: '03:00 PM - 04:00 PM',
    singleName: '03:00 PM',
    id: 15,
  },
  {
    name: '04:00 PM - 05:00 PM',
    singleName: '04:00 PM',
    id: 16,
  },
  {
    name: '05:00 PM - 06:00 PM',
    singleName: '05:00 PM',
    id: 17,
  },
  {
    name: '06:00 PM - 07:00 PM',
    singleName: '06:00 PM',
    id: 18,
  },
  {
    name: '07:00 PM - 08:00 PM',
    singleName: '07:00 PM',
    id: 19,
  },
  {
    name: '08:00 PM - 09:00 PM',
    singleName: '08:00 PM',
    id: 20,
  },
  {
    name: '09:00 PM - 10:00 PM',
    singleName: '09:00 PM',
    id: 21,
  },
  {
    name: '10:00 PM - 11:00 PM',
    singleName: '10:00 PM',
    id: 22,
  },
  {
    name: '11:00 PM - 12:00 AM',
    singleName: '11:00 PM',
    id: 23,
  },
];

export const billedForArr = [
  'Admin',
  'Owner',
  "Resident"
]
