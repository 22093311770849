import { getUserDataReducer } from 'Redux/slices/property/UserSlice';
import Title from 'antd/es/typography/Title';
import { TabComponent } from 'component/CompIndex';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getIdFromUrl } from 'utils/UtilsIndex';
import { EditUserForm, UnitOwned, UnitTenant } from '../PropertyIndex';

const UserDetails = () => {
  const { userDetailData } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const items = [
    {
      key: '1',
      label: `User Details`,
      children: <EditUserForm {...{ userDetailData, id }} />,
    },
    {
      key: '2',
      label: `Units Owned`,
      children: <UnitOwned {...{ userDetailData }} />,
    },
    {
      key: '3',
      label: `Units Tenanted`,
      children: <UnitTenant {...{ userDetailData }} />,
    },
  ];
  useEffect(() => {
    dispatch(getUserDataReducer(id));
  }, [dispatch, id]);
  return (
    <>
      <Title level={3}>Details</Title>
      <TabComponent {...{ items }} activeTabCount={'1'} />
    </>
  );
};

export default UserDetails;
