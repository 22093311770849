import { getVisitorCategoriesReducer } from 'Redux/slices/VisitorSlice';
import { getUnitNameDataReducer } from 'Redux/slices/property/UnitSlice';
import { Checkbox, Collapse, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { visitorStatus, visitorType } from 'staticData/VisitorData';

const VisitorFilter = ({ allFilters, setAllFilters }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const { buildingsName } = useSelector((state) => state.Property);
  const { unitNameData } = useSelector((state) => state.Unit);
  const { visitorCategories } = useSelector((state) => state.Visitor);
  const dispatch = useDispatch();

  const handleFilterChange = (isChecked, name, value) => {
    let updatedValue = value;

    if (Array.isArray(value)) {
      updatedValue = value.join(',');
    }
    if (isChecked) {
      setAllFilters({ ...allFilters, [name]: updatedValue });
    } else {
      setAllFilters({ ...allFilters, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };

  useEffect(() => {
    if (allFilters?.buildingId) {
      dispatch(getUnitNameDataReducer(allFilters?.buildingId));
    } else {
      handleFilterChange(true, 'flatIds', []);
    }
    dispatch(getVisitorCategoriesReducer());
  }, [allFilters?.buildingId]);

  return (
    <div className="flex w-60 flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel header={<h2 className="font-bold">Category</h2>}>
          {visitorCategories?.map((data, index) => (
            <div key={index} className="flex items-baseline">
              <Checkbox
                id={data.id}
                checked={
                  allFilters?.visitorType === data.category ? true : false
                }
                style={{ margin: '0px' }}
                onChange={(e) =>
                  handleFilterChange(
                    e.target?.checked,
                    'visitorType',
                    data.category
                  )
                }
              >
                <label htmlFor={data.category}>{data.category}</label>
              </Checkbox>
            </div>
          ))}
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Property</h2>}>
          <Select
            placeholder="Select Property"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'buildingId', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {buildingsName?.rows?.map((cur, index, key) => (
              <Select.Option value={cur?.id} key={cur?.id}>
                {cur?.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Unit</h2>}>
          <Select
            placeholder="Select Unit"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'flatIds', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            mode="multiple" // Enable multiple selection
            disabled={!allFilters?.buildingId}
          >
            {unitNameData?.rows?.map((cur, index, key) => (
              <Select.Option value={cur?.id} key={cur?.id}>
                {cur?.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel
          header={<h2 className="font-bold">Visited Date Range</h2>}
        >
          <div className="flex-col ">
            <div>
              <p>Checked In</p>
              <DatePicker
                placeholder="Check In Date"
                onChange={(value) =>
                  handleFilterChange(true, 'checkInDate', value)
                }
              />
            </div>
            <div className="mt-2">
              <p>Checked Out</p>
              <DatePicker
                placeholder="Check Out Date"
                onChange={(value) =>
                  handleFilterChange(true, 'checkOutDate', value)
                }
                disabledDate={(current) =>
                  current && current.isBefore(dayjs(allFilters.checkInDate))
                }
              />
            </div>
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Visitor Status</h2>}>
          {visitorStatus?.map((data, index) => (
            <div key={index} className="flex items-baseline">
              <Checkbox
                id={data}
                checked={allFilters?.visitorStatus === data ? true : false}
                style={{ margin: '0px' }}
                onChange={(e) =>
                  handleFilterChange(e.target?.checked, 'visitorStatus', data)
                }
              >
                <label htmlFor={data}>{data}</label>
              </Checkbox>
            </div>
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default VisitorFilter;
