import { CreateUserReducer } from 'Redux/slices/property/UserSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  DateField,
  DocumentUpload,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import countries from 'layouts/localData/CountryCode';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { genderList, idType } from 'staticData/PropertyData';
import {
  addCountryInMob,
  defaultCountryCode,
  getDateFormat,
} from 'utils/UtilsIndex';

const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  //for document
  const [docList, setDocList] = useState([]);
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);
  const alternateCountryCode = Form.useWatch('alternateCountryCode', form);
  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'countryCode',
      'mobileNumber',
      'alternateCountryCode',
      'alternateMobileNumber'
    );
    values.profilePicture = fileList?.[0]?.url;
    values.documents = docList;
    values.dateOfBirth = getDateFormat(values?.dateOfBirth);
    dispatch(
      CreateUserReducer({
        values,
        navigateTo: { navigate, url: routeObj?.user },
      })
    );
  };
  const handleCancelData = () => {
    navigate('/admin/myproperties/user?page=1');
  };

  useEffect(() => {
    const initialValues = {
      countryCode: defaultCountryCode,
      alternateCountryCode: defaultCountryCode,
      userType: 'Individual',
    };
    form.setFieldsValue(initialValues);
  }, [form]);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      initialValues={{
        remember: true,
      }}
      form={form}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New User"
        saveButtonText="Add User"
      />
      <Row justify="space-between">
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Title level={5}>User Details</Title>
          <Row justify="space-between">
            <div className="inputWidth">
              <InputField
                label="Full Name"
                name="name"
                placeholder="Jack Dem"
                message="This field is required"
                width="50rem"
                required="true"
              />
            </div>
            <div className="inputWidth">
              <InputField
                label="POC Email ID"
                name="email"
                placeholder="poc@email.com"
                required="true"
                pattern={/.*@.*/}
              />
              <InputField
                label="Alternate Email (Optional)"
                name="alternateEmail"
                placeholder="poc@email.com"
                pattern={/.*@.*/}
              />
            </div>
            <div className="inputWidth">
              <MobileNumber
                label1="Phone Number"
                countryCodeName="countryCode"
                mobilePlaceHolder="Enter Your Number"
                mobileNumberName="mobileNumber"
                required="true"
                countryCode={countryCode}
              />
              <MobileNumber
                label1="Alternate Phone Number (Optional)"
                countryCodeName="alternateCountryCode"
                mobilePlaceHolder="Alternate Phone Number (Optional)"
                mobileNumberName="alternateMobileNumber"
                countryCode={alternateCountryCode}
              />
            </div>
            <Title level={5}>User ID Details</Title>
            <div className="inputWidth">
              <SelectField
                label="ID Type"
                name="documentType"
                placeholder="ID Type"
                selectArr={idType}
              />
              <InputField
                label="ID Number"
                name="documentId"
                placeholder="ID Number"
              />
            </div>
            <div className="inputWidth">
              <SelectField
                label="Nationality"
                name="nationality"
                placeholder="Nationality"
                selectArr={countries}
                selectedName="label"
                selectedValue="label"
              />
              <SelectField
                label="Gender"
                name="gender"
                placeholder="Gender"
                selectArr={genderList}
              />
            </div>
            <div className="inputWidth">
              <DateField label="Date of  Birth" name="dateOfBirth" />
            </div>
            <Title level={5}>Bank Details</Title>
            <div className="inputWidth">
              <InputField
                label="Account Holder Name"
                name="accountHolderName"
                placeholder="Jack Dem"
              />
              <InputField
                label="Bank Name"
                name="bankName"
                placeholder="Jack Dem"
              />
            </div>
            <div className="inputWidth">
              <InputField
                label="Account Number/IBAN"
                name="accountNumber"
                placeholder="Jack Dem"
              />
              <InputField
                label="Swift Code"
                name="swiftCode"
                placeholder="Jack Dem"
              />
            </div>
          </Row>
        </Col>
        <Col span={7} xs={{ span: 7 }} md={{ span: 7 }}>
          <Title level={5}>Add Profile Images</Title>
          <ImageUpload
            {...{
              previewOpen,
              setPreviewOpen,
              previewImage,
              setPreviewImage,
              previewTitle,
              setPreviewTitle,
              fileList,
              setFileList,
              length: 1,
              multiple: false,
            }}
          />
          <Title level={5}>Attach Documents</Title>
          <DocumentUpload {...{ docList, setDocList }} />
        </Col>
      </Row>
    </Form>
  );
};

export default AddUser;
