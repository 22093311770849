import { axiosInstance } from '../AxiosClient';

export const createNotice = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.post(`/notices/create`, data, { navigateTo });
  return resp;
};
export const getNoticeListing = async (params) => {
  const resp = await axiosInstance.get(`/notices/admin`, {
    params: params,
  });
  return resp;
};
export const getNoticeData = async (id) => {
  const resp = await axiosInstance.get(`/notices/${id}`);
  return resp;
};

export const editNoticeData = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.patch(`/notices/${data?.noticeId}`, data, { navigateTo });
  return resp;
};
