import { headersForMultiForm } from 'utils/UtilsIndex';
import { axiosInstance, baseURL } from './AxiosClient';

export const uploadService = async (formData) => {
  const resp = await axiosInstance.post(
    `${baseURL}/files/upload-file`,
    formData
  );
  return resp;
};
export const getRequestListing = async (params) => {
  const resp = await axiosInstance.get(`/users/requested?limit=10`, {
    params: params,
  });
  return resp;
};
export const approveLoginRequest = async (values) => {
  const resp = await axiosInstance.post(`/master-users/approve`, values);
  return resp;
};
export const requestDeny = async (id) => {
  const resp = await axiosInstance.delete(`/master-users/deny/${id}`);
  return resp;
};
export const getProfileData = async (id) => {
  const resp = await axiosInstance.get(`/admins/details`);
  return resp;
};
export const sendToken = async (payload) => {
  const resp = await axiosInstance.post(
    `/admin-notifications/add-token`,
    payload
  );
  return resp;
};

export const deleteToken = async (payload) => {
  const resp = await axiosInstance.post(
    `/admin-notifications/remove-token`,
    payload
  );
  return resp;
};

export const getAllNotification = async (params) => {
  const resp = await axiosInstance.get(
    `/admin-notifications/?limit=100&page=1`
    // {
    //   params,
    // }
  );
  return resp;
};

export const readNotification = async (payload) => {
  const data = {
    notificationId: payload,
  };
  const resp = await axiosInstance.patch(`/admin-notifications/`, data);
  return resp;
};

export const checkUnreadNotifications = async (params) => {
  const resp = await axiosInstance.get(`/admin-notifications/check-unread`);
  return resp;
};
export const readAllNotification = async (payload) => {
  const resp = await axiosInstance.patch(`/admin-notifications/read-all`);
  return resp;
};
export const removeFile = async (payload) => {
  const resp = await axiosInstance.post(
    '/files/delete-file',
    payload
  );
  return resp;
};
export const getLoginRequestOverview = async () => {
  const resp = await axiosInstance.get('/dashboard/overview');
  return resp;
};

export const uploadFiles = async (data) => {
  const resp = await axiosInstance.post('/files/upload-file', data, headersForMultiForm());
  return resp;
}
