import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  addProductsReducer,
  deleteProductReducer,
  updateProductReducer,
} from 'Redux/slices/ServiceSlice';
import {
  AddInventoryReducer,
  InventoryDropdownReducer,
  InventoryListingReducer,
} from 'Redux/slices/property/CatalogSlice';
import { Button, Form } from 'antd';
import {
  AddProduct,
  AddServiceProduct,
  DataTable,
  DeleteRequest,
  EditTopChanger,
  InputField,
  ModalComp,
  SelectField,
} from 'component/CompIndex';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unitField } from 'staticData/CatalogData';
import { billedForArr, getProductColumns } from 'staticData/ServiceData';

const AttachedProducts = () => {
  const { isLoadAgain, serviceDetailData } = useSelector(
    (state) => state.Service
  );
  const {
    inventoryDropdownData: productTypeArr,
    isLoadAgain: isInventoryLoadAgain,
  } = useSelector((state) => state.Inventory);
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editProductDetails, setEditProductDetails] = useState(false);
  const [showProductTable, setShowProductTable] = useState(true);
  const [handleEditMode, setHandleEditMode] = useState({
    productDetails: false,
  });
  const newProducts = Form.useWatch('newProducts', form);
  let data = serviceDetailData?.maintenanceProducts?.length
    ? serviceDetailData?.maintenanceProducts?.map((data, index) => ({
        key: data?.id,
        inventoryId: data?.inventoryId,
        maintenanceId: data?.maintenanceId,
        name: data?.inventory?.name_en,
        billedFor: data?.billedFor,
        rate: data?.inventory?.rate,
        quantity: data?.quantity,
        description: data?.inventory?.description,
        currency: data?.inventory?.currency,
      }))
    : [];
  const columns = getProductColumns(handleModalOpen, handleDeleteModalOpen);
  const initialObj = {
    newProducts: [
      {
        inventoryId: null,
        billedFor: null,
        quantity: null,
        availableQuantity: null,
      },
    ],
  };
  function handleModalOpen(data) {
    setIsModalOpen(true);
    data && setEditProductDetails(data);
  }
  function handleDeleteModalOpen(data) {
    setIsDeleteModalOpen(true);
    data && setEditProductDetails(data);
  }
  const closeModal = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    } else {
      setIsDeleteModalOpen(false);
    }
    setEditProductDetails(null);
  };
  const handleSaveProducts = (values) => {
    if (editProductDetails?.name) {
      const { billedFor, quantity } = values;
      dispatch(
        updateProductReducer({
          id: editProductDetails?.key,
          body: { billedFor, quantity },
        })
      );
    } else {
      dispatch(AddInventoryReducer({ values }));
    }
  };
  const handleDeleteProduct = () => {
    const payload = {
      id: editProductDetails?.key,
    };
    dispatch(deleteProductReducer(payload));
    closeModal();
  };
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };
  const handleAddNewProduct = () => {
    setShowProductTable(false);
  };
  const handleCancelAddNewProduct = () => {
    setShowProductTable(true);
  };
  const onFinish = (values) => {
    const payload = {
      id: serviceDetailData?.id,
      data: { inventories: newProducts },
    };
    dispatch(addProductsReducer(payload));
    setHandleEditMode({ ...handleEditMode, productDetails: false });
  };
  const handleAddDoc = (addDocFun) => {
    addDocFun(...initialObj?.newProducts);
    setTimeout(() => {
      if (scrollRef?.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }, 0);
  };
  const getSelectedProduct = (data) => {
    const selectedProducts = productTypeArr?.filter((product) => {
      if (product?.id === data?.inventoryId) {
        return {
          rate: product?.rate,

          inventoryId: product?.id,
          unit: product?.unit,
        };
      }
      return false;
    })?.[0];
    if (selectedProducts) {
      return {
        ...data,
        inventoryId: selectedProducts?.id,
        availableQuantity: selectedProducts?.availableQuantity,
        rate: selectedProducts?.rate,
        unit: selectedProducts?.unit,
      };
    }
    return data;
  };
  useEffect(() => {
    const params = {
      buildingId: serviceDetailData?.flat?.building?.id,
    };
    dispatch(InventoryDropdownReducer(params));
  }, [isInventoryLoadAgain, dispatch]);
  useEffect(() => {
    if (!isLoadAgain) {
      setShowProductTable(true);
    }
  }, [isLoadAgain]);
  useEffect(() => {
    let updatedArr = newProducts?.map((data, index) => {
      let updatedObj = data;
      updatedObj = getSelectedProduct(updatedObj);
      updatedObj.amount = updatedObj?.quantity * updatedObj?.rate || null;
      return updatedObj;
    });
    if (updatedArr?.length) {
      form.setFieldsValue({ newProducts: updatedArr });
    } else {
      form.setFieldsValue({ newProducts: initialObj?.newProducts });
    }
  }, [newProducts, form]);
  return (
    <>
      <Form
        layout="vertical"
        name="dynamic_form_nest_item"
        size="large"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        initialValues={initialObj}
        className="w-full"
      >
        <div className="detailPageCard w-[36rem]">
          {serviceDetailData?.status === 'ASSIGNED' ||
          serviceDetailData?.status === 'RE-ASSIGNED' ||
          serviceDetailData?.status === 'PROCESSING' ? (
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              title="Product Details"
              state="productDetails"
              {...{ setHandleEditMode, handleEditMode }}
              showEditButton={true}
              editButtonText={'Add New Product'}
              onEditFunction={handleAddNewProduct}
              onCancelFunction={handleCancelAddNewProduct}
            />
          ) : (
            <EditTopChanger title="Product Details" />
          )}
          <ModalComp
            {...{
              isModalOpen: isModalOpen || isDeleteModalOpen,
              width: '36rem',
            }}
            component={
              isModalOpen ? (
                editProductDetails ? (
                  <AddProduct
                    {...{
                      closeModal,
                      productDetails: editProductDetails,
                      handleSave: handleSaveProducts,
                      isInventory:
                        editProductDetails?.inventoryType === 'Inventory'
                          ? true
                          : false,
                    }}
                  />
                ) : (
                  <AddServiceProduct
                    {...{
                      isModalOpen,
                      setIsModalOpen,
                      closeModal,
                      // handleSave: handleSaveProducts,
                    }}
                  />
                )
              ) : (
                <DeleteRequest
                  text={`Are you sure you want to delete ${editProductDetails?.name}?`}
                  handleClose={closeModal}
                  handleSave={handleDeleteProduct}
                />
              )
            }
          />
          <div
            className="max-h-[21rem] min-h-[19rem] overflow-auto"
            ref={scrollRef}
          >
            {showProductTable ? (
              <>
                <div className=" -mt-[2rem] max-h-[20rem] min-h-[19rem] overflow-auto">
                  <DataTable
                    {...{
                      columns,
                      data,
                    }}
                  />
                </div>
                <span className="flex items-center gap-2 p-2 font-light">
                  <InfoCircleOutlined />
                  Products can be added once they are in Assigned or In-Process
                  state
                </span>
              </>
            ) : (
              <div
                className="w-full p-2"
                style={{
                  pointerEvents: !handleEditMode?.productDetails ? 'none' : '',
                }}
              >
                <Form.List name="newProducts">
                  {(fields, { add, remove }) => (
                    <>
                      {fields?.map(({ key, name, ...restField }, index) => (
                        <Form.Item key={key}>
                          <div className="inputWidth items-end">
                            <div className="flex flex-col gap-2">
                              <h2
                                className="cursor-pointer text-[#229EEF]"
                                onClick={() => handleModalOpen(null)}
                              >
                                <PlusOutlined /> New Catalog
                              </h2>
                              <SelectField
                                label="Product Type"
                                name={[name, 'inventoryId']}
                                placeholder="Product Type"
                                selectArr={productTypeArr}
                                selectedName="name_en"
                                selectedValue="id"
                                width="10rem"
                                restField
                                required
                              />
                            </div>
                            <SelectField
                              label="Billed For"
                              name={[name, 'billedFor']}
                              placeholder="Billed For"
                              selectArr={billedForArr}
                              width="10rem"
                              required
                            />
                            <div className="flex flex-col gap-2">
                              {fields?.length !== 1 && (
                                <h2
                                  className="cursor-pointer text-[#ef2222]"
                                  onClick={() => remove(name)}
                                >
                                  <DeleteOutlined />
                                  Remove Product
                                </h2>
                              )}
                              <InputField
                                label="Quantity"
                                name={[name, 'quantity']}
                                placeholder={
                                  newProducts?.[index]?.availableQuantity !==
                                  null
                                    ? `${newProducts?.[index]?.availableQuantity} Available`
                                    : 'Quantity'
                                }
                                width="10rem"
                                type
                                min={0}
                                required
                              />
                            </div>
                          </div>
                          <div className="inputWidth -mt-4">
                            <SelectField
                              label="Unit"
                              name={[name, 'unit']}
                              placeholder="Select Unit"
                              selectArr={unitField}
                              width="10rem"
                              disabled
                            />
                            <InputField
                              label="Rate"
                              name={[name, 'rate']}
                              placeholder="Rate"
                              width="10rem"
                              type
                              disabled
                            />
                            <InputField
                              label="Amount"
                              name={[name, 'amount']}
                              placeholder="Amount"
                              width="10rem"
                              type
                              disabled
                            />
                          </div>
                          {/* <div className="inputWidth -mt-6">
                            <InputField
                              label="Description"
                              name={[name, 'description']}
                              placeholder="Description"
                              width="30rem"
                            />
                          </div> */}
                        </Form.Item>
                      ))}
                      <Button
                        onClick={() => handleAddDoc(add)}
                        className="customButton -mt-12 w-44"
                      >
                        Add Another Item
                      </Button>
                    </>
                  )}
                </Form.List>
              </div>
            )}
          </div>
        </div>
      </Form>
    </>
  );
};

export default React.memo(AttachedProducts);
