import { loader } from 'assets/AssetIndex';
import React from 'react';

const AppLoader = () => {
  return (
    <div className="absolute left-1/2 top-1/2 z-[1]">
      <img src={loader} alt="..." className="h-32" fetchpriority="high" />
    </div>
  );
};

export default AppLoader;
