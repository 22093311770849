import { Action, Chip, ListingFieldWithIcon } from "component/CompIndex";
import { Link } from "react-router-dom";
import { getFirstPropertyAndCount, timeStampToDate } from "utils/UtilsIndex";

export const getGuardTableData = (guardListingData, handleDelete) => {
    return {
        data: guardListingData?.length ?
            guardListingData?.map((cur, id) => {
                return {
                    key: cur?.id,
                    name: cur?.name,
                    company: cur?.company,
                    mobileNumber: `+${cur?.mobileNumber}`,
                    buildings: getFirstPropertyAndCount(cur?.guardBuildings),
                    lastLogin: timeStampToDate(cur?.lastLogin).formattedDate,
                    lastLoginTime: timeStampToDate(cur?.lastLogin).formattedTime,
                    status: cur?.isActive ? 'Active' : 'In-Active',
                };
            }) : [],
        columns: [
            {
                title: 'Guard Name',
                dataIndex: 'name',
                key: 'id',
                render: (text, key) => (
                    <Link to={`/admin/guard/detail/${key.key}`}>
                        <ListingFieldWithIcon text={text} />
                    </Link>
                ),
            },
            {
                title: 'Company',
                dataIndex: 'company',
                key: 'company',
            },
            {
                title: 'Phone No.',
                dataIndex: 'mobileNumber',
                key: 'mobileNumber',
            },
            {
                title: 'Building',
                dataIndex: 'buildings',
                key: 'buildings',
            },
            {
                title: 'Last Login',
                dataIndex: 'lastLogin',
                key: 'lastLogin',
                render: (text, key) => (
                    <>
                        <p>{text}</p>
                        <p>{key.lastLoginTime}</p>
                    </>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (_, key, { status }) => (
                    <div
                        className="cursor-pointer"
                        onClick={() => handleDelete(key?.key, 'change')}
                    >
                        <Chip color={_ === 'Active' ? 'green' : 'red'} name={_} />
                    </div>
                ),
            },
            {
                title: 'Action',
                dataIndex: 'action',
                render: (_, { key }) => (
                    <Action data={key} handleDelete={handleDelete} />
                ),
            },
        ]
    }
}

export const companyType = ['Concordia', 'Top Secure', 'Secutirybiz'];

export const areaType = ['Active', 'In-Active'];
