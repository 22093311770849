import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { masterUserDataReducer } from 'Redux/slices/property/UserSlice';
import {
  CreateUnitReducer,
  allAmenitiesDataReducer,
  clearAmenitiesReducer,
} from 'Redux/slices/property/UnitSlice';
import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  CheckBoxField,
  CustomSelectField,
  DocumentUpload,
  ImageUpload,
  InputField,
  MobileNumber,
  ModalComp,
  SelectField,
  ShortOwner,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import {
  RentalTypeArr,
  flatType,
  furnishings,
  leaseTypeArr,
} from 'staticData/PropertyData';
import {
  addCountryInMob,
  convertToUrlArray,
  defaultCountryCode,
  findIndex,
  removeUnwantedPayload,
} from 'utils/UtilsIndex';

const AddUnit = () => {
  const location = useLocation();
  const selectedBuildingId = location?.state?.buildingId;
  const { buildingsName } = useSelector((state) => state.Property);
  const { masterUserData } = useSelector((state) => state.User);
  const { amenities } = useSelector((state) => state.Unit);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshOwnerRed, setRefreshOwnerRed] = useState(false);
  const [handleModal, setHandleModal] = useState({
    unit: false,
    owner: false,
    tenant: false,
  });
  const [form] = Form.useForm();
  const buildingId = Form.useWatch('buildingId', form);
  const ownerId = Form.useWatch('ownerId', form);
  const contactCountryCode = Form.useWatch('contactCountryCode', form);
  const poaCountryCode = Form.useWatch('poaCountryCode', form);
  const accessCards = Form.useWatch('accessCards', form);
  const parkingLots = Form.useWatch('parkingLots', form);
  const govId = Form.useWatch('govId', form);
  //for document
  const [docList, setDocList] = useState([]);
  const initialObj = {
    buildingId: buildingId ? buildingId : selectedBuildingId,
    ownerEmail: findIndex(masterUserData, ownerId, 'email'),
    ownerMobileNumber: findIndex(masterUserData, ownerId, 'mobileNumber'),
    contactCountryCode: contactCountryCode
      ? contactCountryCode
      : defaultCountryCode,
    poaCountryCode: poaCountryCode ? poaCountryCode : defaultCountryCode,
    parkingLots: parkingLots?.length ? parkingLots : [{ name: '' }],
    accessCards: accessCards?.length ? accessCards : [{ name: '' }],
    govId: govId?.length ? govId : [{ name: '' }],
  };
  const removeNameFromAllocatedDetails = (allocateArr) => {
    if (!allocateArr?.[0]?.name) {
      return [];
    }
    return allocateArr?.map((data) => data?.name);
  };
  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'contactCountryCode',
      'contactMobileNumber',
      'poaCountryCode',
      'poaMobileNumber'
    );
    values.images = convertToUrlArray(fileList);
    values.documents = docList;
    values.accessCards = removeNameFromAllocatedDetails(values?.accessCards);
    values.parkingLots = removeNameFromAllocatedDetails(values?.parkingLots);
    values.govId = removeNameFromAllocatedDetails(values?.govId);
    values = removeUnwantedPayload(
      values,
      'ownerMobileNumber',
      'ownerEmail',
      'code',
      'description',
      'govId'
    );
    dispatch(
      CreateUnitReducer({
        values,
        navigateTo: { navigate, url: routeObj?.unit },
      })
    );
  };
  const handleCancelData = () => {
    navigate('/admin/myproperties/unit?page=1');
  };
  const handleOpenModal = (type) => {
    setHandleModal({ ...handleModal, [type]: true });
    setIsModalOpen(true);
  };
  useEffect(() => {
    dispatch(masterUserDataReducer());
  }, [refreshOwnerRed, dispatch]);
  useEffect(() => {
    dispatch(allAmenitiesDataReducer('Flats'));
    return () => {
      dispatch(clearAmenitiesReducer());
    };
  }, [dispatch]);
  useEffect(() => {
    form.setFieldsValue(initialObj);
  }, [ownerId, form]);
  return (
    <>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <ShortOwner
            {...{
              isModalOpen,
              setIsModalOpen,
              handleModal,
              setHandleModal,
              setRefreshOwnerRed,
            }}
          />
        }
      />
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        form={form}
        initialValues={{
          remember: true,
          items: [{}],
        }}
        className="w-full"
      >
        <AddNew
          {...{ handleCancelData }}
          title="Add New Unit"
          saveButtonText="Add Unit"
        />
        <Row justify="space-between">
          <Col span={14} xs={{ span: 14 }} md={{ span: 14 }}>
            <Title level={5}>Unit Details</Title>
            <Row justify="space-between">
              <div className="inputWidth">
                <SelectField
                  label="Property Name"
                  name="buildingId"
                  placeholder="Property Name"
                  required="true"
                  selectArr={buildingsName?.rows}
                  selectedValue="id"
                  selectedName="name_en"
                />
                <InputField
                  label="Unit Number"
                  name="name_en"
                  placeholder="Unit Number"
                  message="This field is required"
                  required="true"
                />
              </div>
              <div className="inputWidth">
                <InputField
                  label="Unit Code (if any)"
                  name="code"
                  placeholder="Unit Code"
                />
                {/* <SelectField
                  label="Unit Type"
                  name="flatType"
                  placeholder="Unit Type"
                  required="true"
                  selectArr={flatType}
                /> */}
                <CustomSelectField
                  label="Unit Type"
                  name="flatType"
                  placeholder="Unit Type"
                  required="true"
                  selectArr={flatType}
                  selectedName={'name'}
                  selectedValue={'name'}
                  addItemFunction={() => {}}
                />
              </div>
              <div className="inputWidth">
                {/* <InputField
                  label="Government ID"
                  name="govId"
                  placeholder="Government Id"
                /> */}
                <SelectField
                  label="Furnishing"
                  name="furnishing"
                  placeholder="Furnishing"
                  selectArr={furnishings}
                />
                <InputField
                  label="Unit Size (in Sq. ft)"
                  name="size"
                  placeholder="Unit Size"
                  type
                  min={0}
                />
              </div>
              <div className="inputWidth">
                <InputField
                  label="Number of Bedrooms  (if applicable) "
                  name="bedroom"
                  placeholder="Number of Bedrooms"
                  type
                  min={0}
                />
                <InputField
                  label="Number of Bathrooms  (if applicable)"
                  name="bathroom"
                  placeholder="Number of Bathrooms"
                  type
                  min={0}
                />
              </div>
              <div className="inputWidth">
                <InputField
                  label="Floor"
                  name="floor"
                  placeholder="Floor (e.g., first, second, etc.)"
                />
                <InputField
                  label="Description"
                  name="description"
                  placeholder="Description..."
                />
              </div>
            </Row>
            <Row>
              <div className="inputWidth mb-2 mt-4 items-start text-base font-semibold">
                <Title level={5}>Owner Details</Title>
                <h2
                  className="mr-[10rem] cursor-pointer text-[#229EEF]"
                  onClick={() => handleOpenModal('owner')}
                >
                  <PlusOutlined /> Add New Owner
                </h2>
              </div>
              <div className="inputWidth">
                <SelectField
                  label="Owner"
                  name="ownerId"
                  placeholder={'Owner Name'}
                  selectArr={masterUserData || []}
                  selectedName="name"
                  selectedValue="id"
                  requiredDyn={true}
                />
                <InputField
                  label="Owner Email"
                  name="ownerEmail"
                  placeholder="Owner Email"
                  disabled
                />
              </div>
              <div className="inputWidth">
                <InputField
                  label="Owner Mobile Number"
                  name="ownerMobileNumber"
                  placeholder="Owner Mobile Number"
                  disabled
                />
              </div>
            </Row>
            {/* removed temporary need to fix it from backend */}
            {/* <Row className="my-4">
              <Title level={5}>ID Details</Title>
              <div className="inputWidth">
                <Form.List name="govId">
                  {(fields, { add, remove }) => {
                    return (
                      <div className="flex flex-col">
                        {fields?.map(({ key, name, ...restFields }, index) => (
                          <div className="inputWidth items-center" key={key}>
                            <div className="w-[18rem]">
                              <div className="flex items-center justify-between">
                                <InputField
                                  width="16rem"
                                  label={`Government Id (${index + 1})`}
                                  name={[name, 'name']}
                                  placeholder="Government Card Number"
                                  required={fields?.length !== 1}
                                  restFields={restFields}
                                />
                                {fields?.length >= 2 && (
                                  <MinusCircleOutlined
                                    className="cursor-pointer text-[#E20F0F]"
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-[18rem]">
                          <Button
                            size="sm"
                            className="-mt-2 w-[16rem]"
                            onClick={() => add({ name: '' })}
                          >
                            Add Government Id
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Form.List>
              </div>
            </Row> */}
            <Row className="my-4">
              <Title level={5}>Allocated Details</Title>
              <div className="inputWidth">
                <Form.List name="accessCards">
                  {(fields, { add, remove }) => {
                    return (
                      <div className="flex flex-col">
                        {fields?.map(({ key, name, ...restFields }, index) => (
                          <div className="inputWidth items-center" key={key}>
                            <div className="w-[18rem]">
                              <div className="flex items-center justify-between">
                                <InputField
                                  width="16rem"
                                  label={`Access Card (${index + 1})`}
                                  name={[name, 'name']}
                                  placeholder="Access Card Number"
                                  required={fields?.length !== 1}
                                  restFields={restFields}
                                />
                                {fields?.length >= 2 && (
                                  <MinusCircleOutlined
                                    className="cursor-pointer text-[#E20F0F]"
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-[18rem]">
                          <Button
                            size="sm"
                            className="-mt-2 w-[16rem]"
                            onClick={() => add({ name: '' })}
                          >
                            Add Access Card
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Form.List>
                <Form.List name="parkingLots">
                  {(fields, { add, remove }) => {
                    return (
                      <div className="flex flex-col">
                        {fields?.map(({ key, name, ...restFields }, index) => (
                          <div className="inputWidth items-center" key={key}>
                            <div className="w-[18rem]">
                              <div className="flex items-center justify-between">
                                <InputField
                                  width="16rem"
                                  label={`Parking Space (${index + 1})`}
                                  name={[name, 'name']}
                                  placeholder="Parking Slot Number"
                                  restFields={restFields}
                                  required={fields?.length !== 1}
                                />
                                {fields?.length >= 2 && (
                                  <MinusCircleOutlined
                                    className="cursor-pointer text-[#E20F0F]"
                                    onClick={() => remove(name)}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="w-[18rem]">
                          <Button
                            size="sm"
                            className="-mt-2 w-[16rem]"
                            onClick={() => add({ name: '' })}
                          >
                            Add Parking Space
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Form.List>
              </div>
            </Row>
            <Row>
              <Title level={5}>Lease Details</Title>
              <div className="inputWidth">
                <SelectField
                  label="Lease type"
                  name="leaseType"
                  placeholder="Lease Type"
                  selectArr={leaseTypeArr}
                />
                <SelectField
                  label="Rental Type"
                  name="rentalType"
                  placeholder="Rental Type"
                  selectArr={RentalTypeArr}
                />
              </div>
            </Row>
            <Row>
              <Title level={5}>Point of Contact Details</Title>
              <div className="inputWidth">
                <InputField
                  label="POC Name"
                  name="contactName"
                  placeholder="POC Name"
                />
                <InputField
                  label="POC Email"
                  name="contactEmail"
                  placeholder="POC Email"
                  pattern={/.*@.*/}
                />
              </div>
              <div className="inputWidth">
                <MobileNumber
                  label1="POC Phone Number"
                  countryCodeName="contactCountryCode"
                  mobilePlaceHolder="Enter POC Phone Number"
                  mobileNumberName="contactMobileNumber"
                  countryCode={contactCountryCode}
                />
              </div>
            </Row>
            <Row>
              <Title level={5}>Power of Attorney details</Title>
              <div className="inputWidth">
                <InputField
                  label="POA Name"
                  name="poaName"
                  placeholder="POA Name"
                />
                <InputField
                  label="POA Email"
                  name="poaEmail"
                  placeholder="POA Email"
                  pattern={/.*@.*/}
                />
              </div>
              <MobileNumber
                label1="POA Phone Number"
                countryCodeName="poaCountryCode"
                mobilePlaceHolder="Enter POC Phone Number"
                mobileNumberName="poaMobileNumber"
                countryCode={poaCountryCode}
              />
            </Row>
          </Col>
          <Col span={9} xs={{ span: 9 }} md={{ span: 9 }}>
            <Title level={5}>Add Images</Title>
            <ImageUpload
              {...{
                previewOpen,
                setPreviewOpen,
                previewImage,
                setPreviewImage,
                previewTitle,
                setPreviewTitle,
                fileList,
                setFileList,
              }}
            />
            <Title level={5}>Attach Documents</Title>
            <DocumentUpload {...{ docList, setDocList }} />
            <div>
              {amenities?.length ? (
                <CheckBoxField
                  title="Add Amenities"
                  name="amenities"
                  selectedName="name"
                  selectedValue="name"
                  options={amenities}
                />
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddUnit;
