import { axiosInstance } from '../AxiosClient';

export const getLeaseListing = async (params) => {
  const resp = await axiosInstance.get(`/leases/admin`, {
    params: params,
  });
  return resp;
};
export const createLease = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.post(`/leases/admin`, data, { navigateTo });
  return resp;
};
export const getLeaseData = async (id) => {
  const resp = await axiosInstance.get(`/leases/admin/${id}`);
  return resp;
};
export const updateLeaseStatus = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.post(`/leases/admin/status`, data, {
    navigateTo,
  });
  return resp;
};
export const updateDraftLease = async ({ data, navigateTo, id }) => {
  const resp = await axiosInstance.patch(`leases/admin/${id}`, data, {
    navigateTo,
  });
  return resp;
};
export const terminateLease = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.post(`/leases/admin/terminate`, data, {
    navigateTo,
  });
  return resp;
};
export const getAnalyticsData = async (params) => {
  const resp = await axiosInstance.get(`/leases/admin/statistics`, { params });
  return resp;
};
export const getLeaseExportData = async (params) => {
  const resp = await axiosInstance.get(`/leases/admin/export`, { params });
  return resp;
};

export const renewalListingData = async (params) => {
  const resp = await axiosInstance.get(
    `/flat-contracts/admin/renewal-requests`,
    { params }
  );
  return resp;
};

export const approveRenewalRequest = async ({ requestId, data }) => {
  const resp = await axiosInstance.patch(
    `/flat-contracts/admin/approve-request/${requestId}`,
    data
  );
  return resp;
};

export const denyRenewalRequest = async (requestId) => {
  const resp = await axiosInstance.delete(
    `/flat-contracts/admin/reject-request/${requestId}`
  );
  return resp;
};

export const reminderListingData = async (params) => {
  const resp = await axiosInstance.get(
    `/lease-reminders`,
    { params }
  );
  return resp;
};

export const createBulkReminder = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.post(`/lease-reminders`, data, {
    navigateTo,
  });
  return resp;
};
export const createSingleReminder = async (data) => {
  const resp = await axiosInstance.post(`/lease-reminders/single`, data);
  return resp;
};
