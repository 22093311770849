import {
  amenitiesListingDataReducer,
  clearAmenitiesReducer,
  editAmenitiesReducer,
  editAmenitiesStatusReducer,
} from 'Redux/slices/property/UnitSlice';
import { Switch } from 'antd';
import {
  Action,
  DataTable,
  ModalComp,
  NewAmenities,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getDateFormat, getUrlPageNo } from 'utils/UtilsIndex';

const AmenitiesListing = ({ search }) => {
  const {
    isLoadAgain,
    amenities: { rows, count },
  } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [searchParams, setSearchParam] = useSearchParams();
  const [pageNo, setPageNo] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [amenitiesDetail, setAmenitiesDetail] = useState(null);

  const handleEditAmenities = (data) => {
    setEditModalOpen(true);
    setAmenitiesDetail(data);
  };
  const handleCancelEditModal = () => {
    setAmenitiesDetail(null);
    setEditModalOpen(false);
  };
  const handleSave = (data) => {
    const payload = {
      id: amenitiesDetail?.key,
      data,
    };
    dispatch(editAmenitiesReducer(payload));
  };
  const handleToggler = (id) => {
    dispatch(editAmenitiesStatusReducer(id));
  };

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      limit: 10,
      search,
    };
    dispatch(amenitiesListingDataReducer(params));
    return () => {
      dispatch(clearAmenitiesReducer());
    };
  }, [search, defaultPageNo, dispatch, isLoadAgain]);
  const columns = [
    {
      title: 'Category Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created For',
      dataIndex: 'raisedFor',
      key: 'raisedFor',
      render: (text, key) => <div> {text} </div>,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Visibility',
      dataIndex: 'isVisible',
      key: 'isVisible',
      render: (text, key) => (
        <Switch
          onChange={() => {
            handleToggler(key?.key);
          }}
          defaultChecked={text}
          className="bg-[gray]"
        />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, key) => (
        <Action data={key} handleEdit={handleEditAmenities} />
      ),
    },
  ];
  const data =
    rows?.length > 0
      ? rows?.map((cur, id) => {
          return {
            key: cur?.id,
            name: cur?.name,
            raisedFor: cur?.raisedFor,
            isVisible: cur?.isVisible,
            image: cur?.image,
            createdAt: getDateFormat(cur?.createdAt),
          };
        })
      : [];
  return (
    <>
      <ModalComp
        {...{ isModalOpen: editModalOpen }}
        component={
          <NewAmenities
            {...{
              handleSave,
              handleClose: handleCancelEditModal,
              amenitiesDetail,
            }}
          />
        }
      />
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </>
  );
};

export default AmenitiesListing;
