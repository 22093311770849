import React from 'react';
import { statusColors } from 'staticData/ServiceData';

const Chip = ({ color, name }) => {
  return (
    <>
      {name && (
        <div
          className={`mx-auto min-w-[8rem] max-w-[8rem] rounded-2xl border px-1 py-1 ${statusColors?.[color]?.bgColor} ${statusColors?.[color]?.textColor}`}
        >
          {name}
        </div>
      )}
    </>
  );
};

export default React.memo(Chip);
