import { Form, Input } from 'antd';
import React from 'react';

const PasswordField = ({
  label,
  name,
  placeholder,
  required,
  message,
  pattern,
  patternMessage = 'Enter the valid format',
  width,
  min,
  max,
  type,
  defaultValue,
  disabled,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      className="p-0"
      min="0"
      rules={[
        {
          required: required ? true : false,
          message: message ? message : 'This field is required.',
        },
        {
          pattern: pattern,
          message: patternMessage,
        },
      ]}
    >
      <Input.Password
        placeholder={placeholder}
        bordered={false}
        style={{
          borderBottom: '1px solid #D4D4D4',
          borderRadius: '0',
          padding: '0 0 0px 0',
          width: width ? width : '18rem',
        }}
        disabled={disabled ? disabled : false}
        // defaultValue={defaultValue}
        type={type ? 'number' : 'text'}
        maxLength={max ? max : 50}
      />
    </Form.Item>
  );
};

export default React.memo(PasswordField);
