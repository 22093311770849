import { editStaffDataReducer } from 'Redux/slices/property/StaffSlice';
import { Col, Form, Row } from 'antd';
import {
  DocumentUpload,
  EditTopChanger,
  ImageUpload,
  InputField,
  PasswordField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import countries from 'layouts/localData/CountryCode';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import {
  appointmentArr,
  departmentArr,
  designationArr,
} from 'staticData/StaffData';
import { decrypt, encrypt } from 'utils/Encryption';
import {
  addCountryInMob,
  defaultCountryCode,
  removeCountryCode,
} from 'utils/UtilsIndex';

const EditStaffForm = ({ staffDetailData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [handleEditMode, setHandleEditMode] = useState({
    staffDetails: false,
    personalDetails: false,
    documentDetails: false,
  });
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState([]);

  //for document
  const [docList, setDocList] = useState([]);
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);
  var initialObj = {
    name: staffDetailData?.name,
    mobileNumber: removeCountryCode(
      staffDetailData?.mobileNumber,
      staffDetailData?.countryCode
    ).number,
    password: decrypt(staffDetailData?.password),
    countryCode: staffDetailData?.countryCode || defaultCountryCode,
    email: staffDetailData?.email,
    nationality: staffDetailData?.nationality,
    gender: staffDetailData?.gender,
    documentType: staffDetailData?.documentType,
    emiratesId: staffDetailData?.emiratesId,
    passportNumber: staffDetailData?.passportNumber,
    department: staffDetailData?.department,
    designation: staffDetailData?.designation,
    appointment: staffDetailData?.appointment,
  };

  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };
  useEffect(() => {
    form.setFieldsValue(initialObj);
    if (staffDetailData?.profilePicture) {
      setDefaultFileList([staffDetailData?.profilePicture]);
    }
  }, [form, staffDetailData]);

  const onFinish = (values) => {
    values = addCountryInMob(values, 'countryCode', 'mobileNumber');
    values.staffId = staffDetailData?.id;
    values.password = encrypt(values?.password);
    values.profilePicture = fileList?.[0]?.url ?? null;
    values.documents = docList;
    const payload = {
      values,
    };

    setHandleEditMode({
      staffDetails: false,
      personalDetails: false,
      documentDetails: false,
    });
    dispatch(editStaffDataReducer(payload));
  };
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      preserve={true}
      className="w-full"
    >
      <Row justify="space-between" gutter={10}>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="staffDetails"
                title="Staff Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div className="p-4">
                <ImageUpload
                  {...{
                    previewOpen,
                    setPreviewOpen,
                    previewImage,
                    setPreviewImage,
                    previewTitle,
                    setPreviewTitle,
                    showUploadButton: handleEditMode?.staffDetails,
                    setDefaultFileList,
                    defaultFileList,
                    isDetailPage: true,
                    isUserImage: !staffDetailData?.profilePicture,
                    fileList,
                    setFileList,
                    length: 1,
                    circleImage: true,
                  }}
                />
              </div>
              <div
                style={{
                  pointerEvents: !handleEditMode.staffDetails ? 'none' : '',
                }}
                className="p-4"
              >
                <InputField
                  label="Full Name"
                  name="name"
                  placeholder="Jack Dem"
                  message="This field is required"
                  required="true"
                  //   defaultValue={staffDetailData?.name}
                  width="23rem"
                />
                <div className="mt-12">
                  <MobileNumber
                    label1="Phone Number"
                    countryCodeName="countryCode"
                    mobilePlaceHolder="Enter Your Number"
                    mobileNumberName="mobileNumber"
                    countryCode={countryCode}
                    required
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="personalDetails"
                title="Personal Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.personalDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="Email"
                    name="email"
                    placeholder="Email"
                    pattern={/.*@.*/}
                    required="true"
                    width="15rem"
                  />
                  <SelectField
                    width="15rem"
                    label="Department"
                    name="department"
                    placeholder="Department"
                    required="true"
                    selectedName="value"
                    selectedValue="key"
                    selectArr={departmentArr}
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Nationality"
                    name="nationality"
                    placeholder="Nationality"
                    width="15rem"
                    selectArr={countries}
                    selectedName="label"
                    selectedValue="label"
                  />
                  <InputField
                    label="ID Number"
                    name="emiratesId"
                    placeholder="123"
                    width="15rem"
                  />
                </div>
                {/* <div className="inputWidth">
                  <SelectField
                    label="Id Type"
                    name="documentType"
                    selectArr={idType}
                    width="15rem"
                  />
                  <InputField
                    label="ID Number"
                    name="emiratesId"
                    placeholder="123"
                    width="15rem"
                  />
                </div> */}
                <div className="inputWidth">
                  <SelectField
                    width="15rem"
                    label="Designation"
                    name="designation"
                    placeholder="Designation"
                    selectedName="value"
                    selectedValue="key"
                    selectArr={designationArr}
                  />
                  <SelectField
                    width="15rem"
                    label="Appointment"
                    name="appointment"
                    placeholder="Appointment"
                    required="true"
                    selectedName="value"
                    selectedValue="key"
                    selectArr={appointmentArr}
                  />
                </div>
                <div className="inputWidth">
                  <PasswordField
                    label="Password"
                    name="password"
                    type="password"
                    width="15rem"
                    pattern={
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/
                    }
                    placeholder="***"
                    required
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" gutter={10}>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem]">
              {/* <StaffAvailability {...{ height: 300 }} /> */}
            </div>
          </Row>
        </Col>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="documentDetails"
                title="Attached Documents"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <DocumentUpload
                {...{
                  docList,
                  setDocList,
                  showUploadButton: handleEditMode?.documentDetails,
                  isDetailPage: true,
                  defaultFileList: staffDetailData?.documents,
                }}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EditStaffForm;
