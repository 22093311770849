import {
  cityNameReducer,
  countryNameReducer,
} from 'Redux/slices/property/PropertySlice';
import { Collapse, Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { companyTypeArr } from 'staticData/UserData';

const CompanyFilter = ({ filter, setFilter }) => {
  const { countryNameData, cityNameData } = useSelector(
    (state) => state.Property
  );
  const dispatch = useDispatch();
  const [searchParams, setSearchParam] = useSearchParams();
  const handleFilterChange = (isChecked, name, value) => {
    if (isChecked) {
      setFilter({ ...filter, [name]: value });
    } else {
      setFilter({ ...filter, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };

  useEffect(() => {
    if (countryNameData.length < 1) dispatch(countryNameReducer());

    if (filter.companyCountry) {
      dispatch(cityNameReducer(filter.companyCountry));
    } else {
      handleFilterChange(true, 'companyCityId', '');
    }
  }, [filter.companyCountry]);

  return (
    <div className="flex w-60 flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel header={<h2 className="font-bold">Company Type</h2>}>
          <Select
            placeholder="Select Company Type"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'companyType', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {companyTypeArr?.map((cur, index, key) => (
              <Select.Option value={cur} key={index}>
                {cur}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Country</h2>}>
          <Select
            placeholder="Select Country"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) =>
              handleFilterChange(true, 'companyCountry', value)
            }
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {countryNameData?.map((cur, index, key) => (
              <Select.Option value={cur} key={index}>
                {cur}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">City</h2>}>
          <Select
            placeholder="Select City"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) =>
              handleFilterChange(true, 'companyCityId', value)
            }
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            disabled={filter.companyCountry ? false : true}
          >
            {cityNameData?.map((cur, index, key) => (
              <Select.Option value={cur.id} key={cur.id}>
                {cur.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default CompanyFilter;
