import {
  SubUnitListingReducer,
  deleteSubUnitDataReducer,
} from 'Redux/slices/property/UnitSlice';
import Title from 'antd/es/typography/Title';
import {
  DeleteRequest,
  MixedHeadContent,
  ModalComp,
  TabComponent,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { subUnitItemReturn } from 'staticData/UnitData';
import { getIdFromUrl, getUrlPageNo } from 'utils/UtilsIndex';
import { SubUnitListingData } from '../PropertyIndex';
import { toast } from 'react-toastify';
import UnitFilter from 'component/filter/UnitFilter';
import {
  exportSubUnitData,
  exportUnitData,
} from 'Redux/services/ExportCsv/Export';

const SubUnitListing = () => {
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const { isLoadAgain } = useSelector((state) => state.Unit);
  const [search, setSearch] = useState('');
  const { search: searchUrl } = useLocation();
  const { pathname } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const flatId = getIdFromUrl(pathname);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subUnitId, setSubUnitId] = useState(null);
  const subUnitFilter = true;

  const [allFilters, setAllFilters] = useState({
    flatType: '',
    contractStatus: '',
    ownerIds: '',
    buildingId: '',
    furnishing: '',
    rentalType: '',
    flatIds: '',
  });
  const items = subUnitItemReturn(search, setIsModalOpen, setSubUnitId);
  const handleClose = () => {
    setSubUnitId(null);
    setIsModalOpen(false);
  };
  const handleDeleteUnitData = () => {
    dispatch(deleteSubUnitDataReducer(subUnitId));
    handleClose();
  };

  const handleFilter = () => {
    toast('filter');
  };

  const handleExport = async () => {
    const params = {
      search,
      buildingId: selectedBuildingName || allFilters?.buildingId,
      status: allFilters?.contractStatus,
      flatType: allFilters?.flatType,
      ownerIds: allFilters?.ownerIds,
      furnishing: allFilters?.furnishing,
      rentalType: allFilters?.rentalType,
      flatIds: allFilters?.flatIds,
    };
    return await exportSubUnitData(params);
  };
  const handleImport = (data) => {
    const payload = {
      data,
      id: selectedBuildingName,
    };
    // dispatch(importCsvDataReducer(payload));
  };

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      search,
      flatType: allFilters?.flatType,
      buildingId: selectedBuildingName || allFilters?.buildingId,
      status: allFilters?.contractStatus,
      ownerIds: allFilters?.ownerIds,
      furnishing: allFilters?.furnishing,
      rentalType: allFilters?.rentalType,
      flatIds: allFilters?.flatIds,
    };
    dispatch(SubUnitListingReducer(params));
  }, [
    search,
    defaultPageNo,
    isLoadAgain,
    dispatch,
    flatId,
    allFilters,
    selectedBuildingName,
  ]);

  return (
    <div>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <DeleteRequest
            text={`Are you sure you want to delete?`}
            handleSave={handleDeleteUnitData}
            handleClose={handleClose}
          />
        }
      />
      {!flatId ? (
        <>
          <div className="relative">
            <Title level={3}>My Properties</Title>
            <div className="absolute right-0 z-10">
              <MixedHeadContent
                AddNew="Add Sub Unit"
                route="/admin/myproperties/subunit/add"
                {...{
                  setSearch,
                  handleFilter,
                  handleExport,
                  exportFileName: 'Sub-unit Data',
                }}
                filterComp=<UnitFilter
                  filter={allFilters}
                  setFilter={setAllFilters}
                  subUnitFilter={subUnitFilter}
                />
              />
            </div>
          </div>
          <div>
            <TabComponent {...{ items }} activeTabCount={'6'} />
          </div>
        </>
      ) : (
        <SubUnitListingData {...{ search, setIsModalOpen, setSubUnitId }} />
      )}
    </div>
  );
};

export default SubUnitListing;
