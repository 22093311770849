import { Checkbox, Collapse } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { leaseTypeList } from 'staticData/LeaseData';
import { leaseStatus } from 'staticData/PropertyData';

const LeaseFilter = ({ filter, setFilter, statusOnly }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const handleFilterChange = (isChecked, name, value) => {
    if (isChecked) {
      setFilter({ ...filter, [name]: value });
    } else {
      setFilter({ ...filter, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };
  return (
    <div className="flex w-[18rem] flex-col justify-start gap-2 p-2">
      <Collapse
        ghost
        expandIconPosition="end"
        defaultActiveKey={statusOnly ? 2 : 0}
      >
        {statusOnly ? (
          <></>
        ) : (
          <Collapse.Panel
            key={1}
            header={<h2 className="font-bold">Purpose</h2>}
          >
            <div className="grid grid-cols-2 items-center justify-between gap-2">
              {leaseTypeList?.map((data, index) => (
                <div key={index} className="flex items-baseline">
                  <Checkbox
                    id={data}
                    checked={filter?.type === data ? true : false}
                    style={{ margin: '0px' }}
                    onChange={(e) =>
                      handleFilterChange(e.target?.checked, 'type', data)
                    }
                  >
                    <label htmlFor={data}>{data}</label>
                  </Checkbox>
                </div>
              ))}
            </div>
          </Collapse.Panel>
        )}
        <Collapse.Panel
          key={2}
          header={<h2 className="font-bold">Lease Status</h2>}
        >
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {leaseStatus?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={filter?.status === data ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(e.target?.checked, 'status', data)
                  }
                >
                  <label htmlFor={data}>{data}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default LeaseFilter;
