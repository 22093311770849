import { DeleteOutlined } from '@ant-design/icons';
import {
  CreateSubUnitReducer,
  getUnitNameDataReducer,
} from 'Redux/slices/property/UnitSlice';
import { Button, Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import AddNew from 'component/AddNew';
import {
  DocumentUpload,
  ImageUpload,
  InputField,
  SelectField,
  TextField,
} from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { routeObj } from 'staticData/Data';
import { furnishings, rentalType } from 'staticData/PropertyData';
import { convertToUrlArray } from 'utils/UtilsIndex';

const AddSubUnit = () => {
  const { buildingsName } = useSelector((state) => state.Property);
  const { unitNameData } = useSelector((state) => state.Unit);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  const selectedUnitId = location?.state?.unitId;
  const selectedBuildingId = location?.state?.buildingId;
  //image
  const [allFiles, setAllFiles] = useState([[]]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  //for document
  const [allDocuments, setAllDocuments] = useState([[]]);
  const [selectedFormIndex, setSelectedFormIndex] = useState(0);
  const buildingId = Form.useWatch('buildingId', form);

  const onFinish = (values) => {
    delete values?.buildingId;
    values.subFlats = values?.subFlats?.map((data, index) => {
      data.documents = allDocuments?.[index];
      data.images = convertToUrlArray(allFiles?.[index]);
      return data;
    });

    let url = '';
    if (selectedUnitId) {
      url = `${routeObj?.subUnitDetails}/${selectedUnitId}`;
    } else {
      url = routeObj?.subUnit;
    }
    dispatch(
      CreateSubUnitReducer({
        values,
        navigateTo: { navigate, url: url },
      })
    );
  };

  const handleCancelData = () => {
    navigate('/admin/myproperties/subunit?page=1');
  };
  const handleAddDoc = (addDocFun) => {
    const updatedDocs = [...allDocuments];
    const updatedFiles = [...allFiles];
    updatedDocs.push([]);
    updatedFiles.push([]);
    setAllDocuments(updatedDocs);
    setAllFiles(updatedFiles);
    addDocFun();
  };
  const handleRemoveDoc = (removeDocFun, name, index) => {
    let updatedDocs = [...allDocuments];
    updatedDocs = updatedDocs.filter((_, idx) => idx !== index);
    setAllDocuments([...updatedDocs]);
    removeDocFun(name);
  };
  const setFileList = (fileList) => {
    let updatedFiles = [...allFiles];
    updatedFiles[selectedFormIndex] = [...fileList];
    setAllFiles(updatedFiles);
  };
  const setDocList = (docList) => {
    let updatedDocs = [...allDocuments];
    updatedDocs[selectedFormIndex] = [...docList];
    setAllDocuments(updatedDocs);
  };
  const handleSelectIndex = (index) => {
    if (selectedFormIndex !== index) {
      setSelectedFormIndex(index);
    }
  };
  useEffect(() => {
    if (buildingId) {
      dispatch(getUnitNameDataReducer(buildingId));
    }
  }, [buildingId, dispatch]);
  const initialObj = {
    buildingId: selectedBuildingId,
    flatId: selectedUnitId,
    subFlats: [
      {
        name_en: null,
        size: null,
        furnishing: null,
      },
    ],
  };
  return (
    <Form
      layout="vertical"
      name="dynamic_form_nest_item"
      size="large"
      onFinish={onFinish}
      form={form}
      autoComplete="off"
      initialValues={initialObj}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New Sub Unit"
        saveButtonText="Add Sub Unit"
      />
      <Row>
        <Col span={15} xs={{ span: 15 }} md={{ span: 15 }}>
          <Title level={5}>Building Details</Title>

          <div className="inputWidth">
            <SelectField
              label="Property Name"
              name="buildingId"
              placeholder="Property Name"
              selectArr={buildingsName?.rows}
              required
              selectedName="name_en"
              selectedValue="id"
            />
            <SelectField
              label="Unit Number"
              name="flatId"
              placeholder="Unit Number"
              disabled={buildingId ? false : true}
              required
              selectArr={unitNameData?.rows}
              selectedName="name_en"
              selectedValue="id"
            />
          </div>
        </Col>
      </Row>
      <Form.List name="subFlats">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Form.Item key={key}>
                <Row justify="space-between my-4">
                  <Col span={15} xs={{ span: 15 }} md={{ span: 15 }}>
                    <Title level={5}>Subunit Details</Title>
                    <Row justify="space-between">
                      <div className="inputWidth">
                        <InputField
                          label="Sub Unit Name"
                          name={[name, 'name_en']}
                          placeholder="Name"
                          restField
                          required
                        />
                        <InputField
                          label="Size"
                          name={[name, 'size']}
                          placeholder="Size"
                          type
                          required
                          min={0}
                          restFields
                        />
                      </div>
                      <div className="inputWidth">
                        <SelectField
                          label="Furnishings"
                          name={[name, 'furnishing']}
                          placeholder="Select furnishings"
                          selectArr={furnishings}
                          required
                          restFields
                        />
                        <SelectField
                          label="Rental Type"
                          name={[name, 'rentalType']}
                          placeholder="Select Rental Type"
                          selectArr={rentalType}
                          restFields
                        />
                      </div>
                      <div className="inputWidth">
                        <TextField
                          className="w-full p-4"
                          label="Description"
                          name={[name, 'description']}
                          placeholder="Explain your service request..."
                          minRows={3}
                          maxRows={3}
                          width="42rem"
                          bordered={true}
                          maxLength={500}
                          showCount={true}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col
                    span={7}
                    xs={{ span: 7 }}
                    md={{ span: 7 }}
                    onMouseEnter={() => handleSelectIndex(index)}
                  >
                    <Title level={5}>Add Images</Title>
                    <ImageUpload
                      {...{
                        previewOpen,
                        setPreviewOpen,
                        previewImage,
                        setPreviewImage,
                        previewTitle,
                        setPreviewTitle,
                        isMultipleForm: true,
                        fileList: allFiles?.[index],
                        setFileList,
                      }}
                    />
                    <Title level={5}>Attach Documents</Title>
                    <DocumentUpload
                      {...{
                        docList: allDocuments?.[index],
                        setDocList,
                        isMultipleForm: true,
                      }}
                    />
                    {allDocuments?.length !== 1 && (
                      <Button
                        size="large"
                        className="customButton"
                        htmlType="submit"
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveDoc(remove, name, index)}
                      >
                        Delete Form
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                size="large"
                className="customButton w-[46rem]"
                onClick={() => handleAddDoc(add)}
              >
                Add Another Sub Unit
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default AddSubUnit;
