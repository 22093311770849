import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor = ({
  setValue,
  value = null,
  name,
  description,
  defaultValue = '',
  setDescription,
  width,
  height,
  placeholder,
  required,
}) => {
  const [editorContent, setEditorContent] = useState('');

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'background',
  ];

  const editorOptions = {
    modules: modules,
    formats: formats,
    theme: 'snow',
    placeholder: placeholder || 'Write something...',
    height: '500px',
  };
  const editorStyle = {
    height: height || '15rem',
    width: '100%',
  };

  const handleChange = (value) => {
    setDescription(value);
  };
  return (
    <div className={`mb-16 flex flex-col justify-start  gap-3 ${width}`}>
      <p>
        {required && name ? (
          <span className="pr-0.5 text-[red]">*</span>
        ) : (
          <></>
        )}
        {name}
      </p>
      <ReactQuill
        theme="snow"
        value={description}
        defaultValue={defaultValue}
        onChange={handleChange}
        modules={editorOptions.modules}
        formats={editorOptions.formats}
        placeholder={editorOptions.placeholder}
        height={editorOptions.height}
        style={editorStyle}
      />
    </div>
  );
};

export default TextEditor;
