import { CreateGuardReducer } from 'Redux/slices/GuardSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import AddNew from 'component/AddNew';
import {
  DocumentUpload,
  ImageUpload,
  InputField,
  MobileNumber,
  MultiSelect,
  PasswordField,
  SelectField,
} from 'component/CompIndex';
import countries from 'layouts/localData/CountryCode';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { genderList, idType } from 'staticData/PropertyData';
import { encrypt } from 'utils/Encryption';
import { addCountryInMob, defaultCountryCode } from 'utils/UtilsIndex';

const AddGuard = () => {
  const { buildingsName } = useSelector((state) => state.Property);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  //for document
  const [docList, setDocList] = useState([]);
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);
  const alternateCountryCode = Form.useWatch('alternateCountryCode', form);
  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'countryCode',
      'mobileNumber',
      'alternateCountryCode',
      'alternateMobileNumber'
    );
    const encryptValue = encrypt(values.password);
    values['password'] = encryptValue;
    values.profilePicture = fileList?.[0]?.url ?? null;
    values.documents = docList;
    const payload = {
      data: { ...values },
      navigateTo: { navigate, url: routeObj?.guard },
    };
    dispatch(CreateGuardReducer(payload));
  };
  const handleCancelData = () => {
    navigate('/admin/guard?page=1');
  };

  useEffect(() => {
    const initialValues = {
      countryCode: defaultCountryCode,
      alternateCountryCode: defaultCountryCode,
    };
    form.setFieldsValue(initialValues);
  }, [form]);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      initialValues={{
        remember: true,
      }}
      form={form}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New Guard"
        saveButtonText="Add Guard"
      />
      <Row justify="space-between my-4">
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Title level={5}>User Details</Title>
          <Row justify="space-between">
            <div className="inputWidth">
              <InputField
                label="Full Name"
                name="name"
                placeholder="Full Name"
                required="true"
              />
              <InputField
                label="Company"
                name="company"
                placeholder="Company"
              />
            </div>
            <div className="inputWidth">
              <InputField
                label="POC Email ID"
                name="pocEmail"
                placeholder="POC Email ID"
                pattern={/.*@.*/}
              />
              <InputField
                label="Alternate Email (Optional)"
                name="alternateEmail"
                placeholder="Alternate Email"
                pattern={/.*@.*/}
              />
            </div>
            <div className="inputWidth">
              <MobileNumber
                label1="Phone Number"
                countryCodeName="countryCode"
                mobilePlaceHolder="Enter Your Number"
                mobileNumberName="mobileNumber"
                countryCode={countryCode}
                required
              />
              <MobileNumber
                label1="Alternate Phone Number (Optional)"
                countryCodeName="alternateCountryCode"
                mobilePlaceHolder="Alternate Phone Number (Optional)"
                mobileNumberName="alternateMobileNumber"
                countryCode={alternateCountryCode}
              />
            </div>
            <div className="inputWidth">
              <InputField
                label="Username"
                name="userName"
                required
                placeholder="Created username"
              />
              <PasswordField
                label="Password"
                name="password"
                type="password"
                pattern={
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/
                }
                placeholder="Create Password"
                required
              />
            </div>
            <Title level={5}>ID Details</Title>
            <div className="inputWidth">
              <SelectField
                label="ID Type"
                name="documentType"
                placeholder="ID Type"
                selectArr={idType}
              />
              <InputField
                label="ID Number"
                name="documentId"
                placeholder="ID Number"
              />
            </div>
            <div className="inputWidth">
              <SelectField
                label="Nationality"
                name="nationality"
                placeholder="Nationality"
                selectArr={countries}
                selectedName="label"
                selectedValue="label"
              />
              <SelectField
                label="Gender"
                name="gender"
                placeholder="Gender"
                selectArr={genderList}
              />
            </div>
            <Title level={5}>Select Building</Title>

            <div className="inputWidth">
              <MultiSelect
                label="Select Building"
                placeholder="Building Name"
                name="buildings"
                required="true"
                width="42rem"
                selectArr={buildingsName?.rows}
                selectedName="name_en"
                selectedValue="id"
              />
            </div>
          </Row>
        </Col>
        <Col span={7} xs={{ span: 7 }} md={{ span: 7 }}>
          <Title level={5}>Add Profile Images</Title>
          <ImageUpload
            {...{
              previewOpen,
              setPreviewOpen,
              previewImage,
              setPreviewImage,
              previewTitle,
              setPreviewTitle,
              fileList,
              setFileList,
              length: 1,
              multiple: false,
            }}
          />
          <Title level={5}>Attach Documents</Title>
          <DocumentUpload {...{ docList, setDocList }} />
        </Col>
      </Row>
    </Form>
  );
};

export default AddGuard;
