import { exportLeaseData } from 'Redux/services/ExportCsv/Export';
import { LeaseListingReducer } from 'Redux/slices/LeaseSlice';
import { getUnitDataReducer } from 'Redux/slices/property/UnitSlice';
import Title from 'antd/es/typography/Title';
import { MixedHeadContent, TabComponent } from 'component/CompIndex';
import LeaseFilter from 'component/filter/LeaseFilter';
import { LeaseListing } from 'pages/PagesIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getIdFromUrl, getUrlPageNo } from 'utils/UtilsIndex';
import { SubUnitListing, UnitDetailsField } from '../PropertyIndex';

const UnitDetail = () => {
  const { unitDetailData } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const { search: searchUrl, pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [showLease, setShowLease] = useState(false);
  const [showSubUnits, setShowSubUnits] = useState(false);
  const [isLeaseExpired, setIsLeaseExpired] = useState(false);
  const [allFilters, setAllFilters] = useState({
    type: '',
    status: '',
  });
  const items = [
    {
      key: '1',
      label: `Unit Details`,
      children: <UnitDetailsField {...{ unitDetailData }} />,
    },
    {
      key: '2',
      label: `Leases`,
      children: <LeaseListing />,
    },
    {
      key: '3',
      label: `Sub-units`,
      children: <SubUnitListing />,
    },
  ];
  const onChangeTab = (key) => {
    if (key === '2') {
      setShowLease(true);
      setShowSubUnits(false);
    } else if (key === '3') {
      setShowLease(false);
      setShowSubUnits(true);
    } else {
      setShowLease(false);
      setShowSubUnits(false);
    }
  };
  const handleFilter = () => {};
  const handleLeaseExport = async () => {
    const params = {
      flatUsage: allFilters?.type,
      status: allFilters?.status,
      flatId: id,
    };
    return await exportLeaseData(params);
  };
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      flatId: id,
      limit: 10,
      status: allFilters?.status,
      flatUsage: allFilters?.type,
      buildingId: unitDetailData?.buildingId,
    };
    if (showLease) dispatch(LeaseListingReducer(params));
  }, [showLease, allFilters, dispatch, unitDetailData, id, defaultPageNo]);

  useEffect(() => {
    dispatch(getUnitDataReducer(id));
  }, [dispatch, id]);

  useEffect(() => {
    const isExpired = unitDetailData?.contractDetails?.length
      ? unitDetailData?.contractDetails?.[0]?.isExpired
      : true;
    setIsLeaseExpired(isExpired);
  }, [unitDetailData]);
  return (
    <>
      <Title level={3}>Details</Title>
      <div className="relative flex justify-between">
        <div className="w-full">
          <TabComponent {...{ items, onChangeTab }} activeTabCount={'1'} />
        </div>
        <div className="absolute right-0 z-10">
          {showLease ? (
            <MixedHeadContent
              filterComp=<LeaseFilter
                filter={allFilters}
                setFilter={setAllFilters}
              />
              AddNew={'Add Lease'}
              route={isLeaseExpired ? '/admin/lease/add' : ''}
              routeState={{
                unitId: id,
                buildingId: unitDetailData?.buildingId,
              }}
              handleExport={handleLeaseExport}
              handleFilter={handleFilter}
              {...{
                exportFileName: `${unitDetailData?.name_en} Lease Details`,
              }}
            />
          ) : showSubUnits ? (
            <MixedHeadContent
              AddNew={'Add Sub Unit'}
              route={'/admin/myproperties/subunit/add'}
              routeState={{
                unitId: id,
                buildingId: unitDetailData?.buildingId,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default UnitDetail;
