import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { acceptedImages } from 'staticData/Data';
import { statusColors } from 'staticData/ServiceData';

export const defaultCountryCode = '971';

export const clearAdminInfo = () => {
  localStorage.clear();
};

export const getAdminInfo = () => {
  const token = localStorage.getItem('token');
  return { token };
};

export const setAdminInfo = (key, token) => {
  localStorage.setItem(key, token);
};


export const getLocalKeyInfo = (key) => {
  const value = localStorage.getItem(key);
  return { value };
};

export const setLocalKeyInfo = (key, token) => {
  localStorage.setItem(key, token);
};

export const getUrlPageNo = (queryParam) => {
  let param = new URLSearchParams(queryParam);
  const queryCount = param.get('page');
  return queryCount;
};
export const getUrlBuilding = (queryParam) => {
  let param = new URLSearchParams(queryParam);
  const queryCount = param.get('buildingId');
  return queryCount;
};
export const getIdFromUrl = (url) => {
  const id = url?.split('detail/')?.[1] ?? '';
  return id;
};
export const makeMobileNumber = (countryCode, number) => {
  const phoneNumber =
    '+' + countryCode ? countryCode : defaultCountryCode + number;
  return phoneNumber;
};

export const getDateFormat = (type) => {
  let date;
  if (type) {
    date = dayjs(type).format('YYYY/MM/DD');
    return date;
  } else {
    date = dayjs().format('YYYY/MM/DD');
    return date;
  }
};

export const getCustomDateFormat = (type, format) => {
  let date = type?.$d || type;
  if (date) {
    date = dayjs(date).format(format);
    return date;
  } else {
    return null;
  }
};

export const getIsoFormatFromDate = (date, desiredHour) => {
  let timeStamp = dayjs(date)
    .set('hour', desiredHour)
    .set('minute', 0)
    .set('second', 0)
    .toISOString();
  return timeStamp;
};

export function forOpenTab(obj, str) {
  const arr = Object?.keys(obj);
  let routeArr = ['/admin'];
  for (let i = 0; i < arr.length; i++) {
    const getKey = str.includes(arr[i]);
    if (getKey === true) {
      routeArr = [obj[arr[i]]];
      break;
    }
  }
  return routeArr;
}

export function timeStampToDate(timestamp, format = 'DD/MM/YYYY') {
  const dayjsObj = dayjs(timestamp);
  const formattedDate = timestamp && dayjsObj.format(format);
  const formattedTime = timestamp && dayjsObj.format('hh:mm A');
  return { formattedDate, formattedTime, dayjsObj };
}

export function convertToDubaiTime(utcTime) {
  // Create a Date object from the provided UTC time string
  const date = new Date(utcTime);

  // Convert the UTC time to Dubai time
  const dubaiTime = new Date(date.getTime() + 4 * 60 * 60 * 1000); // Dubai is UTC+4

  // Extract hours and minutes components
  const hours = dubaiTime.getHours();
  const minutes = dubaiTime.getMinutes();

  // Format hours and minutes
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0'); // Convert to 12-hour format
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const period = hours < 12 ? 'AM' : 'PM';

  // Construct the time string
  const timeString = `${formattedHours}:${formattedMinutes} ${period}`;

  return timeString;
}

export function dateToDayJsObj(date, format = 'DD/MM/YYYY') {
  if (date) {
    return dayjs(date);
  }
  return null;
}

export const addOneYearToDate = (date) => {
  if (date) {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + 1);
    return dayjs(newDate);
  }
  return null;
};

export function removeCountryCode(num, countryCode = '') {
  const number = num?.replace(countryCode, '');
  return { number };
}

export function getParamId(url) {
  const arr = url?.split('/');
  const number = arr[arr.length - 1];
  return number;
}

export const handleHorizontalScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
    if (element.scrollLeft === 0) {
      // setArrowDisable(true);
    } else {
      // setArrowDisable(false);
    }
  }, speed);
};

export const getFirstPropertyAndCount = (properties) => {
  if (properties?.length === 1) {
    return properties[0]?.building?.name_en;
  } else {
    return `${properties[0]?.building?.name_en}${','} ${'+'}${
      properties?.length - 1
    } ${'more'}`;
  }
};
export const findIndex = (arr, keyMatch, returnVal) => {
  const getIndex = arr?.find((cur, id) => cur?.id === keyMatch);
  if (returnVal === 'mobileNumber') {
    return getIndex?.[returnVal] ? '+' + getIndex?.[returnVal] : '';
  }
  return getIndex?.[returnVal];
};

export const calculateDiscount = (
  rentAmount,
  securityDeposit,
  applicableOn,
  discountAmount,
  discountType
) => {
  let totalAmount = applicableOn === 'Deposit' ? securityDeposit : rentAmount;
  if (discountType === 'Percentage') {
    totalAmount =
      applicableOn === 'Deposit'
        ? securityDeposit - (securityDeposit * discountAmount) / 100
        : rentAmount - (rentAmount * discountAmount) / 100;
  } else if (discountType === 'Fixed') {
    totalAmount =
      applicableOn === 'Deposit'
        ? securityDeposit - discountAmount
        : rentAmount - discountAmount;
  }
  return totalAmount;
};

export const isMoreThanOneMonthAgo = (inputDate) => {
  const currentDate = dayjs();
  const inputDayjs = dayjs(inputDate);
  const differenceInMonths = currentDate.diff(inputDayjs, 'months');
  return differenceInMonths >= 1;
};

export const dateDaysCount = (inputDate, inputDate2) => {
  dayjs.extend(relativeTime);
  let currentDate = dayjs();
  const inputDayjs = dayjs(inputDate);
  if (inputDate2 && dayjs(inputDate2) > currentDate) {
    currentDate = dayjs(inputDate2);
  }
  const differenceInDays = Math.abs(currentDate.diff(inputDayjs, 'days'));
  if (dayjs(currentDate).diff(inputDate) <= 1) {
    if (differenceInDays > 90) {
      return {
        daysCount: differenceInDays,
        textColor: statusColors?.gray?.textColor,
        differenceInDays: `${differenceInDays} days left`,
      };
    } else if (differenceInDays > 60) {
      return {
        daysCount: differenceInDays,
        textColor: statusColors?.yellow?.textColor,
        differenceInDays: `${differenceInDays} days left`,
      };
    } else {
      return {
        daysCount: differenceInDays,
        textColor: statusColors?.red?.textColor,
        differenceInDays: `${differenceInDays} days left`,
      };
    }
  } else {
    if (differenceInDays == 0) {
      return {
        daysCount: differenceInDays,
        textColor: statusColors?.red?.textColor,
        differenceInDays: `today`,
      };
    } else if (differenceInDays) {
      return {
        daysCount: differenceInDays,
        textColor: statusColors?.red?.textColor,
        differenceInDays: `${differenceInDays} days ago`,
      };
    }
    return {};
  }
};

export const getUrlsFromFiles = (fileArray) => {
  const array = fileArray?.map((cur, id) => {
    if (cur?.url) {
      return cur.url;
    }
    return cur?.response?.data[0]?.location;
  });
  return array;
};

export const convertFileUrlsToObj = (file) => {
  if (file && file?.length) {
    return file?.map((value) => {
      return { url: value };
    });
  }
  return [];
};
export const disablePastDates = (current) => {
  return current && current < new Date().setHours(0, 0, 0, 0);
};

export const disableFutureDates = (current) => {
  return current && current > new Date();
};

export const disableAllDates = (current) => {
  return true;
};
export function addCountryInMob(obj, ...args) {
  for (let i = 1; i < args?.length; i++) {
    const code = args?.[i - 1];
    const num = args?.[i];
    if (i % 2 !== 0) {
      if (!obj?.[num]) {
        delete obj?.[code];
      } else {
        if (!obj?.[code]) {
          obj[code] = defaultCountryCode;
        }
        if (obj?.[num]) {
          obj[num] = obj?.[code] + obj?.[num];
        }
      }
    }
  }
  return obj;
}

export function convertToAmPm(timeString) {
  const dayjsTime = dayjs(timeString, 'HH:mm');

  if (dayjsTime.isValid()) {
    return dayjsTime.format('h:mm A');
  } else {
    return 'Invalid Time';
  }
}

export function getBuildingIdFromArray(arr) {
  if (arr?.length) {
    return arr?.map((cur) => cur?.buildingId || cur?.building?.id);
  }
  return [];
}

export function dateFromNow(timeStamp) {
  if (timeStamp) {
    dayjs.extend(relativeTime);
    const currDate = dayjs();
    const localDate = dayjs(timeStamp);
    const hoursAgo = currDate.diff(localDate, 'hours');
    if (hoursAgo < 24) {
      if (hoursAgo === 1) {
        return '1 hour ago';
      } else if (hoursAgo > 1) {
        return `${hoursAgo} hours ago`;
      } else {
        return 'just now';
      }
    } else {
      return localDate.fromNow();
    }
  } else {
    return null;
  }
}

export const isValidUUID = (uuid) => {
  const regex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regex.test(uuid);
};

export const overviewTotalCount = (overViewData) => {
  const array = Object?.values(overViewData);
  const totalCount = array?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return totalCount;
};

export const headersForMultiForm = () => {
  const { token } = getAdminInfo();
  let TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    headers: {
      timezone: TimeZone,
      'Content-type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getFileNameFromUrl = (url) => {
  const parts = url?.split?.('/');
  if (!parts) {
    return '';
  }
  const fileName = parts[parts.length - 1];
  return fileName;
};

export const getExtensionFromUrl = (url) => {
  const parts = url?.split?.('/');
  if (!parts) {
    return '';
  }
  const fileExt = parts?.[parts.length - 1]?.split?.('.')?.[1];
  if (acceptedImages?.includes(fileExt)) {
    return `image/${fileExt}`;
  } else {
    return `application/${fileExt}`;
  }
};

export const convertToUrlArray = (images) => {
  if (!images?.length) {
    return [];
  }
  const urlArray = images?.map((data) => {
    if (typeof data === 'string') {
      return data;
    }
    return data?.url;
  });
  return urlArray;
};

export const removeUnwantedPayload = (values, ...allFields) => {
  for (let index in allFields) {
    delete values?.[allFields?.[index]];
  }
  return values;
};

export function removeNullProperties(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => removeNullProperties(item));
  }
  const result = {};
  for (const key in obj) {
    if (obj[key] !== null) {
      result[key] = removeNullProperties(obj[key]);
    }
  }
  return result;
}

export function removeNull(obj) {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === '') {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNull(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export const fetchSelectedUser = (userArr, userId) => {
  return userArr?.filter((data) => data.id === userId)?.[0];
};
