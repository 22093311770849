import { axiosInstance } from '../AxiosClient';

export const getVisitorListing = async (params) => {
  const resp = await axiosInstance.get(`/visitings/all-logs?&limit=10`, {
    params: params,
  });
  return resp;
};
export const getVisitorData = async (id) => {
  const resp = await axiosInstance.get(`/visitings/${id}`);
  return resp;
};

export const getVisitorCategories = async (params) => {
  const resp = await axiosInstance.get('/visitors/dropdown/categories');
  return resp;
};

export const toggleVisitorCategory = async (categoryId) => {
  const resp = await axiosInstance.patch(
    `/visitors/types/visibility/${categoryId}`
  );
  return resp;
};

export const getVisitorExportData = async (params) => {
  const resp = await axiosInstance.get(`/visitings/admin/all-export`, {
    params,
  });
  return resp;
}
