import { Checkbox, Collapse, DatePicker, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  noticeCategories,
  noticeType,
  targetUser,
} from 'staticData/PropertyData';

const NoticeFilter = ({ allFilters, setAllFilters }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const { buildingsName } = useSelector((state) => state.Property);
  const handleFilterChange = (isChecked, name, value) => {
    let updatedValue = value;

    if (Array.isArray(value)) {
      updatedValue = value.join(',');
    }
    if (isChecked) {
      setAllFilters({ ...allFilters, [name]: updatedValue });
    } else {
      setAllFilters({ ...allFilters, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };
  return (
    <div className="flex w-60 flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel header={<h2 className="font-bold">Property</h2>}>
          <Select
            placeholder="Select Property"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'buildingId', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {buildingsName?.rows?.map((cur, index, key) => (
              <Select.Option value={cur?.id} key={cur?.id}>
                {cur?.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Notice Status</h2>}>
          {noticeType?.map((data, index) => (
            <div key={index} className="flex items-baseline">
              <Checkbox
                id={data}
                checked={allFilters?.status === data ? true : false}
                style={{ margin: '0px' }}
                onChange={(e) =>
                  handleFilterChange(e.target?.checked, 'status', data)
                }
              >
                <label htmlFor={data}>{data}</label>
              </Checkbox>
            </div>
          ))}
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Notice Type</h2>}>
          {noticeCategories?.map((data, index) => (
            <div key={index} className="flex items-baseline">
              <Checkbox
                id={data}
                checked={allFilters?.noticeType === data ? true : false}
                style={{ margin: '0px' }}
                onChange={(e) =>
                  handleFilterChange(e.target?.checked, 'noticeType', data)
                }
              >
                <label htmlFor={data}>{data}</label>
              </Checkbox>
            </div>
          ))}
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Notice For</h2>}>
          {targetUser?.map((data, index) => (
            <div key={index} className="flex items-baseline">
              <Checkbox
                id={data}
                checked={allFilters?.noticeFor === data ? true : false}
                style={{ margin: '0px' }}
                onChange={(e) =>
                  handleFilterChange(e.target?.checked, 'noticeFor', data)
                }
              >
                <label htmlFor={data}>{data}</label>
              </Checkbox>
            </div>
          ))}
        </Collapse.Panel>
        <Collapse.Panel
          header={<h2 className="font-bold">Notice Date Range</h2>}
        >
          <div className="flex-col ">
            <div>
              <p>Valid From</p>
              <DatePicker
                placeholder="Valid From"
                onChange={(value) =>
                  handleFilterChange(true, 'validFrom', value)
                }
              />
            </div>
            <div className="mt-2">
              <p>Valid Till</p>
              <DatePicker
                placeholder="Valid Till"
                onChange={(value) =>
                  handleFilterChange(true, 'validTill', value)
                }
                disabledDate={(current) =>
                  current && current.isBefore(dayjs(allFilters.checkInDate))
                }
              />
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default NoticeFilter;
