import {
  DeliveryByCompaniesReducer,
  VisitorAnalyticsDataReducer,
  VisitorAnalyticsLiveReducer,
  VisitorOverViewReducer,
  VisitorStatsHourReducer,
  VisitorStatsReducer,
  VisitorStatsWeekReducer,
} from 'Redux/slices/DashboardSlice';
import { Col, Row, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  guardIcon,
  activeCheckIn,
  checkIn,
  delivery,
  unique,
  total,
  daily,
  duration,
  month,
  repeatVisitor,
} from 'assets/AssetIndex';
import PropertyTopCard from 'component/PropertyTopCard';
import { useEffect, useState } from 'react';
import { default as Chart, default as ReactApexChart } from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import {
  donutCompanyData,
  donutData,
  visitorStatsFunction,
  visitorStatsOverviewFunction,
} from './DynamicData';
import { visitorType } from 'staticData/VisitorData';
import dayjs from 'dayjs';
import { getVisitorCategoriesReducer } from 'Redux/slices/VisitorSlice';

const VisitorStat = ({ startDate, endDate }) => {
  const {
    visitorAnalyticsData,
    mostVisitedVisitors,
    visitorAnalyticsLiveData,
    deliveryAnalyticsData,
    visitorStatsDataHour,
    visitorStatsDataWeek,
    visitorStatsData,
  } = useSelector((state) => state.Dashboard);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const { visitorCategories } = useSelector((state) => state.Visitor);

  const dispatch = useDispatch();
  const [visitorTypeFilter, setVisitorTypeFilter] = useState('');
  const currentDate = dayjs().locale('en'); // Set locale to English
  const isoCurrentDate = currentDate.format('DD MMM');
  const dayName = currentDate.format('dddd');

  const propertyTopData = [
    {
      img: total,
      title: 'Total Visitors',
      count: visitorAnalyticsData?.totalVisitors,
      lastContent: `Last Month: ${visitorAnalyticsData?.totalVisitors} Visitors`,
    },
    {
      img: delivery,
      title: 'Total Deliveries',
      count: visitorAnalyticsData?.totalDeliveries,
      lastContent: `Last Month: ${visitorAnalyticsData?.totalCheckins} Check-Ins`,
    },
    {
      img: unique,
      title: 'Total unique visitors',
      count: visitorAnalyticsData?.totalUniqueVisitors,
      lastContent: `Last Month: ${visitorAnalyticsData?.totalUniqueVisitors} Unique`,
    },
    // {
    //   img: signOut,
    //   title: 'Auto sign out',
    //   count: 0,
    //   lastContent: `Last Month: ${visitorAnalyticsData?.totalCheckins?.previousMonth} Check-Ins`,
    // },
    {
      img: daily,
      title: 'Average daily visitors',
      count: visitorAnalyticsData?.averageDailyVisitors,
      lastContent: `Last Month: ${visitorAnalyticsData?.averageDailyVisitors} Check-Ins`,
    },
    {
      img: duration,
      title: 'Average visit duration (mins)',
      count: visitorAnalyticsData?.averageVisitDuration,
      lastContent: `Last Month: ${visitorAnalyticsData?.averageVisitDuration} Check-Ins`,
    },
    {
      img: month,
      title: 'Average visitors Per unit/month',
      count: visitorAnalyticsData?.averageVisitorsPerFlatPerMonth,
      lastContent: `Last Month: ${visitorAnalyticsData?.averageVisitorsPerFlatPerMonth} Check-Ins`,
    },
    {
      img: repeatVisitor,
      title: 'Repeat Visitor Rate (%)',
      count: visitorAnalyticsData?.totalRepeatVisitorRate,
      lastContent: `Last Month: ${visitorAnalyticsData?.totalRepeatVisitors} Check-Ins`,
    },
  ];

  const visitorLiveData = [
    {
      img: checkIn,
      title: 'Check Ins',
      count: visitorAnalyticsLiveData?.totalCheckins,
    },
    {
      img: activeCheckIn,
      title: 'Active',
      count: visitorAnalyticsLiveData?.totalActive,
    },
    {
      img: delivery,
      title: 'Deliveries',
      count: visitorAnalyticsLiveData?.totalDeliveries,
    },
    {
      img: guardIcon,
      title: 'Active Guard Logins',
      count: visitorAnalyticsLiveData?.totalActiveGuards,
    },
  ];

  useEffect(() => {
    const overviewParams = {
      startDate,
      endDate,
      buildingId: selectedBuildingName,
    };
    const currentDate = new Date();
    const overviewLiveParams = {
      startDate: currentDate,
      endDate: currentDate,
      buildingId: selectedBuildingName,
    };
    dispatch(VisitorOverViewReducer(overviewParams));
    dispatch(
      VisitorStatsHourReducer({
        ...overviewParams,
        categoryName: visitorTypeFilter,
      })
    );
    dispatch(
      VisitorStatsWeekReducer({
        ...overviewParams,
        categoryName: visitorTypeFilter,
      })
    );
    dispatch(VisitorAnalyticsLiveReducer(overviewLiveParams));
    dispatch(DeliveryByCompaniesReducer(overviewParams));
    dispatch(VisitorAnalyticsDataReducer(overviewParams));
    dispatch(
      VisitorStatsReducer({
        ...overviewParams,
        categoryName: visitorTypeFilter,
      })
    );
    // dispatch(getVisitorCategoriesReducer());
  }, [selectedBuildingName, startDate, endDate, dispatch, visitorTypeFilter]);

  return (
    <>
      <div className="mb-4 flex gap-[0.5rem] align-middle text-xl font-bold">
        <h1>Real Time Statistics</h1>
        <h5>
          ({isoCurrentDate}, {dayName})
        </h5>
      </div>
      <PropertyTopCard propertyTopData={visitorLiveData} />
      <h1 className="mb-4 text-xl font-bold">Historical Statistics</h1>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <PropertyTopCard propertyTopData={propertyTopData} vertical="true" />
        </Col>
        <Col span={18}>
          <Row gutter={[16, 16]} className="mt-6">
            <Col span={12}>
              <div className="shadowOnly flex h-[22rem] flex-col items-center justify-around">
                <Title level={5}>Most Visited</Title>
                <Chart
                  options={donutData(mostVisitedVisitors)?.options}
                  series={donutData(mostVisitedVisitors)?.series}
                  type="donut"
                  width="130%"
                  color="#FE8B02"
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="shadowOnly flex h-[22rem] flex-col items-center justify-around">
                <Title level={5}>Delivery Data</Title>
                <Chart
                  options={donutCompanyData(deliveryAnalyticsData)?.options}
                  series={donutCompanyData(deliveryAnalyticsData)?.series}
                  type="pie"
                  width="120%"
                  color="#FE8B02"
                />
              </div>
            </Col>
          </Row>
          <div className="mt-3 w-[100%] rounded-xl bg-grey p-3">
            <div className="flex justify-between">
              <h1 className=" text-xl font-bold">Visiting Overview</h1>
              <div className="">
                <Select
                  placeholder="Select Visitor Type"
                  defaultOpen={false}
                  bordered={true}
                  className="changeSelect w-[10rem] rounded-r-md text-center"
                  allowClear
                  onChange={(value) => setVisitorTypeFilter(value)}
                  showSearch
                  filterOption={(input, option) => {
                    return option?.children
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}
                >
                  {visitorCategories?.map((cur, index, key) => (
                    <Select.Option value={cur.category} key={cur}>
                      {cur.category}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <Row gutter={[16, 16]} className="mt-6">
              <Col span={24}>
                <div className="shadowOnly">
                  <Title level={5}>Daily Visitings Overview</Title>
                  <ReactApexChart
                    options={visitorStatsFunction(visitorStatsData)?.options}
                    series={visitorStatsFunction(visitorStatsData)?.series}
                    type="bar"
                    height={300}
                    width="100%"
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]} className="mt-6">
              <Col span={24}>
                <div className="shadowOnly">
                  <Title level={5}>Weekly Visitings Overview</Title>
                  <ReactApexChart
                    options={
                      visitorStatsOverviewFunction(visitorStatsDataWeek)
                        ?.options
                    }
                    series={
                      visitorStatsOverviewFunction(visitorStatsDataWeek)?.series
                    }
                    type="bar"
                    height={300}
                    width="100%"
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]} className="mt-6">
              <Col span={24}>
                <div className="shadowOnly" style={{ width: '100%' }}>
                  <Title level={5}>Hourly Visitings Overview</Title>
                  <ReactApexChart
                    options={
                      visitorStatsOverviewFunction(visitorStatsDataHour)
                        ?.options
                    }
                    series={
                      visitorStatsOverviewFunction(visitorStatsDataHour)?.series
                    }
                    type="bar"
                    height={300}
                    width="100%"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default VisitorStat;
