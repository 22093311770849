import { UserOutlined } from '@ant-design/icons';
import { getVisitorDataReducer } from 'Redux/slices/VisitorSlice';
import { Avatar, Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { EditTopChanger, ImageUpload, InputField } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getIdFromUrl, timeStampToDate } from 'utils/UtilsIndex';

const Details = () => {
  const { visitorDetailData } = useSelector((state) => state.Visitor);
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [initial] = Form.useForm();
  const [initialObj, setInitialObj] = useState({}); // State for initial object

  useEffect(() => {
    dispatch(getVisitorDataReducer(id));
  }, [dispatch, id]);

  useEffect(() => {
    const initialObj = {
      name: visitorDetailData?.name,
      mobileNumber:
        visitorDetailData?.mobileNumber &&
        '+' + visitorDetailData?.mobileNumber,
      buildingName_en: visitorDetailData?.buildingName_en,
      unitName: visitorDetailData?.flatName_en,
      visitorCategory: visitorDetailData?.category_en,
      companyName:
        visitorDetailData?.company_en &&
        visitorDetailData?.company_type === 'Others'
          ? `Others (${visitorDetailData?.company_en})`
          : visitorDetailData?.company_en,
      visitorsCount: visitorDetailData?.visitorsCount,
      floor: visitorDetailData?.floor,
      inDate:
        visitorDetailData?.inTime &&
        timeStampToDate(visitorDetailData?.inTime).formattedDate,
      inTime:
        visitorDetailData?.inTime &&
        timeStampToDate(visitorDetailData?.inTime).formattedTime,
      outDate:
        visitorDetailData?.outTime &&
        timeStampToDate(visitorDetailData?.outTime).formattedDate,
      outTime:
        visitorDetailData?.outTime &&
        timeStampToDate(visitorDetailData?.outTime).formattedTime,
      // Personal Details
      nameOnDocument:
        visitorDetailData?.additionalDetails?.nameOnDocument ?? 'NA',
      numberOnDocument:
        visitorDetailData?.additionalDetails?.numberOnDocument &&
        '+' + visitorDetailData?.additionalDetails?.numberOnDocument,
      email: visitorDetailData?.additionalDetails?.email ?? 'NA',
      dateOfBirth: visitorDetailData?.additionalDetails?.dateOfBirth ?? 'NA',
      age: visitorDetailData?.additionalDetails?.age ?? 'NA',
      nationality: visitorDetailData?.documentCountry ?? 'NA',
      gender: visitorDetailData?.additionalDetails?.gender
        ? visitorDetailData?.additionalDetails?.gender === 'F'
          ? 'Female'
          : 'Male'
        : 'NA',
      occupation: visitorDetailData?.additionalDetails?.occupation ?? 'NA',
      vehicleNumber:
        visitorDetailData?.additionalDetails?.vehicleNumber ?? 'NA',
      licenseIssueAuthority:
        visitorDetailData?.additionalDetails?.licenseIssueAuthority ?? 'NA',
      address: visitorDetailData?.additionalDetails?.address ?? 'NA',
      description: visitorDetailData?.description ?? 'NA',
      // ID Details
      documentType:
        visitorDetailData?.documentType === 'P'
          ? 'Passport'
          : visitorDetailData?.documentType === 'IL'
          ? 'Emirates ID'
          : visitorDetailData?.documentType === 'DL'
          ? 'Driving License'
          : visitorDetailData?.documentType === 'AADHAR'
          ? 'Aadhar Card'
          : visitorDetailData?.documentType === 'PAN'
          ? 'PAN Card'
          : visitorDetailData?.documentType === 'VOTERID'
          ? 'Voter Id Card'
          : visitorDetailData?.documentType ?? 'NA',
      documentNumber: visitorDetailData?.documentId ?? 'NA',
      documentId: visitorDetailData?.additionalDetails?.cardNumber ?? 'NA',
      documentIssueState: visitorDetailData?.documentIssueState ?? 'NA',
      documentDate: `${visitorDetailData?.documentIssueDate ?? ''} ${
        visitorDetailData?.documentExpiry ?? ''
      }`,
      passportNumber: visitorDetailData?.passportNumber ?? 'NA',
      docTypeDetail:
        visitorDetailData?.additionalDetails?.docTypeDetail ?? 'NA',
      // Agent Details
      agentName: visitorDetailData?.brokerDetails?.agentName ?? 'NA',
      agentId: visitorDetailData?.brokerDetails?.agentId ?? 'NA',
      agentNumber: visitorDetailData?.brokerDetails?.agentMobileNumber ?? 'NA',
      agentCompany: visitorDetailData?.brokerDetails?.agentCompany ?? 'NA',
      salesAdvisor: visitorDetailData?.salesAdvisor ?? 'NA',
      approvedBy: visitorDetailData?.approvedBy,
    };
    initial.setFieldsValue(initialObj);
    setInitialObj(initialObj); // Set the state with initialObj
    var defaultImg = [];
    if (visitorDetailData?.documentImage) {
      defaultImg.push({
        url: visitorDetailData?.documentImage,
        showRemove: false,
      });
    }
    if (visitorDetailData?.profilePicture) {
      defaultImg.push({
        url: visitorDetailData?.profilePicture,
        showRemove: false,
      });
    }
    setFileList(defaultImg);
  }, [initial, visitorDetailData]);
  return (
    <Form layout="vertical" size="large" className="w-full" form={initial}>
      <Title level={3}>Details</Title>
      <Row justify="space-between">
        <Col span={13} xs={{ span: 14 }} md={{ span: 12 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger title="User Details" />
              <div className="p-4">
                <div className="center">
                  <div className="flex gap-6">
                    {visitorDetailData?.documentImage ||
                    visitorDetailData?.profilePicture ? (
                      <ImageUpload
                        {...{
                          length: 0,
                          circleImage: true,
                          showRemoveIcon: false,
                          previewOpen,
                          setPreviewOpen,
                          previewImage,
                          setPreviewImage,
                          previewTitle,
                          setPreviewTitle,
                          fileList,
                          setFileList,
                        }}
                      />
                    ) : (
                      <Avatar
                        className="flex h-[8rem] w-[8rem] items-center justify-center"
                        icon={<UserOutlined className="text-[4rem]" />}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  <InputField label="Name" name="name" width="23rem" />
                  <div className="mt-12">
                    <InputField
                      label="Phone Number"
                      name="mobileNumber"
                      width="23rem"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Row>
          {(initialObj.age !== 'NA' ||
            initialObj.nationality !== 'NA' ||
            initialObj.gender !== 'NA' ||
            initialObj.dateOfBirth !== 'NA') && (
            <Row justify="space-between" className="p-2">
              <div className="detailPageCard w-[36rem] ">
                <EditTopChanger title="Demographics" />
                <div className="wap">
                  <div className="inputWidth">
                    <InputField label="Age" name="age" width="13rem" />
                    <InputField
                      label="Nationality"
                      name="nationality"
                      width="13rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField label="Gender" name="gender" width="13rem" />
                    <InputField
                      label="Date of Birth"
                      name="dateOfBirth"
                      width="13rem"
                    />
                  </div>
                </div>
              </div>
            </Row>
          )}
          {(initialObj.email !== 'NA' || initialObj.occupation !== 'NA') && (
            <Row justify="space-between" className="p-2">
              <div className="detailPageCard w-[36rem] ">
                <EditTopChanger title="Personal Details" />
                <div className="wap">
                  <div className="inputWidth">
                    <InputField label="Email" name="email" width="13rem" />

                    <InputField
                      label="Occupation"
                      name="occupation"
                      width="13rem"
                    />
                  </div>
                </div>
              </div>
            </Row>
          )}
          {(initialObj.vehicleNumber !== 'NA' ||
            initialObj.address !== 'NA' ||
            initialObj.description !== 'NA') && (
            <Row justify="space-between" className="p-2">
              <div className="detailPageCard w-[36rem] ">
                <EditTopChanger title="Other Personal Details" />
                <div className="wap">
                  <div className="inputWidth">
                    <InputField
                      label="Vehicle Number"
                      name="vehicleNumber"
                      width="13rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField label="Address" name="address" width="26rem" />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Description"
                      name="description"
                      width="26rem"
                    />
                  </div>
                </div>
              </div>
            </Row>
          )}
        </Col>

        <Col
          span={13}
          xs={{ span: 14 }}
          md={{ span: 12 }}
          className="flex flex-col justify-between"
        >
          {/* Property Details.... */}

          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger title="Property Details" />
              <div className="wap">
                <div className="inputWidth">
                  <InputField
                    label="Building Name"
                    name="buildingName_en"
                    width="15rem"
                  />
                  <InputField label="Flat Name" name="unitName" width="15rem" />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Visitor Category"
                    name="visitorCategory"
                    width="15rem"
                  />
                  <InputField
                    label="Company Name"
                    name="companyName"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Total Visitor"
                    name="visitorsCount"
                    width="15rem"
                  />
                  <InputField
                    label="Approved By"
                    name="approvedBy"
                    width="15rem"
                  />
                </div>

                <div className="inputWidth">
                  <InputField
                    label="Check In Date"
                    name="inDate"
                    width="15rem"
                  />
                  <InputField
                    label="Check In Time"
                    name="inTime"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Check Out Date "
                    name="outDate"
                    width="15rem"
                  />
                  <InputField
                    label="Check Out Time "
                    name="outTime"
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
          {/* Id details  */}

          {(initialObj.nameOnDocument !== 'NA' ||
            initialObj.documentType !== 'NA' ||
            initialObj.documentNumber !== 'NA' ||
            initialObj.documentId !== 'NA' ||
            initialObj.passportNumber !== 'NA' ||
            initialObj.documentIssueState !== 'NA' ||
            initialObj.docTypeDetail !== 'NA' ||
            initialObj.licenseIssueAuthority !== 'NA') && (
            <Row justify="space-between" className="p-2">
              <div className="detailPageCard w-[36rem] ">
                <EditTopChanger title="ID Details" />
                <div className="wap">
                  <div className="inputWidth">
                    <InputField
                      label="Name on Document"
                      name="nameOnDocument"
                      width="13rem"
                    />
                    <InputField
                      label="Number on Document"
                      name="numberOnDocument"
                      width="13rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Document Type"
                      name="documentType"
                      width="13rem"
                    />
                    <InputField
                      label="Document Number"
                      name="documentNumber"
                      width="13rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Document ID"
                      name="documentId"
                      width="13rem"
                    />
                    <InputField
                      label="Passport Number"
                      name="passportNumber"
                      width="13rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Issue State"
                      name="documentIssueState"
                      width="13rem"
                    />
                    <InputField
                      label="Issue/Expiry Date"
                      name="documentDate"
                      width="13rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Document Type Details"
                      name="docTypeDetail"
                      width="13rem"
                    />
                    <InputField
                      label="License Issuing Authority"
                      name="licenseIssueAuthority"
                      width="13rem"
                    />
                  </div>
                </div>
              </div>
            </Row>
          )}

          {/* Agent Details... */}
          {(initialObj.agentName !== 'NA' ||
            initialObj.agentId !== 'NA' ||
            initialObj.agentNumber !== 'NA' ||
            initialObj.agentCompany !== 'NA' ||
            initialObj.salesAdvisor !== 'NA') && (
            <Row justify="space-between" className="p-2">
              <div className="detailPageCard w-[36rem] ">
                <EditTopChanger title="Agent Details" />
                <div className="wap">
                  <div className="inputWidth">
                    <InputField
                      label="Agent Name"
                      name="agentName"
                      width="13rem"
                    />
                    <InputField label="Agent ID" name="agentId" width="13rem" />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Agent Number"
                      name="agentNumber"
                      width="13rem"
                    />
                    <InputField
                      label="Company"
                      name="agentCompany"
                      width="13rem"
                    />
                  </div>
                  <div className="inputWidth">
                    <InputField
                      label="Sales Advisor"
                      name="salesAdvisor"
                      width="13rem"
                    />
                  </div>
                </div>
              </div>
            </Row>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default Details;
