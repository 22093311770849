import { Form, Select } from 'antd';
import React from 'react';

const MultiSelect = ({
  name,
  label,
  required,
  placeholder,
  width,
  selectArr,
  selectedValue,
  selectedName,
  disabled,
}) => {
  const checkType = selectArr?.length > 0 ? typeof selectArr[0] : '';
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required ? true : false,
          message: 'This field is required',
        },
      ]}
    >
      <Select
        showSearch
        mode="multiple"
        className="max-h-[2.5rem] overflow-y-auto overflow-x-hidden"
        bordered={false}
        placeholder={placeholder}
        style={{
          borderBottom: '1px solid #D4D4D4',
          borderRadius: '0',
          marginLeft: '0px',
          width: width ? width : '18rem',
        }}
        filterOption={(inputValue, option) => {
          return option?.name
            ?.toLowerCase()
            ?.includes(inputValue?.toLowerCase());
        }}
        disabled={disabled ? true : false}
      >
        {checkType === 'string' ? (
          <>
            {selectArr?.map((cur, index, key) => (
              <Select.Option
                value={cur}
                key={index}
                name={cur?.[selectedName] ?? cur}
                className="my-0.5"
              >
                {cur}
              </Select.Option>
            ))}
          </>
        ) : (
          <>
            {selectArr?.map((cur, index, key) => (
              <Select.Option
                value={cur?.[selectedValue]}
                name={cur?.[selectedName] ?? cur}
                key={cur?.[selectedValue] || index}
                className="my-0.5"
              >
                {cur?.[selectedName]}
              </Select.Option>
            ))}
          </>
        )}
      </Select>
    </Form.Item>
  );
};

export default React.memo(MultiSelect);
