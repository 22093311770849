import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import Title from 'antd/es/typography/Title';
import { editIcon } from 'assets/AssetIndex';
import React from 'react';
import { getFirstPropertyAndCount } from 'utils/UtilsIndex';

const HelplineCard = ({
  helpLineData,
  setEditDataForModal,
  handleDeleteModal,
}) => {
  const title = helpLineData?.name_en;
  const subtitle = helpLineData?.name_ar;
  const number =
    (helpLineData?.countryCode ? `+${helpLineData?.countryCode} ` : '') +
    helpLineData?.contactNumber;
  const profile = helpLineData?.image;

  const handleEditModal = () => {
    setEditDataForModal(helpLineData);
  };
  return (
    <div className="cardCss relative  h-[12rem] max-h-[12rem] w-[30%] flex-wrap justify-start overflow-hidden">
      <div className="absolute right-4 top-5 flex cursor-pointer gap-3 text-xl">
        <EditOutlined
          onClick={handleEditModal}
          className="cursor-pointer text-[#2BB256]"
        />
        <DeleteOutlined
          onClick={() => handleDeleteModal(helpLineData?.id)}
          className="cursor-pointer text-[#E20F0F]"
        />
      </div>
      <div className="h-[6rem] w-[6rem] rounded-full bg-[#d7d7d7]">
        <img src={profile} className="h-full w-full rounded-full" />
      </div>
      <div className="ml-4 flex h-[6rem] w-[7rem] flex-col justify-center">
        <Title className="truncate" level={4}>
          {title}
        </Title>
        <div className="bVariant mt-[-0.8rem] truncate text-sm">{number}</div>
      </div>
      <div className="my-4 flex w-full justify-between">
        {/* <div>
          <Space className="colorFontSm">Phone No.</Space> <br />
          <Space className="bVariant">{number}</Space>
        </div> */}
        <div>
          <Space className="colorFontSm">Property Name</Space>
          <br />
          <Space className="bVariant truncate">
            {getFirstPropertyAndCount(helpLineData?.helplineBuildings)}
          </Space>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HelplineCard);
