import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import LoginReducer from './slices/loginSlice';
import BasicSlice from './slices/BasicSlice';
import PropertySlice from './slices/property/PropertySlice';
import UnitSlice from './slices/property/UnitSlice';
import UserSlice from './slices/property/UserSlice';
import StaffSlice from './slices/property/StaffSlice';
import GuardSlice from './slices/GuardSlice';
import VisitorSlice from './slices/VisitorSlice';
import LeaseSlice from './slices/LeaseSlice';
import NoticeSlice from './slices/NoticeSlice';
import HelplineSlice from './slices/HelplineSlice';
import InvoiceSlice from './slices/InvoiceSlice';
import ServiceSlice from './slices/ServiceSlice';
import ServiceCategorySlice from './slices/category/ServiceCategorySlice';
import DashboardSlice from './slices/DashboardSlice';
import InventorySlice from './slices/property/CatalogSlice';
import CompanyCategorySlice from './slices/category/CompanyCategorySlice';

const store = configureStore({
  reducer: {
    Login: LoginReducer,
    Basic: BasicSlice,
    Property: PropertySlice,
    Unit: UnitSlice,
    User: UserSlice,
    Staff: StaffSlice,
    Inventory: InventorySlice,
    Invoice: InvoiceSlice,
    Guard: GuardSlice,
    Visitor: VisitorSlice,
    Lease: LeaseSlice,
    Notice: NoticeSlice,
    Helpline: HelplineSlice,
    Service: ServiceSlice,
    serviceCategory: ServiceCategorySlice,
    companyCategory: CompanyCategorySlice,
    Dashboard: DashboardSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
