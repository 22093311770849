import { getProfileDataReducer } from 'Redux/slices/BasicSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  DateField,
  EditTopChanger,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { genderList, idType } from 'staticData/PropertyData';
import { dateToDayJsObj, defaultCountryCode } from 'utils/UtilsIndex';

const Profile = ({ id }) => {
  const { profileData } = useSelector((state) => state.Basic);
  const dispatch = useDispatch();
  const [handleEditMode, setHandleEditMode] = useState({
    userDetails: false,
    personalDetails: false,
    bankDetails: false,
    documentDetails: false,
  });
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  //for document
  // const [docList, setDocList] = useState([]);

  const [initial] = Form.useForm();
  const alternateCountryCode = Form.useWatch('alternateCountryCode', initial);
  const onFinish = (values) => {
    values.masterUserId = id;
    // dispatch(editUserDataReducer({ values, navigate }));
  };
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    const initialObj = {
      name: profileData?.name,
      mobileNumber: `+${profileData?.mobileNumber}`,
      countryCode: profileData?.countryCode ?? defaultCountryCode,
      email: profileData?.email,
      dateOfBirth: dateToDayJsObj(profileData?.dateOfBirth),
      nationality: profileData?.nationality,
      gender: profileData?.gender,
      documentType: profileData?.documentType,
      documentId: profileData?.documentId,
      // alternateMobileNumber: profileData?.alternateContact?.mobileNumber,
      alternateEmail: profileData?.alternateContact?.email,
      accountHolderName: profileData?.bankDetails?.accountHolderName,
      accountNumber: profileData?.bankDetails?.accountNumber,
      alternateCountryCode:
        profileData?.alternateCountryCode ?? defaultCountryCode,
      bankName: profileData?.bankDetails?.bankName,
      swiftCode: profileData?.bankDetails?.swiftCode,
    };
    initial.setFieldsValue(initialObj);
    var defaultImg = profileData?.images?.map((value, index) => {
      return { url: value };
    });
    setFileList(defaultImg);
  }, [initial, profileData]);

  useEffect(() => {
    dispatch(getProfileDataReducer());
  }, [dispatch]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      preserve={true}
      className="w-full"
      form={initial}
    >
      <Title level={3}>Your Profile</Title>
      <Row justify="space-between" gutter={10}>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                // state="userDetails"
                title="User Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                style={{
                  pointerEvents: !handleEditMode.userDetails ? 'none' : '',
                }}
                className="p-4"
              >
                <ImageUpload
                  {...{
                    previewOpen,
                    setPreviewOpen,
                    previewImage,
                    setPreviewImage,
                    previewTitle,
                    setPreviewTitle,
                    fileList,
                    setFileList,
                  }}
                  circleImage="true"
                />
                <InputField
                  label="Full Name"
                  name="name"
                  placeholder="Jack Dem"
                  message="This field is required"
                  required="true"
                  // defaultValue={profileData?.name}
                  width="23rem"
                />
                <div className="mt-12">
                  <InputField name="mobileNumber" label="Phone Number" />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                // state="personalDetails"
                title="Personal Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.personalDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="POC Email ID"
                    name="email"
                    placeholder="poc@email.com"
                    pattern={/.*@.*/}
                    required="true"
                    width="15rem"
                  />
                  <DateField label="Date of  Birth" name="dob" width="15rem" />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Nationality"
                    name="nationality"
                    placeholder="Nationality"
                    width="15rem"
                  />
                  <SelectField
                    label="Gender"
                    name="gender"
                    placeholder="Gender"
                    selectArr={genderList}
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Id Type"
                    name="documentType"
                    selectArr={idType}
                    width="15rem"
                  />
                  <InputField
                    label="ID Number"
                    name="documentId"
                    placeholder="123"
                    type="ni"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <MobileNumber
                    label1="Alternate Phone Number"
                    countryCodeName="alternateCountryCode"
                    mobilePlaceHolder="Alternate Phone Number"
                    mobileNumberName="alternateMobileNumber"
                    countryCode={alternateCountryCode}
                    width="15rem"
                  />
                  <InputField
                    label="Alternate Email (Optional)"
                    name="alternateEmail"
                    placeholder="poc@email.com"
                    pattern={/.*@.*/}
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Profile;
