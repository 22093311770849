import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createBuilding,
  deleteBuildingData,
  editBuildingData,
  getAnalyticsData,
  getBuildingData,
  getBuildingListing,
  getBuildingsName,
  getCityList,
  getCountryList,
  getLocalityList,
} from 'Redux/services/property/Property';
import { initialListingState, initialState } from '../InitialState';

// locality data
export const LocalityReducer = createAsyncThunk('/locality', async (cityId) => {
  try {
    const resp = await getLocalityList(cityId);
    return resp?.data;
  } catch (error) {}
});
// country data
export const countryNameReducer = createAsyncThunk('/countryName', async () => {
  try {
    const resp = await getCountryList();
    return resp?.data;
  } catch (error) {}
});

// cityName
export const cityNameReducer = createAsyncThunk(
  '/cityName',
  async (countryName) => {
    try {
      const resp = await getCityList(countryName);
      return resp?.data;
    } catch (error) {}
  }
);

// get listing page data
export const PropertyListingReducer = createAsyncThunk(
  '/buildingListing',
  async (data) => {
    try {
      const resp = await getBuildingListing(data);
      return resp?.data;
    } catch (error) {}
  }
);
//create building
export const CreateBuildingReducer = createAsyncThunk(
  '/createBuilding',
  async (payload) => {
    try {
      const resp = await createBuilding(payload);
      payload?.dispatch(PropertyNameReducer());
      return resp;
    } catch (error) {}
  }
);
// building name
export const PropertyNameReducer = createAsyncThunk(
  '/buildingsName',
  async () => {
    try {
      const resp = await getBuildingsName();
      return resp?.data;
    } catch (error) {}
  }
);
//getBuildingData
export const getBuildingDataReducer = createAsyncThunk(
  'getBuildingData',
  async (id) => {
    try {
      const resp = await getBuildingData(id);
      return resp?.data.data;
    } catch (error) {}
  }
);
//editBuildingData
export const editBuildingDataReducer = createAsyncThunk(
  'editBuildingData',
  async (payload) => {
    const { values } = payload;
    try {
      const resp = await editBuildingData(values);
      return resp;
    } catch (error) {}
  }
);
//deleteBuildingData
export const deleteBuildingDataReducer = createAsyncThunk(
  'deleteBuildingData',
  async (buildingId) => {
    try {
      const resp = await deleteBuildingData(buildingId);
      return resp;
    } catch (error) { }
  }
)

export const BuildingAnalyticsDataReducer = createAsyncThunk(
  '/analyticsPropertyData',
  async () => {
    try {
      const resp = await getAnalyticsData();
      return resp?.data;
    } catch (error) {}
  }
);

const PropertySlice = createSlice({
  name: 'propertySlice',
  initialState,
  reducers: {
    selectedBuildingNameReducer: (state, action) => {
      state.selectedBuildingName = action?.payload;
    },
  },

  extraReducers: {
    // propertyListing
    [PropertyListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [PropertyListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.propertyListingData = action?.payload?.data ?? initialListingState;
    },
    [PropertyListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //locality
    [LocalityReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [LocalityReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.localityData = action?.payload?.data;
    },
    [LocalityReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //countryName
    [countryNameReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [countryNameReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.countryNameData = action?.payload?.data;
    },
    [countryNameReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //cityName
    [cityNameReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [cityNameReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.cityNameData = action?.payload?.data;
    },
    [cityNameReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //addProperty
    [CreateBuildingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateBuildingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [CreateBuildingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // property Name
    [PropertyNameReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [PropertyNameReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.buildingsName = action?.payload?.data;
    },
    [PropertyNameReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get building data
    [getBuildingDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getBuildingDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.propertyDetailData = action?.payload;
    },
    [getBuildingDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editBuildingData
    [editBuildingDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editBuildingDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editBuildingDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //deleteBuildingData
    [deleteBuildingDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteBuildingDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteBuildingDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // LeaseAnalytics
    [BuildingAnalyticsDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [BuildingAnalyticsDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.buildingAnalyticsData = action?.payload?.data;
    },
    [BuildingAnalyticsDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export const { selectedBuildingNameReducer } = PropertySlice.actions;
export default PropertySlice.reducer;
