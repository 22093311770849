import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { Chip, ListingFieldWithIcon } from 'component/CompIndex';
import { Link } from 'react-router-dom';

export const getLeadTableData = (leadListingData, handleDelete, handleEdit) => {
  return {
    data: leadListingData?.length
      ? leadListingData?.map((cur) => {
          return cur;
          // {
          //     key: cur?.id,
          //     name: cur?.name,
          //     company: cur?.company,
          //     mobileNumber: `+${cur?.mobileNumber}`,
          //     buildings: getFirstPropertyAndCount(cur?.guardBuildings),
          //     lastLogin: timeStampToDate(cur?.lastLogin).formattedDate,
          //     lastLoginTime: timeStampToDate(cur?.lastLogin).formattedTime,
          //     status: cur?.isActive ? 'Active' : 'In-Active',
          // };
        })
      : [],
    columns: [
      {
        title: 'Visitor',
        dataIndex: 'name',
        key: 'id',
        render: (text, key) => (
          <Link
            to={`/admin/leads/detail/${'535cfa70-9a69-11ee-aec8-41946cf5c50d'}`}
          >
            <ListingFieldWithIcon text={text} smText={key?.mobileNumber} />
          </Link>
        ),
      },
      {
        title: 'Property',
        dataIndex: 'buildings',
        key: 'buildings',
      },
      {
        title: 'Unit',
        dataIndex: 'buildings',
        key: 'buildings',
      },
      {
        title: 'Lead Owner',
        dataIndex: 'name',
        key: 'id',
        render: (text, key) =>
          text ? (
            <ListingFieldWithIcon text={text} smText={key?.mobileNumber} />
          ) : (
            ''
          ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => (
          <div className="whitespace-nowrap">
            <Chip
              color={
                status === 'Draft' || status === 'Negotiation Closed'
                  ? 'green'
                  : 'red'
              }
              name={status}
            />
          </div>
        ),
      },
      {
        title: 'Converted',
        dataIndex: 'converted',
        key: 'converted',
        render: (text, { status, key }) => (
          <div>
            {text ? (
              <CheckOutlined className="text-[#2BB256]" />
            ) : status === 'Negotiation Closed' || status === 'Draft' ? (
              <Button
                className="customButton w-auto"
                onClick={() => handleDelete(key, true)}
              >
                Convert
              </Button>
            ) : (
              <CloseOutlined className="text-[#E20F0F]" />
            )}
          </div>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, { status, key }) => (
          // <Action data={key} handleEdit={() => { }} />
          <>
            {status !== '' ? (
              <EditOutlined
                onClick={() => handleEdit(key)}
                className="cursor-pointer text-[#2BB256]"
              />
            ) : (
              <Button className="customButton w-auto">
                <Link
                  to={`/admin/lease/add`}
                  className="h-full w-full"
                  state={{
                    buildingId: '8fb5cb90-1978-11ee-b1db-65eeb619385d',
                    unitId: '330544d0-1be8-11ee-a903-e1acec8b0dcd',
                    cancelUrl: '/admin/leads?page=1',
                    tenant: {
                      name: 'Abcd',
                      mobile: '+918368616227',
                    },
                  }}
                >
                  <PlusOutlined /> Draft
                </Link>
              </Button>
            )}
            {handleDelete && (
              <DeleteOutlined
                onClick={() => handleDelete(key)}
                className="ml-3 cursor-pointer text-[#E20F0F]"
              />
            )}
          </>
        ),
      },
    ],
  };
};
