import { guardCompanyDataReducer } from 'Redux/slices/GuardSlice';
import { Checkbox, Collapse, Select } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { areaType, companyType } from 'staticData/GuardData';

const GuardFilter = ({ allFilters, setAllFilters }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const { buildingsName } = useSelector((state) => state.Property);
  const handleFilterChange = (isChecked, name, value) => {
    let updatedValue = value;

    if (Array.isArray(value)) {
      updatedValue = value.join(',');
    }
    if (isChecked) {
      setAllFilters({ ...allFilters, [name]: updatedValue });
    } else {
      setAllFilters({ ...allFilters, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };
  const dispatch = useDispatch();
  const { guardCompanyData } = useSelector((state) => state.Guard);

  useEffect(() => {
    dispatch(guardCompanyDataReducer());
  }, [dispatch]);

  return (
    <div className="flex w-[18rem] flex-col justify-start gap-2 p-2">
      <Collapse defaultActiveKey={'1'} ghost expandIconPosition="end">
        <Collapse.Panel header={<h2 className="font-bold">Property</h2>}>
          <Select
            placeholder="Select Property"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'buildingId', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {buildingsName?.rows?.map((cur, index, key) => (
              <Select.Option value={cur?.id} key={cur?.id}>
                {cur?.name_en}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Guard Company</h2>}>
          <Select
            placeholder="Select Company"
            defaultOpen={false}
            bordered={true}
            style={{
              width: '100%',
              textAlign: 'center',
              borderRadius: '6px',
            }}
            className="changeSelect"
            allowClear
            onChange={(value) => handleFilterChange(true, 'company', value)}
            showSearch
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
          >
            {guardCompanyData?.map((cur, index, key) => (
              <Select.Option value={cur} key={index}>
                {cur}
              </Select.Option>
            ))}
          </Select>
        </Collapse.Panel>
        <Collapse.Panel
          key={'1'}
          header={<h2 className="font-bold">Status</h2>}
        >
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {areaType?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={allFilters?.status === data ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(e.target?.checked, 'status', data)
                  }
                >
                  <label htmlFor={data}>{data}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default GuardFilter;
