import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createGuard,
  deleteGuardData,
  editGuardData,
  editGuardStatus,
  getGuardData,
  getGuardListing,
  guardCompanyData,
} from 'Redux/services/Guard/Guard';
import { initialListingState, initialState } from './InitialState';

// create
export const CreateGuardReducer = createAsyncThunk(
  '/createGuard',
  async (payload) => {
    try {
      const resp = await createGuard(payload);
      return resp;
    } catch (error) {}
  }
);

// get listing page data
export const GuardListingReducer = createAsyncThunk(
  '/guardListing',
  async (data) => {
    try {
      const resp = await getGuardListing(data);
      return resp?.data;
    } catch (error) {}
  }
);
//getGuardData
export const getGuardDataReducer = createAsyncThunk(
  'getGuardData',
  async (id) => {
    try {
      const resp = await getGuardData(id);
      return resp?.data?.data;
    } catch (error) {}
  }
);
//editGuardData
export const editGuardDataReducer = createAsyncThunk(
  'editGuardData',
  async (payload) => {
    try {
      const resp = await editGuardData(payload);
      return resp;
    } catch (error) {}
  }
);
//editGuardStatus
export const editGuardStatusReducer = createAsyncThunk(
  'editGuardStatus',
  async (guardId) => {
    try {
      const resp = await editGuardStatus(guardId);
      return resp;
    } catch (error) {}
  }
);
//deleteGuardData
export const deleteGuardDataReducer = createAsyncThunk(
  'deleteGuardData',
  async (guardId) => {
    try {
      const resp = await deleteGuardData(guardId);
      return resp;
    } catch (error) {}
  }
);

export const guardCompanyDataReducer = createAsyncThunk(
  'guardCompanyData',
  async () => {
    try {
      const resp = await guardCompanyData();
      return resp.data;
    } catch (error) {}
  }
);

const GuardSlice = createSlice({
  name: 'guardSlice',
  initialState,
  reducers: {},

  extraReducers: {
    //addGuard
    [CreateGuardReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateGuardReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [CreateGuardReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // guardListing
    [GuardListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [GuardListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.guardListingData = action?.payload?.data ?? initialListingState;
    },
    [GuardListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get guard company
    [guardCompanyDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [guardCompanyDataReducer.fulfilled]: (state, action) => {
      state.isSuccess = true;
      state.isLoading = false;
      state.guardCompanyData = action?.payload?.data ?? initialListingState;
    },
    [guardCompanyDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get Guard data
    [getGuardDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getGuardDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.guardDetailData = action?.payload;
    },
    [getGuardDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editGuardData
    [editGuardDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editGuardDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editGuardDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editGuardStatus
    [editGuardStatusReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editGuardStatusReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [editGuardStatusReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //deleteGuardData
    [deleteGuardDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteGuardDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteGuardDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default GuardSlice.reducer;
