import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { NoDataFound } from 'assets/AssetIndex';
import { useNavigate } from 'react-router-dom';
const NoData = ({ text = '', navigate, isTextBold = true }) => {
  const navigateTo = useNavigate();
  const handleNavigate = () => {
    if (navigate) navigateTo(navigate);
  };
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-5">
      <img src={NoDataFound} />
      <div className="text-center">
        <p
          className={`${
            isTextBold ? 'text-2xl font-semibold' : 'text-xl'
          } text-[#474747]`}
        >
          OOPS! No Data Found.
        </p>
        {text && (
          <p className="text-lg font-light leading-5 text-[#A4A4A4]">
            Looks like you haven’t added any data yet!
          </p>
        )}
      </div>
      {text && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="customButton h-10 px-10"
          onClick={handleNavigate}
        >
          {text}
        </Button>
      )}
    </div>
  );
};

export default NoData;
