import { CreateUserReducer } from 'Redux/slices/property/UserSlice';
import { Button, Form } from 'antd';
import Title from 'antd/es/typography/Title';
import { InputField } from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addCountryInMob, defaultCountryCode } from 'utils/UtilsIndex';

const ShortNewUser = ({ handleClose, isCompany = false }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);
  const pocCountryCode = Form.useWatch('pocCountryCode', form);
  const initialValues = {
    countryCode: defaultCountryCode,
    pocCountryCode: defaultCountryCode,
  };
  const handleCloseModal = () => {
    handleClose();
    form.resetFields();
    form.setFieldsValue(initialValues);
  };
  const onFinish = async (values) => {
    if (!isCompany) {
      values = addCountryInMob(values, 'countryCode', 'mobileNumber');
    } else {
      values = addCountryInMob(
        values,
        'countryCode',
        'mobileNumber',
        'pocCountryCode',
        'pocMobileNumber'
      );
    }
    dispatch(
      CreateUserReducer({
        values,
        isForCompany: isCompany,
      })
    );
    handleCloseModal();
  };
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      form={form}
      initialValues={{
        remember: true,
      }}
      className="w-full"
    >
      <div className="p-8">
        <Title level={3}>Add {isCompany ? 'Company' : 'Customer'}</Title>
        <div className="inputWidth">
          <InputField
            label={`${isCompany ? 'Company' : 'Customer'} Name`}
            name="name"
            placeholder={`${isCompany ? 'Company' : 'Customer'} Name`}
            width="13rem"
            message="This field is required"
            required
          />
          <InputField
            label={`${isCompany ? 'Company' : 'Customer'} Email`}
            name="email"
            width="13rem"
            placeholder={`${isCompany ? 'Company' : 'Customer'} Email`}
            pattern={/.*@.*/}
            required
          />
        </div>
        <div className="inputWidth">
          <MobileNumber
            label1={`${isCompany ? 'Company' : 'Customer'} Phone Number`}
            countryCodeName="countryCode"
            mobilePlaceHolder={`${
              isCompany ? 'Company' : 'Customer'
            } Phone Number`}
            mobileNumberName="mobileNumber"
            countryCode={countryCode}
            required="true"
            width="28rem"
          />
        </div>
        {isCompany && (
          <>
            <Title level={5}>Poc Details</Title>
            <div className="inputWidth">
              <InputField
                label="POC Name"
                name="pocName"
                placeholder="POC Name"
                width="13rem"
                message="This field is required"
                required
              />
              <InputField
                label="POC Email ID"
                name="pocEmail"
                width="13rem"
                placeholder="POC Email"
                pattern={/.*@.*/}
                required
              />
            </div>
            <div className="inputWidth">
              <MobileNumber
                label1="POC Phone Number"
                countryCodeName="pocCountryCode"
                mobilePlaceHolder="POC Mobile Number"
                mobileNumberName="pocMobileNumber"
                required
                countryCode={pocCountryCode}
                width="28rem"
              />
            </div>
          </>
        )}
        <div className="center">
          <Button className="w-40" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className="customButton ml-4 w-40" htmlType="submit">
            Add {isCompany ? 'Company' : 'Customer'}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ShortNewUser;
