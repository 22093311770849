import { useEffect } from 'react';
import { Navigate } from 'react-router';
import { getAdminInfo } from 'utils/UtilsIndex';

function PrivateRoute({ children }) {
  const { token } = getAdminInfo();
  useEffect(() => {
    getAdminInfo();
  }, [token]);

  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

export default PrivateRoute;
