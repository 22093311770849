import { axiosInstance } from "../AxiosClient";

export const getCategoryListing = async (params) => {
    const resp = await axiosInstance.get(`/maintenances/admin/categories`, { params });
    return resp;
}

export const getCategoryDetail = async (id) => {
    const resp = await axiosInstance.get(`/maintenances/admin/categories/${id}`);
    return resp;
};

export const AddCategory = async (payload) => {
    const resp = await axiosInstance.post(`/maintenances/admin/categories`, payload);
    return resp;
};

export const toggleVisibility = async (categoryId) => {
    const resp = await axiosInstance.patch(
        `/maintenances/admin/categories/visibility/${categoryId}`
    );
    return resp;
};

export const editCategory = async ({ data, categoryId }) => {
    const resp = await axiosInstance.patch(`/maintenances/admin/categories/${categoryId}`, data);
    return resp;
};

export const deleteCategory = async (id) => {
    const resp = await axiosInstance.delete(`/maintenances/admin/categories/${id}`);
    return resp;
};