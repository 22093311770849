import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initialListingState, initialState } from "../InitialState";
import { addInventory, deleteInventory, editInventoryDetails, getInventoryDetails, getInventoryDropdown, getInventoryList } from "Redux/services/property/Catalog";

export const InventoryListingReducer = createAsyncThunk(
    'inventoryListing',
    async (params) => {
        try {
            const resp = await getInventoryList(params);
            return resp?.data;
        } catch (error) { }
    }
)

export const InventoryDropdownReducer = createAsyncThunk(
    'inventoryDropdown',
    async (params) => {
        try {
            const resp = await getInventoryDropdown(params);
            return resp?.data;
        } catch (error) { }
    }
)

export const AddInventoryReducer = createAsyncThunk(
    'addInventory',
    async (payload) => {
        try {
            const { values, navigateTo } = payload;
            const resp = await addInventory(values, navigateTo);
            return resp?.data;
        } catch (error) { }
    }
)

export const InventoryDetailReducer = createAsyncThunk(
    'inventoryDetails',
    async (id) => {
        try {
            const resp = await getInventoryDetails(id);
            return resp?.data;
        } catch (error) { }
    }
)

export const DeleteInventoryReducer = createAsyncThunk(
    'deleteInventory',
    async (id) => {
        try {
            const resp = await deleteInventory(id);
            return resp?.data;
        } catch (error) { }
    }
)

export const EditInventoryReducer = createAsyncThunk(
    'editInventory',
    async (payload) => {
        try {
            const { values, navigateTo, id } = payload;
            const resp = await editInventoryDetails(id, values, navigateTo);
            return resp?.data;
        } catch (error) { }
    }
)

const InventorySlice = createSlice({
    name: 'inventorySlice',
    initialState,
    extraReducers: {
        [InventoryListingReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [InventoryListingReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = false;
            state.inventoryListingData = action?.payload?.data ?? initialListingState;
        },
        [InventoryListingReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // dropdown data
        [InventoryDropdownReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [InventoryDropdownReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = false;
            state.inventoryDropdownData = action?.payload?.data;
        },
        [InventoryDropdownReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // inventory detail
        [InventoryDetailReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [InventoryDetailReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.inventoryDetailData = action?.payload?.data;
        },
        [InventoryDetailReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // inventory edit
        [EditInventoryReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [EditInventoryReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [EditInventoryReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // delete Inventory
        [DeleteInventoryReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [DeleteInventoryReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [DeleteInventoryReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // Add Catalog
        [AddInventoryReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
            state.isLoadAgain = true;
        },
        [AddInventoryReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        },
        [AddInventoryReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default InventorySlice.reducer;