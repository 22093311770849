import {
  exportGuardData,
  exportVisitorData,
} from 'Redux/services/ExportCsv/Export';
import {
  GuardListingReducer,
  deleteGuardDataReducer,
  editGuardStatusReducer,
} from 'Redux/slices/GuardSlice';
import Title from 'antd/es/typography/Title';
import {
  DataTable,
  MixedHeadContent,
  ModalComp,
  DeleteRequest,
} from 'component/CompIndex';
import GuardFilter from 'component/filter/GuardFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getGuardTableData } from 'staticData/GuardData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const GuardListing = () => {
  const {
    guardListingData: { rows, count },
    isLoadAgain,
  } = useSelector((state) => state.Guard);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [guardId, setGuardId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [allFilters, setAllFilters] = useState({
    buildingId: '',
    company: '',
    status: '',
  });
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);

  const handleDeleteModalOpen = (guardId, str) => {
    if (str) {
      setIsStatusModalOpen(true);
      setGuardId(str);
    }
    setGuardId(guardId);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    if (isStatusModalOpen) {
      setIsStatusModalOpen(false);
    }
    setGuardId(null);
    setIsModalOpen(false);
  };

  const handleDeleteGuardData = () => {
    if (isStatusModalOpen) {
      dispatch(editGuardStatusReducer(guardId));
    } else {
      dispatch(deleteGuardDataReducer(guardId));
    }
    handleClose();
  };

  const handleFilter = () => {};
  const handleExport = async () => {
    const params = {
      status: allFilters?.status,
      search,
      buildingId: allFilters?.buildingId ?? selectedBuildingName,
      company: allFilters?.company,
    };
    return await exportGuardData(params);
  };
  const { data, columns } = getGuardTableData(rows, handleDeleteModalOpen);

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      status: allFilters?.status,
      search,
      buildingId: allFilters?.buildingId ?? selectedBuildingName,
      company: allFilters?.company,
    };
    dispatch(GuardListingReducer(params));
  }, [
    defaultPageNo,
    search,
    selectedBuildingName,
    allFilters,
    isLoadAgain,
    dispatch,
  ]);

  return (
    <>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <DeleteRequest
            text={
              isStatusModalOpen
                ? `Are you sure you want to change the Guard Status ?`
                : `Are you sure you want to delete Guard ?`
            }
            handleSave={handleDeleteGuardData}
            handleClose={handleClose}
            anotherTitle="Yes"
          />
        }
      />
      <div className="relative">
        <Title level={3}>Details</Title>
        <div className="absolute right-0 top-0 z-10">
          <MixedHeadContent
            filterComp=<GuardFilter
              allFilters={allFilters}
              setAllFilters={setAllFilters}
            />
            AddNew={'Add Guard'}
            route="/admin/guard/add"
            {...{
              setSearch,
              handleExport,
              handleFilter,
              setSearchParam,
              exportFileName: 'Guard Data',
            }}
          />
        </div>
        <DataTable
          {...{
            columns,
            data,
            count,
            pageNo,
            setPageNo,
            searchParams,
            setSearchParam,
          }}
        />
      </div>
    </>
  );
};

export default GuardListing;
