import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createCompany,
  createUser,
  deleteCompanyData,
  deleteUserData,
  editCompanyData,
  editUserData,
  getCompanyData,
  getCompanyListing,
  getMasterUserData,
  getUserData,
  getUserListing,
} from 'Redux/services/property/User';
import { initialListingState, initialState } from '../InitialState';

export const UserListingReducer = createAsyncThunk(
  '/unitListing',
  async (data) => {
    const resp = await getUserListing(data);
    return resp?.data;
  }
);

export const masterUserDataReducer = createAsyncThunk(
  '/masterUserData',
  async () => {
    try {
      const resp = await getMasterUserData();
      return resp?.data;
    } catch (error) { }
  }
);

export const CreateUserReducer = createAsyncThunk(
  '/createUser',
  async (payload) => {
    const { values, navigateTo, isForCompany } = payload;
    try {
      if (isForCompany) {
        const resp = await createCompany(values, navigateTo);
        return resp;
      } else {
        const resp = await createUser(values, navigateTo);
        return resp;
      }
    } catch (error) { }
  }
);

//getUserData
export const getUserDataReducer = createAsyncThunk(
  'getUserData',
  async (id) => {
    try {
      const resp = await getUserData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);

//editUserData
export const editUserDataReducer = createAsyncThunk(
  'editUserData',
  async (payload) => {
    try {
      const resp = await editUserData(payload);
      return resp;
    } catch (error) { }
  }
);

//editUserData
export const editCompanyDataReducer = createAsyncThunk(
  'editCompanyData',
  async (payload) => {
    const { navigateTo, id, values } = payload;
    try {
      const resp = await editCompanyData(id, values, navigateTo);
      return resp;
    } catch (error) { }
  }
);

//deleteUserData
export const deleteUserDataReducer = createAsyncThunk(
  'deleteUserData',
  async (userId) => {
    const resp = await deleteUserData(userId);
    return resp;
  }
);

export const CompanyListingReducer = createAsyncThunk(
  '/companyListing',
  async (data) => {
    const resp = await getCompanyListing(data);
    return resp?.data;
  }
);
//getUserData
export const GetCompanyDataReducer = createAsyncThunk(
  'getCompanyData',
  async (id) => {
    try {
      const resp = await getCompanyData(id);
      return resp?.data?.data;
    } catch (error) { }
  }
);
//deleteCompanyData
export const deleteCompanyDataReducer = createAsyncThunk(
  'deleteCompanyData',
  async (companyId) => {
    const resp = await deleteCompanyData(companyId);
    return resp;
  }
);

const UserSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {},

  extraReducers: {
    [UserListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [UserListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.userListingData = action?.payload?.data ?? initialListingState;
    },
    [UserListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [CreateUserReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateUserReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [CreateUserReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // get masterUser
    [masterUserDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [masterUserDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.masterUserData = action?.payload?.data ?? [];
    },
    [masterUserDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //getUserData
    [getUserDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getUserDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.userDetailData = action?.payload;
    },
    [getUserDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editUserData
    [editUserDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editUserDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editUserDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },

    //deleteUserData
    [deleteUserDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteUserDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteUserDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //company
    [CompanyListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CompanyListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.companyListingData = action?.payload?.data;
    },
    [CompanyListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //GetCompanyData
    [GetCompanyDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [GetCompanyDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.companyDetailData = action?.payload;
    },
    [GetCompanyDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editCompanyData
    [editCompanyDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editCompanyDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [editCompanyDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //deleteCompanyData
    [deleteCompanyDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteCompanyDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteCompanyDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export default UserSlice.reducer;
