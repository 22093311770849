import axios from 'axios';
import { toast } from 'react-toastify';
import {
  clearAdminInfo,
  getAdminInfo,
  removeNull,
  setAdminInfo,
} from 'utils/UtilsIndex';

// do not touch any function  --- requested comment
export const baseURL = process.env.REACT_APP_API_KEY;
let navigationRoute = null;

const getToken = () => {
  return new Promise((resolve) => {
    const { token } = getAdminInfo();
    resolve(token);
  });
};
let TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    timezone: TimeZone,
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

axiosInstance.interceptors.request.use(
  async function (config) {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (config?.navigateTo) {
      navigationRoute = config?.navigateTo;
      delete config?.navigateTo;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    // if (config.method === 'patch' || config.method === 'PATCH') {
    // Modify the data of the PATCH request before sending
    config.data = removeNull(config.data);
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers = [];

function refreshToken() {
  const token = localStorage.getItem('reToken');
  return axiosInstance.post('/tokens', {
    // Send any necessary data for refreshing the token
    // For example, you might send the expired token to get a new one
    refreshToken: token,
  });
}
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { config, response } = error;
    const originalRequest = error.config;

    if (!originalRequest.hasOwnProperty('_retry')) {
      originalRequest._retry = false;
    }

    // Check if the error is due to an expired token (for example, HTTP 401 Unauthorized)
    if (response && response?.status === 401 && !originalRequest?._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        originalRequest._retry = true;
        return refreshToken()
          .then((newTokenResponse) => {
            const newAccessToken = newTokenResponse.data.data.accessToken;
            setAdminInfo('token', newAccessToken);
            setAdminInfo('reToken', newTokenResponse.data.data.refreshToken);
            config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            // to do --- this is not a good approach so need to be change this
            window.location.reload();
            return axiosInstance(config);
          })
          .catch((refreshError) => {
            clearAdminInfo();
            window.location.href = '/login';
            return Promise.reject(refreshError);
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        // If token is already being refreshed, enqueue the request
        return new Promise((resolve, reject) => {
          refreshSubscribers.push((token) => {
            config.headers['Authorization'] = `Bearer ${token}`;
            resolve(axiosInstance(config));
          });
        });
      }
    }

    return Promise.reject(error);
  }
);

// Subscribe to the refresh token logic
function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data?.msg) {
      if (response?.config?.method !== 'get') {
        toast.dismiss();
      }
      response?.data?.status === 'success'
        ? response?.config?.method !== 'get' && toast.success(response?.data?.msg)
        : toast.error(response.data.msg);
    }

    if (response?.data?.status === 'success') {
      if (navigationRoute) {
        const { navigate, url } = navigationRoute;
        navigationRoute = null;
        navigate(url);
      }
      return response;
    }
  },
  (error) => {
    navigationRoute = null;
    if (error?.response?.status === 420) {
      window.location.reload();
      clearAdminInfo();
      window.location.href = '/login';
    }
    if (error?.response?.data?.status === 'fail') {
      toast.error(error?.response?.data?.msg, { toastId: 'Error' });
    } else {
      toast.error(error?.message, { toastId: 'Error' })
    }
  }
);

export { subscribeTokenRefresh };
