import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateLeaseReducer } from 'Redux/slices/LeaseSlice';
import {
  getOwnerDataReducer,
  getTenantDataReducer,
  getVacantSubUnitReducer,
  getVacantUnitReducer,
} from 'Redux/slices/property/UnitSlice';
import { Button, Col, Form, Row, Switch } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  DateField,
  DocumentUpload,
  InputField,
  ModalComp,
  SelectField,
  ShortOwner,
  ShortTenant,
  ShortUnit,
} from 'component/CompIndex';
import RentalDiscount from 'component/RentalDiscount';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routeObj } from 'staticData/Data';
import { currencyList, leaseTypeList } from 'staticData/LeaseData';
import { paymentFrequency, paymentModes } from 'staticData/PropertyData';
import {
  addOneYearToDate,
  calculateDiscount,
  disablePastDates,
  findIndex,
  getCustomDateFormat,
  removeUnwantedPayload,
} from 'utils/UtilsIndex';
import AddAllocatedDetails from './AddAllocatedDetails';
import { masterUserDataReducer } from 'Redux/slices/property/UserSlice';

const AddLease = () => {
  const { buildingsName } = useSelector((state) => state.Property);
  const { masterUserData } = useSelector((state) => state.User);
  const {
    vacantUnitData,
    vacantSubUnitData,
    ownerListData,
    tenantData: { rows },
  } = useSelector((state) => state.Unit);

  let [duplicateOwnerListData, setDuplicateOwnerListData] = useState({});

  const location = useLocation();
  const selectedUnitId = location?.state?.unitId;
  const selectedBuildingId = location?.state?.buildingId;
  const selectedCancelUrl = location?.state?.cancelUrl ?? '/admin/lease?page=1';
  const selectedTenant = location?.state?.tenant ?? false;
  const [refreshUnitRed, setRefreshUnitRed] = useState(false);
  const [refreshTenantRed, setRefreshTenantRed] = useState(false);
  const [refreshOwnerRed, setRefreshOwnerRed] = useState(false);
  const [showTermsForm, setShowTermsForm] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [docList, setDocList] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const buildingId = Form.useWatch('buildingType', form);
  const unitId = Form.useWatch('flatId', form);
  const ownerId = Form.useWatch('ownerId', form);
  const tenantId = Form.useWatch('masterUserId', form);
  const currency = Form.useWatch('currency', form)?.toLowerCase();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);
  const moveInDate = Form.useWatch('moveInDate', form);
  const discountApplicability = Form.useWatch('discountApplicability', form);
  const rentAmount = Form.useWatch('rentAmount', form);
  const securityDeposit = Form.useWatch('securityDeposit', form);
  const discountValue = Form.useWatch('discountValue', form);
  const discountType = Form.useWatch('discountType', form);
  const isDiscountRequired =
    Form.useWatch('isDiscountRequired', form)?.[0] || false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [handleModal, setHandleModal] = useState({
    unit: false,
    owner: false,
    tenant: false,
  });
  const onFinish = (values) => {
    values['startDate'] = getCustomDateFormat(
      values?.startDate?.$d || values?.startDate?._d,
      'YYYY/MM/DD'
    );
    values['endDate'] = getCustomDateFormat(
      values?.endDate?.$d || values?.endDate?._d,
      'YYYY/MM/DD'
    );
    values['moveInDate'] = getCustomDateFormat(
      values?.moveInDate?.$d || values?.moveInDate?._d,
      'YYYY/MM/DD'
    );
    values['moveOutDate'] = getCustomDateFormat(
      values?.moveOutDate?.$d || values?.moveOutDate?._d,
      'YYYY/MM/DD'
    );
    values.documents = docList;
    values.isDiscountRequired = isDiscountRequired;
    values.terms = values?.terms?.map((data) => data?.description ?? data);
    values.amenities = amenities;
    if (values?.subFlatId) {
      delete values?.flatId;
    }
    values = removeUnwantedPayload(
      values,
      'ownerEmail',
      'ownerMobileNumber',
      'tenantEmail',
      'tenantMobileNumber',
      'totalDiscount',
      'periodMonth',
      'buildingType'
    );
    const payload = {
      data: { ...values, paymentDetailsRequired: showPaymentDetails },
      navigateTo: { navigate, url: routeObj?.lease },
    };
    dispatch(CreateLeaseReducer(payload));
  };
  const handleAddNewTerms = () => {
    setShowTermsForm(true);
  };
  const handleCancelData = () => {
    navigate(selectedCancelUrl);
  };
  const handleOpenModal = (type) => {
    if (!buildingId && type === 'unit') {
      toast.error('Please select the building');
    } else if (!unitId && type === 'owner') {
      toast.error('Please select the unit');
    } else {
      setHandleModal({
        ...handleModal,
        [type]: true,
      });
      setIsModalOpen(true);
      setRefreshUnitRed(false);
      setRefreshTenantRed(false);
      setRefreshOwnerRed(false);
    }
  };
  const dayjs = require('dayjs'); // Import dayjs if not already imported

  const disableBeforeContractStartDate = (current) => {
    if (startDate) {
      const date = dayjs(startDate);
      return current && current <= date;
    }
    return false;
  };

  const disableBeforeMOveInDate = (current) => {
    if (moveInDate) {
      const date = dayjs(moveInDate);
      return current && current <= date;
    }
    return false;
  };

  const calculateMonths = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return null;
    }
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    return (
      (date2.getFullYear() - date1.getFullYear()) * 12 +
      (date2.getMonth() - date1.getMonth())
    );
  };

  useEffect(() => {
    dispatch(getTenantDataReducer());
  }, [refreshTenantRed, dispatch]);

  useEffect(() => {
    if (!unitId) {
      setDuplicateOwnerListData({});
      form.setFieldsValue({
        ownerId: null,
        ownerEmail: null,
        ownerMobileNumber: null,
      });
    }

    if (unitId || refreshOwnerRed) {
      dispatch(getOwnerDataReducer(unitId));
      dispatch(getVacantSubUnitReducer({ flatId: unitId }));
      dispatch(masterUserDataReducer());
    }
  }, [unitId, refreshUnitRed, refreshOwnerRed, dispatch, form]);

  useEffect(() => {
    form.setFieldsValue({
      flatId: null,
    });
    if (buildingId || refreshUnitRed) {
      dispatch(getVacantUnitReducer(buildingId));
    }
  }, [buildingId, refreshUnitRed]);

  useEffect(() => {
    if (unitId && ownerListData)
      var initialObj = {
        flatId: unitId ? unitId : selectedUnitId,
        buildingType: buildingId ? buildingId : selectedBuildingId,
        tenantName: selectedTenant?.name,
        tenantMobile: selectedTenant?.mobile,
        ownerId: ownerListData?.ownerId || null,
        terms: [{ description: '' }],
        totalDiscount: null,
      };
    form.setFieldsValue(initialObj);
  }, [form, ownerListData, unitId]);

  useEffect(() => {
    if (ownerId) {
      const selectedUserObj = {
        ownerEmail: findIndex(masterUserData, ownerId, 'email'),
        ownerMobileNumber: findIndex(masterUserData, ownerId, 'mobileNumber'),
        tenantEmail: findIndex(rows, tenantId, 'email'),
        tenantMobileNumber: findIndex(rows, tenantId, 'mobileNumber'),
      };
      form.setFieldsValue(selectedUserObj);
    } else {
      // Set ownerEmail and ownerMobileNumber to null when ownerId is null
      form.setFieldsValue({
        ownerEmail: null,
        ownerMobileNumber: null,
      });
    }

    if (!ownerId) {
      form.setFieldValue({ ownerEmail: null, ownerMobileNumber: null });
    }
  }, [ownerId, tenantId, masterUserData]);

  useEffect(() => {
    if ((rentAmount || securityDeposit) && discountValue) {
      form.setFieldsValue({
        totalDiscount: calculateDiscount(
          rentAmount,
          securityDeposit,
          discountApplicability,
          discountValue,
          discountType
        ),
      });
    }
  }, [
    rentAmount,
    securityDeposit,
    discountApplicability,
    discountValue,
    discountType,
    form,
  ]);

  useEffect(() => {
    const dateAfterOneYear = addOneYearToDate(startDate?.$d);
    form.setFieldsValue({
      endDate: endDate ? endDate : dateAfterOneYear,
      moveOutDate: endDate,
      moveInDate: startDate,
      periodMonth: calculateMonths(
        startDate?.$d,
        endDate?.$d ?? dateAfterOneYear?._d
      ),
    });
  }, [startDate, form]);

  useEffect(() => {
    if (endDate && startDate) {
      form.setFieldsValue({
        moveOutDate: endDate,
        moveInDate: startDate,
        periodMonth: calculateMonths(startDate?.$d, endDate?.$d ?? endDate?._d),
      });
    }
  }, [endDate]);

  const handlePaymentDetailsToggle = () => {
    setShowPaymentDetails(!showPaymentDetails);
  };

  return (
    <>
      {handleModal.unit ? (
        <ModalComp
          {...{ isModalOpen }}
          component={
            <ShortUnit
              {...{
                isModalOpen,
                setIsModalOpen,
                buildingId,
                handleModal,
                setHandleModal,
                setRefreshUnitRed,
              }}
            />
          }
        />
      ) : handleModal?.owner ? (
        <ModalComp
          {...{ isModalOpen }}
          component={
            <ShortOwner
              {...{
                isModalOpen,
                setIsModalOpen,
                buildingId,
                handleModal,
                setHandleModal,
                setRefreshOwnerRed,
              }}
            />
          }
        />
      ) : (
        <ModalComp
          {...{ isModalOpen }}
          component={
            <ShortTenant
              {...{
                isModalOpen,
                setIsModalOpen,
                buildingId,
                handleModal,
                setHandleModal,
                setRefreshTenantRed,
              }}
            />
          }
        />
      )}
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        form={form}
        initialValues={{
          terms: [],
        }}
        preserve={true}
        className="w-full"
      >
        <AddNew
          {...{ handleCancelData }}
          title="Add New Lease"
          saveButtonText="Add Lease"
        />
        <Row justify="space-between my-4">
          <Col span={15} xs={{ span: 15 }} md={{ span: 15 }}>
            <div className="inputWidth text-base font-semibold">
              <h2>Property Details</h2>
              <h2
                className="cursor-pointer text-[#229EEF]"
                onClick={() => handleOpenModal('unit')}
              >
                <PlusOutlined /> Add New Unit
              </h2>
            </div>
            <Row>
              <div className="inputWidth">
                <SelectField
                  label="Lease Type"
                  name="flatUsage"
                  placeholder="Lease Type"
                  selectArr={leaseTypeList}
                  required
                />
                <SelectField
                  label="Property Name"
                  name="buildingType"
                  placeholder="Property Name"
                  selectArr={buildingsName?.rows || []}
                  required
                  selectedName="name_en"
                  selectedValue="id"
                />
              </div>
              <div className="inputWidth">
                <SelectField
                  label="Unit Number"
                  name="flatId"
                  placeholder="Unit Number"
                  disabled={buildingId ? false : true}
                  required
                  selectArr={vacantUnitData || []}
                  selectedName="name"
                  selectedValue="id"
                />
                {/* <SelectField
                  label="Sub-Unit Number"
                  name="subFlatId"
                  placeholder="Sub-Unit Number"
                  disabled={buildingId && unitId ? false : true}
                  selectArr={vacantSubUnitData || []}
                  selectedName="name_en"
                  selectedValue="id"
                /> */}
              </div>
              <div className="inputWidth text-base font-semibold">
                <h2>Owner Details</h2>
                <h2
                  className="cursor-pointer text-[#229EEF]"
                  onClick={() => handleOpenModal('owner')}
                >
                  <PlusOutlined /> Add New Owner
                </h2>
              </div>
              <div className="inputWidth">
                <SelectField
                  label="Owner"
                  name="ownerId"
                  placeholder={'Owner Name'}
                  selectArr={masterUserData || []}
                  selectedName="name"
                  selectedValue="id"
                  required
                  disabled={unitId ? false : true}
                />
                <InputField
                  label="Owner Email"
                  name="ownerEmail"
                  placeholder={'Owner Email'}
                  disabled
                />
              </div>
              <div className="inputWidth">
                <InputField
                  label="Owner Mobile Number"
                  name="ownerMobileNumber"
                  placeholder={'Owner Mobile Number'}
                  disabled
                />
              </div>
              <div className="inputWidth text-base font-semibold">
                <h2>Tenant Details</h2>
                {selectedTenant ? (
                  ''
                ) : (
                  <h2
                    className="cursor-pointer text-[#229EEF]"
                    onClick={() => handleOpenModal('tenant')}
                  >
                    <PlusOutlined /> Add New Tenant
                  </h2>
                )}
              </div>
              {selectedTenant ? (
                <div className="inputWidth">
                  <InputField
                    label="Tenant Name"
                    name="tenantName"
                    placeholder="Tenant Name"
                    required
                    disabled
                  />
                  <InputField
                    label="Tenant Mobile Number"
                    name="tenantMobile"
                    placeholder="poc@gmail.com"
                    disabled
                  />
                </div>
              ) : (
                <div className="inputWidth">
                  <SelectField
                    label="Tenant Name"
                    name="masterUserId"
                    placeholder="Tenant Name"
                    selectArr={rows}
                    required
                    selectedName="name"
                    selectedValue="id"
                  />
                  <InputField
                    label="Tenant Email ID"
                    name="tenantEmail"
                    placeholder="poc@gmail.com"
                    disabled
                  />
                </div>
              )}
              {selectedTenant ? (
                ''
              ) : (
                <div className="inputWidth">
                  <InputField
                    label="Tenant Mobile Number"
                    name="tenantMobileNumber"
                    placeholder="Tenant Mobile Number"
                    disabled
                  />
                </div>
              )}
              <Title level={5}>Allocated Details</Title>
              <AddAllocatedDetails {...{ setAmenities, amenities, unitId }} />
              <div className="mt-6 w-full">
                <Title level={5}>Lease Details</Title>
                <div className="inputWidth">
                  <DateField
                    label="Contract Start Date"
                    name="startDate"
                    acceptAllDate={true}
                    required
                  />
                  <DateField
                    label="Contract End Date"
                    name="endDate"
                    isDisabledDate={true}
                    disabledDate={disableBeforeContractStartDate}
                    required
                  />
                </div>
                <div className="inputWidth">
                  <DateField
                    label="Move In Date"
                    name="moveInDate"
                    acceptAllDate={true}
                  />
                  <DateField
                    label="Move Out Date"
                    name="moveOutDate"
                    isDisabledDate={true}
                    disabledDate={disableBeforeMOveInDate}
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Contract Period (in Months)"
                    name="periodMonth"
                    type
                    placeholder="Contract Period"
                    disabled
                  />
                </div>
                <div className="my-3 flex gap-4">
                  <Title level={5}>Payment Details</Title>
                  <Switch
                    style={{
                      backgroundColor: showPaymentDetails ? '#007FFF' : 'grey',
                    }}
                    onChange={handlePaymentDetailsToggle}
                  />
                </div>
                {showPaymentDetails && (
                  <>
                    <div className="inputWidth">
                      <SelectField
                        label="Select Your Currency"
                        name="currency"
                        placeholder="Select Your Currency"
                        selectArr={currencyList}
                        selectedName="country"
                        secondName="currency_code"
                        selectedValue="currency_code"
                        required={showPaymentDetails}
                      />
                      <InputField
                        label={`Rent Amount ( in ${
                          currency?.toUpperCase() ?? 'currency'
                        } )`}
                        name="rentAmount"
                        placeholder="Rent Amount"
                        type
                        min={0}
                        required={showPaymentDetails}
                      />
                    </div>
                    <div className="inputWidth">
                      <InputField
                        label={`Lease Activation Fee ( in ${
                          currency?.toUpperCase() ?? 'currency'
                        } )`}
                        name="activationFee"
                        placeholder="Lease Activation Fee"
                        type
                        min={0}
                      />
                      <InputField
                        label={`Security Deposit ( in ${
                          currency?.toUpperCase() ?? 'currency'
                        } )`}
                        name="securityDeposit"
                        placeholder="Security Deposit"
                        type
                        min={0}
                        required={showPaymentDetails}
                      />
                    </div>
                    <div className="inputWidth">
                      <SelectField
                        label="Payment Mode"
                        name="paymentMode"
                        placeholder="Payment Mode"
                        selectArr={paymentModes}
                        required={showPaymentDetails}
                      />
                      <SelectField
                        label="Payment Frequency"
                        name="paymentFrequency"
                        placeholder="Payment Frequency"
                        selectArr={paymentFrequency}
                        required={showPaymentDetails}
                      />
                    </div>
                  </>
                )}
                <div className="inputWidth">
                  <InputField
                    label="Notice Period (in Months)"
                    name="noticePeriod"
                    type
                    min={0}
                    placeholder="Notice Period"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Description"
                    name="description"
                    placeholder="Enter Description..."
                    width="43rem"
                  />
                </div>
                {showPaymentDetails && (
                  <RentalDiscount
                    {...{
                      discountApplicability,
                      isDiscountRequired,
                      discountType,
                      rentAmount,
                      currency,
                      securityDeposit,
                      discountValue,
                      discountValueWidth: '18rem',
                      totalAmountWidth: '18rem',
                    }}
                  />
                )}
              </div>
              <div className="w-full">
                <Title level={5}>Terms & Conditions</Title>
                {showTermsForm ? (
                  <Form.List name="terms">
                    {(fields, { add, remove }) => (
                      <>
                        {fields?.map(({ key, name, ...restField }, index) => (
                          <Form.Item key={key}>
                            <div
                              className={`${
                                index !== 0 ? '-mt-6' : ''
                              } inputWidth`}
                            >
                              <div className="flex items-center gap-2">
                                <span className="-mt-5 rounded-full border border-black px-[6px]">
                                  {index + 1}
                                </span>
                                <InputField
                                  name={[name, 'description']}
                                  placeholder="Write term & condition here"
                                  width="40rem"
                                  restField={restField}
                                  required
                                  max={200}
                                />
                              </div>
                              <div
                                className="cursor-pointer text-[#E20F0F]"
                                onClick={() => remove(name)}
                              >
                                <MinusCircleOutlined />
                              </div>
                            </div>
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <h1
                            onClick={() => add('')}
                            className={`${
                              fields?.length ? '-mt-5' : ''
                            } cursor-pointer text-[16px] text-[#229EEF] underline`}
                          >
                            <PlusOutlined /> Add Terms & Conditions
                          </h1>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                ) : (
                  <Button
                    type="text"
                    onClick={handleAddNewTerms}
                    disabled={unitId ? false : true}
                    className="flex items-center text-[16px] text-[#229EEF] underline"
                  >
                    <PlusOutlined /> Add Allocated Items
                  </Button>
                )}
              </div>
            </Row>
          </Col>
          <Col span={8} xs={{ span: 8 }} md={{ span: 8 }}>
            <Title level={5}>Attached Documents</Title>
            <DocumentUpload {...{ docList, setDocList }} />
            {duplicateOwnerListData?.amenities?.length && unitId ? (
              <div
                className="overflow-hidden"
                style={{ pointerEvents: 'none' }}
              >
                <Title level={5}>Attached Amenities</Title>
                <div className="flex flex-wrap items-center gap-3">
                  {duplicateOwnerListData?.amenities?.map((data, index) => (
                    <span className="rounded-lg border border-[#D4D4D4] bg-[#FFFFFF] p-2 shadow-sm">
                      {data}
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default AddLease;
