import { axiosInstance, baseURL } from '../AxiosClient';

export const getUserListing = async (params) => {
  const resp = await axiosInstance.get(
    `/master-users/admin`,
    {
      params,
    }
  );
  return resp;
};
export const createUser = async (values, navigateTo) => {
  const resp = await axiosInstance.post(
    `/master-users/admin`,
    values, { navigateTo }
  );
  return resp;
};
export const getUserData = async (id) => {
  const resp = await axiosInstance.get(`/master-users/admin/${id}`);
  return resp;
};
export const getMasterUserData = async () => {
  const resp = await axiosInstance.get(`/master-users/admin/list/all`);
  return resp;
};
export const editUserData = async ({ data, navigateTo, id }) => {
  const resp = await axiosInstance.patch(`/master-users/admin/${id}`, data, { navigateTo });
  return resp;
};
export const deleteUserData = async (id) => {
  const resp = await axiosInstance.delete(`/master-users/admin/${id}`);
  return resp;
};
export const getUserExportData = async (params) => {
  const resp = axiosInstance.get(`/master-users/export`, { params });
  return resp;
};
// company
export const createCompany = async (values, navigateTo) => {
  const resp = await axiosInstance.post(
    `${baseURL}/master-users/admin/companies`,
    values, { navigateTo }
  );
  return resp;
};

export const getCompanyExportData = async (params) => {
  const resp = axiosInstance.get(`/master-users/admin/companies/export`, {
    params,
  });
  return resp;
};

export const getCompanyListing = async (params) => {
  const resp = await axiosInstance.get(
    `${baseURL}/master-users/admin/companies?limit=10`,
    {
      params,
    }
  );
  return resp;
};
export const getCompanyData = async (id) => {
  const resp = await axiosInstance.get(`master-users/admin/companies/${id}`);
  return resp;
};
export const editCompanyData = async (id, data, navigateTo) => {
  const resp = await axiosInstance.patch(
    `/master-users/admin/companies/${id}`,
    data, { navigateTo }
  );
  return resp;
};
export const deleteCompanyData = async (id) => {
  const resp = await axiosInstance.delete(`master-users/admin/companies/${id}`);
  return resp;
};
