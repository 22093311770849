import { Button, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';

const AddNew = ({
  onFinish,
  handleCancelData,
  title,
  saveButtonText = 'Save Changes',
  cancelButtonText = 'Cancel',
}) => {
  return (
    <div className="flex justify-between">
      <Title level={4}>{title}</Title>
      <Space>
        <Button size="large" onClick={handleCancelData}>
          {cancelButtonText}
        </Button>
        {onFinish ? (
          <Button
            size="large"
            className="customButton"
            htmlType="submit"
            onClick={onFinish}
          >
            {saveButtonText}
          </Button>
        ) : (
          <Button
            size="large"
            className="customButton"
            htmlType="submit"
          >
            {saveButtonText}
          </Button>
        )}
      </Space>
    </div>
  );
};

export default AddNew;
