import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createHelpline,
  deleteHelplineData,
  editHelplineData,
  getHelpLineImagesData,
  getHelplineData,
  getHelplineListing,
} from 'Redux/services/helplines/Helplines';
import { initialListingState, initialState } from './InitialState';

// create
export const CreateHelplineReducer = createAsyncThunk(
  '/createHelpline',
  async (payload) => {
    const { values, navigate } = payload;
    try {
      const resp = await createHelpline(values);
      navigate('/admin/Helpline');
      return resp;
    } catch (error) {}
  }
);

// get listing page data
export const HelplineListingReducer = createAsyncThunk(
  '/helplineListing',
  async (data) => {
    try {
      const resp = await getHelplineListing(data);
      return resp?.data;
    } catch (error) {}
  }
);
//getHelplineData
export const getHelplineDataReducer = createAsyncThunk(
  'getHelplineData',
  async (id) => {
    try {
      const resp = await getHelplineData(id);
      return resp?.data?.data;
    } catch (error) {}
  }
);

//getHelplineImages
export const getHelpLineImagesReducer = createAsyncThunk(
  'getHelpLineImagesData',
  async (data) => {
    try {
      const resp = await getHelpLineImagesData(data);
      return resp?.data?.data;
    } catch (error) {}
  }
);
//editHelplineData
export const editHelplineDataReducer = createAsyncThunk(
  'editHelplineData',
  async (payload) => {
    const { navigate, values } = payload;
    try {
      const resp = await editHelplineData(values);
      navigate('/admin/Helpline?page=1');
      return resp;
    } catch (error) {}
  }
);
//deleteHelplineData
export const deleteHelplineDataReducer = createAsyncThunk(
  'deleteHelplineData',
  async (helplineId) => {
    try {
      const resp = await deleteHelplineData(helplineId);
      return resp;
    } catch (error) { }
  }
)

const HelplineSlice = createSlice({
  name: 'helplineSlice',
  initialState,
  reducers: {},

  extraReducers: {
    //addHelpline
    [CreateHelplineReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateHelplineReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isHelplineUpdated = true;
    },
    [CreateHelplineReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // HelplineListing
    [HelplineListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [HelplineListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isHelplineUpdated = false;
      state.helplineListingData = action?.payload?.data ?? initialListingState;
    },
    [HelplineListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get Helpline data
    [getHelplineDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getHelplineDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.helplineDetailData = action?.payload;
    },
    [getHelplineDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //get helpline image data
    [getHelpLineImagesReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getHelpLineImagesReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.helplineImageData = action?.payload;
      state.isHelplineUpdated = true;
    },
    [getHelpLineImagesReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //editHelplineData
    [editHelplineDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [editHelplineDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isHelplineUpdated = true;
    },
    [editHelplineDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //deleteHelplineData
    [deleteHelplineDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteHelplineDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isHelplineUpdated = true;
    },
    [deleteHelplineDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default HelplineSlice.reducer;
