import { useEffect } from 'react';
import { Navigate } from 'react-router';
import { getAdminInfo } from 'utils/UtilsIndex';

function PrivateRouteLogin({ children }) {
  const { token } = getAdminInfo();
  useEffect(() => {
    getAdminInfo();
  }, [token]);

  if (token) {
    return <Navigate to="/admin" replace />;
  }
  return children;
}

export default PrivateRouteLogin;
