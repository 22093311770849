import { Button, Space } from 'antd';

const DeleteRequest = ({
  title,
  text,
  handleSave,
  handleClose,
  anotherTitle,
}) => { 
  return (
    <div className="center flex-col p-4">
      {title && <p className="text-3xl">{title}</p>}
      <br />
      <p className="mb-8 mt-[-0.5rem] text-base">{text}</p>
      <Space className="center">
        <Button className="w-auto" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="customButton" onClick={handleSave}>
          {anotherTitle ?? 'Delete'}
        </Button>
      </Space>
    </div>
  );
};

export default DeleteRequest;
