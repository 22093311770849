import { exportCompanyData } from 'Redux/services/ExportCsv/Export';
import { CompanyListingReducer } from 'Redux/slices/property/UserSlice';
import Title from 'antd/es/typography/Title';
import { MixedHeadContent, TabComponent } from 'component/CompIndex';
import CompanyFilter from 'component/filter/CompanyFilter';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { companyTabItem } from 'staticData/UserData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const CompanyListing = () => {
  const [search, setSearch] = useState('');
  const items = companyTabItem(search);
  const dispatch = useDispatch();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [allFilters, setAllFilters] = useState({
    companyType: '',
    companyCountry: '',
    companyCityId: '',
  });

  const { isLoadAgain } = useSelector((state) => state.User);
  const { selectedBuildingName } = useSelector((state) => state.Property);

  const handleFilter = () => {
    toast('filter');
  };

  const handleExport = async () => {
    const params = {
      search,
      buildingId: selectedBuildingName,
      companyType: allFilters?.companyType,
      companyCountry: allFilters?.companyCountry,
      companyCityId: allFilters?.companyCityId,
    };
    return await exportCompanyData(params);
  };

  const handleImport = (data) => {
    const payload = {
      data,
      // id: selectedBuildingName,
    };
    // dispatch(importCsvDataReducer(payload));
  };

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      search,
      buildingId: selectedBuildingName,
      companyType: allFilters?.companyType,
      companyCountry: allFilters?.companyCountry,
      companyCityId: allFilters?.companyCityId,
    };
    dispatch(CompanyListingReducer(params));
  }, [
    defaultPageNo,
    search,
    isLoadAgain,
    dispatch,
    selectedBuildingName,
    allFilters,
  ]);

  return (
    <div>
      <div className="relative">
        <Title level={3}>My Properties</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            AddNew="Add Company"
            route="/admin/myproperties/company/add"
            {...{
              setSearch,
              handleFilter,
              handleExport,
              exportFileName: 'Company Data',
            }}
            filterComp=<CompanyFilter
              filter={allFilters}
              setFilter={setAllFilters}
            />
          />
        </div>
      </div>
      <TabComponent {...{ items }} activeTabCount={'7'} />
    </div>
  );
};

export default CompanyListing;
