import { Modal } from 'antd';
import React from 'react';

const ModalComp = ({ isModalOpen, component, width }) => {
  return (
    <>
      <Modal
        open={isModalOpen}
        closable={false}
        className="modal"
        width={width ? width : '32rem'}
        centered
        footer={null}
      >
        {component}
      </Modal>
    </>
  );
};

export default React.memo(ModalComp);
