import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AddCategory, deleteCategory, editCategory, getCategoryListing, toggleVisibility } from 'Redux/services/Category/serviceCategory';
import { initialListingState, initialState } from '../InitialState';

export const CreateServiceCategoryReducer = createAsyncThunk(
    'AddCategory',
    async (payload) => {
        const resp = await AddCategory(payload)
        return resp;
    }
)

export const ServiceCategoryListingReducer = createAsyncThunk(
    'getCategoryListing',
    async (params) => {
        const resp = await getCategoryListing(params);
        return resp?.data?.data;
    }
)

export const EditServiceCategoryReducer = createAsyncThunk(
    'editCategory',
    async (payload) => {
        const resp = await editCategory(payload);
        return resp;
    }
)

export const ToggleVisibilityReducer = createAsyncThunk(
    'toggleVisibility',
    async (id) => {
        const resp = await toggleVisibility(id);
        return resp;
    }
)

export const deleteCategoryReducer = createAsyncThunk(
    'deleteCategory',
    async (id) => {
        const resp = await deleteCategory(id);
        return resp;
    }
)

const ServiceCategorySlice = createSlice({
    name: 'serviceCategorySlice',
    initialState,
    reducers: {},
    extraReducers: {
        [CreateServiceCategoryReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [CreateServiceCategoryReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [CreateServiceCategoryReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // all service category
        [ServiceCategoryListingReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [ServiceCategoryListingReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.serviceCategoryData = action?.payload ?? initialListingState;
            state.isSuccess = true;
            state.isLoadAgain = false;
        },
        [ServiceCategoryListingReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // service category details
        [EditServiceCategoryReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [EditServiceCategoryReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [EditServiceCategoryReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // toggle category visibility
        [ToggleVisibilityReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [ToggleVisibilityReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [ToggleVisibilityReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
        // delete category
        [deleteCategoryReducer.pending]: (state, action) => {
            state.isLoading = true;
            state.isSuccess = false;
        },
        [deleteCategoryReducer.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.isLoadAgain = true;
        },
        [deleteCategoryReducer.rejected]: (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        },
    }
})

export default ServiceCategorySlice.reducer;