import { Button, Form, Row, Space } from 'antd';
import {
  DateField,
  InputField,
  SelectField,
  TextField,
} from 'component/CompIndex';
import RentalDiscount from 'component/RentalDiscount';
import { useEffect } from 'react';
import { currencyList, leaseTypeList } from 'staticData/LeaseData';
import {
  paymentFrequency,
  paymentModes,
  selectArr,
} from 'staticData/PropertyData';
import {
  addOneYearToDate,
  calculateDiscount,
  dateToDayJsObj,
  disablePastDates,
  getCustomDateFormat,
  removeUnwantedPayload,
} from 'utils/UtilsIndex';

const AcceptLoginReq = ({ requestData, handleClose, handleSave }) => {
  const [form] = Form.useForm();
  let initialObj = {};
  if (requestData) {
    initialObj = {
      startDate: dateToDayJsObj(requestData?.startDate),
      endDate: dateToDayJsObj(requestData?.contractEndDate),
      moveOutDate: dateToDayJsObj(requestData?.moveOutDate),
      paymentFrequency: requestData?.paymentFrequency,
      paymentMode: requestData?.paymentMode,
      rentAmount: '' + requestData?.rentAmount,
      flatUsage: requestData?.flatUsage,
      currency: requestData?.currency,
      noticePeriod: '' + requestData?.noticePeriod,
      securityDeposit: '' + requestData?.securityDeposit,
      activationFee: requestData?.activationFee
        ? '' + requestData?.activationFee
        : '',
    };
  }
  const currency = Form.useWatch('currency', form)?.toLowerCase();
  const startDate = Form.useWatch('startDate', form);
  const endDate = Form.useWatch('endDate', form);
  const rentAmount = Form.useWatch('rentAmount', form);
  const securityDeposit = Form.useWatch('securityDeposit', form);
  const discountValue = Form.useWatch('discountValue', form);
  const isDiscountRequired =
    Form.useWatch('isDiscountRequired', form)?.[0] || false;
  const discountType = Form.useWatch('discountType', form);
  const discountApplicability = Form.useWatch('discountApplicability', form);
  const onFinish = (values) => {
    values = removeUnwantedPayload(values, 'totalDiscount');
    values['startDate'] = getCustomDateFormat(
      values?.startDate?.$d || values?.startDate?._d,
      'YYYY-MM-DD'
    );
    values['endDate'] = getCustomDateFormat(
      values?.endDate?.$d || values?.endDate?._d,
      'YYYY-MM-DD'
    );
    values.isDiscountRequired = isDiscountRequired;
    handleSave(values);
    handleCancel();
  };
  const handleCancel = () => {
    form.resetFields();
    handleClose();
  };
  useEffect(() => {
    if ((rentAmount || securityDeposit) && discountValue) {
      form.setFieldsValue({
        totalDiscount: calculateDiscount(
          rentAmount,
          securityDeposit,
          discountApplicability,
          discountValue,
          discountType
        ),
      });
    }
  }, [rentAmount, securityDeposit, discountApplicability, discountValue, discountType, form]);
  useEffect(() => {
    if (!endDate) {
      const dateAfterOneYear = addOneYearToDate(startDate?.$d);
      form.setFieldsValue({
        endDate: dateAfterOneYear,
      });
    }
  }, [startDate]);
  useEffect(() => {
    form.setFieldsValue(initialObj);
  }, [form, requestData]);
  return (
    <div className="center flex-col p-4">
      <p className="text-3xl">Are you sure you want to approve ?</p>
      <br />
      <Form
        layout="vertical"
        size="large"
        onFinish={onFinish}
        initialValues={{
          remember: true,
        }}
        form={form}
        className="w-full"
      >
        <Row justify="space-between">
          <DateField
            label="Contract Start Date"
            name="startDate"
            width="14rem"
            isDisabledDate={true}
            disabledDate={disablePastDates}
            required
          />
          <DateField
            label="Contract End Date"
            name="endDate"
            width="14rem"
            isDisabledDate={true}
            disabledDate={disablePastDates}
            required
          />
          <SelectField
            label="Lease Type"
            name="flatUsage"
            placeholder="Lease Type"
            selectArr={leaseTypeList}
            width="14rem"
            required
          />
          <SelectField
            label="Payment Mode"
            name="paymentMode"
            placeholder="Payment Mode"
            selectArr={paymentModes}
            width="14rem"
            required
          />
          <SelectField
            label="Payment Frequency"
            name="paymentFrequency"
            placeholder="Payment Frequency"
            selectArr={paymentFrequency}
            width="14rem"
            required
          />
          <SelectField
            label="Select Your Currency"
            name="currency"
            placeholder="Select Your Currency"
            selectArr={currencyList}
            selectedName="country"
            secondName="currency_code"
            selectedValue="currency_code"
            width="14rem"
            required
          />
          <InputField
            label={`Lease Activation Fee ( in ${
              currency?.toUpperCase() ?? 'currency'
            } )`}
            name="activationFee"
            type
            min={0}
            placeholder="Lease Activation Fee"
            selectArr={selectArr}
            width="14rem"
          />
          <InputField
            label={`Security Deposit ( in ${
              currency?.toUpperCase() ?? 'currency'
            } )`}
            name="securityDeposit"
            type
            min={0}
            placeholder="Security Deposit"
            width="14rem"
            required
          />
          <InputField
            label={`Payment Rent Amount ( in ${
              currency?.toUpperCase() ?? 'currency'
            } )`}
            name="rentAmount"
            type
            min={0}
            placeholder="Rent Amount"
            selectArr={selectArr}
            width="14rem"
            required
          />
          <InputField
            label="Notice Period (in Months)"
            name="noticePeriod"
            type
            min={0}
            placeholder="Notice Period"
            selectArr={selectArr}
            width="14rem"
          />
          {requestData && (
            <TextField
              label="Description..."
              name="description"
              minRows={2}
              maxRows={3}
              width="30rem"
            />
          )}
        </Row>
        <RentalDiscount
          {...{
            discountApplicability,
            isDiscountRequired,
            discountType,
            rentAmount,
            currency,
            securityDeposit,
            discountValue,
            isForRenewal: requestData ? true : false,
          }}
        />
        <Space className="center">
          <Button onClick={handleCancel} className="h-10">
            Cancel
          </Button>
          <Button
            className="customButton h-10 w-[6rem] px-10"
            htmlType="submit"
          >
            Save
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default AcceptLoginReq;
