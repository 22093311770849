import {
  cityNameReducer,
  countryNameReducer,
} from 'Redux/slices/property/PropertySlice';
import { CreateUserReducer } from 'Redux/slices/property/UserSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  DocumentUpload,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { companyTypeArr } from 'staticData/UserData';
import { addCountryInMob, defaultCountryCode } from 'utils/UtilsIndex';

const AddCompany = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  //for document
  const [docList, setDocList] = useState([]);
  const [form] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', form);
  const pocCountryCode = Form.useWatch('pocCountryCode', form);
  const alternateCountryCode = Form.useWatch('alternateCountryCode', form);
  const companyCountry = Form.useWatch('companyCountry', form);
  const { countryNameData, cityNameData } = useSelector(
    (state) => state.Property
  );
  useEffect(() => {
    if (countryNameData.length < 1) dispatch(countryNameReducer());

    if (companyCountry) {
      dispatch(cityNameReducer(companyCountry));
    }
  }, [companyCountry]);

  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'countryCode',
      'mobileNumber',
      'pocCountryCode',
      'pocMobileNumber',
      'alternateCountryCode',
      'alternateMobileNumber'
    );
    values.documents = docList;
    if (fileList?.[0]?.url) {
      values.profilePicture = fileList?.[0]?.url;
    }
    dispatch(
      CreateUserReducer({
        values,
        navigateTo: { navigate, url: routeObj?.company },
        isForCompany: true,
      })
    );
  };
  const handleCancelData = () => {
    navigate('/admin/myproperties/company?page=1');
  };
  useEffect(() => {
    const initialValues = {
      pocCountryCode: defaultCountryCode,
      countryCode: defaultCountryCode,
      alternateCountryCode: defaultCountryCode,
      userType: 'Company',
    };
    form.setFieldsValue(initialValues);
  }, [form]);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      initialValues={{
        remember: true,
      }}
      form={form}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New Company"
        saveButtonText="Add Company"
      />
      <Row justify="space-between">
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Title level={5}>Company Details</Title>
          <Row justify="space-between">
            <div className="inputWidth">
              <InputField
                label="Company Name"
                name="name"
                placeholder="Jack Dem"
                message="This field is required"
                required
              />
              <InputField
                label="Company Email ID"
                name="email"
                placeholder="xyz@company.com"
                required
                pattern={/.*@.*/}
              />
            </div>
            <div className="inputWidth">
              <MobileNumber
                label1="Phone Number"
                countryCodeName="countryCode"
                mobilePlaceHolder="Enter Phone Number"
                mobileNumberName="mobileNumber"
                required
                countryCode={countryCode}
              />
              <SelectField
                label="Company Type"
                name="companyType"
                placeholder="Company Type"
                selectArr={companyTypeArr}
              />
            </div>
            <div className="inputWidth">
              <SelectField
                label="Country"
                name="companyCountry"
                placeholder="Select Country"
                selectArr={countryNameData}
              />
              <SelectField
                label="City"
                name="companyCityId"
                placeholder="Select City"
                selectArr={cityNameData}
                selectedValue="id"
                selectedName="name_en"
                disabled={!companyCountry}
              />
            </div>
            <div className="inputWidth">
              <InputField
                label="License Number / ID Number"
                name="licenseNumber"
                placeholder="Enter License Number / ID Number"
                message="This field is required"
              />
              <InputField
                label="Trade License"
                name="tradeLicense"
                placeholder="Jack Dem"
              />
            </div>
            <Title level={5}>Point of Contact Details</Title>
            <div className="inputWidth">
              <InputField
                label="POC Name"
                name="pocName"
                placeholder="Jack Dem"
                message="This field is required"
                width="50rem"
                required
              />
            </div>
            <div className="inputWidth">
              <InputField
                label="POC Email ID"
                name="pocEmail"
                placeholder="poc@email.com"
                required
                pattern={/.*@.*/}
              />
              <InputField
                label="Alternate Email (Optional)"
                name="alternateEmail"
                placeholder="poc@email.com"
                pattern={/.*@.*/}
              />
            </div>
            <div className="inputWidth">
              <MobileNumber
                label1="Phone Number"
                countryCodeName="pocCountryCode"
                mobilePlaceHolder="Enter Your Number"
                mobileNumberName="pocMobileNumber"
                required
                countryCode={pocCountryCode}
              />
              <MobileNumber
                label1="Alternate Phone Number (Optional)"
                countryCodeName="alternateCountryCode"
                mobilePlaceHolder="Alternate Phone Number (Optional)"
                mobileNumberName="alternateMobileNumber"
                countryCode={alternateCountryCode}
              />
            </div>
            <Title level={5}>Bank Details</Title>
            <div className="inputWidth">
              <InputField
                label="Account Holder Name"
                name="accountHolderName"
                placeholder="Jack Dem"
              />
              <InputField
                label="Bank Name"
                name="bankName"
                placeholder="Jack Dem"
              />
            </div>
            <div className="inputWidth">
              <InputField
                label="Account Number/IBAN"
                name="accountNumber"
                placeholder="Jack Dem"
              />
              <InputField
                label="Swift Code"
                name="swiftCode"
                placeholder="Jack Dem"
              />
            </div>
          </Row>
        </Col>
        <Col span={7} xs={{ span: 7 }} md={{ span: 7 }}>
          <Title level={5}>Add Profile Images</Title>
          <ImageUpload
            {...{
              previewOpen,
              setPreviewOpen,
              previewImage,
              setPreviewImage,
              previewTitle,
              setPreviewTitle,
              fileList,
              setFileList,
              length: 1,
              multiple: false,
            }}
          />
          <Title level={5}>Attach Documents</Title>
          <DocumentUpload {...{ docList, setDocList }} />
        </Col>
      </Row>
    </Form>
  );
};

export default AddCompany;
