import {
  GetCompanyDataReducer,
  editCompanyDataReducer,
} from 'Redux/slices/property/UserSlice';
import { Col, Form, Row } from 'antd';
import {
  DocumentUpload,
  EditTopChanger,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { companyTypeArr } from 'staticData/UserData';
import {
  addCountryInMob,
  defaultCountryCode,
  getIdFromUrl,
  removeCountryCode,
} from 'utils/UtilsIndex';

const EditCompanyForm = () => {
  const { companyDetailData } = useSelector((state) => state.User);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [handleEditMode, setHandleEditMode] = useState({
    companyDetails: false,
    personalDetails: false,
    bankDetails: false,
    documentDetails: false,
  });
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState([]);

  //for document
  const [docList, setDocList] = useState([]);

  const [initial] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', initial);
  const pocCountryCode = Form.useWatch('pocCountryCode', initial);
  const alternateCountryCode = Form.useWatch('alternateCountryCode', initial);

  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'countryCode',
      'mobileNumber',
      'pocCountryCode',
      'pocMobileNumber',
      'alternateCountryCode',
      'alternateMobileNumber'
    );
    values.documents = docList;
    if (fileList?.[0]?.url ?? null) {
      values.profilePicture = fileList?.[0]?.url;
    }
    dispatch(
      editCompanyDataReducer({
        values,
        id,
      })
    );
    setHandleEditMode({
      companyDetails: false,
      personalDetails: false,
      bankDetails: false,
      documentDetails: false,
    });
  };

  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    dispatch(GetCompanyDataReducer(id));
  }, [dispatch, id]);

  useEffect(() => {
    var initialObj = {
      name: companyDetailData?.name,
      tradeLicense: companyDetailData?.tradeLicense,
      licenseNumber: companyDetailData?.licenseNumber,
      companyType: companyDetailData?.companyType,
      countryCode: companyDetailData?.countryCode || defaultCountryCode,
      mobileNumber: removeCountryCode(
        companyDetailData?.mobileNumber,
        companyDetailData?.countryCode
      ).number,
      pocName: companyDetailData?.companyPoc?.name,
      pocCountryCode:
        companyDetailData?.companyPoc?.countryCode || defaultCountryCode,
      pocMobileNumber: removeCountryCode(
        companyDetailData?.companyPoc?.mobileNumber,
        companyDetailData?.companyPoc?.countryCode
      ).number,
      alternateCountryCode:
        companyDetailData?.alternateContact?.countryCode || defaultCountryCode,
      alternateMobileNumber: removeCountryCode(
        companyDetailData?.alternateContact?.mobileNumber,
        companyDetailData?.alternateContact?.countryCode
      ).number,
      email: companyDetailData?.email,
      alternateEmail: companyDetailData?.alternateContact?.email,
      accountHolderName: companyDetailData?.bankDetails?.accountHolderName,
      accountNumber: companyDetailData?.bankDetails?.accountNumber,
      bankName: companyDetailData?.bankDetails?.bankName,
      swiftCode: companyDetailData?.bankDetails?.swiftCode,
    };
    initial.setFieldsValue(initialObj);
    if (companyDetailData?.profilePicture) {
      setDefaultFileList([companyDetailData?.profilePicture]);
    }
  }, [initial, companyDetailData]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      preserve={true}
      className="w-full"
      form={initial}
    >
      <Row justify="space-between" gutter={10}>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="companyDetails"
                title="Company Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                style={{
                  pointerEvents: !handleEditMode.companyDetails ? 'none' : '',
                }}
                className="p-4"
              >
                <ImageUpload
                  {...{
                    previewOpen,
                    setPreviewOpen,
                    previewImage,
                    setPreviewImage,
                    previewTitle,
                    setPreviewTitle,
                    fileList,
                    setFileList,
                    length: 1,
                    isDetailPage: true,
                    isUserImage: !companyDetailData?.profilePicture,
                    showUploadButton: handleEditMode.companyDetails,
                    defaultFileList,
                  }}
                  circleImage="true"
                />
                <InputField
                  label="Company Name"
                  name="name"
                  placeholder="Jack Dem"
                  message="This field is required"
                  required="true"
                  width="23rem"
                />
                <InputField
                  label="License Number / ID Number"
                  name="licenseNumber"
                  placeholder="Jack Dem"
                  message="This field is required"
                  required="true"
                  width="23rem"
                />
                <SelectField
                  label="Company Type"
                  name="companyType"
                  placeholder="Company Type"
                  selectArr={companyTypeArr}
                  width="23rem"
                />
              </div>
            </div>
          </Row>
        </Col>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="personalDetails"
                title="Personal Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.personalDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="Company Email ID"
                    name="email"
                    placeholder="xyz@company.com"
                    required
                    width="15rem"
                    pattern={/.*@.*/}
                  />
                  <MobileNumber
                    label1="Phone Number"
                    countryCodeName="countryCode"
                    mobilePlaceHolder="Enter Phone Number"
                    mobileNumberName="mobileNumber"
                    required
                    width="15rem"
                    countryCode={countryCode}
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Trade License"
                    name="tradeLicense"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                  <InputField
                    label="POC Name"
                    name="pocName"
                    placeholder="Jack Dem"
                    message="This field is required"
                    required="true"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <MobileNumber
                    label1="Phone Number"
                    countryCodeName="pocCountryCode"
                    mobilePlaceHolder="Enter Your Number"
                    mobileNumberName="pocMobileNumber"
                    countryCode={pocCountryCode}
                    required
                    width="15rem"
                  />
                  <InputField
                    label="POC Email ID"
                    name="email"
                    placeholder="poc@email.com"
                    pattern={/.*@.*/}
                    required="true"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <MobileNumber
                    label1="Alternate Phone Number"
                    countryCodeName="alternateCountryCode"
                    mobilePlaceHolder="Alternate Phone Number"
                    mobileNumberName="alternateMobileNumber"
                    width="15rem"
                    countryCode={alternateCountryCode}
                  />
                  <InputField
                    label="Alternate Email (Optional)"
                    name="alternateEmail"
                    placeholder="poc@email.com"
                    pattern={/.*@.*/}
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" gutter={10}>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="bankDetails"
                title="Bank Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.bankDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="Account Holder Name"
                    name="accountHolderName"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                  <InputField
                    label="Bank Name"
                    name="bankName"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Account Number/IBAN"
                    name="accountNumber"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                  <InputField
                    label="Swift Code"
                    name="swiftCode"
                    placeholder="Jack Dem"
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={10} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="documentDetails"
                title="Attached Documents"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <DocumentUpload
                {...{
                  docList,
                  setDocList,
                  isDetailPage: true,
                  showUploadButton: handleEditMode.documentDetails,
                  defaultFileList: companyDetailData?.documents,
                }}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default EditCompanyForm;
