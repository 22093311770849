import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { useLocation } from 'react-router-dom';

function AccessRoute({ children, label }) {
  const { sideBarData } = useSelector((state) => state?.Basic?.profileData);
  const { pathname } = useLocation();
  let IsValidUser = false;
  if (sideBarData?.includes(label)) {
    IsValidUser = true;
  }

  if (!IsValidUser) {
    return <Navigate to={pathname} replace />;
  }
  return children;
}

export default AccessRoute;
