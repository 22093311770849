import { loginReducer } from 'Redux/slices/loginSlice';
import { Button, Checkbox, Form, Input, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import { homeTherapyLogo, logoLivo } from 'assets/AssetIndex';
import { requestPermission } from '../firebase/Config';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAdminInfo } from 'utils/UtilsIndex';
import { Helmet } from 'react-helmet';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isSuccess } = useSelector((state) => state.Login);
  const { token } = getAdminInfo();
  const onFinish = (values) => {
    dispatch(loginReducer({ values, navigate }));
  };
  const isProjectLivo =
    process.env.REACT_APP_PROJECT_NAME === 'LIVO-' ? true : false;
  useEffect(() => {
    if (isSuccess && !!token) {
      requestPermission(dispatch);
    }
  }, [isSuccess, token]);

  return (
    <div className="center h-full w-full">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {isProjectLivo ? 'Livo Dashboard' : 'Home Therapy Dashboard'}
        </title>
        <link rel="icon" href={`${isProjectLivo ?'assets/livologo.svg':'assets/homeTherapyLogo.png'}`} />
      </Helmet>
      <Space className="flex flex-col text-center" size={20}>
        <img
          src={isProjectLivo ? logoLivo : homeTherapyLogo}
          alt="logo"
          className={`${isProjectLivo ? 'h-16' : 'mt-auto h-40'}`}
          loading="lazy"
        />
        <Title level={2}>
          Sign in to {isProjectLivo ? 'Livo!' : 'Home Therapy!'}
        </Title>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            width: '22rem',
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="change w-[600px]">
            <Form.Item
              label="Email ID"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                className="customButton mt-4 h-12 w-full text-lg"
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Space>
    </div>
  );
};

export default LoginForm;
