import { Action, ListingFieldWithIcon } from 'component/CompIndex';
import {
  CompanyListingData,
  EditCompanyForm,
  UnitOwned,
  UnitTenant,
} from 'pages/myProperties/PropertyIndex';
import { Link, Navigate } from 'react-router-dom';

export const getUserTableData = (userData, handleDelete) => {
  return {
    data: userData?.length
      ? userData?.map((cur, id) => {
        return {
          key: cur?.id,
          userName: cur?.name,
          email: cur?.email,
          userID: cur?.userId,
          phoneNo: cur?.mobileNumber ? `+${cur?.mobileNumber}` : '',
          role: cur?.userType,
          tenanted: cur?.tenantedFlat,
          building: cur?.tenantedBuilding,
          appLink: 'Go to Link',
        };
      })
      : [],
    columns: [
      {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName',
        render: (text, key) => (
          <Link to={`/admin/myproperties/user/detail/${key.key}`}>
            <ListingFieldWithIcon text={text} smText={key?.email} />
          </Link>
        ),
      },
      {
        title: 'User ID',
        dataIndex: 'userID',
        key: 'userID',
      },
      {
        title: 'Phone No.',
        dataIndex: 'phoneNo',
        key: 'phoneNo',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Tenanted',
        dataIndex: 'tenanted',
        key: 'tenanted',
      },
      {
        title: 'Property',
        dataIndex: 'building',
        key: 'building',
      },
      {
        title: 'App Link',
        dataIndex: 'appLink',
        key: 'appLink',
        render: (text) => (
          <Link to="https://play.google.com/store/apps/details?id=com.livo">
            {text}
          </Link>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (text, { key, tenanted }) =>
          !tenanted ? <Action data={key} handleDelete={handleDelete} /> : '-',
      },
    ],
  };
};

export const userType = [
  {
    name: 'Resident',
    value: 'Resident',
  },
  {
    name: 'Owner',
    value: 'Owner',
  },
  {
    name: 'New User',
    value: 'New User',
  },
  {
    name: 'Resident Owner',
    value: 'Residing Owner',
  },
];


export const docTypeArr = [
  { name: 'Passport', value: 'passportImage' },
  { name: 'Government Id', value: 'govIdImage' },
];
export function companyTabItem(search) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <Navigate to="/admin/myproperties/property?page=1" />,
    },
    {
      key: '2',
      label: `Units`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: <Navigate to="/admin/myproperties/subunit?page=1" />,
    },
    {
      key: '3',
      label: `Users`,
      children: <Navigate to="/admin/myproperties/user?page=1" />,
    },
    {
      key: '7',
      label: `Company`,
      children: <CompanyListingData {...{ search }} />,
    },
    {
      key: '4',
      label: `Staff`,
      children: <Navigate to="/admin/myproperties/staff?page=1" />,
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}
export function getCompanyAllData(rows, handleDeleteModalOpen) {
  const data = rows?.length
    ? rows?.map((cur, id) => {
      return {
        key: cur?.id,
        companyId: cur?.userId,
        name: cur?.name,
        mobileNumber: cur?.mobileNumber && `+${cur?.mobileNumber}`,
        tradeLicense: cur?.tradeLicense,
        companyType: cur?.companyType,
        country: cur?.companyCountry ?? 'NA',
        city: cur?.cities?.name_en ?? 'NA',
      };
    })
    : [];
  const columns = [
    {
      title: 'Company Id',
      dataIndex: 'companyId',
      key: 'companyId',
      render: (text, key) => (
        <Link to={`/admin/myproperties/company/detail/${key?.key}`}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Trade License',
      dataIndex: 'tradeLicense',
      key: 'tradeLicense',
    },
    {
      title: 'Company Type',
      dataIndex: 'companyType',
      key: 'companyType',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, { key }) => (
        <Action data={key} handleDelete={handleDeleteModalOpen} />
      ),
    },
  ];
  return { data, columns };
}

export const companyTypeArr = [
  'Limited Liability Company',
  'Free Zone Company',
  'Sole Proprietorship',
  'Offshore Company',
  'Private Shareholding Company',
  'Mainland Company',
  'Private Joint Stock Company',
  'Foreign Company Branch',
  'General Partnership',
  'Joint Venture',
  'Civil Company',
  'Partnership Company',
  'Others',
];

export function companyDataTabItem(userDetailData) {
  const items = [
    {
      key: '1',
      label: `Company Details`,
      children: <EditCompanyForm />,
    },
    {
      key: '2',
      label: `Unit Owned`,
      children: <UnitOwned userDetailData={userDetailData} />,
    },
    {
      key: '3',
      label: `Unit Tenanted`,
      children: <UnitTenant userDetailData={userDetailData} />,
    },
  ];
  return items;
}
