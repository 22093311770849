export const initialState = {
  isLoading: false,
  isSuccess: false,
  isLoadAgain: false,
  isNewNotification: false,
  amenities: {},
  uploadedData: {},
  profileData: {},
  fcmToken: '',
  userDetails: {},
  allNotificationData: [],
  checkUnreadNotificationData: {},
  propertyListingData: {},
  propertyDetailData: {},
  localityData: [],
  countryNameData: [],
  cityNameData: [],
  buildingsName: [],
  selectedBuildingName: '',
  buildingAnalyticsData: {},
  serviceAnalyticsData: {},
  visitorAnalyticsData: {},
  visitorAnalyticsLiveData: {},
  deliveryAnalyticsData: [],
  staffListingData: {},
  staffDetailData: {},
  staffAvailabilityData: [],
  unitListingData: {},
  unitDetailData: {},
  vacantUnitData: [],
  vacantSubUnitData: [],
  unitNameData: [],
  subUnitListingData: {},
  subUnitDetailData: {},
  companyListingData: {},
  companyDetailData: {},
  userListingData: {},
  userDetailData: {},
  ownerListData: {},
  tenantData: {},
  guardListingData: {},
  guardCompanyData: [],
  guardDetailData: {},
  leaseListingData: {},
  renewalListingData: {},
  reminderListingData: {},
  leaseDetailData: {},
  leaseAnalyticsData: {},
  masterUserData: [],
  visitorListingData: {},
  visitorDetailData: {},
  visitorCategories: [],
  requestListingData: {},
  noticeListingData: {},
  noticeDetailData: {},
  helplineListingData: {},
  helplineDetailData: {},
  helplineImageData: {},
  isHelplineUpdated: false,
  serviceListingData: {},
  serviceCategoryData: {},
  adminCategoryData: [],
  userCategoryData: [],
  serviceRequestCountData: [],
  availableStaffData: {},
  serviceStatusesData: [],
  inventoryListingData: {},
  inventoryDropdownData: [],
  inventoryDetailData: {},
  serviceOverviewData: {},
  mostVisitedVisitors: {},
  userOverviewData: {},
  unitAnalyticsData: [],
  serviceBifurcationData: [],
  visitorStatsDataHour: {},
  visitorStatsDataWeek: {},
  visitorStatsDataDaily: {},
  visitorStatsData: {},
  mostRequestedData: {},
  LeaseStatsDonutData: {},
  LeaseStatusStatsData: {},
  LeaseBiCountData: {},
  tagListingData: [],
  invoiceListingData: {},
  invoiceDetailData: {},
};

export const initialListingState = {
  rows: [],
  count: 0,
};
