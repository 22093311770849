import { DeleteOutlined } from '@ant-design/icons';
import {
  CreateInvoiceReducer,
  EditInvoiceReducer,
  InvoiceDetailReducer,
} from 'Redux/slices/InvoiceSlice';
import { Button, Divider, Form } from 'antd';
import {
  AddServiceProduct,
  DataTable,
  DocumentUpload,
  EditTopChanger,
  InputField,
  InvoiceDiscount,
  ModalComp,
  SelectField,
} from 'component/CompIndex';
import TextEditor from 'component/TextEditor';
import SaveInvoice from 'component/popups/SaveInvoice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routeObj } from 'staticData/Data';
import {
  addCountryInMob,
  fetchSelectedUser,
  getCustomDateFormat,
  getIdFromUrl,
  removeCountryCode,
  removeUnwantedPayload,
} from 'utils/UtilsIndex';

const EditableRow = ({ form, ...props }) => {
  const { inventoryDropdownData: inventoryArr } = useSelector(
    (state) => state.Inventory
  );
  const selectedProduct = Form.useWatch('name', form);
  const filterSelectedProduct = () => {
    const product = inventoryArr?.filter(
      (data) => data.name_en === selectedProduct
    )?.[0];
    if (product) {
      return { rate: product?.rate };
    }
    return {};
  };
  useEffect(() => {
    const rate = filterSelectedProduct()?.rate;
    if (rate) {
      form.setFieldsValue({ rate, quantity: 1 });
    }
  }, [selectedProduct]);
  return (
    <Form form={form} component={false}>
      <tr {...props} />
    </Form>
  );
};
const EditableCell = ({
  title,
  index,
  editable,
  children,
  dataIndex,
  render,
  record,
  handleSave,
  editingKey,
  ...restProps
}) => {
  let childNode = children;
  if (editable) {
    childNode = render && render(dataIndex, record, editingKey === index);
  }
  return <td {...restProps}>{childNode}</td>;
};
const InvoiceTable = ({
  dueAmount,
  setDueAmount,
  isReceivePayment,
  setIsReceivePayment,
  invoiceForm,
  currency,
}) => {
  const { inventoryDropdownData: inventoryArr } = useSelector(
    (state) => state.Inventory
  );
  const { masterUserData } = useSelector((state) => state.User);
  const { invoiceDetailData, isLoadAgain } = useSelector(
    (state) => state.Invoice
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [form] = Form.useForm();
  const [discountForm] = Form.useForm();
  const [editingKey, setEditingKey] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState({
    value: false,
    key: null,
    payload: {},
  });
  const [handleEditMode, setHandleEditMode] = useState({
    documentDetails: false,
  });
  const [docList, setDocList] = useState([]);
  const initialData = [
    {
      key: 0,
      name: undefined,
      description: undefined,
      quantity: undefined,
      rate: undefined,
      amount: undefined,
    },
    {
      key: 1,
      name: undefined,
      description: undefined,
      quantity: undefined,
      rate: undefined,
      amount: undefined,
    },
    {
      key: 2,
      name: undefined,
      description: undefined,
      quantity: undefined,
      rate: undefined,
      amount: undefined,
    },
  ];
  const [dataSource, setDataSource] = useState(initialData);
  const defaultTermsConditions = `<ol>
      <li>
        This is a digitally generated invoice and does not required a signature.
      </li>
      <li>
        Payments can be made through the app itself, or via cheque/cash, which
        can be paid at maintenance office. For NEFT/IMPS payments, please ask
        the maintenance manager for the bank account details and then make the
        transfer.
      </li>
      <li>
        Failure to make payments by the due date may result in the imposition of
        a fine, so we request you to kindly make the payment on time.
      </li>
    </ol>`;
  const [tAndC, setTAndC] = useState(defaultTermsConditions);
  const [count, setCount] = useState(3);
  useEffect(() => {
    if (id) {
      invoiceForm.setFieldsValue({
        masterUserId: invoiceDetailData?.masterUserId,
        invoiceFor: invoiceDetailData?.invoiceFor,
        tagIds: invoiceDetailData?.tagIds ?? [],
        currency: invoiceDetailData?.currency,
        paymentMode: invoiceDetailData?.paymentMode,
        invoiceId: invoiceDetailData?.invoiceId,
        email: invoiceDetailData?.userInfo?.email,
        countryCode: invoiceDetailData?.userInfo?.countryCode,
        mobileNumber: removeCountryCode(
          invoiceDetailData?.userInfo?.mobileNumber,
          invoiceDetailData?.userInfo?.countryCode
        )?.number,
        billingAddress: invoiceDetailData?.userInfo?.billingAddress,
      });
      discountForm.setFieldsValue({
        discountType: invoiceDetailData?.discountType,
        discountValue: invoiceDetailData?.discountValue,
        taxValue: invoiceDetailData?.taxValue,
      });
      AddKeysToProducts(invoiceDetailData?.products);
      setTAndC(invoiceDetailData?.termsConditions);
    }
  }, [invoiceDetailData]);
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };
  const removeKeysFromProducts = (productData) => {
    let product = [];
    productData.forEach((elm) => {
      delete elm.key;
      if (elm.name) {
        product.push(elm);
      }
    });
    return product;
  };
  const AddKeysToProducts = (productData = []) => {
    let invoiceProducts = [];
    let key = 0;
    productData?.forEach((elm) => {
      invoiceProducts.push({ ...elm, key: key++ });
    });
    setDataSource([...invoiceProducts]);
    setCount(++key);
  };
  const toggleEdit = async (index) => {
    if (isReceivePayment) {
      return;
    }
    if (editingKey !== index) {
      try {
        await handleSave(index);
        setEditingKey(index);
      } catch (error) {}
    }
  };
  const onRow = (record, index) => ({
    onClick: () => toggleEdit(index),
  });
  const handleDelete = (event, key) => {
    event.stopPropagation();
    if (dataSource?.length <= 1) {
      setDataSource([...initialData]);
      setEditingKey(0);
      form.resetFields();
    } else {
      const newData = dataSource.filter((item) => item.key !== key);
      setDataSource(newData);
      setEditingKey(-1);
    }
  };
  const defaultColumns = [
    {
      title: '#',
      dataIndex: undefined,
      render: (value, record, index) => <div>{index + 1}.</div>,
    },
    {
      title: 'PRODUCT/SERVICE',
      dataIndex: 'name',
      render: (_, { name }, isEditable) =>
        isEditable ? (
          <SelectField
            selectArr={inventoryArr}
            name="name"
            width="13rem"
            selectedName="name_en"
            selectedValue="name_en"
            isAddNew
            AddNewDropDown={(menu) => (
              <>
                <div className="p-1">
                  <Button
                    size="middle"
                    className="customButton w-full"
                    onClick={() => setIsModalOpen(true)}
                  >
                    + New Product/Service
                  </Button>
                </div>
                <div className="relative -top-5">
                  <Divider />
                  {menu}
                </div>
              </>
            )}
            required
            placeholder="Select a product/service"
          />
        ) : (
          <div className="truncate">{name}</div>
        ),
      editable: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
      render: (_, { description }, isEditable) =>
        isEditable ? (
          <InputField name="description" width="15rem" />
        ) : (
          <div className={`mx-auto w-[22rem] truncate text-center`}>
            {description}
          </div>
        ),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      editable: true,
      render: (_, { quantity }, isEditable) =>
        isEditable ? (
          <InputField
            message="required"
            name="quantity"
            width="6rem"
            required
            type
            min={0}
          />
        ) : (
          <div className={`truncate`}>{quantity}</div>
        ),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      editable: true,
      render: (_, { rate }, isEditable) =>
        isEditable ? (
          <InputField
            name="rate"
            message="required"
            width="6rem"
            type
            required
            min={0}
          />
        ) : (
          <div className={`truncate`}>{rate}</div>
        ),
    },
    {
      title: `Amount ${currency ? `(${currency})` : ''}`,
      dataIndex: 'amount',
      editable: true,
      render: (_, { amount }) => <div className={`truncate`}>{amount}</div>,
    },
    {
      title: undefined,
      dataIndex: 'actions',
      render: (_, record) => (
        <div className=" z-10 mx-auto flex w-[1rem] items-center justify-center gap-2 text-lg">
          {dataSource?.length >= 1 && !isReceivePayment ? (
            <DeleteOutlined
              onClick={(e) => handleDelete(e, record.key)}
              className="cursor-pointer text-[#E20F0F]"
            />
          ) : null}
        </div>
      ),
    },
  ];
  const handleSave = async (newIndex) => {
    try {
      const values = await form.validateFields();
      let newData = [...dataSource];
      if (editingKey !== -1) {
        // save the previous form
        newData[editingKey] = {
          ...values,
          key: editingKey,
          amount:
            values.quantity && values.rate
              ? values?.quantity * values?.rate
              : undefined,
        };
      } else {
        form.resetFields();
      }
      if (newIndex <= dataSource?.length - 1) {
        if (newIndex !== -1 && newData?.[newIndex]?.name) {
          form.setFieldsValue({ ...newData[newIndex] });
        } else {
          form.setFieldsValue({ ...dataSource[newIndex] });
        }
      }
      if (
        newIndex === dataSource?.length - 1 ||
        newIndex === dataSource.length
      ) {
        newData[dataSource?.length] = { ...initialData[0], key: count };
        setCount(count + 1);
      }
      setDataSource(newData);
    } catch (error) {}
  };
  const handleSaveInvoice = async (discountData) => {
    try {
      let values = await invoiceForm.validateFields();
      await form.validateFields();
      values.documents = docList;
      values.name = fetchSelectedUser(
        masterUserData,
        values?.masterUserId
      )?.name;
      values.termsConditions = tAndC;
      values = { ...values, ...discountData };
      values.products = removeKeysFromProducts(dataSource);
      if (!values.products.length) {
        toast.error('Please select at least one product/service');
        return;
      }
      values.dueDate = getCustomDateFormat(values?.dueDate?.$d, 'MM-DD-YYYY');
      values.invoiceDate = getCustomDateFormat(
        values?.invoiceDate?.$d,
        'MM-DD-YYYY'
      );
      values = addCountryInMob(values, 'countryCode', 'mobileNumber');
      const { email, mobileNumber, countryCode, billingAddress, name } = values;
      values.userInfo = {
        name,
        email,
        mobileNumber,
        countryCode,
        billingAddress,
      };
      if (id) {
        values = removeUnwantedPayload(
          values,
          'taxType',
          'mobileNumber',
          'email',
          'deposit',
          'name',
          'email',
          'countryCode',
          'mobileNumber',
          'invoiceId',
          'invoiceFor',
          'billingAddress'
        );
      } else {
        values = removeUnwantedPayload(
          values,
          'taxType',
          'mobileNumber',
          'email',
          'deposit',
          'name',
          'email',
          'countryCode',
          'mobileNumber'
        );
      }
      setIsSaveModalOpen({
        key: values?.sendEmail,
        payload: values,
        value: true,
      });
    } catch (error) {
      toast.dismiss();
      toast.error('Please fill in all the required fields');
    }
  };
  const components = {
    body: {
      row: (props) => EditableRow({ ...props, form }),
      cell: (props) => EditableCell({ ...props, editingKey }),
    },
  };
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record, index) => ({
        record,
        index,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        render: col.render,
        handleSave,
      }),
    };
  });
  const handleDataTableSave = async () => {
    try {
      if (!isModalOpen) {
        await toggleEdit(-1);
      }
    } catch (error) {}
  };
  const handleSaveModal = (data) => {
    if (id) {
      const payload = {
        id,
        data,
      };
      dispatch(EditInvoiceReducer(payload));
    } else {
      const payload = {
        data,
        navigateTo: { url: routeObj?.invoices, navigate },
      };
      dispatch(CreateInvoiceReducer(payload));
    }
    handleCloseSaveModal();
  };
  const handleCloseSaveModal = () => {
    setIsSaveModalOpen({ key: null, payload: {}, value: false });
  };
  useEffect(() => {
    if (id) {
      dispatch(InvoiceDetailReducer(id));
      if (isReceivePayment) {
        setIsReceivePayment(false);
      }
    }
    return () => {
      if (handleEditMode?.documentDetails) {
        setHandleEditMode({ documentDetails: false });
      }
    };
  }, [dispatch, isLoadAgain]);
  useEffect(() => {
    form.setFieldsValue({ name: undefined });
  }, [isModalOpen]);
  return (
    <>
      <ModalComp
        {...{
          isModalOpen: isModalOpen || isSaveModalOpen?.value,
          width: '36rem',
        }}
        component={
          isModalOpen ? (
            <AddServiceProduct
              {...{
                isModalOpen,
                setIsModalOpen,
              }}
            />
          ) : (
            <SaveInvoice
              {...{
                isModalOpen: isSaveModalOpen,
                setIsModalOpen: setIsSaveModalOpen,
                isReceivePayment: isReceivePayment,
                saveModal: handleSaveModal,
                closeModal: handleCloseSaveModal,
                invoiceDetails: isSaveModalOpen?.payload,
              }}
            />
          )
        }
      />
      <div
        className="hiddenScroll h-full w-full overflow-x-auto overflow-y-hidden pb-6"
        onMouseLeave={handleDataTableSave}
      >
        <DataTable
          components={components}
          rowClassName={(record, index) =>
            index === editingKey ? 'shadow-md' : undefined
          }
          data={dataSource}
          onRow={onRow}
          columns={columns}
        />
        {!isReceivePayment && (
          <Button
            className="mt-10"
            onClick={() => handleSave(dataSource?.length)}
          >
            Add Lines
          </Button>
        )}
      </div>
      <div className="inputWidth">
        <Form
          layout="vertical"
          size="large"
          className="mt-[0.5rem] w-[50%] space-y-2"
        >
          <div className="detailPageCard w-full">
            <EditTopChanger title="Invoice Message" />
            <div className="px-2 pb-2">
              <TextEditor
                description={tAndC}
                setDescription={setTAndC}
                placeholder={'Write description....'}
                width={'w-[32rem]'}
                height={'10rem'}
                required
              />
              <p className="p-1 text-sm font-bold italic">
                This will shown up on the invoice.
              </p>
            </div>
          </div>
          <div className="detailPageCard w-full">
            <EditTopChanger
              title="Documents"
              handleEditChanges={handleEditChanges}
              {...{ setHandleEditMode, handleEditMode }}
              state={id ? 'documentDetails' : null}
              showSaveButton={false}
            />
            <div className="center py-4">
              <DocumentUpload
                {...{ docList, setDocList }}
                defaultFileList={id ? invoiceDetailData?.documents : undefined}
                showUploadButton={id ? handleEditMode?.documentDetails : true}
                isDetailPage={id ? true : false}
              />
            </div>
          </div>
        </Form>
        <div className="mt-[3rem] flex w-[45%] flex-col items-end justify-between">
          <InvoiceDiscount
            {...{
              dueAmount,
              setDueAmount,
              itemsArr: dataSource,
              depositedAmount: invoiceDetailData?.depositedAmount,
              isReceivePayment,
              handleSave: handleSaveInvoice,
              currency,
              form: discountForm,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default InvoiceTable;
