import { Action, Chip } from 'component/CompIndex';
import { SubUnitListingData } from 'pages/myProperties/PropertyIndex';
import { Link, Navigate } from 'react-router-dom';

export function SubUnitItemsData(handleDeleteModalOpen) {
  const columns = [
    {
      title: 'Sub Unit Id',
      dataIndex: 'subUnitId',
      key: 'subUnitId',
      render: (text, key) => (
        <Link to={`/admin/myproperties/subunit/detail/${key?.key}`}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Sub Unit Name',
      dataIndex: 'subUnitName',
      key: 'subUnitName',
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
    {
      title: 'Building Name',
      dataIndex: 'buildingName',
      key: 'buildingName',
    },
    {
      title: 'Unit Name',
      dataIndex: 'unitName',
      key: 'unitName',
    },
    {
      title: 'Furnishing',
      dataIndex: 'furnishing',
      key: 'furnishing',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, { key }) => {
        return (
          <Action data={key} handleDelete={() => handleDeleteModalOpen(key)} />
        );
      },
    },
  ];
  return columns;
}
export function SubUnitData(rows) {
  const data =
    rows?.length > 0
      ? rows?.map((cur, id) => {
          return {
            key: cur?.id,
            subUnitId: cur?.subFlatId,
            subUnitName: cur?.name_en,
            size: cur?.size,
            buildingName: cur?.flat?.building?.name_en,
            unitName: cur?.flat?.name_en,
            furnishing: cur?.furnishing,
          };
        })
      : [];
  return data;
}
export function subUnitItemReturn(search, setIsModalOpen, setSubUnitId) {
  const items = [
    {
      key: '1',
      label: `Properties`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '2',
      label: `Units`,
      children: <Navigate to="/admin/myproperties/unit?page=1" />,
    },
    {
      key: '6',
      label: `Sub Unit`,
      children: (
        <SubUnitListingData {...{ search, setIsModalOpen, setSubUnitId }} />
      ),
    },
    {
      key: '3',
      label: `Users`,
      children: <Navigate to="/admin/myproperties/user?page=1" />,
    },
    {
      key: '7',
      label: `Company`,
      children: <Navigate to="/admin/myproperties/company?page=1" />,
    },
    {
      key: '4',
      label: `Staff`,
      children: <Navigate to="/admin/myproperties/staff?page=1" />,
    },
    {
      key: '5',
      label: `Catalog`,
      children: <Navigate to="/admin/myproperties/Catalog?page=1" />,
    },
  ];
  return items;
}

export const getUnitData = (unitListingData, handleDelete) => {
  return {
    data: unitListingData?.length
      ? unitListingData?.map((cur, id) => {
          return {
            key: cur?.id,
            unitNo: cur?.name_en,
            propertyName: cur?.building?.name_en,
            status: cur?.flatStatus,
            type: cur?.flatType,
            size: cur?.size,
            leaseStatus: cur?.leaseStatus,
            period: cur?.period,
            owner: cur?.owner?.name,
          };
        })
      : [],
    columns: [
      {
        title: 'Unit Name',
        dataIndex: 'unitNo',
        key: 'unitNo',
        render: (text, key) => (
          <Link to={`/admin/myproperties/unit/detail/${key.key}`}>{text}</Link>
        ),
      },
      {
        title: 'Property Name',
        dataIndex: 'propertyName',
        key: 'propertyName',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, { status }) => (
          <>
            <Chip color={status === 'Vacant' ? 'red' : 'blue'} name={status} />
          </>
        ),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Size (sqft)',
        dataIndex: 'size',
        key: 'size',
      },
      {
        title: 'Lease Status',
        dataIndex: 'leaseStatus',
        key: 'leaseStatus',
        render: (_, { leaseStatus }) => (
          <>
            <Chip
              color={leaseStatus === 'Active' ? 'green' : 'red'}
              name={leaseStatus}
            />
          </>
        ),
      },
      {
        title: 'Period',
        dataIndex: 'period',
        key: 'period',
        render: (_, { period }) => (
          <div>
            {period >= 12
              ? Math.floor(period / 12) + ' Year'
              : period
              ? period + ' Month'
              : ''}
          </div>
        ),
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_, { key, owner, leaseStatus }) => {
          return !owner && leaseStatus !== 'Active' ? (
            <Action data={key} handleDelete={handleDelete} />
          ) : (
            '-'
          );
        },
      },
    ],
  };
};
export const allAmenitiesArr = ['Properties', 'Flats'];
export const unitAmenitiesList = [
  'Balcony',
  'Pets Allowed',
  'Private Pool',
  'View of Water',
  'Walk-in Closet',
  'View of Landmark',
  'Barbecue Area',
  'Mezzanine',
  'Networked',
  'Study',
  'Lobby',
  'Security',
  'Private Gym',
  'Central A/C',
  'Laundry',
  'Fenced yard',
  'Courtyard',
  'Built in Wardrobes',
  'Security alarm',
  'Kitchen Appliances',
];
