import { Form, Input, InputNumber } from 'antd';
import React from 'react';

const InputField = ({
  label,
  name,
  placeholder,
  required,
  message,
  pattern,
  width,
  min,
  max,
  type,
  disabled,
  formatter = null,
}) => {
  return (
    <>
      {type ? (
        <Form.Item
          label={label}
          name={name}
          className="p-0"
          rules={[
            {
              required: required ? true : false,
              message: message ? message : 'This field is required.',
            },
          ]}
        >
          <InputNumber
            placeholder={placeholder}
            bordered={false}
            formatter={formatter}
            style={{
              borderBottom: '1px solid #D4D4D4',
              borderRadius: '0',
              padding: '0 0 0px 0',
              width: width ? width : '18rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            disabled={disabled ? disabled : false}
            min={min}
            max={max}
          />
        </Form.Item>
      ) : (
        <Form.Item
          label={label}
          name={name}
          className="p-0"
          min="0"
          rules={[
            {
              required: required ? true : false,
              message: message ? message : 'This field is required.',
            },
            {
              pattern: pattern,
              message: 'Enter the valid format',
            },
          ]}
        >
          <Input
            placeholder={placeholder}
            bordered={false}
            style={{
              borderBottom: '1px solid #D4D4D4',
              borderRadius: '0',
              padding: '0 0 0px 0',
              width: width ? width : '18rem',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            disabled={disabled ? disabled : false}
            maxLength={max ? max : 100}
          />
        </Form.Item>
      )}
    </>
  );
};

export default React.memo(InputField);
