import { exportUserData } from 'Redux/services/ExportCsv/Export';
import {
  UserListingReducer,
  deleteUserDataReducer,
} from 'Redux/slices/property/UserSlice';
import Title from 'antd/es/typography/Title';
import {
  DeleteRequest,
  MixedHeadContent,
  ModalComp,
  TabComponent,
} from 'component/CompIndex';
import UserFilter from 'component/filter/UserFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { userTabItem } from 'staticData/PropertyData';
import { getUserTableData } from 'staticData/UserData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const UserDataListing = () => {
  const {
    userListingData: { rows, count },
    isLoadAgain,
  } = useSelector((state) => state.User);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [userId, setUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    userType: '',
    nationality: '',
    gender: '',
  });

  const handleDeleteModalOpen = (userId) => {
    setUserId(userId);
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setUserId(null);
    setIsModalOpen(false);
  };
  const handleDeleteUser = () => {
    dispatch(deleteUserDataReducer(userId));
    handleClose();
  };
  const handleFilter = () => {};
  const handleExport = async () => {
    const params = {
      search,
      userType: filter.userType,
      gender: filter.gender,
      nationality: filter.nationality,
    };
    return await exportUserData(params);
  };
  const { data, columns } = getUserTableData(rows, handleDeleteModalOpen);
  const items = userTabItem({
    columns,
    data,
    count,
    pageNo,
    setPageNo,
    searchParams,
    setSearchParam,
  });

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      limit: 10,
      search,
      userType: filter.userType,
      gender: filter.gender,
      nationality: filter.nationality,
      buildingId: selectedBuildingName,
    };
    dispatch(UserListingReducer(params));
  }, [
    defaultPageNo,
    search,
    selectedBuildingName,
    filter,
    isLoadAgain,
    dispatch,
  ]);
  return (
    <div>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <DeleteRequest
            text={`Are you sure you want to delete user?`}
            handleSave={handleDeleteUser}
            handleClose={handleClose}
          />
        }
      />
      <div className="relative">
        <Title level={3}>My Properties</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            filterComp=<UserFilter filter={filter} setFilter={setFilter} />
            AddNew={'Add User'}
            route="/admin/myproperties/user/add"
            {...{
              setSearch,
              handleFilter,
              handleExport,
              exportFileName: 'User Data',
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'3'} />
      </div>
    </div>
  );
};

export default UserDataListing;
