import { axiosInstance } from '../AxiosClient';

export const createHelpline = async (values) => {
  const resp = await axiosInstance.post(`/helplines`, values);
  return resp;
};
export const getHelplineListing = async (params) => {
  const resp = await axiosInstance.get(`/helplines/admin`, {
    params: params,
  });
  return resp;
};
export const getHelplineData = async (id) => {
  const resp = await axiosInstance.get(`/helplines/${id}`);
  return resp;
};
export const getHelpLineImagesData = async (params) => {
  const resp = await axiosInstance.get(`/images`, {
    params: params
  });
  return resp;
}

export const editHelplineData = async (data) => {
  const resp = await axiosInstance.patch(
    `/helplines/${data?.helplineId}`,
    data
  );
  return resp;
};

export const deleteHelplineData = async (id) => {
  const resp = await axiosInstance.delete(`/helplines/${id}`);
  return resp;
}
