import { PlusCircleFilled } from '@ant-design/icons';
import {
  editServiceStatusReducer,
  getServiceStatusesReducer,
} from 'Redux/slices/ServiceSlice';
import { Button, Timeline } from 'antd';
import { ModalComp } from 'component/CompIndex';
import { UpdateServiceStatus } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { statusColors } from 'staticData/ServiceData';
import { getCustomDateFormat, getIdFromUrl } from 'utils/UtilsIndex';

const StatusTimeline = ({ statusArr = [] }) => {
  const { serviceStatusesData, isLoadAgain } = useSelector(
    (state) => state.Service
  );
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const maintenanceId = getIdFromUrl(pathname);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSave = (values) => {
    values.requestId = maintenanceId;
    dispatch(editServiceStatusReducer(values));
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    dispatch(getServiceStatusesReducer(maintenanceId));
  }, [isLoadAgain, dispatch, maintenanceId]);
  return (
    <>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <UpdateServiceStatus
            {...{
              isModalOpen,
              setIsModalOpen,
              statusArr: serviceStatusesData,
              handleSave,
            }}
          />
        }
      />
      <Timeline
        items={[
          {
            dot: (
              <PlusCircleFilled
                onClick={handleOpenModal}
                className="text-4xl text-[#0C344E]"
              />
            ),
            children: (
              <Button
                onClick={handleOpenModal}
                className="customButton -mt-2 w-full"
              >
                Update Status
              </Button>
            ),
          },
          ...statusArr?.map((data, index) => {
            return {
              dot: (
                <div className="h-[0.5rem] w-[0.5rem] rounded-full bg-[#0C344E]"></div>
              ),
              color: '#0C344E',
              children: (
                <div className="pl-1">
                  <div className="flex items-center justify-between">
                    <span className="text-[#0C344E]">
                      Status Updated to &nbsp;
                      <span
                        className={`${
                          statusColors?.[data?.status]?.textColor
                        } font-semibold`}
                      >
                        {data?.status === 'Pending' ? 'Open' : data?.status}
                      </span>
                    </span>
                    <span className="text-xs font-light text-[#BDBDBD]">
                      {getCustomDateFormat(data?.createdAt, 'DD/MM, hh:mm A')}
                    </span>
                  </div>
                  <span className="font-light italic text-[#474747]">
                    {data?.comment}
                  </span>
                </div>
              ),
            };
          }),
        ]}
      />
    </>
  );
};

export default StatusTimeline;
