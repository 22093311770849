import {
  ContainerOutlined,
  CopyOutlined,
  DesktopOutlined,
  GitlabOutlined,
  IdcardOutlined,
  KeyOutlined,
  PhoneOutlined,
  PieChartOutlined,
  SettingOutlined,
  UnlockOutlined
} from '@ant-design/icons';


export const itemsSideNav = (GetItem) => {
  return [
    GetItem('Dashboard', '/admin', <PieChartOutlined />),
    GetItem(
      'My Properties',
      '/admin/myproperties/property?page=1',
      <DesktopOutlined />
    ),
    GetItem('Services', '/admin/services?page=1', <SettingOutlined />),
    GetItem('Lease Management', '/admin/lease?page=1', <KeyOutlined />),
    GetItem('Visitor Management', '/admin/visitor?page=1', <IdcardOutlined />),
    GetItem('Guard Management', '/admin/guard?page=1', <UnlockOutlined />),
    GetItem('Login Requests', '/admin/request?page=1', <ContainerOutlined />),
    GetItem('Notices', '/admin/notices', <CopyOutlined />),
    GetItem(
      'Helpline',
      '/admin/helpline',
      <PhoneOutlined className="rotate-90" />
    ),
    GetItem('Configuration', '/admin/category?page=1', <GitlabOutlined />),
    // GetItem('Invoice Management', '/admin/invoices?page=1', <GitlabOutlined />),
  ];
}


// !!!!!!!!!!!!!!!!!!!!!do not change this object
export const routeObj = {
  myproperties: '/admin/myproperties/property?page=1',
  unit: '/admin/myproperties/unit?page=1',
  subUnitDetails: '/admin/myproperties/unit/detail',
  user: '/admin/myproperties/user?page=1',
  subUnit: '/admin/myproperties/subunit?page=1',
  company: '/admin/myproperties/company?page=1',
  staff: '/admin/myproperties/staff?page=1',
  catalog: '/admin/myproperties/Catalog?page=1',
  lease: '/admin/lease?page=1',
  lead: '/admin/leads?page=1',
  visitor: '/admin/visitor?page=1',
  guard: '/admin/guard?page=1',
  notices: '/admin/notices',
  helpline: '/admin/helpline',
  request: '/admin/request?page=1',
  services: '/admin/services?page=1',
  category: '/admin/category?page=1',
  invoices: '/admin/invoices?page=1',
};

export const acceptedImages = [
  'png',
  'jpg',
  'jpeg',
  'svg',
  'svg+xml',
  'webp',
  'jfif',
];

export const acceptedDocs = [
  'pdf',
  'msword',
  'x-msi',
  'png',
  'jpg',
  'jpeg',
  'svg',
  'svg+xml',
  'webp',
  'jfif',
];
