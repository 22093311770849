import {
  DeleteInventoryReducer,
  InventoryListingReducer,
} from 'Redux/slices/property/CatalogSlice';
import { DataTable, DeleteRequest, ModalComp } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { inventoryTableData } from 'staticData/CatalogData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const InventoryListingData = ({ search }) => {
  const {
    inventoryListingData: { rows = [], count },
    isLoadAgain,
  } = useSelector((state) => state.Inventory);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [searchParams, setSearchParam] = useSearchParams();
  const [pageNo, setPageNo] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productId, setProductId] = useState(null);
  const handleDeleteModalOpen = (id) => {
    setProductId(id);
    setIsDeleteModalOpen(true);
  };
  const handleClose = () => {
    setProductId(null);
    setIsDeleteModalOpen(false);
  };
  const handleDelete = () => {
    dispatch(DeleteInventoryReducer(productId));
    handleClose();
  };
  //variables
  const { data, columns } = inventoryTableData(rows, handleDeleteModalOpen);
  //useEffect
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      buildingId: selectedBuildingName,
      search,
      limit: 10,
    };
    dispatch(InventoryListingReducer(params));
  }, [defaultPageNo, search, isLoadAgain, , selectedBuildingName]);

  return (
    <>
      <ModalComp
        {...{ isModalOpen: isDeleteModalOpen }}
        component={
          <DeleteRequest
            text={`Are you sure you want to delete?`}
            handleSave={handleDelete}
            handleClose={handleClose}
          />
        }
      />
      <div>
        <DataTable
          {...{
            columns,
            data,
            count,
            pageNo,
            setPageNo,
            searchParams,
            setSearchParam,
          }}
        />
      </div>
    </>
  );
};

export default InventoryListingData;
