import {
  GuardListingReducer,
  deleteGuardDataReducer,
  editGuardStatusReducer,
} from 'Redux/slices/GuardSlice';
import Title from 'antd/es/typography/Title';
import {
  DataTable,
  DeleteRequest,
  MixedHeadContent,
  ModalComp,
} from 'component/CompIndex';
import GuardFilter from 'component/filter/GuardFilter';
import { UpdateServiceStatus } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getLeadTableData } from 'staticData/leadData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const LeadListing = () => {
  const {
    guardListingData: { rows, count },
    isLoadAgain,
  } = useSelector((state) => state.Guard);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [guardId, setGuardId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConvertModalOpen, setIsConvertModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [allFilters, setAllFilters] = useState({
    company: '',
    status: '',
  });
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);

  const handleDeleteModalOpen = (guardId, isConvert) => {
    setGuardId(guardId);
    if (isConvert) {
      setIsConvertModalOpen(true);
    }
    setIsDeleteModalOpen(true);
  };
  const handleClose = () => {
    if (isStatusModalOpen) {
      setIsStatusModalOpen(false);
    } else {
      setIsDeleteModalOpen(false);
      setIsConvertModalOpen(false);
    }
    setGuardId(null);
  };
  const handleDeleteGuardData = () => {
    if (isStatusModalOpen) {
      dispatch(editGuardStatusReducer(guardId));
    } else {
      dispatch(deleteGuardDataReducer(guardId));
    }
    handleClose();
  };
  const handleFilter = () => {};
  const data1 = [
    {
      key: '123',
      name: 'ABC name',
      company: 'cur?.company',
      mobileNumber: `1234567890`,
      buildings: 'Tower A',
      lastLogin: '2023-12-04',
      lastLoginTime: '6:30 PM',
      status: 'Draft',
      converted: false,
    },
    {
      key: '123',
      name: 'ABC name',
      company: 'cur?.company',
      mobileNumber: `1234567890`,
      buildings: 'Tower A',
      lastLogin: '2023-12-04',
      lastLoginTime: '6:30 PM',
      status: '',
      converted: false,
    },
    {
      key: '123',
      name: 'ABC name',
      company: 'cur?.company',
      mobileNumber: `1234567890`,
      buildings: 'Tower A',
      lastLogin: '2023-12-04',
      lastLoginTime: '6:30 PM',
      status: 'Negotiation Open',
      converted: false,
    },
    {
      key: '123',
      name: 'ABC name',
      company: 'cur?.company',
      mobileNumber: `1234567890`,
      buildings: 'Tower A',
      lastLogin: '2023-12-04',
      lastLoginTime: '6:30 PM',
      status: 'Negotiation Closed',
      converted: true,
    },
  ];
  const handleEdit = () => {
    setIsStatusModalOpen(true);
  };
  const { data, columns } = getLeadTableData(
    data1,
    handleDeleteModalOpen,
    handleEdit
  );
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      // company: allFilters?.company,
      status: allFilters?.status,
      search,
      buildingId: selectedBuildingName,
    };
    dispatch(GuardListingReducer(params));
  }, [
    defaultPageNo,
    search,
    selectedBuildingName,
    allFilters,
    isLoadAgain,
    dispatch,
  ]);

  return (
    <>
      <ModalComp
        {...{ isModalOpen: isDeleteModalOpen || isStatusModalOpen }}
        component={
          isDeleteModalOpen ? (
            <DeleteRequest
              text={
                isConvertModalOpen
                  ? `Are you sure you want to convert this lead?`
                  : `Are you sure you want to delete this lead?`
              }
              handleSave={handleDeleteGuardData}
              handleClose={handleClose}
              anotherTitle={isConvertModalOpen ? 'Convert' : 'Delete'}
            />
          ) : (
            <UpdateServiceStatus
              {...{
                isModalOpen: isStatusModalOpen,
                setIsModalOpen: setIsStatusModalOpen,
                statusArr: [
                  { status: 'Draft', key: 'draft' },
                  { status: 'Negotiation Open', key: 'open' },
                  { status: 'Negotiation Closed', key: 'closed' },
                ],
                handleSave: () => {},
              }}
            />
          )
        }
      />
      <div className="relative">
        <Title level={3}>Lead Management</Title>
        <div className="absolute right-0 top-0 z-10">
          <MixedHeadContent
            filterComp=<GuardFilter
              filter={allFilters}
              setFilter={setAllFilters}
            />
            {...{
              setSearch,
              handleFilter,
            }}
          />
        </div>
        <DataTable
          {...{
            columns,
            data,
            count,
            pageNo,
            setPageNo,
            searchParams,
            setSearchParam,
          }}
        />
      </div>
    </>
  );
};

export default LeadListing;
