import { Checkbox, Collapse } from 'antd';
import { useSearchParams } from 'react-router-dom';
import {
  availabilityType,
  departmentType,
  designationType,
} from 'staticData/StaffData';

const StaffFilter = ({ filter, setFilter }) => {
  const [searchParams, setSearchParam] = useSearchParams();
  const handleFilterChange = (isChecked, name, value) => {
    if (isChecked) {
      setFilter({ ...filter, [name]: value });
    } else {
      setFilter({ ...filter, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };
  return (
    <div className="flex w-80 flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        {/* <Collapse.Panel header={<h2 className="font-bold">Availability</h2>}>
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {availabilityType?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={filter?.availability === data ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(e.target?.checked, 'availability', data)
                  }
                ></Checkbox>
                <label className="-ml-4" htmlFor={data}>
                  {data}
                </label>
              </div>
            ))}
          </div>
        </Collapse.Panel> */}
        <Collapse.Panel header={<h2 className="font-bold">Department</h2>}>
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {departmentType?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data?.value}
                  checked={filter?.department === data?.value ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(
                      e.target?.checked,
                      'department',
                      data?.value
                    )
                  }
                >
                  <label htmlFor={data?.value}>{data?.name}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel header={<h2 className="font-bold">Designation</h2>}>
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {designationType?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data?.value}
                  checked={filter?.designation === data?.value ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(
                      e.target?.checked,
                      'designation',
                      data?.value
                    )
                  }
                >
                  <label htmlFor={data?.value}>{data?.name}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default StaffFilter;
