import { RightOutlined } from '@ant-design/icons';
import {
  MostRequestedReducer,
  ServiceAnalyticsDataReducer,
  ServiceBifurcationReducer,
} from 'Redux/slices/DashboardSlice';
import { Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { totalCompleted, totalInProgress, totalOpen } from 'assets/AssetIndex';
import { DataTable } from 'component/CompIndex';
import PropertyTopCard from 'component/PropertyTopCard';
import { useEffect } from 'react';
import { default as Chart, default as ReactApexChart } from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { columnsNew } from 'staticData/ServiceData';
import { getDateFormat, overviewTotalCount } from 'utils/UtilsIndex';
import {
  donutData,
  donutServiceData,
  serviceBiData,
  serviceOverViewFunction,
} from './DynamicData';
import { ServiceRequestCountReducer } from 'Redux/slices/ServiceSlice';

const ServiceStat = ({ rows = [], startDate, endDate }) => {
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const { serviceRequestCountData } = useSelector((state) => state.Service);
  const {
    serviceAnalyticsData,
    serviceBifurcationData,
    serviceOverviewData,
    mostRequestedData,
  } = useSelector((state) => state.Dashboard);
  const dispatch = useDispatch();
  const data =
    rows?.length > 0
      ? rows?.map((cur, id) => {
          return {
            key: cur?.id,
            requestId: cur?.requestId,
            status: cur?.statusDetails?.status,
            createdOn: getDateFormat(cur?.createdAt),
            category: cur?.category.name_en,
            building: cur?.flat?.building?.name_en,
            unitNo: cur?.flat?.name_en,
            requestedFor: cur.isBuilding ? 'Public Area' : 'Unit',
          };
        })
      : [];
  const propertyTopData = [
    {
      img: totalOpen,
      title: 'Total Open',
      count: serviceAnalyticsData?.Open?.total,
      lastContent: `Last Month: ${serviceAnalyticsData?.Open?.previousMonth} Open`,
    },
    {
      img: totalInProgress,
      title: 'Total In Progress',
      count: serviceAnalyticsData?.['In-Process']?.total,
      lastContent: `Last Month: ${serviceAnalyticsData?.['In-Process']?.previousMonth} In-Progress`,
    },
    {
      img: totalCompleted,
      title: 'Total Completed',
      count: serviceAnalyticsData?.Completed?.total,
      lastContent: `Last Month: ${serviceAnalyticsData?.Completed?.previousMonth} Completed`,
    },
  ];
  useEffect(() => {
    dispatch(ServiceAnalyticsDataReducer({ buildingId: selectedBuildingName }));
  }, [dispatch, selectedBuildingName]);

  useEffect(() => {
    const params = {
      startDate,
      endDate,
      buildingId: selectedBuildingName,
    };
    dispatch(ServiceBifurcationReducer(params));
    dispatch(MostRequestedReducer(params));
  }, [startDate, endDate, selectedBuildingName, dispatch]);

  useEffect(() => {
    const params = {
      buildingId: selectedBuildingName,
      list: 'new',
    };
    dispatch(ServiceRequestCountReducer(params));
  }, [selectedBuildingName, dispatch]);

  return (
    <>
      <PropertyTopCard {...{ propertyTopData }} />
      <Row gutter={10} justify="space-between" className="my-12">
        <Col
          span={12}
          xs={{ span: 12 }}
          md={{ span: 12 }}
          className="shadowOnly"
        >
          <div className="p-2">
            <div className="inputWidth">
              <Title level={5}>Service Requests</Title>
              <Link to="/admin/services?page=1" className="text-[#008ffb]">
                View All Requests <RightOutlined />
              </Link>
            </div>
            <div className="hiddenScroll h-[18rem] overflow-x-auto">
              <DataTable {...{ columns: columnsNew, data }} />
            </div>
          </div>
        </Col>
        <Col
          span={11}
          xs={{ span: 11 }}
          md={{ span: 11 }}
          className="shadowOnly flex flex-col justify-between"
        >
          <div className="flex items-center justify-between">
            <Title level={5}>Services Overview</Title>
            <div className="flex items-center gap-1 pr-4">
              <span className="font-semibold text-[#FE8B02]">
                {serviceOverViewFunction(serviceOverviewData)?.totalRequest}
              </span>
              <span>Total Requests</span>
            </div>
          </div>
          <ReactApexChart
            options={serviceOverViewFunction(serviceOverviewData)?.options}
            series={serviceOverViewFunction(serviceOverviewData)?.series}
            type="line"
            height={250}
          />
        </Col>
      </Row>
      <Row gutter={10} justify="space-between" className="my-12">
        <Col
          span={12}
          xs={{ span: 12 }}
          md={{ span: 12 }}
          className="shadowOnly"
        >
          <div className="flex items-center justify-between">
            <Title level={5}>Service Bifurcation</Title>
            <div className="flex items-center gap-1 pr-4">
              <span className="font-semibold text-[#FE8B02]">
                {overviewTotalCount(serviceBifurcationData)}
              </span>
              <span>Overall Requests</span>
            </div>
          </div>
          <ReactApexChart
            options={serviceBiData(serviceBifurcationData).options}
            series={serviceBiData(serviceBifurcationData).series}
            type="bar"
            height={250}
          />
        </Col>
        <Col
          span={11}
          xs={{ span: 11 }}
          md={{ span: 11 }}
          className="shadowOnly"
        >
          <div className="flex items-center justify-between">
            <Title level={5}>Most Requested</Title>
            <div className="flex items-center gap-1 pr-4">
              <span className="font-semibold text-[#FE8B02]">
                {overviewTotalCount(mostRequestedData)}
              </span>
              <span>Overall Requests</span>
            </div>
          </div>
          <Chart
            options={donutData(mostRequestedData).options}
            series={donutData(mostRequestedData).series}
            type="donut"
            width="380"
            color="#FE8B02"
          />
        </Col>
      </Row>
      <Row>
        <Col
          span={11}
          xs={{ span: 11 }}
          md={{ span: 11 }}
          className="shadowOnly"
        >
          <div className="flex items-center justify-between">
            <Title level={5}>Request Overview</Title>
            <div className="flex items-center gap-1 pr-4">
              <span className="font-semibold text-[#FE8B02]">
                {/* {overviewTotalCount(mostRequestedData)} */}
              </span>
              {/* <span>Overall Requests</span> */}
            </div>
          </div>
          <Chart
            options={donutServiceData(serviceRequestCountData).options}
            series={donutServiceData(serviceRequestCountData).series}
            type="donut"
            width="380"
            color="#FE8B02"
          />
        </Col>
      </Row>
    </>
  );
};

export default ServiceStat;
