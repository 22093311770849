import { exportVisitorData } from 'Redux/services/ExportCsv/Export';
import { VisitorListingReducer } from 'Redux/slices/VisitorSlice';
import Title from 'antd/es/typography/Title';
import { Chip, DataTable, MixedHeadContent } from 'component/CompIndex';
import VisitorFilter from 'component/filter/VisitorFilter';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { columns } from 'staticData/VisitorData';
import {
  convertToDubaiTime,
  getDateFormat,
  getUrlPageNo,
  timeStampToDate,
} from 'utils/UtilsIndex';

const VisitorListing = () => {
  const {
    visitorListingData: { rows, count },
  } = useSelector((state) => state.Visitor);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();
  const { search: searchUrl } = useLocation();
  const updatedSearch = search.includes('+') ? search.replace('+', '') : search;
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [allFilters, setAllFilters] = useState({
    buildingId: '',
    flatIds: '',
    visitorType: '',
    checkInDate: '',
    checkOutDate: '',
    status: '',
  });
  const data = rows?.map((cur, id) => {
    return {
      key: cur.id,
      name: cur.name,
      mobileNumber: cur.mobileNumber,
      category: cur.category_en,
      companyName: cur.company_en ?? '-',
      building: cur.buildingName_en,
      unitNo: cur.flatName_en,
      checkedIn: cur.inTime && getDateFormat(cur.inTime),
      checkedInTime: cur.inTime && convertToDubaiTime(cur.inTime),
      checkedOut: (cur?.outTime && getDateFormat(cur.outTime)) ?? (
        <Chip name="Active" color="green" />
      ),
      checkedOutTime: (cur.outTime && convertToDubaiTime(cur.outTime)) ?? '',
    };
  });

  const handleExport = async () => {
    const updatedCheckInDate = allFilters?.checkInDate
      ? dayjs(allFilters?.checkInDate).format('YYYY-MM-DD')
      : '';
    const updatedCheckOutDate = allFilters?.checkOutDate
      ? dayjs(allFilters?.checkOutDate).format('YYYY-MM-DD')
      : '';
    const params = {
      buildingId: selectedBuildingName || allFilters.buildingId,
      search: updatedSearch,
      categoryName: allFilters?.visitorType,
      flatIds: allFilters?.flatIds,
      checkInDate: updatedCheckInDate,
      checkOutDate: updatedCheckOutDate,
      status: allFilters?.visitorStatus,
    };
    return await exportVisitorData(params);
  };
  const handleFilter = () => {};

  useEffect(() => {
    const updatedCheckInDate = allFilters?.checkInDate
      ? dayjs(allFilters?.checkInDate).format('YYYY-MM-DD')
      : '';
    const updatedCheckOutDate = allFilters?.checkOutDate
      ? dayjs(allFilters?.checkOutDate).format('YYYY-MM-DD')
      : '';
    const params = {
      page: defaultPageNo,
      search: updatedSearch,
      buildingId: selectedBuildingName || allFilters?.buildingId,

      categoryName: allFilters?.visitorType,
      flatIds: allFilters?.flatIds,
      checkInDate: updatedCheckInDate,
      checkOutDate: updatedCheckOutDate,
      status: allFilters?.visitorStatus,
    };

    dispatch(VisitorListingReducer(params));
  }, [
    defaultPageNo,
    updatedSearch,
    selectedBuildingName,
    allFilters,
    dispatch,
  ]);
  return (
    <div className="relative">
      <Title level={3}>Visitor Management</Title>
      <div className="absolute right-0 top-0 z-10">
        <MixedHeadContent
          filterComp=<VisitorFilter
            allFilters={allFilters}
            setAllFilters={setAllFilters}
          />
          {...{
            setSearch,
            handleExport,
            handleFilter,
            setSearchParam,
            exportFileName: 'Visitor Data',
          }}
        />
      </div>
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </div>
  );
};

export default VisitorListing;
