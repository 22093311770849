import {
  StaffAvailabilityReducer,
  editStaffAvailabilityReducer,
  getStaffDataReducer,
} from 'Redux/slices/property/StaffSlice';
import { DataTable, DeleteRequest, ModalComp } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { statusColors } from 'staticData/ServiceData';
import { getIdFromUrl } from 'utils/UtilsIndex';
const StaffAvailability = ({ dateFilter }) => {
  const { staffAvailabilityData, isLoadAgain } = useSelector(
    (state) => state.Staff
  );
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [weekData, setWeekData] = useState({});

  const handleStatusModalOpen = (data) => {
    if (data?.status === 'Booked') {
      toast.error('Booked slots can not be changed', { toastId: 1 });
      return;
    }
    setIsStatusModalOpen(true);
    setSelectedSlot(data);
  };
  const handleClose = () => {
    setIsStatusModalOpen(false);
    setSelectedSlot(null);
  };
  const handleStatusChange = () => {
    const payload = {
      id,
      data: {
        status:
          selectedSlot?.status === 'Available' ? 'Unavailable' : 'Available',
        staffSlots: [selectedSlot?.staffTimeSlotId],
      },
    };
    dispatch(editStaffAvailabilityReducer(payload));
    handleClose();
  };
  const setTableData = () => {
    const data = [];
    const keys = Object.keys(weekData);
    for (let i = 0; i < 24; i++) {
      const rowObj = { key: `availability_${i}` };
      keys?.forEach((day) => {
        rowObj[day] = weekData[day][i];
      });
      data.push(rowObj);
    }
    return data;
  };
  const fetchColumnTitle = (date) => {
    return (
      <div className="flex flex-col items-center justify-center">
        <span>{date ? date?.split(',')?.[0] : ''}</span>
        <span>{date ? date?.split(',')?.[1] : ''}</span>
      </div>
    );
  };
  const setColumnData = () => {
    let columns = [];
    const weeks = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    weeks?.forEach((day) => {
      if (weekData?.[day]) {
        const obj = {
          title: fetchColumnTitle(weekData?.[day]?.[0]?.startTime),
          dataIndex: day,
          key: day,
          render: (text) => {
            const { bgColor, textColor } = statusColors[text.status];
            return (
              <div
                className={`cursor-pointer whitespace-nowrap rounded-lg p-3 shadow-md ${bgColor} ${textColor}`}
                onClick={() => handleStatusModalOpen(text)}
              >
                {text?.startTime?.slice(-8)} - {text?.endTime?.slice(-8)}
              </div>
            );
          },
        };
        columns.push(obj);
      }
    });
    return columns;
  };
  const columns = setColumnData();
  const data = setTableData();
  useEffect(() => {
    if (id) {
      dispatch(
        StaffAvailabilityReducer({
          userId: id,
          date: dateFilter?.date ?? '',
          isWeekly: true,
        })
      );
    } else {
      dispatch(getStaffDataReducer(id));
    }
  }, [id, isLoadAgain, dateFilter, dispatch]);
  useEffect(() => {
    const handleWeekData = () => {
      const obj = {};
      for (let i = 0; i <= 144; i += 24) {
        if (staffAvailabilityData?.[i]) {
          const key = staffAvailabilityData?.[i]?.startTime?.split(',')?.[0];
          obj[key] = staffAvailabilityData?.slice(i, i + 24);
        }
      }
      return obj;
    };
    if (staffAvailabilityData) {
      setWeekData(handleWeekData());
    }
  }, [staffAvailabilityData]);
  return (
    <>
      <ModalComp
        {...{ isModalOpen: isStatusModalOpen }}
        component={
          <DeleteRequest
            {...{
              title: 'Are you sure ?',
              text: (
                <div className="text-center">
                  <p>
                    you want to mark status as&nbsp;
                    <span className="font-semibold">
                      {selectedSlot?.status === 'Available'
                        ? 'Unavailable'
                        : 'Available'}
                    </span>
                    &nbsp;from
                  </p>
                  <p className="font-semibold">
                    <span>{selectedSlot?.startTime?.slice(-8)}</span>
                    &nbsp;to
                    <span>{selectedSlot?.endTime?.slice(-8)}</span>
                    &nbsp;(
                    {selectedSlot?.startTime?.split(',')?.[1] +
                      ', ' +
                      selectedSlot?.startTime?.split(',')?.[2]?.split(' ')?.[1]}
                    )
                  </p>
                </div>
              ),
              anotherTitle: 'Yes',
              handleSave: handleStatusChange,
              handleClose,
            }}
          />
        }
      />
      <div className="hiddenScroll w-full overflow-auto">
        {staffAvailabilityData?.length ? (
          <DataTable {...{ data, columns }} />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default StaffAvailability;
