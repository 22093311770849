import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AddCompany,
  deleteCompany,
  editCompany,
  getCompanyListing,
  toggleVisibility,
} from 'Redux/services/Category/companyCategory';
import { initialListingState, initialState } from '../InitialState';

export const CreateCompanyCategoryReducer = createAsyncThunk(
  'AddCompany',
  async (payload) => {
    const resp = await AddCompany(payload);
    return resp;
  }
);

export const CompanyCategoryListingReducer = createAsyncThunk(
  'getCompanyListing',
  async (params) => {
    const resp = await getCompanyListing(params);
    return resp?.data?.data;
  }
);

export const EditCompanyCategoryReducer = createAsyncThunk(
  'editCompany',
  async (payload) => {
    const resp = await editCompany(payload);
    return resp;
  }
);

export const ToggleVisibilityReducer = createAsyncThunk(
  'toggleVisibility',
  async (id) => {
    const resp = await toggleVisibility(id);
    return resp;
  }
);

export const deleteCompanyReducer = createAsyncThunk(
  'deleteCompany',
  async (id) => {
    const resp = await deleteCompany(id);
    return resp;
  }
);

const CompanyCategorySlice = createSlice({
  name: 'companyCategorySlice',
  initialState,
  reducers: {},
  extraReducers: {
    [CreateCompanyCategoryReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CreateCompanyCategoryReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [CreateCompanyCategoryReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // all company category
    [CompanyCategoryListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [CompanyCategoryListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.serviceCategoryData = action?.payload ?? initialListingState;
      state.isSuccess = true;
      state.isLoadAgain = false;
    },
    [CompanyCategoryListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // company category edit
    [EditCompanyCategoryReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [EditCompanyCategoryReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [EditCompanyCategoryReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // toggle category visibility
    [ToggleVisibilityReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [ToggleVisibilityReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [ToggleVisibilityReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // delete category
    [deleteCompanyReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [deleteCompanyReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [deleteCompanyReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export default CompanyCategorySlice.reducer;
