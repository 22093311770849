import { getBuildingDataReducer } from 'Redux/slices/property/PropertySlice';
import {
  UnitListingReducer,
  importCsvDataReducer,
} from 'Redux/slices/property/UnitSlice';
import Title from 'antd/es/typography/Title';
import { MixedHeadContent, TabComponent } from 'component/CompIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIdFromUrl, getUrlPageNo } from 'utils/UtilsIndex';
import { FilterUnitListing } from '../PropertyIndex';
import PropertyDetails from './PropertyDetails';
import { exportUnitData } from 'Redux/services/ExportCsv/Export';
import UnitFilter from 'component/filter/UnitFilter';
import { toast } from 'react-toastify';

const Details = () => {
  const { propertyDetailData } = useSelector((state) => state.Property);
  const { unitListingData } = useSelector((state) => state.Unit);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search: searchUrl, pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const [showUnit, setShowUnit] = useState(false);
  const [allFilters, setAllFilters] = useState({
    flatType: '',
    contractStatus: '',
    status: '',
    ownerId: '',
  });
  const defaultPageNo = getUrlPageNo(searchUrl);
  const onChangeTab = (key) => {
    if (key === '2') {
      setShowUnit(true);
    } else {
      setShowUnit(false);
    }
  };
  const items = [
    {
      key: '1',
      label: `Property Details`,
      children: <PropertyDetails />,
    },
    {
      key: '2',
      label: `Units`,
      children: <FilterUnitListing {...{ unitListingData }} />,
    },
  ];
  const handleClick = () => {
    navigate('/admin/myproperties/unit/add');
  };

  const handleExport = async () => {
    const params = {
      buildingId: id,
      contractStatus: allFilters?.contractStatus,
      flatType: allFilters?.flatType,
      status: allFilters?.status,
      ownerId: allFilters?.ownerId,
    };
    return await exportUnitData(params);
  };
  const handleImport = (data) => {
    const payload = {
      data,
      id: id,
    };
    dispatch(importCsvDataReducer(payload));
  };
  const handleFilter = () => {
    toast('filter');
  };
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      buildingId: id,
      flatType: allFilters?.flatType,
      contractStatus: allFilters?.contractStatus,
      status: allFilters?.status,
      ownerId: allFilters?.ownerId,
    };

    if (showUnit) {
      dispatch(UnitListingReducer(params));
    }
  }, [defaultPageNo, showUnit, allFilters, dispatch, id]);

  return (
    <>
      <Title level={3}>Details </Title>
      <div className="relative flex justify-between">
        <div className="w-full">
          <TabComponent {...{ items, onChangeTab }} activeTabCount={'1'} />
        </div>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            AddNew={'Add Unit'}
            route={showUnit ? `/admin/myproperties/unit/add` : ''}
            routeState={{ buildingId: id }}
            {...{
              handleClick,
            }}
            filterComp=<UnitFilter
              filter={allFilters}
              setFilter={setAllFilters}
            />
            handleImport={showUnit ? handleImport : null}
            handleExport={showUnit ? handleExport : null}
            handleFilter={showUnit ? handleFilter : null}
            {...{
              exportFileName: `${propertyDetailData?.name_en} Unit Details`,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Details;
