import { Button } from 'antd';
import Title from 'antd/es/typography/Title';
import { cancelButton, editIcon } from 'assets/AssetIndex';
import React from 'react';

const EditTopChanger = ({
  handleEditMode,
  state,
  setHandleEditMode,
  title,
  showEditButton,
  editButtonText,
  onEditFunction,
  onCancelFunction,
  showSaveButton = true,
}) => {
  const onEditPress = () => {
    setHandleEditMode({ ...handleEditMode, [state]: true });
    if (onEditFunction) {
      onEditFunction(state, true);
    }
  };
  const onCancelPress = () => {
    setHandleEditMode({ ...handleEditMode, [state]: false });
    if (onCancelFunction) {
      onCancelFunction(state, false);
    }
  };
  return (
    <div className="detailTopStyle">
      <Title level={5}>{title}</Title>
      {state && (
        <div className="cursor-pointer">
          {!handleEditMode[state] && (
            <div onClick={onEditPress}>
              {showEditButton ? (
                <Button className="customButton w-auto">
                  {editButtonText}
                </Button>
              ) : (
                <img src={editIcon} alt="editIcon" fetchpriority="high" />
              )}
            </div>
          )}
          {handleEditMode[state] && (
            <div className="flex">
              <div className="mr-4 mt-3 h-4 w-4">
                <img
                  src={cancelButton}
                  alt="..."
                  fetchpriority="high"
                  onClick={onCancelPress}
                />
              </div>
              {showSaveButton && (
                <Button htmlType="submit" className="customButton">
                  Save
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(EditTopChanger);
