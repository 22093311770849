import { axiosInstance } from '../AxiosClient';

export const createService = async ({ data, navigateTo }) => {
  const resp = await axiosInstance.post(`/maintenances/admin/multiple`, data, { navigateTo });
  return resp;
};
export const getServiceListing = async (params) => {
  const resp = await axiosInstance.get(`/maintenances/admin`, {
    params: params,
  });
  return resp;
};
export const getServiceData = async (id) => {
  const resp = await axiosInstance.get(`/maintenances/admin/${id}`);
  return resp;
};

export const getServiceCategory = () => {
  const resp = axiosInstance.get(`/maintenances/admin/categories/all`);
  return resp;
};

export const editServiceData = async ({ data, id }) => {
  const resp = await axiosInstance.patch(`/maintenances/admin/${id}`, data);
  return resp;
};

export const uploadServiceFile = async ({ data, id }) => {
  const resp = await axiosInstance.post(`/maintenances/admin/files/${id}`, data);
  return resp;
}

export const getServiceRequestCount = async (params) => {
  const resp = await axiosInstance.get(`maintenances/admin/request-count`, {
    params: params,
  });
  return resp;
};

export const getServiceStatuses = async (id) => {
  const resp = await axiosInstance.get(`maintenances/statuses/${id}`);
  return resp;
};

export const getAvailableStaffData = async () => {
  const resp = await axiosInstance.get('staffs/admin');
  return resp;
};

export const getStaffAvailableTime = async ({ id, params }) => {
  const resp = await axiosInstance.get(`staffs/availability/${id}`, {
    params: params,
  });
  return resp;
};

export const editServiceStatus = async (data) => {
  const resp = await axiosInstance.patch(`/maintenances/complete`, data);
  return resp;
};

export const addServiceStaff = async (data) => {
  const resp = await axiosInstance.post('/maintenances/assign-staff', data);
  return resp;
};

export const editServiceStaff = async ({ id, data }) => {
  const resp = await axiosInstance.patch(
    `maintenances/admin/timings/${id}`,
    data
  );
  return resp;
};

export const getServiceExportData = async (params) => {
  const resp = await axiosInstance.get(`/maintenances/admin/export`, { params })
  return resp;
}

export const addProductInMaintenances = async ({ id, data }) => {
  const resp = await axiosInstance.post(`/maintenances/product/${id}`, data);
  return resp;
}

export const updateProductInMaintenances = async ({ id, body }) => {
  const resp = await axiosInstance.patch(`/maintenances/product/${id}`, body);
  return resp;
}

export const deleteProductInMaintenances = async ({ id }) => {
  const resp = await axiosInstance.delete(`/maintenances/product/${id}`);
  return resp;
}