import { axiosInstance } from '../AxiosClient';

export const getCompanyListing = async (params) => {
  const resp = await axiosInstance.get(`/visitors/types/all-companies`, {
    params,
  });
  return resp;
};

export const getCompanyDetail = async (id) => {
  const resp = await axiosInstance.get(`/visitors/types/all-companies/${id}`);
  return resp;
};

export const AddCompany = async (payload) => {
  const resp = await axiosInstance.post(`/visitors/types/new-company`, payload);
  return resp;
};

export const toggleVisibility = async (category_id) => {
  const resp = await axiosInstance.patch(
    `/visitors/types/company/change-visibility/`,
    { category_id }
  );
  return resp;
};

export const editCompany = async ({ category_id, ...data }) => {
  const resp = await axiosInstance.patch(`/visitors/types/update-company`, {
    category_id,
    ...data,
  });
  return resp;
};

export const deleteCompany = async (id) => {
  const resp = await axiosInstance.delete(
    `/maintenances/admin/categories/${id}`
  );
  return resp;
};
