import { PlusCircleFilled } from '@ant-design/icons';
import { Button, Timeline } from 'antd';
import React from 'react';
import { statusColors } from 'staticData/ServiceData';
import { timeStampToDate } from 'utils/UtilsIndex';

const InvoiceTimeline = ({ statusArr = [] }) => {
  const statusTimeline = [
    {
      dot: (
        <PlusCircleFilled
          // onClick={handleOpenModal}
          className="text-2xl text-[#0C344E]"
        />
      ),
      children: (
        <Button
          // onClick={handleOpenModal}
          className="customButton -mt-2 w-[9.5rem]"
        >
          Update Status
        </Button>
      ),
    },
    ...statusArr?.map((data, index) => {
      return {
        dot: (
          <div className="h-[0.5rem] w-[0.5rem] rounded-full bg-[#0C344E]"></div>
        ),
        // color: '#0C344E',
        label: (
          <span className="-ml-4 font-light italic text-[#474747]">
            {timeStampToDate(data?.createdAt, 'YYYY/MM/DD')?.formattedDate}
          </span>
        ),
        children: (
          <div className="">
            <div className="flex w-[9.5rem] flex-col items-end justify-between gap-1 text-end">
              <span
                className={`${statusColors?.['green']?.textColor} text-lg font-semibold`}
              >
                {data?.status === 'Pending' ? 'Open' : data?.status}
              </span>
              {data?.amountPaid && (
                <>
                  <span className="text-xs text-[#BDBDBD]">
                    Paid {data?.amountPaid} | {data?.paymentMode}
                  </span>
                  <span className="text-xs text-[#BDBDBD]">
                    Amount Due {data?.dueAmount}
                  </span>
                </>
              )}
              <span className="w-full text-end font-light italic text-[#474747]">
                {data?.transactionId}
              </span>
            </div>
          </div>
        ),
      };
    }),
  ];
  return (
    <div className="space-y-3 ">
      <p className="w-full text-end text-xl font-bold">Status Timeline</p>
      <div className="hiddenScroll flex max-h-[36.5rem] w-[18rem] flex-col items-start justify-start gap-y-2 overflow-auto">
        <Timeline mode="left" items={statusTimeline} className="ml-6 mt-4" />
      </div>
    </div>
  );
};

export default InvoiceTimeline;
