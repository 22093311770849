import { Button, Result } from "antd"
import React from "react"
import { useNavigate, useRouteError } from "react-router-dom"

export const ErrorBoundary = () => {
  let error = useRouteError()
  const navigate = useNavigate()
  return (
    <div>
      <Result
        status="error"
        title={error?.statusText}
        subTitle={error?.error?.message}
        extra={[
          <Button
            type="primary"
            size="large"
            onClick={() => navigate("/login")}
          >
            Go Back Home
          </Button>,
        ]}
      />
    </div>
  )
}
