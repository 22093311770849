import { MinusCircleOutlined } from '@ant-design/icons';
import { masterUserDataReducer } from 'Redux/slices/property/UserSlice';
import {
  allAmenitiesDataReducer,
  clearAmenitiesReducer,
  editUnitDataReducer,
} from 'Redux/slices/property/UnitSlice';
import { Button, Col, Form, Row } from 'antd';
import {
  CheckBoxField,
  DocumentUpload,
  EditTopChanger,
  ImageUpload,
  InputField,
  SelectField,
} from 'component/CompIndex';
import MobileNumber from 'component/coreComponent/MobileNumber';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import {
  RentalTypeArr,
  flatType,
  furnishings,
  leaseTypeArr,
} from 'staticData/PropertyData';
import { unitAmenitiesList } from 'staticData/UnitData';
import {
  addCountryInMob,
  convertToUrlArray,
  defaultCountryCode,
  findIndex,
  getIdFromUrl,
  removeCountryCode,
  removeUnwantedPayload,
} from 'utils/UtilsIndex';

const Details = ({ unitDetailData }) => {
  const { buildingsName } = useSelector((state) => state.Property);
  const { ownerListData, amenities } = useSelector((state) => state.Unit);
  const { masterUserData } = useSelector((state) => state.User);
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [handleEditMode, setHandleEditMode] = useState({
    unitDetails: false,
    ownerDetails: false,
    imagesDetails: false,
    documentDetails: false,
    amenitiesDetails: false,
    poaDetails: false,
  });
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  //for document
  const [docList, setDocList] = useState([]);

  const [initial] = Form.useForm();
  const tenantCountryCode = Form.useWatch('tenantCountryCode', initial);
  const contactCountryCode = Form.useWatch('contactCountryCode', initial);
  const poaCountryCode = Form.useWatch('poaCountryCode', initial);
  const ownerId = Form.useWatch('ownerId', initial);

  const removeNameFromAllocatedDetails = (allocateArr) => {
    if (!allocateArr?.[0]?.name) {
      return [];
    }
    return allocateArr?.map((data) => data?.name);
  };

  const convertAllocatedDetailsToObj = (allocateArr) => {
    if (!allocateArr || !allocateArr?.length) {
      return [{ name: '' }];
    }
    return allocateArr?.map((data) => {
      return { name: data };
    });
  };
  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'poaCountryCode',
      'poaMobileNumber',
      'contactCountryCode',
      'contactMobileNumber'
    );
    values = removeUnwantedPayload(
      values,
      'tenantCountryCode',
      'tenantMobileNumber',
      'tenantName',
      'tenantEmail'
    );
    values.ownerId = ownerId ?? '';
    // values.flatId = id;
    // values = removeUnwantedPayload(values,'leaseType','rentalType');
    values.documents = docList;
    values.images = convertToUrlArray(fileList);
    values.accessCards = removeNameFromAllocatedDetails(values?.accessCards);
    values.parkingLots = removeNameFromAllocatedDetails(values?.parkingLots);
    dispatch(
      editUnitDataReducer({
        id,
        data: values,
      })
    );

    setHandleEditMode({
      unitDetails: false,
      ownerDetails: false,
      imagesDetails: false,
      documentDetails: false,
      amenitiesDetails: false,
      poaDetails: false,
    });
  };

  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    dispatch(allAmenitiesDataReducer('Flats'));
    dispatch(masterUserDataReducer());
    return () => {
      dispatch(clearAmenitiesReducer());
    };
  }, [dispatch]);

  useEffect(() => {
    const initialObj = {
      name_en: unitDetailData?.name_en,
      buildingId: unitDetailData?.buildingId,
      size: unitDetailData?.size,
      flatType: unitDetailData?.flatType,
      bedroom: unitDetailData?.flatInfo?.bedroom,
      bathroom: unitDetailData?.flatInfo?.bathroom,
      tags: unitDetailData?.tags,
      furnishing: unitDetailData?.flatInfo?.furnishing,
      leaseType: unitDetailData?.flatInfo?.leaseType,
      rentalType: unitDetailData?.flatInfo?.rentalType,
      amenities: unitDetailData?.amenities || [],
      contactName: unitDetailData?.flatInfo?.primaryContact?.name,
      contactEmail: unitDetailData?.flatInfo?.primaryContact?.email,
      contactCountryCode: unitDetailData?.flatInfo?.primaryContact?.countryCode,
      contactMobileNumber: removeCountryCode(
        unitDetailData?.flatInfo?.primaryContact?.mobileNumber,
        unitDetailData?.flatInfo?.primaryContact?.countryCode
      ).number,
      ownerId: unitDetailData?.owner?.id,
      ownerEmail: unitDetailData?.owner?.email,
      // contactCountryCode: unitDetailData?.owner?.countryCode,  to do #@yogesh
      ownerMobileNumber: unitDetailData?.owner?.mobileNumber,
      floor: unitDetailData?.floor,
      tenantName: unitDetailData?.tenantDetails?.name,
      tenantEmail: unitDetailData?.tenantDetails?.email,
      tenantCountryCode:
        unitDetailData?.tenantDetails?.countryCode ?? defaultCountryCode,
      tenantMobileNumber: removeCountryCode(
        unitDetailData?.tenantDetails?.mobileNumber,
        unitDetailData?.tenantDetails?.countryCode
      ).number,
      poaName: unitDetailData?.flatInfo?.poaDetails?.name,
      poaEmail: unitDetailData?.flatInfo?.poaDetails?.email,
      poaMobileNumber: removeCountryCode(
        unitDetailData?.flatInfo?.poaDetails?.mobileNumber,
        unitDetailData?.flatInfo?.poaDetails?.countryCode
      ).number,
      poaCountryCode:
        unitDetailData?.flatInfo?.poaDetails?.countryCode ?? defaultCountryCode,
      accessCards: convertAllocatedDetailsToObj(
        unitDetailData?.flatInfo?.accessCards
      ),
      parkingLots: convertAllocatedDetailsToObj(
        unitDetailData?.flatInfo?.parkingLots
      ),
    };
    initial.setFieldsValue(initialObj);
  }, [initial, unitDetailData]);

  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      preserve={true}
      className="w-full"
      form={initial}
    >
      <Row justify="space-between">
        <Col span={13} xs={{ span: 13 }} md={{ span: 13 }}>
          <Row justify="space-between">
            <div className="detailPageCard w-[38rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="unitDetails"
                title="Unit Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.unitDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="Unit Name"
                    name="name_en"
                    placeholder="Unit Name"
                    message="This field is required"
                    required="true"
                    width="15rem"
                  />
                  <SelectField
                    label="Property Name"
                    name="buildingId"
                    placeholder="Property Name"
                    required="true"
                    selectArr={buildingsName?.rows}
                    selectedValue="id"
                    selectedName="name_en"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Unit Size (sqft)"
                    type
                    min={0}
                    name="size"
                    placeholder="Unit Size"
                    width="15rem"
                  />
                  <SelectField
                    label="Unit Type"
                    name="flatType"
                    placeholder="Unit Type"
                    required="true"
                    selectArr={flatType}
                    selectedName="name"
                    selectedValue="name"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <InputField
                    label="Number of Bedrooms"
                    name="bedroom"
                    type
                    min={0}
                    placeholder="Number of Bedrooms"
                    width="15rem"
                  />
                  <InputField
                    label="Number of Bathrooms"
                    name="bathroom"
                    type
                    min={0}
                    placeholder="Number of Bathrooms"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Furnishing"
                    name="furnishing"
                    placeholder="Furnishing"
                    selectArr={furnishings}
                    width="15rem"
                  />
                  <InputField
                    width="15rem"
                    placeholder="Floor"
                    label="Floor"
                    name="floor"
                  />
                </div>
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[38rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="allocatedDetails"
                title="Allocated Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode?.allocatedDetails
                    ? 'none'
                    : '',
                }}
              >
                <div className="inputWidth py-3">
                  <Form.List name="accessCards">
                    {(fields, { add, remove }) => {
                      return (
                        <div className="flex flex-col">
                          {fields?.map(
                            ({ key, name, ...restFields }, index) => (
                              <div
                                key={key}
                                className="inputWidth items-center"
                              >
                                <div className="w-[16rem]">
                                  <div className="flex items-center justify-between">
                                    <InputField
                                      width="15rem"
                                      label={`Access Card (${index + 1})`}
                                      name={[name, 'name']}
                                      placeholder="Access Card Number"
                                      required={fields?.length !== 1}
                                      restFields={restFields}
                                    />
                                    {fields?.length !== 1 &&
                                    handleEditMode?.allocatedDetails ? (
                                      <MinusCircleOutlined
                                        className="cursor-pointer text-[#E20F0F]"
                                        onClick={() => remove(name)}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          <div className="w-[16rem]">
                            {handleEditMode?.allocatedDetails && (
                              <Button
                                size="md"
                                className="-mt-2 w-[15rem]"
                                onClick={() => add({ name: '' })}
                              >
                                Add Access Card
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Form.List>
                  <Form.List name="parkingLots">
                    {(fields, { add, remove }) => {
                      return (
                        <div className="flex flex-col">
                          {fields?.map(
                            ({ key, name, ...restFields }, index) => (
                              <div
                                key={key}
                                className="inputWidth items-center"
                              >
                                <div className="w-[16rem]">
                                  <div className="flex items-center justify-between">
                                    <InputField
                                      width="15rem"
                                      label={`Parking Space (${index + 1})`}
                                      name={[name, 'name']}
                                      placeholder="Parking Slot Number"
                                      restFields={restFields}
                                      required={fields?.length !== 1}
                                    />
                                    {fields?.length !== 1 &&
                                    handleEditMode?.allocatedDetails ? (
                                      <MinusCircleOutlined
                                        className="cursor-pointer text-[#E20F0F]"
                                        onClick={() => remove(name)}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                          <div className="w-[16rem]">
                            {handleEditMode?.allocatedDetails && (
                              <Button
                                size="md"
                                className="-mt-2 w-[15rem]"
                                onClick={() => add({ name: '' })}
                              >
                                Add Parking Space
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  </Form.List>
                </div>
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[36rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="leaseDetails"
                title="Lease Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode?.leaseDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <SelectField
                    label="Lease type"
                    name="leaseType"
                    placeholder="Lease Type"
                    selectArr={leaseTypeArr}
                    width="15rem"
                  />
                  <SelectField
                    label="Rental Type"
                    name="rentalType"
                    placeholder="Rental Type"
                    selectArr={RentalTypeArr}
                    width="15rem"
                  />
                </div>
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[36rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="ownerDetails"
                title="Owner Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode?.ownerDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <SelectField
                    label="Owner"
                    name="ownerId"
                    placeholder={ownerListData?.name || '-'}
                    selectArr={masterUserData || []}
                    selectedName="name"
                    selectedValue="id"
                    width="15rem"
                    // requiredDyn={true}
                    // disabled={unitId ? false : true}
                  />
                  <InputField
                    label="Owner Email ID"
                    width="15rem"
                    placeholder={
                      findIndex(masterUserData, ownerId, 'email') || '-'
                    }
                    disabled
                    pattern={/.*@.*/}
                  />
                </div>
                <InputField
                  label="Owner Mobile Number"
                  placeholder={
                    findIndex(masterUserData, ownerId, 'mobileNumber') ??
                    'Owner Mobile Number'
                  }
                  width="34rem"
                  disabled
                />
              </div>
            </div>
            <div className="detailPageCard mt-8 w-[36rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="poaDetails"
                title="Power of Attorney Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode?.poaDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="POA Name"
                    name="poaName"
                    placeholder="POA Name"
                    width="15rem"
                  />
                  <InputField
                    label="POA Email"
                    name="poaEmail"
                    placeholder="POA Email"
                    pattern={/.*@.*/}
                    width="15rem"
                  />
                </div>
                <MobileNumber
                  label1="POA Phone Number"
                  countryCodeName="poaCountryCode"
                  mobilePlaceHolder="Enter POC Phone Number"
                  mobileNumberName="poaMobileNumber"
                  countryCode={poaCountryCode}
                />
              </div>
            </div>
            {unitDetailData?.flatInfo?.primaryContact?.name ? (
              <div className="detailPageCard mt-8 w-[36rem]">
                <EditTopChanger title="POC Details" />
                <div
                  className="wap"
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  <div className="inputWidth">
                    <InputField
                      label="POC Name"
                      name="contactName"
                      placeholder="POC Name"
                      width="15rem"
                    />
                    <InputField
                      label="POC Email ID"
                      name="contactEmail"
                      placeholder="poc@email.com"
                      pattern={/.*@.*/}
                      width="15rem"
                    />
                  </div>
                  <MobileNumber
                    label1="POC Phone Number"
                    countryCodeName="contactCountryCode"
                    mobilePlaceHolder="Enter POC Phone Number Number"
                    mobileNumberName="contactMobileNumber"
                    countryCode={contactCountryCode}
                    width="34rem"
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            {unitDetailData?.tenantDetails ? (
              <div className="detailPageCard mt-8 w-[36rem]">
                <EditTopChanger title="Tenant Details" />
                <div
                  className="wap"
                  style={{
                    pointerEvents: 'none',
                  }}
                >
                  <div className="inputWidth">
                    <InputField
                      label="Tenant Name"
                      name="tenantName"
                      placeholder="Tenant Name"
                      width="15rem"
                    />
                    <InputField
                      label="Tenant Email ID"
                      name="tenantEmail"
                      placeholder="poc@email.com"
                      pattern={/.*@.*/}
                      width="15rem"
                    />
                  </div>
                  <MobileNumber
                    label1="Tenant Phone Number"
                    countryCodeName="tenantCountryCode"
                    mobilePlaceHolder="Enter Your Number"
                    mobileNumberName="tenantMobileNumber"
                    width="34rem"
                    countryCode={tenantCountryCode}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
          </Row>
        </Col>
        <Col span={10} xs={{ span: 10 }} md={{ span: 10 }}>
          <div className="detailPageCard mb-8">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="imagesDetails"
              title="Add Images"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <div>
              <ImageUpload
                {...{
                  previewOpen,
                  setPreviewOpen,
                  previewImage,
                  setPreviewImage,
                  previewTitle,
                  setPreviewTitle,
                  fileList,
                  setFileList,
                  isDetailPage: true,
                  showUploadButton: handleEditMode?.imagesDetails,
                  defaultFileList: unitDetailData?.images,
                }}
              />
            </div>
          </div>
          <div className="detailPageCard">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="documentDetails"
              title="Attach Documents"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <div className="center py-4">
              <DocumentUpload
                {...{ docList, setDocList }}
                defaultFileList={unitDetailData?.documents}
                showUploadButton={handleEditMode?.documentDetails}
                isDetailPage={true}
              />
            </div>
          </div>
          <div className="detailPageCard mt-8">
            <EditTopChanger
              handleEditChanges={handleEditChanges}
              state="amenitiesDetails"
              title="Amenities Details"
              {...{ setHandleEditMode, handleEditMode }}
            />
            <div
              className="wap"
              style={{
                pointerEvents: !handleEditMode?.amenitiesDetails ? 'none' : '',
              }}
            >
              <div className="-mt-5">
                {amenities?.length ? (
                  <CheckBoxField
                    name="amenities"
                    selectedName="name"
                    selectedValue="name"
                    options={amenities}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default Details;
