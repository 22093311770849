import {
  editGuardDataReducer,
  getGuardDataReducer,
} from 'Redux/slices/GuardSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  DocumentUpload,
  EditTopChanger,
  ImageUpload,
  InputField,
  MobileNumber,
  MultiSelect,
  PasswordField,
  SelectField,
} from 'component/CompIndex';
import countries from 'layouts/localData/CountryCode';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeObj } from 'staticData/Data';
import { genderList, idType } from 'staticData/PropertyData';
import { decrypt, encrypt } from 'utils/Encryption';
import {
  addCountryInMob,
  defaultCountryCode,
  getBuildingIdFromArray,
  getIdFromUrl,
  removeCountryCode,
} from 'utils/UtilsIndex';

const GuardDetail = () => {
  const { guardDetailData } = useSelector((state) => state.Guard);
  const { buildingsName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const id = getIdFromUrl(pathname);

  const [handleEditMode, setHandleEditMode] = useState({
    guardDetails: false,
    personalDetails: false,
    buildingsDetails: false,
    documentDetails: false,
  });
  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [initial] = Form.useForm();
  const countryCode = Form.useWatch('countryCode', initial);
  const alternateCountryCode = Form.useWatch('alternateCountryCode', initial);
  const onFinish = (values) => {
    values = addCountryInMob(
      values,
      'countryCode',
      'mobileNumber',
      'alternateCountryCode',
      'alternateMobileNumber'
    );
    values.password = encrypt(values?.password);
    values.documents = docList;
    values.profilePicture = fileList?.[0]?.url ?? null;
    const payload = {
      id,
      data: { ...values },
      navigateTo: { navigate, url: routeObj?.guard },
    };
    dispatch(editGuardDataReducer(payload));
  };
  const handleEditChanges = (type) => {
    setHandleEditMode({ ...handleEditMode, [type]: true });
  };

  useEffect(() => {
    dispatch(getGuardDataReducer(id));
  }, [dispatch, id]);

  useEffect(() => {
    const initialObj = {
      name: guardDetailData?.name,
      countryCode: guardDetailData?.countryCode || defaultCountryCode,
      mobileNumber: removeCountryCode(
        guardDetailData?.mobileNumber,
        guardDetailData?.countryCode
      ).number,
      email: guardDetailData?.pocContact?.email,
      password: decrypt(guardDetailData?.password),
      company: guardDetailData?.company,
      nationality: guardDetailData?.nationality,
      gender: guardDetailData?.gender,
      documentType: guardDetailData?.documentType,
      documentId: guardDetailData?.documentId,
      userName: guardDetailData?.userName,
      buildings: getBuildingIdFromArray(guardDetailData?.guardBuildings),
      alternateCountryCode:
        guardDetailData?.alternateContact?.countryCode || defaultCountryCode,
      alternateMobileNumber: removeCountryCode(
        guardDetailData?.alternateContact?.mobileNumber,
        guardDetailData?.alternateContact?.countryCode
      ).number,
      alternateEmail: guardDetailData?.alternateContact?.email,
    };
    initial.setFieldsValue(initialObj);
    if (guardDetailData?.profilePicture) {
      setDefaultFileList([guardDetailData?.profilePicture]);
    }
  }, [initial, guardDetailData]);
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      preserve={true}
      className="w-full"
      form={initial}
    >
      <Title level={3}>Details</Title>
      <Row justify="space-between" gutter={10}>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="guardDetails"
                title="Guard Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div className="p-4">
                <ImageUpload
                  {...{
                    previewOpen,
                    setPreviewOpen,
                    previewImage,
                    setPreviewImage,
                    previewTitle,
                    setPreviewTitle,
                    showUploadButton: handleEditMode?.guardDetails,
                    setDefaultFileList,
                    defaultFileList,
                    isDetailPage: true,
                    isUserImage: !guardDetailData?.profilePicture,
                    fileList,
                    setFileList,
                    length: 1,
                    circleImage: true,
                  }}
                />
              </div>
              <div
                style={{
                  pointerEvents: !handleEditMode.guardDetails ? 'none' : '',
                }}
                className="p-4"
              >
                <InputField
                  label="Name"
                  name="name"
                  placeholder="Jack Dem"
                  required="true"
                  width="23rem"
                />
                <div className="mt-12">
                  <MobileNumber
                    label1="Phone Number"
                    countryCodeName="countryCode"
                    mobilePlaceHolder="Enter Your Number"
                    mobileNumberName="mobileNumber"
                    countryCode={countryCode}
                    required
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="personalDetails"
                title="Personal Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.personalDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <InputField
                    label="Company"
                    name="company"
                    placeholder="Concord"
                    width="15rem"
                  />
                  <InputField
                    label="POC Email ID"
                    name="email"
                    placeholder="poc@email.com"
                    pattern={/.*@.*/}
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Nationality"
                    name="nationality"
                    placeholder="Nationality"
                    selectArr={countries}
                    selectedName="label"
                    selectedValue="label"
                    width="15rem"
                  />
                  <SelectField
                    label="Gender"
                    name="gender"
                    placeholder="Gender"
                    selectArr={genderList}
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <SelectField
                    label="Id Type"
                    name="documentType"
                    placeholder="Id Type"
                    selectArr={idType}
                    width="15rem"
                  />
                  <InputField
                    label="ID Number"
                    name="documentId"
                    placeholder="123"
                    width="15rem"
                  />
                </div>
                <div className="inputWidth">
                  <MobileNumber
                    label1="Alternate Phone Number (Optional)"
                    countryCodeName="alternateCountryCode"
                    mobilePlaceHolder="Alternate Phone Number"
                    mobileNumberName="alternateMobileNumber"
                    width="15rem"
                    countryCode={alternateCountryCode}
                  />
                  <InputField
                    label="Alternate Email (Optional)"
                    name="alternateEmail"
                    placeholder="Alternate Email"
                    pattern={/.*@.*/}
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" gutter={10}>
        <Col span={13} xs={{ span: 14 }} md={{ span: 14 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard w-[36rem] ">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="buildingsDetails"
                title="Building Details"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <div
                className="wap"
                style={{
                  pointerEvents: !handleEditMode.buildingsDetails ? 'none' : '',
                }}
              >
                <div className="inputWidth">
                  <MultiSelect
                    label="Select Building"
                    placeholder="Building Name"
                    name="buildings"
                    required="true"
                    width="34rem"
                    selectArr={buildingsName?.rows || []}
                    selectedName="name_en"
                    selectedValue="id"
                  />
                </div>
                <div className="inputWidth my-4">
                  <InputField
                    label="Username"
                    name="userName"
                    placeholder="Jack Dem"
                    required="true"
                    width="15rem"
                  />
                  <PasswordField
                    label="Password"
                    name="password"
                    pattern={
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/
                    }
                    placeholder="***"
                    required="true"
                    width="15rem"
                  />
                </div>
              </div>
            </div>
          </Row>
        </Col>
        <Col span={8} xs={{ span: 10 }} md={{ span: 10 }}>
          <Row justify="space-between" className="p-2">
            <div className="detailPageCard mb-8 min-w-[25rem]">
              <EditTopChanger
                handleEditChanges={handleEditChanges}
                state="documentDetails"
                title="Attached Documents"
                {...{ setHandleEditMode, handleEditMode }}
              />
              <DocumentUpload
                {...{
                  docList,
                  setDocList,
                  defaultFileList: guardDetailData?.documents,
                  isDetailPage: true,
                  showUploadButton: handleEditMode.documentDetails,
                }}
              />
            </div>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default GuardDetail;
