import { CreateNoticeReducer } from 'Redux/slices/NoticeSlice';
import { Col, Form, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import {
  AddNew,
  DateField,
  DocumentUpload,
  InputField,
  MultiSelect,
  RadioField,
  SelectField,
} from 'component/CompIndex';
import TextEditor from 'component/TextEditor';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routeObj } from 'staticData/Data';
import {
  noticeCategories,
  targetUser,
  yesOrNoArr,
} from 'staticData/PropertyData';
import { disablePastDates, getCustomDateFormat } from 'utils/UtilsIndex';

const AddNotice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { buildingsName } = useSelector((state) => state.Property);
  const [description, setDescription] = useState('');
  //for document
  const [docList, setDocList] = useState([]);

  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (
      values?.title &&
      description?.replace(/<(.|\n)*?>/g, '')?.trim()?.length === 0
    ) {
      toast.error('Please enter description');
      return;
    }
    values.validFrom = getCustomDateFormat(values?.validFrom, 'DD-MM-YYYY');
    values.validTill = getCustomDateFormat(values?.validTill, 'DD-MM-YYYY');
    values.actionDeadline = getCustomDateFormat(
      values?.actionDeadline,
      'DD-MM-YYYY'
    );
    values.description = description;
    values.documents = docList;
    const payload = {
      data: { ...values },
      navigateTo: { navigate, url: routeObj?.notices },
    };
    dispatch(CreateNoticeReducer(payload));
  };
  const handleCancelData = () => {
    navigate('/admin/notices');
  };
  return (
    <Form
      layout="vertical"
      size="large"
      onFinish={onFinish}
      initialValues={{
        remember: true,
      }}
      form={form}
      className="w-full"
    >
      <AddNew
        {...{ handleCancelData }}
        title="Add New Notice"
        saveButtonText="Add Notice"
      />
      <Row justify="space-between">
        <Col span={16} xs={{ span: 16 }} md={{ span: 16 }}>
          <Title level={5}>Building Details</Title>
          <Row justify="space-between">
            <div className="inputWidth">
              <InputField
                label="Title"
                name="title"
                placeholder="Title"
                required
              />
              <SelectField
                label="Notice Type"
                name="category"
                placeholder="Notice Type"
                required
                selectArr={noticeCategories}
              />
            </div>
            <div className="inputWidth">
              <TextEditor
                description={description}
                setDescription={setDescription}
                placeholder={'Write description....'}
                name="Description"
                width={'w-[42rem]'}
                height={'20rem'}
                required={true}
              />
            </div>
            <div className="inputWidth">
              <DateField
                label="Valid From"
                name="validFrom"
                placeholder="Valid From"
                isDisabledDate={true}
                disabledDate={disablePastDates}
                required
              />
              <DateField
                label="Valid Till"
                name="validTill"
                placeholder="Valid Till"
                isDisabledDate={true}
                disabledDate={disablePastDates}
                required
              />
            </div>
            <div className="inputWidth">
              <RadioField
                label="User Action required?"
                name="userAction"
                radioArr={yesOrNoArr}
                selectedName="name"
                selectedValue="name"
              />
              <DateField
                label="Deadline"
                name="actionDeadline"
                placeholder="Deadline"
                isDisabledDate={true}
                disabledDate={disablePastDates}
              />
            </div>
            <Title level={5}>Building Details</Title>
            <div className="inputWidth">
              <MultiSelect
                label="Select Building"
                placeholder="Building Name"
                name="buildings"
                required
                selectArr={buildingsName?.rows}
                selectedName="name_en"
                selectedValue="id"
              />
              <MultiSelect
                label="Notice for"
                name="targetUser"
                placeholder="Notice for"
                selectArr={targetUser}
              />
            </div>
          </Row>
        </Col>
        <Col span={7} xs={{ span: 7 }} md={{ span: 7 }}>
          <Title level={5} className="my-8">
            Attach Documents
          </Title>
          <DocumentUpload {...{ docList, setDocList }} />
        </Col>
      </Row>
    </Form>
  );
};

export default AddNotice;
