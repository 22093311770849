import { exportPropertyData } from 'Redux/services/ExportCsv/Export';
import Title from 'antd/es/typography/Title';
import { MixedHeadContent, TabComponent } from 'component/CompIndex';
import PropertyFilter from 'component/filter/PropertyFilter';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { propertyTabItem } from 'staticData/PropertyData';

const PropertyListing = () => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({
    propertyType: '',
    country: '',
    cityId: '',
    localityId: '',
  });
  const handleFilter = () => {
    toast('filter');
  };

  const handleExport = async () => {
    const params = {
      search,
      buildingType: filter.propertyType,
      country: filter.country,
      cityId: filter.cityId,
      localityId: filter.localityId,
      list: 'new',
    };
    return await exportPropertyData(params);
  };
  const items = propertyTabItem(search, filter);
  return (
    <div>
      <div className="relative">
        <Title level={3}>My Properties</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            AddNew="Add Property"
            route="/admin/myproperties/add"
            handleExport={handleExport}
            exportFileName="Property Data"
            filterComp=<PropertyFilter filter={filter} setFilter={setFilter} />
            {...{
              setSearch,
              handleFilter,
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'1'} />
      </div>
    </div>
  );
};

export default PropertyListing;
