import {
  CalendarOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { removeLoader, showLoader } from 'Redux/slices/BasicSlice';
import { Button, Dropdown, Input, Space } from 'antd';
import { exportImage, filterImage } from 'assets/AssetIndex';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const MixedHeadContent = ({
  handleFilter,
  handleExport,
  handleImport,
  setSearch,
  AddNew,
  route,
  routeState,
  handleOpen,
  filterComp,
  dateFilterComp,
  exportFileName,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParam] = useSearchParams();
  const [showFilterDropDown, setShowFilterDropDown] = useState(false);
  const [showDateDropDown, setShowDateDropDown] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvImportData, setCsvImportData] = useState(null);
  const searchInputRef = useRef(null);
  const exportDataRef = useRef(null);
  const handleChange = (e) => {
    setTimeout(() => {
      setSearch(e.target.value);
      setSearchParam({
        page: 1,
      });
    }, 1000);
  };
  const handleClick = () => {
    const state = routeState;
    navigate(route, { state });
  };
  const handleExportClick = async () => {
    dispatch(showLoader());
    const csvFile = await handleExport();
    if (!csvFile.length) {
      toast.error('No data found');
    }
    setCsvData(csvFile);
    dispatch(removeLoader());
    return csvFile;
  };

  const filterItems = [
    {
      label: (
        <div
          onClick={(e) => e.preventDefault()}
          className="max-h-[65vh] min-w-[10rem] overflow-y-auto"
        >
          {filterComp}
        </div>
      ),
      key: '1',
    },
  ];
  const DateItems = [
    {
      label: (
        <div
          onClick={(e) => e.preventDefault()}
          className="min-h-[13vh] min-w-[12rem]"
        >
          {dateFilterComp}
        </div>
      ),
      key: '1',
    },
  ];

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'm') {
        window.scrollTo(0, 0);
        if (searchInputRef.current) {
          searchInputRef.current.focus();
        }
      }
      if ((event.ctrlKey || event.metaKey) && event.key === 'x') {
        event.preventDefault();
        if (exportDataRef.current.link) {
          handleExportClick();
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    if (csvData?.length) {
      exportDataRef?.current?.link?.click();
    }
  }, [csvData]);

  useEffect(() => {
    if (csvImportData) {
      let formData = new FormData();
      formData.append('file', csvImportData);
      handleImport(formData);
      setCsvImportData(null);
    }
  }, [csvImportData]);

  return (
    <Space className="mt-0 flex h-10" size={24}>
      {setSearch && (
        <Input
          placeholder="Search"
          prefix={
            <SearchOutlined className="site-form-item-icon text-[#0c344e]" />
          }
          className="inputHover h-10 border"
          onChange={handleChange}
          ref={searchInputRef}
        />
      )}
      {route && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="customButton h-10 px-10"
          onClick={handleClick}
        >
          {AddNew}
        </Button>
      )}
      {handleOpen && (
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="customButton h-10 px-10"
          onClick={handleOpen}
        >
          {AddNew}
        </Button>
      )}
      {handleFilter && (
        <Dropdown
          open={showFilterDropDown}
          onOpenChange={() => setShowFilterDropDown(!showFilterDropDown)}
          menu={{
            items: filterItems,
          }}
          trigger={['click']}
          placement="bottom"
        >
          <img
            src={filterImage}
            alt="filterImage"
            className="h-8 w-8 cursor-pointer"
            fetchpriority="high"
          />
        </Dropdown>
      )}
      {dateFilterComp && (
        <Dropdown
          open={showDateDropDown}
          onOpenChange={() => setShowDateDropDown(!showDateDropDown)}
          menu={{
            items: DateItems,
          }}
          trigger={['click']}
          placement="bottom"
        >
          <div className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border-[1.3px] border-black text-xl opacity-80">
            <CalendarOutlined />
          </div>
        </Dropdown>
      )}
      {handleExport && (
        <div>
          <div onClick={handleExportClick}>
            <img
              src={exportImage}
              alt="exportImage"
              className="h-8 w-8 cursor-pointer"
              fetchpriority="high"
            />
          </div>
          <CSVLink
            data={csvData}
            className="mr-2"
            filename={exportFileName}
            target="_blank"
            ref={exportDataRef}
            style={{
              border: '2px solid black',
              padding: '10px',
              borderRadius: '5px',
              textDecoration: 'none',
              color: 'black',
              display: 'none',
            }}
          ></CSVLink>
        </div>
      )}
      {handleImport && (
        <div>
          <label htmlFor="importInput">
            <img
              src={exportImage}
              alt="exportImage"
              className="h-8 w-8 rotate-180 cursor-pointer"
              fetchpriority="high"
            />
          </label>
          <input
            type="file"
            id="importInput"
            accept=".csv"
            style={{ display: 'none' }}
            onChange={(e) => {
              setCsvImportData(e?.target?.files?.[0]);
            }}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
        </div>
      )}
    </Space>
  );
};

export default React.memo(MixedHeadContent);
