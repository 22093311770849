import { Checkbox, Collapse, DatePicker } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { priorityArr } from 'staticData/ServiceData';

const ServiceFilter = ({ allFilters, setAllFilters, categoryArr }) => {
  const [searchParams, setSearchParam] = useSearchParams();

  const handleFilterChange = (isChecked, name, value) => {
    let updatedValue = value;

    if (Array.isArray(value)) {
      updatedValue = value.join(',');
    }
    if (isChecked) {
      setAllFilters({ ...allFilters, [name]: updatedValue });
    } else {
      setAllFilters({ ...allFilters, [name]: '' });
    }
    setSearchParam({
      page: 1,
    });
  };

  return (
    <div className="flex w-[22rem] flex-col justify-start gap-2 p-2">
      <Collapse ghost expandIconPosition="end">
        <Collapse.Panel
          key={0}
          header={<h2 className="font-bold">Admin Category</h2>}
        >
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {categoryArr?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={
                    allFilters?.adminCategory === data?.id ? true : false
                  }
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(
                      e.target?.checked,
                      'adminCategory',
                      data?.id
                    )
                  }
                >
                  <label htmlFor={data}>{data?.name_en}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key={1}
          header={<h2 className="font-bold">User Category</h2>}
        >
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {categoryArr?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={allFilters?.userCategory === data?.id ? true : false}
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(
                      e.target?.checked,
                      'userCategory',
                      data?.id
                    )
                  }
                >
                  <label htmlFor={data}>{data?.name_en}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key={2}
          header={<h2 className="font-bold">Priority Type</h2>}
        >
          <div className="grid grid-cols-2 items-center justify-between gap-2">
            {priorityArr?.map((data, index) => (
              <div key={index} className="flex items-baseline">
                <Checkbox
                  id={data}
                  checked={
                    allFilters?.priorityType === data?.key ? true : false
                  }
                  style={{ margin: '0px' }}
                  onChange={(e) =>
                    handleFilterChange(
                      e.target?.checked,
                      'priorityType',
                      data?.key
                    )
                  }
                >
                  <label htmlFor={data}>{data?.name}</label>
                </Checkbox>
              </div>
            ))}
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key={3}
          header={<h2 className="font-bold">Service Request Date</h2>}
        >
          <div>
            <DatePicker
              placeholder="Request Date"
              onChange={(value) => handleFilterChange(true, 'date', value)}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ServiceFilter;
