import { axiosInstance } from "../AxiosClient"

export const getInventoryList = async (params) => {
    const resp = await axiosInstance.get(`/inventories`, { params });
    return resp;
}

export const getInventoryDropdown = async (params) => {
    const resp = await axiosInstance.get(`/inventories/list/all`, { params });
    return resp;
}

export const getInventoryDetails = async (productId) => {
    const resp = await axiosInstance.get(`inventories/${productId}`);
    return resp;
}

export const editInventoryDetails = async (id, values, navigateTo) => {
    const resp = await axiosInstance.patch(`inventories/${id}`, values, { navigateTo });
    return resp;
}

export const addInventory = async (data, navigateTo) => {
    const resp = await axiosInstance.post(`inventories`, data, { navigateTo });
    return resp;
}
export const deleteInventory = async (productId) => {
    const resp = await axiosInstance.delete(`inventories/${productId}`);
    return resp;
}