import { Form, Input, Select } from 'antd';
import { Countries } from 'layouts/LayoutIndex';
import React, { useState } from 'react';
import { defaultCountryCode } from 'utils/UtilsIndex';
import { parsePhoneNumber } from 'libphonenumber-js';
import countries from 'layouts/localData/CountryCode';

const MobileNumber = ({
  label1,
  countryCodeName,
  mobileNumberName,
  required,
  mobilePlaceHolder,
  width,
  paddingLeft,
  countryCode = defaultCountryCode,
}) => {
  // const [selectedCountryCode, setSelectedCountryCode] = useState('AE');

  // const validatePhoneNumber = (_, value) => {
  //   if (!value && !required) {
  //     return Promise.resolve();
  //   }

  //   if (!value) {
  //     return Promise.reject('This field is required.');
  //   }

  //   if (selectedCountryCode) {
  //     const phoneNumber = parsePhoneNumber(value, selectedCountryCode);
  //     if (phoneNumber.isValid()) {
  //       return Promise.resolve();
  //     } else {
  //       return Promise.reject('Enter a valid phone number.');
  //     }
  //   }
  // };

  // const handleCountryCodeChange = (value, option) => {
  //   const countryCodeName = countries.filter(
  //     (country) => country.phone === value
  //   );
  //   setSelectedCountryCode(countryCodeName?.code);
  // };

  return (
    <div style={{ paddingLeft }}>
      <Form.Item
        className="p-0"
        label={
          <div className="flex items-center gap-[2px]">
            <span className="text-[#FF9D9E]"> {required ? '*' : ''} </span>
            <span></span> {label1}
          </div>
        }
        rules={[
          {
            required: required ? true : false,
            message: 'This field is required.',
          },
        ]}
      >
        <div className="m-0 mt-[-1rem] flex h-8 w-full">
          <Form.Item name={countryCodeName}>
            <Select
              showSearch
              defaultValue={countryCode}
              bordered={false}
              filterOption={(inputValue, option) => {
                const isFindByValuePresent = option?.value
                  ?.toLowerCase()
                  ?.includes(inputValue?.replace('+', '')?.toLowerCase());
                const isFindByCountry = option?.country
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
                return isFindByValuePresent || isFindByCountry;
              }}
              // onChange={handleCountryCodeChange}
              style={{
                borderBottom: '1px solid #D4D4D4',
                borderRadius: '0',
                padding: '0 0 0px 0',
                width: width ? '6rem' : '6.5rem',
                height: '35px',
              }}
            >
              {Countries.map((country) => (
                <Select.Option
                  value={country?.phone}
                  key={country?.phone}
                  country={country?.label}
                >
                  <div className=" flex w-fit items-center justify-center">
                    <img
                      fetchpriority="high"
                      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    +{country?.phone}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="w-full">
            <Form.Item
              name={mobileNumberName}
              rules={[
                {
                  required: required ? true : false,
                  message: 'This field is required.',
                },
                {
                  pattern: '^[0-9-+/s]{8,}$',
                  min: 12 - countryCode?.replace('-', '')?.length,
                  max: 13 - countryCode?.replace('-', '')?.length,
                  message: `Please enter a valid mobile Number`,
                },
              ]}
            >
              <Input
                bordered={false}
                style={{
                  borderBottom: '1px solid #D4D4D4',
                  borderRadius: '0',
                  padding: '0',
                  marginTop: '10px',
                  width: `calc(${width} - 6rem)`,
                }}
                placeholder={mobilePlaceHolder}
              />
            </Form.Item>
          </div>
        </div>
      </Form.Item>
    </div>
  );
};

export default React.memo(MobileNumber);
