import {
  EditOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  getProfileDataReducer,
  removeTokenReducer,
} from 'Redux/slices/BasicSlice';
import {
  PropertyNameReducer,
  selectedBuildingNameReducer,
} from 'Redux/slices/property/PropertySlice';
import { Dropdown, Select, Space } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { clearAdminInfo } from 'utils/UtilsIndex';
import Notifications from './Notifications';

const TopNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParam] = useSearchParams();
  const { buildingsName } = useSelector((state) => state.Property);
  const { fcmToken } = useSelector((state) => state.Basic);
  const handleMenuClick = (e) => {
    if (e.key === '3') {
      const payload = {
        token: fcmToken,
      };
      dispatch(removeTokenReducer(payload));
      clearAdminInfo();
      navigate('/login');
    } else if (e.key === '1') {
      navigate('/admin/profile');
    } else if (e.key === '2') {
      navigate('/admin/setting');
    }
  };
  const items = [
    {
      label: 'Profile',
      key: '1',
      icon: <EditOutlined />,
    },
    {
      label: 'Settings',
      key: '2',
      icon: <SettingOutlined />,
    },
    {
      label: 'Log out',
      key: '3',
      icon: <LogoutOutlined />,
      danger: true,
    },
  ];
  const handleChange = (value) => {
    dispatch(selectedBuildingNameReducer(value));
    setSearchParam({
      page: 1,
    });
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    dispatch(PropertyNameReducer());
    dispatch(getProfileDataReducer());
  }, [dispatch]);

  return (
    <Space
      className="flex h-20 w-full justify-end space-x-1 p-4"
      style={{ boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)', minWidth: '60rem' }}
      direction="horizontal"
    >
      <Space wrap>
        <Select
          placeholder="Select Property"
          defaultOpen={false}
          bordered={true}
          style={{
            width: 192,
            textAlign: 'center',
            borderRadius: '6px',
          }}
          className="changeSelect"
          allowClear
          onChange={handleChange}
          showSearch
          filterOption={(input, options) => {
            return options?.buildingName
              ?.toLowerCase()
              ?.includes(input?.toLowerCase());
          }}
        >
          {buildingsName?.rows?.map((cur, index, key) => (
            <Select.Option
              buildingName={cur?.name_en}
              value={cur?.id}
              key={cur?.id}
            >
              {cur?.name_en}
            </Select.Option>
          ))}
        </Select>
      </Space>
      <Notifications />
      <Space>
        <Dropdown.Button
          menu={menuProps}
          destroyPopupOnHide={true}
          placement="bottom"
          border={'#0c344e'}
          icon={<UserOutlined />}
        >
          Admin
        </Dropdown.Button>
      </Space>
    </Space>
  );
};

export default React.memo(TopNav);
