import { deleteUserDataReducer } from 'Redux/slices/property/UserSlice';
import { DataTable, DeleteRequest, ModalComp } from 'component/CompIndex';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getCompanyAllData } from 'staticData/UserData';

const CompanyListingData = ({ search }) => {
  const {
    companyListingData: { rows, count },
  } = useSelector((state) => state.User);

  const dispatch = useDispatch();
  const [searchParams, setSearchParam] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [companyId, setCompanyId] = useState('');
  // modal functions
  const handleDeleteModalOpen = (id) => {
    setIsModalOpen(true);
    setCompanyId(id);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleDeleteCompany = () => {
    dispatch(deleteUserDataReducer(companyId));
    handleClose();
  };
  const { data, columns } = getCompanyAllData(rows, handleDeleteModalOpen);

  return (
    <div>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <DeleteRequest
            text={`Are you sure you want to delete?`}
            handleSave={handleDeleteCompany}
            handleClose={handleClose}
          />
        }
      />
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </div>
  );
};

export default CompanyListingData;
