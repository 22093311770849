import { exportUnitData } from 'Redux/services/ExportCsv/Export';
import {
  UnitListingReducer,
  deleteUnitDataReducer,
  importCsvDataReducer,
} from 'Redux/slices/property/UnitSlice';
import Title from 'antd/es/typography/Title';
import {
  DeleteRequest,
  MixedHeadContent,
  ModalComp,
  TabComponent,
} from 'component/CompIndex';
import UnitFilter from 'component/filter/UnitFilter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { unitTabItem } from 'staticData/PropertyData';
import { getUnitData } from 'staticData/UnitData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const UnitDataListing = () => {
  const {
    unitListingData: { rows, count },
    isLoadAgain,
  } = useSelector((state) => state.Unit);
  const { selectedBuildingName } = useSelector((state) => state.Property);
  const dispatch = useDispatch();
  const [unitId, setUnitId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [search, setSearch] = useState('');
  const [allFilters, setAllFilters] = useState({
    flatType: '',
    contractStatus: '',
    status: '',
    ownerIds: '',
    buildingId: '',
    furnishing: '',
    rentalType: '',
    flatIds: '',
  });
  const [searchParams, setSearchParam] = useSearchParams();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);

  const handleDeleteModalOpen = (id) => {
    setUnitId(id);
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setUnitId(null);
    setIsModalOpen(false);
  };
  const handleDeleteUnitData = () => {
    dispatch(deleteUnitDataReducer(unitId));
    handleClose();
  };

  const handleFilter = () => {
    toast('filter');
  };

  const handleExport = async () => {
    const params = {
      search,
      buildingId: selectedBuildingName || allFilters?.buildingId,
      contractStatus: allFilters?.contractStatus,
      flatType: allFilters?.flatType,
      status: allFilters?.status,
      ownerIds: allFilters?.ownerIds,
      furnishing: allFilters?.furnishing,
      rentalType: allFilters?.rentalType,
      flatIds: allFilters?.flatIds,
    };
    return await exportUnitData(params);
  };
  const handleImport = (data) => {
    const payload = {
      data,
      id: selectedBuildingName,
    };
    dispatch(importCsvDataReducer(payload));
  };
  //variables
  const { data, columns } = getUnitData(rows, handleDeleteModalOpen);
  const items = unitTabItem({
    columns,
    data,
    count,
    pageNo,
    setPageNo,
    searchParams,
    setSearchParam,
  });

  useEffect(() => {
    const params = {
      page: defaultPageNo,
      search,
      flatType: allFilters?.flatType,
      contractStatus: allFilters?.contractStatus,
      buildingId: selectedBuildingName || allFilters?.buildingId,
      status: allFilters?.status,
      ownerIds: allFilters?.ownerIds,
      furnishing: allFilters?.furnishing,
      rentalType: allFilters?.rentalType,
      flatIds: allFilters?.flatIds,
    };
    dispatch(UnitListingReducer(params));
  }, [
    defaultPageNo,
    search,
    selectedBuildingName,
    allFilters,
    isLoadAgain,
    dispatch,
  ]);

  return (
    <div>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <DeleteRequest
            text={`Are you sure you want to delete?`}
            handleSave={handleDeleteUnitData}
            handleClose={handleClose}
          />
        }
      />
      <div className="relative">
        <Title level={3}>My Properties</Title>
        <div className="absolute right-0 z-10">
          <MixedHeadContent
            AddNew={'Add Unit'}
            route="/admin/myproperties/unit/add"
            filterComp=<UnitFilter
              filter={allFilters}
              setFilter={setAllFilters}
            />
            handleImport={selectedBuildingName ? handleImport : null}
            {...{
              setSearch,
              handleFilter,
              handleExport,
              exportFileName: 'Unit Data',
            }}
          />
        </div>
      </div>
      <div>
        <TabComponent {...{ items }} activeTabCount={'2'} />
      </div>
    </div>
  );
};

export default UnitDataListing;
