import { DataTable } from 'component/CompIndex';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ReminderListingDataTable } from 'staticData/LeaseData';

const ReminderListingData = () => {
  const {
    reminderListingData: { rows = [], count },
  } = useSelector((state) => state.Lease);
  const [pageNo, setPageNo] = useState(1);
  const [searchParams, setSearchParam] = useSearchParams();
  const { data, columns } = ReminderListingDataTable(rows);
  return (
    <>
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </>
  );
};

export default ReminderListingData;
