import {
  BuildingAnalyticsDataReducer,
  PropertyListingReducer,
  deleteBuildingDataReducer,
} from 'Redux/slices/property/PropertySlice';
import { DataTable, DeleteRequest, ModalComp } from 'component/CompIndex';
import PropertyTopCard from 'component/PropertyTopCard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getPropertyData } from 'staticData/PropertyData';
import { getUrlPageNo } from 'utils/UtilsIndex';

const PropertyListingData = ({ search, filter }) => {
  // get  data
  const {
    propertyListingData: { rows, count },
    buildingAnalyticsData,
    isLoadAgain,
  } = useSelector((state) => state.Property);
  // hook
  const dispatch = useDispatch();
  const { search: searchUrl } = useLocation();
  const defaultPageNo = getUrlPageNo(searchUrl);
  const [searchParams, setSearchParam] = useSearchParams();
  const [propertyId, setPropertyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  // modal functions
  const handleDeleteModalOpen = (propertyId) => {
    setPropertyId(propertyId);
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setPropertyId(null);
    setIsModalOpen(false);
  };
  const handleDeleteProperty = () => {
    dispatch(deleteBuildingDataReducer(propertyId));
    handleClose();
  };
  //variables
  const { propertyTopData, data, columns } = getPropertyData(
    buildingAnalyticsData,
    rows,
    handleDeleteModalOpen
  );

  //useEffect
  useEffect(() => {
    const params = {
      page: defaultPageNo,
      search,
      buildingType: filter?.propertyType,
      country: filter?.country,
      cityId: filter?.cityId,
      localityId: filter?.localityId,
      list: 'new',
    };
    dispatch(PropertyListingReducer(params));
  }, [defaultPageNo, search, filter, isLoadAgain, dispatch]);

  useEffect(() => {
    if (!isLoadAgain) {
      dispatch(BuildingAnalyticsDataReducer());
    }
  }, [dispatch, isLoadAgain]);

  return (
    <div>
      <ModalComp
        {...{ isModalOpen }}
        component={
          <DeleteRequest
            text={`Are you sure you want to delete?`}
            handleSave={handleDeleteProperty}
            handleClose={handleClose}
          />
        }
      />
      <PropertyTopCard {...{ propertyTopData }} />
      <DataTable
        {...{
          columns,
          data,
          count,
          pageNo,
          setPageNo,
          searchParams,
          setSearchParam,
        }}
      />
    </div>
  );
};

export default PropertyListingData;
