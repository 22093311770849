import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  approveLoginRequest,
  checkUnreadNotifications,
  getAllNotification,
  getLoginRequestOverview,
  getProfileData,
  getRequestListing,
  readAllNotification,
  readNotification,
  removeFile,
  requestDeny,
  sendToken,
  uploadService,
} from 'Redux/services/BasicService';
import { deleteToken } from 'firebase/messaging';
import { toast } from 'react-toastify';
import { initialListingState, initialState } from './InitialState';

export const uploadReducer = createAsyncThunk('/upload', async (formData) => {
  try {
    const resp = await uploadService(formData);
    if (resp?.data?.status === 'success') {
      toast.success(resp?.data?.status);
    } else {
      toast.error(resp?.data?.msg);
    }
  } catch (error) {
    toast.error(error?.response?.data?.msg);
  }
});

// get listing page data
export const requestListingReducer = createAsyncThunk(
  '/requestListing',
  async (data) => {
    try {
      const resp = await getRequestListing(data);
      return resp?.data;
    } catch (error) { }
  }
);

export const ApproveLoginRequestReducer = createAsyncThunk(
  '/approveLoginRequest',
  async (values) => {
    try {
      const resp = await approveLoginRequest(values);
      return resp?.data;
    } catch (error) { }
  }
);

export const requestDenySlice = createAsyncThunk('requestDeny', async (id) => {
  try {
    const resp = await requestDeny(id);
    return resp?.data;
  } catch (error) { }
});

//getProfileData
export const getProfileDataReducer = createAsyncThunk(
  'getProfileData',
  async () => {
    try {
      const resp = await getProfileData();
      return resp?.data?.data;
    } catch (error) { }
  }
);

//send token
export const sendTokenReducer = createAsyncThunk(
  '/sendToken',
  async (payload) => {
    try {
      const resp = await sendToken(payload);
      return resp;
    } catch (error) { }
  }
);
//remove token
export const removeTokenReducer = createAsyncThunk(
  '/removeToken',
  async (payload) => {
    try {
      const resp = await deleteToken(payload);
      return resp;
    } catch (error) { }
  }
);
export const allNotificationReducer = createAsyncThunk(
  '/allNotification',
  async (params) => {
    try {
      const resp = await getAllNotification(params);
      return resp;
    } catch (error) { }
  }
);
export const readNotificationReducer = createAsyncThunk(
  'readNotification',
  async (payload) => {
    try {
      const resp = await readNotification(payload);
      return resp;
    } catch (error) { }
  }
);
export const checkUnreadNotificationReducer = createAsyncThunk(
  '/checkUnreadNotification',
  async (params) => {
    try {
      const resp = await checkUnreadNotifications(params);
      return resp;
    } catch (error) { }
  }
);

export const readAllNotificationReducer = createAsyncThunk(
  'readAllNotification',
  async (payload) => {
    try {
      const resp = await readAllNotification(payload);
      return resp;
    } catch (error) { }
  }
);

export const removeFileReducer = createAsyncThunk(
  'removeFile',
  async (payload) => {
    try {
      const resp = await removeFile(payload);
      return resp;
    } catch (error) { }
  }
)

export const RequestOverviewReducer = createAsyncThunk(
  'loginRequestOverview',
  async () => {
    const resp = await getLoginRequestOverview();
    return resp?.data;
  }
)
const BasicSlice = createSlice({
  name: 'basic',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoading = true;
    },
    removeLoader: (state) => {
      state.isLoading = false;
    },
    tokenReducer: (state, action) => {
      state.fcmToken = action.payload;
    },
  },

  extraReducers: {
    [uploadReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [uploadReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.uploadedData = action?.payload;
    },
    [uploadReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // requestListing
    [requestListingReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [requestListingReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.requestListingData = action?.payload?.data ?? initialListingState;
    },
    [requestListingReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //approve login request
    [ApproveLoginRequestReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isLoadAgain = true;
    },
    [ApproveLoginRequestReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [ApproveLoginRequestReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    [requestDenySlice.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [requestDenySlice.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [requestDenySlice.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // property Name
    [getProfileDataReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [getProfileDataReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.profileData = action?.payload;
    },
    [getProfileDataReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //sendToken
    [sendTokenReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [sendTokenReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [sendTokenReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    //removeToken
    [removeTokenReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [removeTokenReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [removeTokenReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // allNotification
    [allNotificationReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [allNotificationReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = false;
      state.isNewNotification = action?.payload?.data?.data?.filter((data) => data?.isRead == false)?.length ? true : false
      state.allNotificationData = action?.payload?.data?.data;
    },
    [allNotificationReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // readNotification
    [readNotificationReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [readNotificationReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
    },
    [readNotificationReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // checkUnreadNotification
    [checkUnreadNotificationReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [checkUnreadNotificationReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
      state.checkUnreadNotificationData = action?.payload?.data;
    },
    [checkUnreadNotificationReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // readAllNotification
    [readAllNotificationReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [readAllNotificationReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isLoadAgain = true;
      state.isNewNotification = false;
    },
    [readAllNotificationReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // removeFile
    [removeFileReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [removeFileReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [removeFileReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
    // login request overview
    [RequestOverviewReducer.pending]: (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
    },
    [RequestOverviewReducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.buildingAnalyticsData = action?.payload?.data;
    },
    [RequestOverviewReducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});
export const { showLoader, removeLoader, tokenReducer } = BasicSlice.actions;
export default BasicSlice.reducer;
