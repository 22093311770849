import { Table } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getUrlPageNo } from 'utils/UtilsIndex';

const DataTable = ({
  columns,
  data = [],
  rowSelection,
  count,
  pageNo,
  setPageNo,
  setSearchParam,
  bordered,
  components,
  rowClassName,
  onRow,
}) => {
  const { search } = useLocation();
  const defaultPage = getUrlPageNo(search);
  const defaultPageNo = defaultPage ? defaultPage : 1;
  const getTotalPageCount =
    count > defaultPageNo * 10 ? defaultPageNo * 10 : count;
  return (
    <div>
      <Table
        columns={columns}
        rowSelection={rowSelection ?? null}
        components={components ?? null}
        rowClassName={rowClassName ?? ''}
        onRow={onRow ?? null}
        // width={}
        pagination={
          count && pageNo
            ? {
                current: +defaultPageNo,
                bordered: bordered ? true : false,
                // current: +defaultPageNo > 1 ? +defaultPageNo : pageNo,
                pageSize: 10,
                total: count,
                position: ['none', 'bottomRight'],
                onChange: (page) => {
                  setPageNo(page);
                  setSearchParam({ page: page });
                },
              }
            : false
        }
        dataSource={data}
        style={{ textAlign: 'center', marginTop: '2rem' }}
      />
      <p className="mt-[-3rem]">
        Showing {defaultPageNo * 10 - 9}-{getTotalPageCount} of {count}
      </p>
    </div>
  );
};

export default DataTable;
