import { Button, Form } from 'antd';
import AddImage from 'pages/helpline/AddImage';
import React, { useEffect, useState } from 'react';
import addImage from '../../assets/addImage.png';
import { InputField } from 'component/CompIndex';
import Title from 'antd/es/typography/Title';

const NewCategory = ({
  categoryDetails,
  handleSave,
  handleClose,
}) => {
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [imageData, setImageData] = useState('');
  const [form] = Form.useForm();
  var initialObj = {
    name_en: categoryDetails?.name || '',
    image: categoryDetails?.image,
  };
  const handleAddImage = (image) => {
    setImageData(image);
  };

  const onFinish = (values) => {
    values.image = imageData;
    handleSave(values);
    resetFormValues();
  };

  const resetFormValues = () => {
    setImageData('');
    form.resetFields();
    handleClose();
  };

  useEffect(() => {
    setImageData(categoryDetails?.image);
    form.setFieldsValue(initialObj);
  }, [form, categoryDetails]);
  return (
    <>
      {!isAddImageModalOpen ? (
        <Form
          layout="vertical"
          size="large"
          onFinish={onFinish}
          form={form}
          className="w-full"
        >
          <Title level={4} className="w-full rounded bg-[#F6F6F6] p-4">
            {categoryDetails ? 'Edit' : 'Add'} Category
          </Title>
          <div className="p-4">
            <div className="flex">
              <div
                className="flex h-[10rem] w-[10rem] items-center justify-center rounded-full bg-[#F6F6F6]"
                onClick={() => setIsAddImageModalOpen(true)}
              >
                {imageData || categoryDetails?.image ? (
                  <img
                    src={imageData || categoryDetails?.image}
                    alt="helpLine"
                    className="h-full w-full rounded-full"
                  />
                ) : (
                  <img src={addImage} alt="helpLine"/>
                )}
              </div>
              <div className="ml-10 flex-1">
                <InputField
                  label="Category Name"
                  name="name_en"
                  placeholder="Category Name"
                  width="100%"
                  required
                />
              </div>
            </div>
            {/* onClick={onFinish} */}
            <div className="center mt-4">
              <Button className="w-40" onClick={resetFormValues}>
                Cancel
              </Button>
              <Button className="customButton ml-4 w-40" htmlType="submit">
                Add Category
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <AddImage
          setIsModalOpen={setIsAddImageModalOpen}
          handleAddImage={handleAddImage}
        />
      )}
    </>
  );
};

export default NewCategory;
