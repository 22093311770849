import { Chip } from "component/CompIndex";
import { Link } from "react-router-dom";
import { getFirstPropertyAndCount } from "utils/UtilsIndex";

export const unitField = [
  'Box',
  'Centimeter',
  'day',
  'Feet',
  'Hour',
  'Kilogram',
  'Liter',
  'Lump Sum',
  'Meter',
  'Pack',
  'Roll',
  'Unit',
  'Yard',
];
export const activeDrop = ['Active', 'InActive'];
export const catalogTypeArr = ['Inventory', 'Non Inventory']

export const inventoryTableData = (inventoryListingData, handleDelete) => {
  return {
    data: inventoryListingData?.length > 0
      ? inventoryListingData?.map((cur) => {
        return {
          key: cur?.id,
          name: cur?.name_en,
          buildings: getFirstPropertyAndCount(cur?.inventoryBuildings),
          status: cur?.status,
          inventoryType: cur?.inventoryType,
          totalQuantity: cur?.totalQuantity,
          usedQuantity: cur?.totalQuantity - cur?.availableQuantity,
          availableQuantity: cur?.availableQuantity,
          rate: cur?.rate,
          locality: cur?.locality?.name_en,
          city: cur?.city?.name_en,
          type: cur?.buildingType,
          totalUnits: cur?.totalFlats,
          totalVacant: cur?.totalVacantFlats,
        };
      })
      : [],
    columns: [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, key) => (
          <Link to={`/admin/myproperties/Catalog/detail/${key?.key}`}>
            {text}
          </Link>
        ),
      },
      {
        title: 'Buildings',
        dataIndex: 'buildings',
        key: 'buildings',
      },
      {
        title: 'Product Type',
        dataIndex: 'inventoryType',
        key: 'inventoryType',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (_, { status }) => {
          return (
            // <Switch
            //   onChange={() => {
            //     handleToggler(key?.key);
            //   }}
            //   defaultChecked={status === 'Active' ? true : false}
            //   className="bg-[gray]"
            // />
            <Chip name={status} color={status === 'Active' ? 'green' : 'red'} />
          );
        },
      },
      {
        title: 'Rate (per/unit)',
        dataIndex: 'rate',
        key: 'rate',
      },
      {
        title: 'Total Quantity',
        dataIndex: 'totalQuantity',
        key: 'totalQuantity',
      },
      {
        title: 'Used Quantity',
        dataIndex: 'usedQuantity',
        key: 'usedQuantity',
      },
      {
        title: 'Left Quantity',
        dataIndex: 'availableQuantity',
        key: 'availableQuantity',
      },
      // {
      //   title: 'Action',
      //   dataIndex: 'action',
      //   render: (_, { key }) => {
      //     return <Action data={key} handleDelete={handleDelete} />;
      //   },
      // },
    ]
  }
}
